import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MailAccountGetByOrganizationIdContract } from 'src/app/types/api/mail-account';
import { DispatchingRule } from '@app-types/api/Dispatching';
import { MailFolderContract } from '@app-types/api/mail-folder';
import { Group } from '@app-types/api/group';
import { CheckboxItem } from '@app-types/checkbox.item';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MoveToFolderActionComponent } from './move-to-folder-action/move-to-folder-action.component';
import { ForwardActionComponent } from './forward-action/forward-action.component';
import { ReassignActionComponent } from './reassign-action/reassign-action.component';
import { AssignGroupsActionComponent } from './assign-to-group-action/assign-to-group-action.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-dispatching-actions',
  templateUrl: './dispatching-actions.component.html',
  styleUrls: ['./dispatching-actions.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MoveToFolderActionComponent,
    ForwardActionComponent,
    ReassignActionComponent,
    AssignGroupsActionComponent,
    MatTooltipModule,
  ],
})
export class DispatchingActionsComponent {
  @Input() dispatchingRule: DispatchingRule;
  @Input() availableActions: string[];
  @Input() existingActionTypes: string[];
  @Input() mailAccounts: MailAccountGetByOrganizationIdContract[];
  @Input() availableLocalFolders: MailFolderContract[];
  @Input() checkBoxGroups: CheckboxItem<Group>[] = [];
  @Input() isShowAddIcon: boolean;
  @Input() differentActionsIsValid: boolean;
  @Output() deleteSubmitEventEmitter = new EventEmitter<number>();
  @Output() createSubmitEventEmitter = new EventEmitter();
  @Output() changeSubmitEventEmitter = new EventEmitter();

  constructor() {}

  public onDelete(index: number): void {
    this.deleteSubmitEventEmitter.emit(index);
  }

  public get hasActions(): boolean {
    return !!this.dispatchingRule?.dispatchingRule?.actions?.length;
  }

  public filteredAvailableActions(actionName: string): boolean {
    const actions = this.availableActions.filter(
      e =>
        !this.dispatchingRule.dispatchingRule?.actions.some(x => x.name === e)
    );
    return !actions.some(e => e === actionName);
  }

  public onCreate(): void {
    this.createSubmitEventEmitter.emit();
  }

  public onActionTypeChange(actionName: string, index: number): void {
    this.changeSubmitEventEmitter.emit();
    this.dispatchingRule.dispatchingRule?.actions[index] &&
      (this.dispatchingRule.dispatchingRule.actions[index].args = []);
  }
}
