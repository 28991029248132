import { Component, Input } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-skeleton-card-list',
  standalone: true,
  imports: [NgxSkeletonLoaderModule],
  templateUrl: './skeleton-card-list.component.html',
  styleUrls: ['./skeleton-card-list.component.scss'],
})
export class SkeletonCardListComponent {
  @Input() hasTitle = false;
  @Input() hasHeader = true;
  @Input() height = '81px';
  fields = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  constructor() {}
}
