<div class="w-screen h-screen p-5 flex flex-wrap content-center justify-center text-center">
  <div class="flex flex-col items-center gap-[1rem]">
    @if(authError) {
      <mat-error class="font-bold">
        Authentication is not available. Please retry in 1 minute
      </mat-error>
    }
    <img class="w-[200px]" src="../../../assets/logo.svg" alt="Logo" />
    @if(links.length) {
      <div class="menu">
        <ul>
          @for(link of links; track link) {
            <li>
              <a mat-button href="{{ currentUrl + link.webCode }}"> {{ link.name }} </a>
            </li>
          }
        </ul>
      </div>
    }
  </div>
</div>
