<div class="drawer-content-screen">
  @if(!loadingAvailableData && !cannotLoadAvailableData && isUploaded) {
    <div class="drawer-content-screen__title">
      {{ isCreation ? ("createNewDispatchingRule" | translate) : ("editDispatchingRule" | translate) }}
    </div>
  }
  @if(cannotLoadAvailableData) {
    <div class="flex-col-center">
      <app-something-went-wrong
        [title]="errorText | translate"
        [showPicture]="false"
        (retryEmitter)="loadAvailableData()"></app-something-went-wrong>
    </div>
  }

  @if(loadingAvailableData) {
    <div class="h-full">
      <app-skeleton-form-drawer></app-skeleton-form-drawer>
    </div>
  }
 @if(!loadingAvailableData && !cannotLoadAvailableData && isUploaded) {
  <div class="drawer-content-screen__body">
    <form class="flex flex-col gap-4 lg:gap-8 h-full" [formGroup]="form" autocomplete="off" novalidate>
      <div class="flex-col-container-1 w-full">
        <div class="w-full flex gap-3 flex-wrap md:flex-nowrap">
          <div class="w-full md:w-1/2">
            <mat-form-field >
              <mat-label>{{ "name" | translate }}</mat-label>
              <input
                matInput
                id="name"
                formControlName="name"
                [(ngModel)]="dispatchingRule.dispatchingRule!.name"
                required
              />
              @if(hasError('name', 'required')) {
                <mat-error>{{ "nameIsRequired" | translate }}</mat-error>
              }
              @if(hasError('name', 'maxlength')) {
                <mat-error>{{ "moreThan256Char" | translate }}</mat-error>
              }
            </mat-form-field>
          </div>
          <div class="w-full md:w-1/2">
            <mat-form-field >
              <mat-label>{{ "priority" | translate }}</mat-label>
              <input
                type="number"
                matInput
                id="priority"
                formControlName="priority"
                min="1"
                max="10000"
                [(ngModel)]="dispatchingRule.dispatchingRule!.priority"
                required />
              @if(hasError('priority', 'required')) {
                <mat-error>{{ "priorityIsRequired" | translate }}</mat-error>
              }
              @if(hasError('priority', 'min')) {
                <mat-error>{{ "priorityMustMore0" | translate }}</mat-error>
              }
              @if(hasError('priority', 'max')) {
                <mat-error>{{ "priorityMustLessOrEqual100" | translate }}</mat-error>
              }
              @if(hasError('priority', 'pattern')) {
                <mat-error>{{ "mustBeInt" | translate }}</mat-error>
              }
            </mat-form-field>
          </div>
        </div>
        <mat-checkbox
          color="primary"
          (change)="onChangeContinueNextRules($event)"
          [(ngModel)]="dispatchingRule.dispatchingRule?.options!.continueNextRulesExecutionOnMatch"
          [ngModelOptions]="{standalone: true}"
        >
          <span>
            {{ "continueNextRulesExecutionOnMatch" | translate }}
          </span>
        </mat-checkbox>
      </div>
      <app-dispatching-folders
        [isShowError]="isShowError"
        [checkBoxFolders]="checkBoxFolders"
        [dispatchingRule]="dispatchingRule"
      ></app-dispatching-folders>
      <app-dispatching-conditions
        [applyToEmails]="applyToEmails"
        [applyToNotifications]="applyToNotifications"
        [emailConditions]="dispatchingRule.dispatchingRule?.matchExpression?.conditions ?? []"
        (changeEmailClassesEventEmitter)="onChangeEmailClasses($event)">
      </app-dispatching-conditions>
      <app-dispatching-actions
        [isShowAddIcon]="this.existingActionTypes.length !== 0"
        [dispatchingRule]="dispatchingRule"
        [availableActions]="availableActions"
        [mailAccounts]="accounts"
        [differentActionsIsValid]="differentActionsIsValid()"
        [availableLocalFolders]="availableLocalFolders"
        [checkBoxGroups]="checkBoxGroups"
        [existingActionTypes]="existingActionTypes"
        (createSubmitEventEmitter)="addAction()"
        (changeSubmitEventEmitter)="existingActionTypesUpdate()"
        (deleteSubmitEventEmitter)="deleteAction($event)">
      </app-dispatching-actions>
      <div>
        @if(!selectedAtLeastOneAction()) {
          <app-error-hint
            [centerAlign]="false"
            errorText="{{ 'addAtLeastOneActionErrorHint' | translate }}"
          ></app-error-hint>
        }
        @if(!differentActionsIsValid()) {
          <app-error-hint
            [centerAlign]="false"
            errorText="{{ 'addTwoDifferentActionsErrorHint' | translate }}"
          ></app-error-hint>
        }
      </div>
      @if(isShowError) {
        <div class="flex-col-container-1">
          @if(hasError('name', 'required')) {
            <app-error-hint
              [centerAlign]="false"
              errorText="{{ 'nameIsRequired' | translate }}"
            ></app-error-hint>
          }
          @if(hasError('name', 'maxlength')) {
            <app-error-hint
              [centerAlign]="false"
              errorText="{{ 'moreThan256Char' | translate }}"
            ></app-error-hint>
          }
          @if(hasError('priority', 'required')) {
            <app-error-hint
              [centerAlign]="false"
              errorText="{{ 'priorityIsRequired' | translate }}"
            ></app-error-hint>
          }
          @if(hasError('priority', 'min')) {
            <app-error-hint
              [centerAlign]="false"
              errorText="{{ 'priorityMustMore0' | translate }}"
            ></app-error-hint>
          }
          @if(hasError('priority', 'max')) {
            <app-error-hint
              [centerAlign]="false"
              errorText="{{ 'priorityMustLessOrEqual100' | translate }}"
            ></app-error-hint>
          }
          @if(hasError('priority', 'pattern')) {
            <app-error-hint
              [centerAlign]="false"
              errorText="{{ 'mustBeInt' | translate }}"
            ></app-error-hint>
          }
          @if(!selectedAtLeastOneFolder()) {
            <app-error-hint
              [centerAlign]="false"
              errorText="{{ 'selectAtLeastOneFolder' | translate }}"
            ></app-error-hint>
          }
        </div>
      }
    </form>
  </div>
  <div class="flex justify-end w-full">
      <button (click)="submit()" mat-flat-button color="primary" [disabled]="isLoading">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
      </button>
  </div>
  }
</div>
