import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MailFolderContract } from '@app-types/api/mail-folder';
import { MailFolderService } from '@app-services/mail-folder/mail.folder.service';
import { EmailClient } from '@app-services/api/clients/email-client';
import { MailFolderType } from '@app-types/enums/mail-folder.type';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SomethingWentWrongComponent } from '../../common/error/something-went-wrong/something-went-wrong.component';
import { ErrorHintComponent } from '../../common/error-hint/error-hint.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { LoadingButtonComponent } from '../../common/loading-button/loading-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-mail-folder-select-dialog',
  templateUrl: './mail-folder-select-dialog.component.html',
  styleUrls: ['./mail-folder-select-dialog.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    SomethingWentWrongComponent,
    ErrorHintComponent,
    MatFormFieldModule,
    MatSelectModule,
    LoadingButtonComponent,
    TranslateModule,
    NgxSkeletonLoaderModule,
  ],
})
export class MailFolderSelectDialogComponent implements OnInit {
  public isLoading = true;
  public cannotLoad = false;
  public moveInProgress = false;
  public cannotSave = false;
  public folders: MailFolderContract[] = [];
  public selectedFolderId: number;
  constructor(
    public matchError: MatchError,
    private mailFolderService: MailFolderService,
    private emailClient: EmailClient,
    public dialogRef: MatDialogRef<MailFolderSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      mailAccountId: number;
      ids: number[];
      isRestore: boolean;
      title: string;
      folderType: number;
      isArchive: boolean;
      sourceFoldersType: number;
      isTrash: boolean;
    }
  ) {}

  async ngOnInit(): Promise<any> {
    await this.loadFolders();
  }

  async loadFolders(): Promise<void> {
    this.isLoading = true;
    this.cannotLoad = false;
    try {
      const response = await this.mailFolderService.getFoldersForAccountFlat(
        this.data.mailAccountId
      );

      if (this.data.isArchive === true) {
        this.folders = response.filter(
          e => e.folderType === this.data.sourceFoldersType
        );
      } else if (this.data.isTrash === true) {
        this.folders = response.filter(
          e => e.folderType === this.data.sourceFoldersType
        );
      } else if (this.data.folderType === MailFolderType.Spam) {
        this.folders = response.filter(
          e => e.folderType === MailFolderType.Inbox
        );
      } else {
        this.folders = response.filter(
          e => e.folderType === this.data.folderType
        );
      }

      if (this.folders.length > 0) {
        this.selectedFolderId = this.folders[0]?.mailFolderId;
      }
    } catch (e) {
      this.cannotLoad = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  async confirm(): Promise<void> {
    if (!this.data.isRestore) {
      this.moveInProgress = true;
      this.cannotSave = false;
      try {
        await this.emailClient.move({
          emailIds: this.data.ids,
          mailFolderId: this.selectedFolderId,
        });
        this.dialogRef.close({ confirmed: true });
      } catch (e) {
        this.cannotSave = true;
        this.matchError.logError(e);
      } finally {
        this.moveInProgress = false;
      }
    }
    if (this.data.isRestore) {
      this.dialogRef.close({
        confirmed: true,
        folderId: this.selectedFolderId,
      });
    }
  }

  cancel(): void {
    this.dialogRef.close({ confirmed: false });
  }
}
