import { Component, Input } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { getFileExtension } from 'src/app/shared/utils/file-helper';
import { BaseGetByEmailFileIdRequest } from '@app-types/base/base';
import { MatchError } from '@app-services/errors/error-matcher';
import { FileClient } from '@app-services/api/clients/file.client';
import { EmailContract } from '@app-types/api/email';
import { Attachment } from '@app-types/api/Attachment';

@Component({
  selector: 'app-email-attachments',
  standalone: true,
  imports: [MatProgressSpinnerModule],
  templateUrl: './email-attachments.component.html',
  styleUrls: ['./email-attachments.component.scss'],
})
export class EmailAttachmentsComponent {
  @Input() public emailId: number | null;
  @Input() public emailDetails: EmailContract;
  public getFileExtension = getFileExtension;
  public downloadingFileIds: number[] = [];

  constructor(
    public matchError: MatchError,
    private fileClient: FileClient
  ) {}

  async getAttachmentFile(file: Attachment): Promise<void> {
    if (!file?.fileId || !this.emailId) return;
    try {
      this.downloadingFileIds.push(file.fileId);
      const request = new BaseGetByEmailFileIdRequest(
        file.fileId,
        this.emailId
      );

      await this.fileClient.download(request);
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.downloadingFileIds = this.downloadingFileIds.filter(
        e => e !== file.fileId
      );
    }
  }

  isFileDownloading(fileId?: number): boolean {
    if (!fileId) {
      return false;
    }
    return this.downloadingFileIds.includes(fileId);
  }
}
