import { Component, Input, OnInit } from '@angular/core';
import { DispatchingAction } from '@app-types/api/Dispatching';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MailAccountGetByOrganizationIdContract } from 'src/app/types/api/mail-account';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-reassign-action',
  templateUrl: './reassign-action.component.html',
  styleUrls: ['./reassign-action.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
  ],
})
export class ReassignActionComponent implements OnInit {
  @Input() dispatchingAction: DispatchingAction;
  @Input() mailAccounts: MailAccountGetByOrganizationIdContract[];
  public form: UntypedFormGroup;
  selected = {
    name: '',
    value: '',
  };
  constructor() {}

  ngOnInit(): void {
    this.setMailAccountValue(
      this.dispatchingAction.args?.[0]?.value || this.mailAccounts[0].email
    );
    this.form = new UntypedFormGroup({
      mailAccount: new UntypedFormControl(
        this.dispatchingAction.args?.[0].value
      ),
    });
  }

  setMailAccountValue(email: string): void {
    this.dispatchingAction.args = [];
    const newData = {
      name: 'MailAccountToReassign',
      value: email,
    };
    this.selected = newData;
    this.dispatchingAction.args[0] = newData;
  }
}
