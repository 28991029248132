import { Injectable } from '@angular/core';
import { environment } from '@app-environments/environment';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import { ApiRequest } from '../api.request';
import {
  OrganizationContract,
  OrganizationCreateRequest,
  OrganizationCreateResponse,
  OrganizationGetByIdResponse,
} from '@app-types/api/organization';
import {
  BaseCollectionSearchRequest,
  BaseGetByIdRequest,
  BaseGetCollectionResponse,
  BaseGetSearchCountRequest,
  BaseId,
  BaseResponse,
} from '@app-types/base/base';

const API_URL = environment.apiUrl;
const { organization } = API_ROUTES;
const {
  search,
  get,
  getSearchCount,
  create,
  getAvailableParentOrganizations,
  update,
} = organization;

@Injectable({ providedIn: 'root' })
export class OrganizationClient {
  constructor(private apiRequest: ApiRequest) {}

  async search(
    request: BaseCollectionSearchRequest
  ): Promise<BaseGetCollectionResponse<OrganizationContract>> {
    return this.apiRequest.get(`${API_URL}${search}?${request.format()}`);
  }

  async getSearchCount(
    request: BaseGetSearchCountRequest
  ): Promise<BaseResponse<number>> {
    return this.apiRequest.get(
      `${API_URL}${getSearchCount}?${request.format()}`
    );
  }

  async create(
    request: OrganizationCreateRequest
  ): Promise<OrganizationCreateResponse> {
    return this.apiRequest.post<
      OrganizationCreateRequest,
      OrganizationCreateResponse
    >(request, `${API_URL}${create}`);
  }

  async update(request: OrganizationCreateRequest): Promise<BaseId> {
    return this.apiRequest.post<OrganizationCreateRequest, BaseId>(
      request,
      `${API_URL}${update}`
    );
  }

  async delete(request: BaseId): Promise<BaseId> {
    return this.apiRequest.post<BaseId, BaseId>(
      request,
      `${API_URL}${organization.delete}`
    );
  }

  async getById(
    request: BaseGetByIdRequest
  ): Promise<OrganizationGetByIdResponse> {
    return this.apiRequest.get(`${API_URL}${get}?${request.format()}`);
  }

  async getAvailableParentOrganizations(): Promise<
    BaseGetCollectionResponse<OrganizationContract>
  > {
    return this.apiRequest.get(`${API_URL}${getAvailableParentOrganizations}`);
  }
}
