import { Injectable } from '@angular/core';
import { MailAccountClient } from '../api/clients/mail-account.client';
import { MailAccountGetByOrganizationIdContract } from '@app-types/api/mail-account';
import {
  BaseCollectionSearchByIdRequest,
  BaseGetByIdRequest,
} from '@app-types/base/base';

@Injectable({
  providedIn: 'root',
})
export class MailAccountService {
  constructor(private mailAccountClient: MailAccountClient) {}

  async getAccountsForAccountOrganization(
    mailAccountId: number
  ): Promise<MailAccountGetByOrganizationIdContract[]> {
    const accountResponse = await this.mailAccountClient.getById(
      new BaseGetByIdRequest(mailAccountId)
    );
    if (
      accountResponse &&
      accountResponse.mailAccount &&
      accountResponse.mailAccount.organizationId
    ) {
      const response = await this.mailAccountClient.getForReassign(
        new BaseCollectionSearchByIdRequest(
          accountResponse.mailAccount.organizationId,
          1,
          2147483647,
          null
        )
      );
      return response.data.filter(value => {
        return value.mailAccountId !== mailAccountId;
      });
    }
    return [];
  }
}
