import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { UserMailAccountContract } from '@app-types/api/user-mail-account';
import { ActivatedRoute } from '@angular/router';
import { PluginsClient } from '@app-services/api/clients/plugins.client';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '@app-components/common/error-dialog/error-dialog.component';
import { UserMailAccountClient } from '@app-services/api/clients/user-mail-account.client';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { getFileExtension } from '@app-utils/file-helper';
import {
  FerreroAttachmentContract,
  FerreroWebCodes,
} from 'src/app/types/api/ferrero-webcodes';
import { ConfirmDialogComponent } from '@app-components/common/confirm-dialog/confirm-dialog.component';
import { SomethingWentWrongComponent } from '@app-components/common/error/something-went-wrong/something-went-wrong.component';
import { LoadingButtonComponent } from '@app-components/common/loading-button/loading-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { EmailBodyComponent } from '@app-components/common/email/email-body/email-body.component';
import { WEB_CODE } from '@app-shared/constants/constants';
import { UserWorkspaceService } from '@app-services/user-workspace-service';

@Component({
  selector: 'app-plugins',
  templateUrl: './plugins.component.html',
  styleUrls: ['./plugins.component.scss'],
  standalone: true,
  imports: [
    SomethingWentWrongComponent,
    LoadingButtonComponent,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    EmailBodyComponent,
  ],
})
export class PluginsComponent implements OnInit {
  public form: UntypedFormGroup = new UntypedFormGroup({});
  @ViewChild('select') select: MatSelect;
  @ViewChild('uploader') uploader: ElementRef;
  public isSendingRequest = false;
  public accounts: UserMailAccountContract[] = [];
  public webCode: string;
  public getFileExtension = getFileExtension;
  public attachmentFiles: FerreroAttachmentContract[] = [];
  public bodyText = '';

  constructor(
    private route: ActivatedRoute,
    private userMailAccountClient: UserMailAccountClient,
    private pluginsClient: PluginsClient,
    private matchError: MatchError,
    public dialog: MatDialog,
    public userWorkspaceService: UserWorkspaceService
  ) {}

  async ngOnInit(): Promise<void> {
    const ferreroWebCodes = new FerreroWebCodes();
    console.log(ferreroWebCodes);
    this.webCode = this.route.parent?.snapshot.paramMap.get(WEB_CODE) ?? '';
    if (!ferreroWebCodes.codes.includes(this.webCode)) {
      window.location.href = `/${this.webCode}/404`;
    }
    this.form = new UntypedFormGroup({
      mailsControl: new UntypedFormControl('', Validators.required),
      subject: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(2048),
      ]),
    });
    this.accounts = this.userWorkspaceService.mailAccounts;
  }

  addAttachmentFileFromButton(event: any): void {
    this.addAttachmentFile(event.target.files);
    this.uploader.nativeElement.value = '';
  }

  public openConfirmDialog(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      autoFocus: false,
      data: { name: 'reset' },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.clearForm();
      }
    });
  }

  public deleteAttachment(file: FerreroAttachmentContract): void {
    const index = this.attachmentFiles.indexOf(file);
    this.attachmentFiles.splice(index, 1);
  }

  openSuccessDialog(title: string, description: string): void {
    this.dialog.open(ErrorDialogComponent, {
      width: '300px',
      data: { title, description },
      autoFocus: false,
    });
  }

  addAttachmentFile(files: File[]): void {
    for (const selectedFile of files) {
      const uploadFile = {
        fileName: selectedFile.name,
        mimeType: selectedFile.type,
        fileContent: '',
      };

      !uploadFile.mimeType &&
        (uploadFile.mimeType = 'application/octet-stream');

      if (this.attachmentFiles.length) {
        const existAttachment = this.attachmentFiles.filter(
          x => x.fileName === selectedFile.name
        );
        if (existAttachment.length === 0) {
          const newAttachments = this.attachmentFiles.filter(
            x => x.fileName !== selectedFile.name
          );
          newAttachments.push(uploadFile);
          this.attachmentFiles = newAttachments;
        }
      } else {
        const newAttachments = this.attachmentFiles.filter(
          x => x.fileName !== selectedFile.name
        );
        newAttachments.push(uploadFile);
        this.attachmentFiles = newAttachments;
      }

      const notUploadFiles = this.attachmentFiles.filter(
        x => x.fileName === selectedFile.name
      );
      if (notUploadFiles.length) {
        const reader = new FileReader();
        reader.onload = () => {
          const fileContent = (reader.result as string).split(',')[1];
          this.attachmentFiles = this.attachmentFiles.map(e =>
            e.fileName === selectedFile.name ? { ...e, fileContent } : e
          );
        };

        reader.readAsDataURL(selectedFile);
      }
    }
  }

  clearForm(): void {
    this.form.get('subject')?.setValue('');
    this.form.get('mailsControl')?.setValue('');
    this.attachmentFiles = [];
    this.bodyText = '';
  }

  valueChangedEventEmitter(event: string): void {
    this.bodyText = event;
  }

  public isBodyValid(): boolean {
    return !!this.bodyText;
  }

  public async onSubmit(): Promise<void> {
    try {
      this.isSendingRequest = true;
      const values = this.form.value;
      const request = {
        from: values.mailsControl,
        subject: values.subject,
        body: this.bodyText,
        attachments: this.attachmentFiles,
      };

      await this.pluginsClient.create(request);
      this.openSuccessDialog('information', `theMessageWasSuccessfullySent`);
      this.clearForm();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isSendingRequest = false;
    }
  }
}
