import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationType } from '@app-types/enums/authentication-type';
import { MatchError } from '@app-services/errors/error-matcher';
import { ErrorCode } from '@app-types/enums/error-code';
import { environment } from '@app-environments/environment';
import { AuthenticationService } from '@app-services/auth/authentication.service';
import { LoggingHandler } from '@app-services/errors/logging.service';
import { LocalStorageService } from '@app-services/local-storage.service';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { STORAGE_NAMES, WEB_CODE } from '@app-shared/constants/constants';
import { TranslateModule } from '@ngx-translate/core';
import { SkeletonSignInComponent } from '@app-pages/sign-in/skeleton/skeleton-sign-in.component';

const { AUTH_TYPE } = STORAGE_NAMES;

@Component({
  selector: 'app-sign-in',
  standalone: true,
  imports: [
    MatSelectModule,
    MatButtonModule,
    MatFormFieldModule,
    TranslateModule,
    SkeletonSignInComponent,
  ],
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  public isLoading = true;
  public webCode: string;
  public authTypes: AuthenticationType[] = [];
  public handlingErrorCodes = new Map<number, string>([
    [ErrorCode.OrganizationDontExist, `Organization doesn't exist`],
  ]);
  public selectedAuthType: AuthenticationType;

  constructor(
    protected router: Router,
    private route: ActivatedRoute,
    public matchError: MatchError,
    protected authenticationService: AuthenticationService,
    private loggingHandler: LoggingHandler,
    private localStorageService: LocalStorageService
  ) {}

  async ngOnInit(): Promise<void> {
    this.webCode = this.route.snapshot.paramMap.get(WEB_CODE) ?? '';

    if (this.authenticationService.isLoggedIn()) {
      await this.loggingHandler.create(
        'information',
        'redirect SignInComponent ngOnInit'
      );
      this.router.navigateByUrl(`/${this.webCode}`);
      return;
    }

    const currentLoginType = this.localStorageService.getData(AUTH_TYPE);
    const params = this.route.snapshot.queryParams?.otherData;
    this.authTypes = params
      ? JSON.parse(this.route.snapshot.queryParams?.otherData)
      : await this.authenticationService.loadAuthTypes(this.webCode);
    this.selectedAuthType = currentLoginType || null;
    this.isLoading = false;
  }

  public getAuthTypeDescription(authType: AuthenticationType): string {
    switch (authType) {
      case AuthenticationType.Adfs:
        return 'ADFS';
      case AuthenticationType.Google:
        return 'google';
      case AuthenticationType.Oidc:
        return 'GAW';
      default:
        return '';
    }
  }

  public onLogin(): undefined {
    this.localStorageService.setData(`authType`, this.selectedAuthType);

    switch (this.selectedAuthType) {
      case AuthenticationType.Oidc:
        this.loggingHandler.create('information', 'redirect onLogin Oidc');
        document.location.href = `${environment.oidcSignInUri}?webCode=${this.webCode}`;
        return;
      case AuthenticationType.Adfs:
        alert('todo');
        return;
      case AuthenticationType.Google:
        this.loggingHandler.create('information', 'redirect onLogin Google');
        document.location.href = `${environment.googleSignInUri}?webCode=${this.webCode}`;
        return;
      default:
        return;
    }
  }
}
