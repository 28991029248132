<div class="w-full h-full flex justify-center">
  <form [formGroup]="form" autocomplete="off" class="w-full flex flex-col h-full py-[1rem]" novalidate>
    <h1 class="mb-4">{{ "massiveSending" | translate }}</h1>
    <mat-form-field class="w-full">
      <mat-label>{{ "from" | translate }}</mat-label>
      <mat-select formControlName="mailsControl" #select>
        @for(account of accounts; track account) {
          <mat-option [value]="account.mailAccountEmail">
            {{ account.mailAccountEmail }}
          </mat-option>
        }
      </mat-select>
      @if(form.controls.mailsControl.hasError('required')) {
        <mat-error>{{ "chooseAnAccount" | translate }}</mat-error>
      }
    </mat-form-field>
    <mat-form-field class="w-full" >
      <mat-label>{{ "subject" | translate }}</mat-label>
      <input matInput formControlName="subject" />
      @if(form.controls['subject'].hasError('subject', 'maxlength')) {
        <mat-error>You have more than 2048 characters</mat-error>
      }
    </mat-form-field>
    @if(!!attachmentFiles.length) {
      <div class="flex relative">
        <span class="absolute custom-top">{{ "attach" | translate }}</span>
        <div class="subject attachments" type="text">
          <div class="flex flex-wrap">
            @for(file of attachmentFiles; track file) {
              <div class="file">
                <div [style.cursor]="'default'" class="main__attachment mr-1">
                  <!--TODO -->
<!--                  <file-icon-->
<!--                    style="width: 16px;font-size: 14px" set="classic"-->
<!--                    [type]="getFileExtension(file.fileName)"-->
<!--                  ></file-icon>-->
                </div>
                <span class="leading-1 px-[3px] flex-wrap">
                   {{ file.fileName }}
                </span>
                <div class="flex justify-end items-center pb-[3px]">
                  <mat-icon
                    class="cursor-pointer"
                    style="transform: scale(0.65); color: red"
                    (click)="!isSendingRequest && deleteAttachment(file)"
                    mat-icon-button
                  >close</mat-icon>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    }

    <app-email-body
      [body]="bodyText"
      class="h-full"
      (valueChangedEventEmitter)="valueChangedEventEmitter($event)"
    ></app-email-body>

    @if(!isBodyValid()) {
      <mat-error class="mb-2">{{ "PleaseFillInTheBodyOfTheEmail" | translate }}</mat-error>
    }

    <div class="flex justify-between">
      <div class="flex gap-2">
        <button
          mat-flat-button
          color="primary"
          [disabled]="isSendingRequest || !form.valid || !attachmentFiles.length || !isBodyValid()"
          (click)="onSubmit()">
          <app-loading-button text="{{ 'send' | translate }}" [loading]="isSendingRequest"></app-loading-button>
        </button>
        <input #uploader [hidden]="true" type="file" multiple (change)="addAttachmentFileFromButton($event)" />
        <button mat-flat-button color="primary" [disabled]="isSendingRequest" (click)="uploader.click()">
          {{ "attachFiles" | translate }}
        </button>
      </div>
      <button [disabled]="isSendingRequest" mat-flat-button color="primary" (click)="openConfirmDialog()">
        {{ "reset" | translate }}
      </button>
    </div>
  </form>
</div>

