<div class="drawer-content-screen">
  <div class="drawer-content-screen__title">
    {{ "updateUser" | translate }}
  </div>
  <div class="drawer-content-screen__body">
    <div>
      <form [formGroup]="form" autocomplete="off" novalidate>
        <mat-form-field>
          <mat-label>{{ "firstName" | translate }}</mat-label>
          <input matInput id="firstName" formControlName="firstName" required />
          @if(hasError('firstName', 'required')) {
            <mat-error>{{ "nameIsRequired" | translate }}</mat-error>
          }
          @if(hasError('firstName', 'maxlength')) {
            <mat-error>{{ "moreThan256Char" | translate }}</mat-error>
          }
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ "lastName" | translate }}</mat-label>
          <input matInput id="lastName" formControlName="lastName" required />
          @if(hasError('lastName', 'required')) {
            <mat-error>{{ "nameIsRequired" | translate }}</mat-error>
          }
          @if(hasError('lastName', 'maxlength')) {
            <mat-error>{{ "moreThan256Char" | translate }}</mat-error>
          }
        </mat-form-field>
        <mat-form-field matTooltip="{{ 'emailCannotBeUpdatedUpdateUser' | translate }}">
          <mat-label>{{ "emailAccountSettings" | translate }}</mat-label>
          <input matInput id="email" formControlName="email" required readonly />
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ "language" | translate }}</mat-label>
          <mat-select id="locale" formControlName="locale">
            @for(item of locales; track item) {
              <ng-container>
                @if(item.id !== 'fr' && item.id !== 'de') {
                  <mat-option [value]="item.id">{{item.language}}</mat-option>
                }
              </ng-container>
            }
          </mat-select>
        </mat-form-field>
      </form>
    </div>
  </div>
  <div class="w-full">
    <div class="flex justify-end">
      <button mat-flat-button color="primary" [disabled]="isLoading || !form.valid" (click)="onSubmit()">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
      </button>
    </div>
  </div>
</div>
