import { Component, Input, OnInit } from '@angular/core';
import { DispatchingAction } from '@app-types/api/Dispatching';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Group } from '@app-types/api/group';
import { CheckboxItem } from '@app-types/checkbox.item';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-assign-to-group-action',
  templateUrl: './assign-to-group-action.component.html',
  styleUrls: ['./assign-to-group-action.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
  ],
})
export class AssignGroupsActionComponent implements OnInit {
  @Input() dispatchingAction: DispatchingAction;
  @Input() checkBoxGroups: CheckboxItem<Group>[] = [];
  public form: UntypedFormGroup = new UntypedFormGroup({});
  public selected: number[] = [];
  constructor() {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      groups: new UntypedFormControl('', Validators.required),
    });
    this.selected = this.checkBoxGroups
      .filter(c => c.checked)
      .map(c => c.value.groupId);
  }

  onChange(group: CheckboxItem<Group>): void {
    const currGroup = this.checkBoxGroups.find(
      item => item.label === group.label
    );
    currGroup && (currGroup.checked = !currGroup.checked);

    this.checkBoxGroups
      .filter(c => !c.checked)
      .forEach(c => {
        const index = this.dispatchingAction.args?.findIndex(
          y => y.value === c.value.groupId.toString()
        );
        index !== undefined && this.dispatchingAction.args?.splice(index, 1);
      });

    this.checkBoxGroups
      .filter(c => c.checked)
      .forEach(c => {
        this.dispatchingAction.args &&
          this.dispatchingAction.args.push({
            name: 'GroupId',
            value: c.value.groupId.toString(),
          });
      });
  }
}
