import { BaseGetRequest } from './base-get.request';

export class BaseResponse<T> {
  result: T;
}

export interface BaseGetCollectionResponse<T> {
  data: T[];
}

export interface BaseIdListRequest {
  list: number[];
}

export interface BaseIdsList {
  ids?: null | Array<number>;
}

export class BaseId {
  id: number;
}

export class BaseGetCollectionRequest extends BaseGetRequest {
  page: number;
  pageSize: number;

  constructor(page: number, pageSize: number) {
    super();
    this.page = page;
    this.pageSize = pageSize;
  }
}

export class BaseCollectionSearchRequest extends BaseGetCollectionRequest {
  search: string | null;

  constructor(page: number, pageSize: number, search: string | null) {
    super(page, pageSize);
    this.search = search;
  }
}

export class BaseCollectionSearchByIdRequest extends BaseCollectionSearchRequest {
  id: number;

  constructor(
    id: number | null,
    page: number,
    pageSize: number,
    search: string | null
  ) {
    super(page, pageSize, search);
    id && (this.id = id);
  }
}

export class BaseGetByEmailFileIdRequest extends BaseGetRequest {
  fileId: number;
  emailId: number;

  constructor(fileId: number, emailId: number) {
    super();
    this.fileId = fileId;
    this.emailId = emailId;
  }
}

export class BaseGetByFileIdRequest extends BaseGetRequest {
  fileId: number;

  constructor(fileId: number) {
    super();
    this.fileId = fileId;
  }
}

export class BaseGetByIdRequest extends BaseGetRequest {
  id: number;
  organizationId?: number;

  constructor(id: number | null, organizationId?: number) {
    super();
    id && (this.id = id);
    organizationId && (this.organizationId = organizationId);
  }
}

export class BaseGetCollectionByIdRequest extends BaseGetRequest {
  id: number;
  page: number;
  pageSize: number;

  constructor(id: number, page: number, pageSize: number) {
    super();
    this.id = id;
    this.page = page;
    this.pageSize = pageSize;
  }
}

export class BaseGetSearchCountRequest extends BaseGetRequest {
  search: string | null;

  constructor(search: string | null) {
    super();
    this.search = search;
  }
}

export class BaseGetSearchCountByIdRequest extends BaseGetSearchCountRequest {
  id: number;

  constructor(id: number | null, search: string | null) {
    super(search);
    id && (this.id = id);
  }
}

export class BasePostCommentRequest {
  id: number;
  comment: string;

  constructor(id: number, comment: string) {
    this.id = id;
    this.comment = comment;
  }
}
