import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  BaseCollectionSearchByIdRequest,
  BaseGetByIdRequest,
} from '@app-types/base/base';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { MailAccountGetByOrganizationIdContract } from 'src/app/types/api/mail-account';
import { UserMailAccountClient } from 'src/app/services/api/clients/user-mail-account.client';
import { UserOrganizationClient } from 'src/app/services/api/clients/user-organization.client';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { SomethingWentWrongComponent } from '../../../../common/error/something-went-wrong/something-went-wrong.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LoadingButtonComponent } from '../../../../common/loading-button/loading-button.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { FilterPipe } from '@app-pipes/filter.pipe';
import { CheckableSearchFilterComponent } from '../../../../common/сheckable-search-filter/сheckable-search-filter.component';
import { ROLES } from '@app-shared/constants/constants';
import { UserMailAccountContract } from '@app-types/api/user-mail-account';
import { DrawerService } from '@app-services/drawer.service';
import { UserCheckboxes } from '@app-types/common';
import { SkeletonFormDrawerComponent } from '@app-components/common/skeletons/skeleton-form-drawer/skeleton-form-drawer.component';

@Component({
  selector: 'app-add-mail-accounts-to-users-form',
  templateUrl: './add-mail-accounts-to-users-form.component.html',
  styleUrls: ['./add-mail-accounts-to-users-form.component.scss'],
  standalone: true,
  imports: [
    SomethingWentWrongComponent,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    LoadingButtonComponent,
    MatCheckboxModule,
    TranslateModule,
    FilterPipe,
    CheckableSearchFilterComponent,
    MatTableModule,
    SkeletonFormDrawerComponent,
  ],
})
export class AddMailAccountsToUsersFormComponent implements OnInit, OnDestroy {
  @Input() public mailAccounts: MailAccountGetByOrganizationIdContract[];
  @Input() public organizationID: number | null;
  @Output() private closeDrawer = new EventEmitter();
  @Output() private submitDrawer = new EventEmitter();
  public cannotLoadUsers: boolean = false;
  public isLoading: boolean = false;
  public isLoadingUsers: boolean = false;
  public isUploaded = false;

  public roles = ROLES;

  public users: UserCheckboxes[] = [];
  public filteredUsersByAdvancedSettings: UserCheckboxes[] = [];

  public search = '';

  public filteredUsers: UserCheckboxes[] = [];
  public dataSource = new MatTableDataSource<UserCheckboxes>(
    this.filteredUsers
  );
  public displayedColumns: string[] = ['select', 'user', 'role'];

  constructor(
    private userOrganizationClient: UserOrganizationClient,
    private userMailAccountClient: UserMailAccountClient,
    public permissionService: PermissionService,
    public matchError: MatchError,
    private drawerService: DrawerService
  ) {}

  ngOnInit(): void {
    this.fetchUsers();
  }

  public async onSubmit(): Promise<void> {
    this.isLoading = true;
    this.drawerService.disabledDrawer(true);
    const mailAccounts = this.mailAccounts.map(
      mailAccount => mailAccount.mailAccountId
    );
    const usersRoles = this.users
      .filter(item => item._checked)
      .map(item => {
        return {
          userId: item.userId,
          role: item.role,
        };
      });

    this.filteredUsersByAdvancedSettings.forEach(e => {
      if (e._checked) {
        const user = { userId: e.userId, role: e.role };
        usersRoles.push(user);
      }
    });

    try {
      await this.userMailAccountClient.setMailAccountUsers({
        mailAccounts,
        usersRoles,
      });
      this.drawerService.disabledDrawer(false);
      this.submitDrawer.emit();
      this.drawerService.closeDrawer();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
      this.drawerService.disabledDrawer(false);
    } finally {
      this.isLoading = false;
    }
  }

  onSearchChange(value: string): void {
    this.search = value;
    this.dataSource.filter = value.trim().toLowerCase();
  }

  async fetchUsers(): Promise<void> {
    this.isUploaded = false;
    const loaderTimeout = setTimeout(() => {
      this.isLoadingUsers = true;
    }, 500);

    try {
      const request = new BaseCollectionSearchByIdRequest(
        this.organizationID,
        1,
        500,
        ''
      );
      const result =
        await this.userOrganizationClient.getOrganizationUsers(request);

      if (this.mailAccounts.length === 1) {
        const mailAccountUsers = await this.fetchMailAccountUsers();

        this.users = result.organizationUsers.map(user => {
          const currItem = mailAccountUsers.find(
            item => item.userId === user.userId
          );
          const needRole = this.roles.find(
            item => item.value === currItem?.roleId
          );
          return {
            ...user,
            role: currItem && needRole ? needRole.value : this.roles[0].value,
            _checked: !!currItem,
          };
        });
      } else {
        this.users = result.organizationUsers.map(user => {
          return { ...user, role: this.roles[0].value, _checked: false };
        });
      }

      this.users = this.users.filter(e => {
        if (this.permissionService.hasPermissionToSeeUserByUserEmail(e.email)) {
          return true;
        } else {
          this.filteredUsersByAdvancedSettings.push(e);
          return false;
        }
      });

      this.filteredUsers = this.users;

      this.dataSource.data = this.filteredUsers;

      this.cannotLoadUsers = false;
    } catch (e) {
      this.cannotLoadUsers = true;
      this.matchError.logError(e);
    } finally {
      clearTimeout(loaderTimeout);
      this.isUploaded = true;
      this.isLoadingUsers = false;
    }
  }

  private async fetchMailAccountUsers(): Promise<UserMailAccountContract[]> {
    const req = new BaseGetByIdRequest(this.mailAccounts[0].mailAccountId);
    const mailAccountUsers =
      await this.userMailAccountClient.getMailAccountUsers(req);

    return mailAccountUsers.userMailAccounts;
  }

  isAllSelected(): boolean {
    const numSelected = this.users.filter(
      mailAccount => mailAccount._checked
    ).length;
    const numRows = this.users.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected()
      ? this.users.forEach(user => (user._checked = false))
      : this.users.forEach(user => (user._checked = true));
  }

  public hasSelected(): boolean {
    let hasSelected = false;
    for (let i = 0; i < this.users.length; i++) {
      if (this.users[i]._checked) {
        hasSelected = true;
        break;
      }
    }
    return hasSelected;
  }

  onFilterSelectionChange(value: number): void {
    this.filteredUsers =
      value < 0
        ? this.users
        : this.users.filter(user =>
            value === 0 ? !user._checked : user._checked
          );
    this.dataSource.data = this.filteredUsers;
  }

  ngOnDestroy(): void {
    this.closeDrawer.emit();
  }
}
