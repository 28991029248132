import { Pipe, PipeTransform } from '@angular/core';
import { UserOrganizationState } from '@app-types/enums/user-organization-state.enum';

@Pipe({
  name: 'formatUserState',
  standalone: true,
})
export class FormatUserStatePipe implements PipeTransform {
  transform(order: UserOrganizationState): string {
    switch (order) {
      case UserOrganizationState.Active:
        return 'activated';
      case UserOrganizationState.Disabled:
        return 'diactivated';
      default:
        return 'Not implemented';
    }
  }
}
