import { Injectable } from '@angular/core';
import { environment } from '@app-environments/environment';
import { ApiRequest } from '../api.request';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import {
  BaseGetCollectionByIdRequest,
  BaseGetCollectionResponse,
} from '@app-types/base/base';

const API_URL = environment.apiUrl;
const { group } = API_ROUTES;
const { getGroupsForOrganization } = group;

@Injectable({
  providedIn: 'root',
})
export class GroupClient {
  constructor(private apiRequest: ApiRequest) {}
  async getGroupsForOrganization(
    request: BaseGetCollectionByIdRequest
  ): Promise<BaseGetCollectionResponse<string[]>> {
    return this.apiRequest.get<BaseGetCollectionResponse<string[]>>(
      `${API_URL}${getGroupsForOrganization}?${request.format()}`
    );
  }
}
