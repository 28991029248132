import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PermissionNavTabHelper } from '@app-services/permission/permission-nav-tab-helper';
import { OrganizationClient } from '@app-services/api/clients/organization.client';
import { MailAccountClient } from '@app-services/api/clients/mail-account.client';
import { PermissionService } from '@app-services/permission/permission.service';
import { MobileObserverService } from '@app-services/adaptive/mobile-observer.service';
import { TagClient } from '@app-services/api/clients/tag.client';
import {
  BaseCollectionSearchByIdRequest,
  BaseGetSearchCountByIdRequest,
} from '@app-types/base/base';
import { TagContract } from '@app-types/api/tag';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SearchBarComponent } from '@app-components/common/search-bar/search-bar.component';
import { NoDataComponent } from '@app-components/common/no-data/no-data.component';
import { SomethingWentWrongComponent } from '@app-components/common/error/something-went-wrong/something-went-wrong.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { LocalStorageService } from '@app-services/local-storage.service';
import { DrawerService } from '@app-services/drawer.service';
import {
  MAIL_ACCOUNT_ID,
  STORAGE_NAMES,
} from '@app-shared/constants/constants';
import { MailAccountTagCreateDialogComponent } from '@app-components/settings/organization/mail-account/mail-account-tag-create-dialog/mail-account-tag-create-dialog.component';
import { DeleteDialogComponent } from '@app-components/common/delete-dialog/delete-dialog.component';
import { Subscription } from 'rxjs';
import { SkeletonTableComponent } from '@app-components/common/skeletons/skeleton-table/skeleton-table.component';

const { PAGE_SIZE_SETTINGS } = STORAGE_NAMES;

@Component({
  selector: 'app-mail-account-tags',
  templateUrl: './mail-account-tags.component.html',
  styleUrls: ['./mail-account-tags.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    SearchBarComponent,
    NoDataComponent,
    SomethingWentWrongComponent,
    MatTableModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatCardModule,
    MailAccountTagCreateDialogComponent,
    SkeletonTableComponent,
  ],
})
export class MailAccountTagsComponent implements OnInit, OnDestroy {
  @HostBinding('class') className = 'setting-container';
  public mailAccountId: number;

  public isLoading = false;
  isUploaded = false;
  public isError = false;

  public searchValue = '';
  public pageSize = 25;
  public pageIndex = 0;
  public totalSize = 0;

  public tags: TagContract[] = [];

  public dataSource = new MatTableDataSource<TagContract>(this.tags);
  public displayedColumns: string[] = ['tag', 'actions'];
  private useMobileViewSubscription: Subscription;
  public useMobileView = false;
  @ViewChild('addDrawer') addDrawer: TemplateRef<any>;

  constructor(
    public matchError: MatchError,
    private route: ActivatedRoute,
    private permissionService: PermissionService,
    private organizationClient: OrganizationClient,
    private permissionNavTabHelper: PermissionNavTabHelper,
    private mailAccountClient: MailAccountClient,
    private mobileObserverService: MobileObserverService,
    private tagClient: TagClient,
    public dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private drawerService: DrawerService
  ) {}

  async ngOnInit(): Promise<void> {
    this.useMobileViewSubscription = this.mobileObserverService
      .mobileObserver()
      .subscribe(isMobile => (this.useMobileView = isMobile));
    this.pageSize = this.localStorageService.getData(PAGE_SIZE_SETTINGS) || 25;
    this.mailAccountId = parseInt(
      this.route.snapshot.paramMap.get(MAIL_ACCOUNT_ID) as string,
      10
    );
    await this.loadTags(true);
  }

  public async loadTags(fetchCounter: boolean): Promise<void> {
    this.isUploaded = false;
    const loaderTimeout = setTimeout(() => {
      this.isLoading = true;
    }, 500);
    this.isError = false;
    if (fetchCounter) {
      this.pageIndex = 0;
    }
    const nullableSearchValue =
      this.searchValue.length > 0 ? this.searchValue : null;
    const request = new BaseCollectionSearchByIdRequest(
      this.mailAccountId,
      this.pageIndex + 1,
      this.pageSize,
      nullableSearchValue
    );
    try {
      const response = await this.tagClient.searchForMailAccount(request);
      this.tags = response.data;
      this.dataSource.data = this.tags;
      if (fetchCounter) {
        const countRequest = new BaseGetSearchCountByIdRequest(
          this.mailAccountId,
          nullableSearchValue
        );
        const counterResponse =
          await this.tagClient.getSearchCountForMailAccount(countRequest);
        this.totalSize = counterResponse.result;
      }
    } catch (e) {
      this.isError = true;
      this.matchError.logError(e);
    } finally {
      clearTimeout(loaderTimeout);
      this.isLoading = false;
      this.isUploaded = true;
    }
  }

  public onSearchValueChanged(newSearchValue: string): void {
    this.searchValue = newSearchValue;
  }

  public async handlePage(e: any): Promise<void> {
    this.localStorageService.setData(PAGE_SIZE_SETTINGS, e.pageSize);
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    await this.loadTags(false);
  }

  public openCreateModelForm(): void {
    this.drawerService.openDrawer(this.addDrawer);
  }

  public async onCreateTag(): Promise<void> {
    this.totalSize += 1;
    await this.loadTags(false);
  }

  async onDeleteBtnClicked(tagId: number): Promise<void> {
    await this.tagClient.delete({ id: tagId });
  }

  async deleteTag(tagId: number, tag: string): Promise<void> {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '450px',
      autoFocus: false,
      data: {
        title: 'confirmTagDeletion',
        subTitles: [
          {
            title: 'tagDeletion',
            subTitle: tag,
          },
        ],
        onDelete: async () => await this.onDeleteBtnClicked(tagId),
      },
    });

    dialogRef.afterClosed().subscribe(async x => {
      if (x.isDeleted) {
        this.totalSize -= 1;
        await this.loadTags(false);
      }
    });
  }

  ngOnDestroy(): void {
    this.useMobileViewSubscription?.unsubscribe();
  }
}
