import { Injectable } from '@angular/core';
import { environment } from '@app-environments/environment';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import { ApiRequest } from '../api.request';
import { BaseGetByIdRequest, BaseId, BaseResponse } from '@app-types/base/base';
import { OrganizationSettingsContract } from '@app-types/api/Settings';

const API_URL = environment.apiUrl;
const { organizationSettings } = API_ROUTES;
const { getByOrganization, createOrUpdate } = organizationSettings;

@Injectable({ providedIn: 'root' })
export class OrganizationSettingsClient {
  constructor(private apiRequest: ApiRequest) {}

  async getByOrganization(
    request: BaseGetByIdRequest
  ): Promise<BaseResponse<OrganizationSettingsContract>> {
    return this.apiRequest.get(
      `${API_URL}${getByOrganization}?${request.format()}`
    );
  }

  async createOrUpdate(request: OrganizationSettingsContract): Promise<BaseId> {
    return this.apiRequest.post<OrganizationSettingsContract, BaseId>(
      request,
      `${API_URL}${createOrUpdate}`
    );
  }
}
