import { EmailValidationState } from '../../types/enums/email.validation-state';

export const ALL = [
  EmailValidationState.New,
  EmailValidationState.Validated,
  EmailValidationState.NotValidated,
];
export const VALIDATED = [
  EmailValidationState.New,
  EmailValidationState.Validated,
];
export const NOT_VALIDATED = [EmailValidationState.NotValidated];
