import { Injectable } from '@angular/core';
import { environment } from '@app-environments/environment';
import { ApiRequest } from '../api.request';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import { GroupsRequest } from '@app-types/api/group';
import { BaseIdListRequest } from '@app-types/base/base';

const API_URL = environment.apiUrl;

const { emailGroup } = API_ROUTES;
const { removeEmailsFromGroups, addEmailsToGroups, getGroupsForEmails } =
  emailGroup;

@Injectable({
  providedIn: 'root',
})
export class EmailGroupClient {
  constructor(private apiRequest: ApiRequest) {}

  async removeGroupsFromEmails(request: GroupsRequest): Promise<void> {
    return this.apiRequest.post<GroupsRequest, void>(
      request,
      `${API_URL}${removeEmailsFromGroups}`
    );
  }

  async addGroupsToEmails(request: GroupsRequest): Promise<void> {
    return this.apiRequest.post<GroupsRequest, void>(
      request,
      `${API_URL}${addEmailsToGroups}`
    );
  }
  async getGroupsForEmails(request: BaseIdListRequest): Promise<string[]> {
    return this.apiRequest.post<BaseIdListRequest, string[]>(
      request,
      `${API_URL}${getGroupsForEmails}`
    );
  }
}
