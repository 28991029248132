import { environment } from '@app-environments/environment';
import { Injectable } from '@angular/core';
import { ApiRequest } from '../api.request';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import {
  BaseGetByIdRequest,
  BaseGetCollectionResponse,
  BaseId,
} from '@app-types/base/base';
import {
  EmailNoteContract,
  EmailNoteCreateRequest,
  EmailNoteUpdateRequest,
} from '@app-types/api/email-note';

const API_URL = environment.apiUrl;
const { emailNote } = API_ROUTES;
const { getForEmail, create, update } = emailNote;

@Injectable({ providedIn: 'root' })
export class EmailNoteClient {
  constructor(private apiRequest: ApiRequest) {}

  async getForEmail(
    request: BaseGetByIdRequest
  ): Promise<BaseGetCollectionResponse<EmailNoteContract>> {
    return this.apiRequest.get(`${API_URL}${getForEmail}?${request.format()}`);
  }

  async delete(request: BaseId): Promise<BaseId> {
    return this.apiRequest.post<BaseId, BaseId>(
      request,
      `${API_URL}${emailNote.delete}`
    );
  }

  async create(request: EmailNoteCreateRequest): Promise<BaseId> {
    return this.apiRequest.post<EmailNoteCreateRequest, BaseId>(
      request,
      `${API_URL}${create}`
    );
  }

  async update(request: EmailNoteUpdateRequest): Promise<BaseId> {
    return this.apiRequest.post<EmailNoteUpdateRequest, BaseId>(
      request,
      `${API_URL}${update}`
    );
  }
}
