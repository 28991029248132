import { MailFolderType } from '../enums/mail-folder.type';
import { BaseGetRequest } from '../base/base-get.request';
import { PignorState } from '../enums/pignor-type';

export interface MailFolderI {
  mailFolderId: number;
  name: string;
  folderType: MailFolderType;
  canReassign?: boolean;
  canValidate?: boolean;
}

export interface MailFolderContract extends MailFolderI {
  mailAccountId: number;
  parentId: number | null;
  creationType: number;
  flowDirection?: number;
  archive?: boolean;
}

export interface MailFolderContractWithFullNameForResponse extends MailFolderI {
  fullName: string;
  mailFolderFlowDirection: string;
  creationType: number;
  providerFolderName: string;
}

export interface MailFolder {
  name: string;
  folderType: MailFolderType;
  providerFolderName: string | null;
  parentId?: number;
}

export interface MailFolderCreateRequest extends MailFolder {
  mailAccountId: number;
}

export interface MailFolderUpdateRequest extends MailFolder {
  mailFolderId: number;
}

export interface MailFolderCreateResponse {
  mailFolderId: number;
}

export interface MailFolderUnreadCount extends MailFolderCreateResponse {
  count: number;
}

export class MailFolderGetDefaultRequest extends BaseGetRequest {
  folderType: MailFolderType;
  mailAccountId: number;

  constructor(mailAccountId: number, folderType: MailFolderType) {
    super();
    this.mailAccountId = mailAccountId;
    this.folderType = folderType;
  }
}

export class MailFolderGetUnreadCountRequest extends BaseGetRequest {
  folderId: number;
  hideNotification: boolean;

  constructor(folderId: number, hideNotification: boolean) {
    super();
    this.folderId = folderId;
    this.hideNotification = hideNotification;
  }
}

export class MailAccountGetUnreadCountRequest extends BaseGetRequest {
  mailAccountId: number;
  hideNotification: boolean;

  constructor(mailAccountId: number, hideNotification: boolean) {
    super();
    this.mailAccountId = mailAccountId;
    this.hideNotification = hideNotification;
  }
}

export interface UserFolderContract {
  creationType: number;
  mailFolderId: number;
  mailFolderName: string;
  mailAccountId: number;
  mailAccountName: string;
  parentFolderId: number | null;
  folderType: MailFolderType;
}

export interface MailFolderSettingsContract {
  mailFolderId: number;
  archive: boolean;
  canValidate: boolean;
  canReassign: boolean;
  archiveSettings?: string;
  code: string;
  pignorState: PignorState;
}

export class MailFolderSettingsGetByMailFolderRequest extends BaseGetRequest {
  constructor(mailAccountId: number, mailFolderId: number) {
    super();
    this.mailAccountId = mailAccountId;
    this.mailFolderId = mailFolderId;
  }

  mailAccountId: number;
  mailFolderId: number;
}

export interface MailFolderStatisticContract {
  name: string;
  emailsCount: number;
  massiveEmailsCount: number;
  diskSpace: number;
}
