<div class="drawer-content-screen">
  @if(isLoading) {
    <app-skeleton-email class="h-full" [hasTitle]="true"></app-skeleton-email>
  }

  @if(isError && !isLoading) {
    <div class="flex-col-center">
       <app-something-went-wrong
         description="Something went wrong"
         (retryEmitter)="loadTemplate()"
         [showPicture]="false"
       ></app-something-went-wrong>
     </div>
  }

  @if(!isError && !isLoading && isUploaded) {
    <div class="drawer-content-screen__title">
      {{  templateId ? ("modifyTemplate" | translate) : ("createTemplate" | translate) }}
    </div>
    <div class="drawer-content-screen flex-1 !gap-2" style="height: calc(100% - 72px)">
      <form
        [formGroup]="form"
        autocomplete="off"
        class="w-full relative flex flex-col"
        novalidate
      >
        <mat-form-field class="w-full" >
          <mat-label>{{ "templateName" | translate }}</mat-label>
          <input matInput formControlName="templateName" />
          @if(hasError('templateName', 'maxlength')) {
            <mat-error>{{ 'moreThan256Char' | translate }}</mat-error>
          }
          @if(hasError('templateName', 'required')) {
            <mat-error>{{ "templateNameIsRequired" | translate }}</mat-error>
          }
        </mat-form-field>
        <mat-form-field class="w-full" >
          <mat-label>{{ "templateDesc" | translate }}</mat-label>
          <input matInput formControlName="templateDesc" />
          @if(hasError('templateDesc', 'maxlength')) {
            <mat-error>{{ 'moreThan256Char' | translate }}</mat-error>
          }
        </mat-form-field>
        <mat-form-field class="w-full" >
          <mat-label>{{ "subject" | translate }}</mat-label>
          <input matInput formControlName="templateSubject" />
          @if(hasError('templateSubject', 'maxlength')) {
           <mat-error>{{ 'moreThan256Char' | translate }}</mat-error>
          }
        </mat-form-field>
        </form>
        <div class="drawer-content-screen__body">
         <app-email-body
          [body]="body"
          class="h-full"
          (valueChangedEventEmitter)="valueChangedEventEmitter($event)"
        ></app-email-body>
      </div>
      <div class="flex justify-end w-full">
        <button
          mat-flat-button
          color="primary"
          [disabled]="isSendingRequest || isLoading || !form.valid || !isBodyHasData()"
          (click)="onSubmit()">
          <app-loading-button text="{{ 'save' | translate }}" [loading]="isSendingRequest"></app-loading-button>
        </button>
      </div>
    </div>
    }
</div>
