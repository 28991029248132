import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
} from '@angular/router';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { BreadcrumbItem, TabItem } from '@app-types/common';
import {
  MAIL_ACCOUNT_ID,
  ORGANIZATION_ID,
  WEB_CODE,
} from '@app-shared/constants/constants';
import { PermissionNavTabHelper } from '@app-services/permission/permission-nav-tab-helper';
import { NavigationTabsComponent } from './navigation-tabs/navigation-tabs.component';
import { filter } from 'rxjs/operators';
import { PermissionService } from '@app-services/permission/permission.service';
import { BaseGetByIdRequest } from '@app-types/base/base';
import { MailAccountClient } from '@app-services/api/clients/mail-account.client';
import { Subscription } from 'rxjs';
import { PermissionType } from '@app-types/enums/permission-type';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  standalone: true,
  imports: [RouterModule, BreadcrumbComponent, NavigationTabsComponent],
})
export class SettingsComponent implements OnInit, OnDestroy {
  @HostBinding('class') className = 'block h-full';
  [key: string]: any;
  public breadcrumbItems: BreadcrumbItem[] = [];
  public tabItemsSettings: TabItem[] | null = null;
  public tabItemsMailAccount: TabItem[] | null = null;
  public organizationId: number;
  public webCode: string;
  public mailAccountId: number | null;
  canEverything: boolean;
  canManageOrganization: boolean;

  router$: Subscription;
  breadcrumb: { [key: string]: BreadcrumbItem[] } = {
    general: [{ index: 1, label: 'generalBreadCrumbs', route: null }],
    groups: [{ index: 1, label: 'groupsBreadCrumbs', route: null }],
    users: [{ index: 1, label: 'usersBreadCrumbs', route: null }],
    'mail-accounts': [
      { index: 1, label: 'mailAccountsBreadCrumbs', route: null },
    ],
  };

  breadcrumbMailAccount: { [key: string]: BreadcrumbItem[] } = {
    general: [{ index: 3, label: 'generalBreadCrumbs', route: null }],
    users: [{ index: 3, label: 'usersBreadCrumbs', route: null }],
    templates: [{ index: 3, label: 'templates', route: null }],
    tags: [{ index: 3, label: 'tagsBreadCrumbs', route: null }],
    folders: [{ index: 3, label: 'foldersBreadCrumbs', route: null }],
    'dispatching-rules': [
      { index: 3, label: 'dispatchingRulesBreadCrumbs', route: null },
    ],
  };

  constructor(
    private route: ActivatedRoute,
    private permissionNavTabHelper: PermissionNavTabHelper,
    private mailAccountClient: MailAccountClient,
    private permissionService: PermissionService,
    private router: Router
  ) {}

  async ngOnInit(): Promise<any> {
    this.webCode = this.route.parent?.snapshot.paramMap.get(WEB_CODE) ?? '';
    this.organizationId = parseInt(
      this.route.firstChild?.snapshot.paramMap.get(ORGANIZATION_ID) ?? '',
      10
    );
    if (
      this.webCode !== 'admin' &&
      (isNaN(this.organizationId) || !this.organizationId)
    ) {
      this.router.navigate([this.webCode, 'emails']);
      return;
    }
    this.canManageOrganization = await this.permissionService.hasPermissionOver(
      this.organizationId,
      null,
      PermissionType.CanManageOrganization
    );

    if (this.webCode === 'admin') {
      !this.tabItemsSettings &&
        (this.tabItemsSettings = this.permissionNavTabHelper.getSettingPageTabs(
          this.webCode
        ));
      return;
    }

    this.init(this.router.url);

    this.router$ = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.init(event.url);
      });
  }

  init(url: string): void {
    this.changeTabs();
    this.changeBreadcrumb(url);
  }

  changeBreadcrumb(url: string): void {
    if (this.mailAccountId) {
      this.loadBreadcrumbItems(url);
    } else {
      this.mailAccountId = null;
      const newBreadcrumbItems: BreadcrumbItem[] = [
        {
          index: 0,
          label: 'settingsBreadCrumbs',
          route: this.canManageOrganization
            ? `/${this.webCode}/settings/organization/${this.organizationId}/general`
            : null,
        },
      ];
      const newEl = this.getNewItem(url, 'breadcrumb');
      !!newEl?.length && newBreadcrumbItems.push(...newEl);
      this.breadcrumbItems = newBreadcrumbItems;
    }
  }

  changeTabs(): void {
    const mailAcc =
      this.route.firstChild?.snapshot.paramMap.get(MAIL_ACCOUNT_ID) ?? '';
    this.mailAccountId = mailAcc ? parseInt(mailAcc, 10) : null;

    if (this.mailAccountId && !this.tabItemsMailAccount) {
      this.tabItemsMailAccount =
        this.permissionNavTabHelper.getTabsForMailAccountPage(
          this.organizationId,
          this.mailAccountId,
          this.webCode
        );
      this.tabItemsSettings = null;
    }

    if (!this.mailAccountId && !this.tabItemsSettings) {
      this.tabItemsSettings = this.canManageOrganization
        ? this.permissionNavTabHelper.getOrganizationPageTabs(
            this.organizationId,
            this.webCode
          )
        : this.permissionNavTabHelper.getMailAccountPageTabs(
            this.organizationId,
            this.webCode
          );
      this.tabItemsMailAccount = null;
    }
  }

  private async loadBreadcrumbItems(url: string): Promise<void> {
    const mailAccountName = (
      await this.mailAccountClient.getById(
        new BaseGetByIdRequest(this.mailAccountId)
      )
    ).mailAccount.name;
    const newBreadcrumbItems: BreadcrumbItem[] = [
      {
        index: 0,
        label: 'settingsBreadCrumbs',
        route: this.canManageOrganization
          ? `/${this.webCode}/settings/organization/${this.organizationId}/general`
          : null,
      },
      {
        index: 1,
        label: 'mailAccountsBreadCrumbs',
        route: `/${this.webCode}/settings/organization/${this.organizationId}/mail-accounts`,
      },
      { index: 2, label: mailAccountName, route: null },
    ];
    const newEl = this.getNewItem(url, 'breadcrumbMailAccount');
    !!newEl?.length && newBreadcrumbItems.push(...newEl);
    this.breadcrumbItems = newBreadcrumbItems;
  }

  getNewItem(url: string, key: string): BreadcrumbItem[] {
    const urlArr = url.split('/');
    const lastChild = urlArr[urlArr.length - 1];
    return this[key][lastChild];
  }

  ngOnDestroy(): void {
    this.router$?.unsubscribe();
  }
}
