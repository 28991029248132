import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../auth/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { MatDialog } from '@angular/material/dialog';
import { LoggingHandler } from '../errors/logging.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalHttpInterceptorService implements HttpInterceptor {
  constructor(
    public router: Router,
    private snackBar: MatSnackBar,
    private zone: NgZone,
    private activateRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private dialogRef: MatDialog,
    protected loggingHandler: LoggingHandler
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    req = req.clone({
      withCredentials: true,
    });
    return next.handle(req).pipe(catchError(error => this.handleError(error)));
  }

  handleError(httpErrorResponse: HttpErrorResponse): Observable<never> {
    const webCode = this.activateRoute.snapshot.firstChild?.params['webCode'];
    if (httpErrorResponse.status === 401 && webCode) {
      this.loggingHandler.create(
        'error',
        `401 webCode:${webCode} Response: ${JSON.stringify(httpErrorResponse)}`
      );
      this.dialogRef.closeAll();
      this.authenticationService.logout(webCode);
    } else if (httpErrorResponse.status === 403 && webCode) {
      this.dialogRef.closeAll();
      this.router
        .navigate([`${webCode}/403`], { state: { returnUrl: this.router.url } })
        .then();
    } else {
      this.getErrorMessage(httpErrorResponse);
    }

    return throwError(() => httpErrorResponse);
  }

  getErrorMessage(httpErrorResponse: HttpErrorResponse): string {
    let message;
    if (
      httpErrorResponse.error == null ||
      httpErrorResponse.error.description == null
    ) {
      message = httpErrorResponse.statusText;
    } else {
      message = httpErrorResponse.error.description;
      if (
        httpErrorResponse.error.meta &&
        httpErrorResponse.error.meta.length > 0 &&
        httpErrorResponse.error.meta[0].errorMessage
      ) {
        message += `(${httpErrorResponse.error.meta[0].errorMessage})`;
      }
    }
    return message;
  }

  openSnackBar(message: string): void {
    const messageText = message === 'Unknown Error' ? 'Fatal error' : message;
    this.zone.run(() => {
      const snackBar = this.snackBar.open(messageText, 'OK', {
        verticalPosition: 'bottom',
        horizontalPosition: 'center',
      });
      snackBar.onAction().subscribe(() => {
        snackBar.dismiss();
      });
    });
  }
}
