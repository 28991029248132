import { Component, Inject, OnInit } from '@angular/core';
import { MailFolderContract } from '@app-types/api/mail-folder';
import { MailFolderService } from '@app-services/mail-folder/mail.folder.service';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MailFolderSelectDialogComponent } from '../mail-account-folders/mail-folder-select-dialog/mail-folder-select-dialog.component';
import { MailAccountGetByOrganizationIdContract } from '@app-types/api/mail-account';
import { MailAccountService } from '@app-services/mail-account/mail.account.service';
import { EmailClient } from '@app-services/api/clients/email-client';
import { MailFolderType } from 'src/app/types/enums/mail-folder.type';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SomethingWentWrongComponent } from '../common/error/something-went-wrong/something-went-wrong.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ErrorHintComponent } from '../common/error-hint/error-hint.component';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingButtonComponent } from '../common/loading-button/loading-button.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-reassign-dialog',
  templateUrl: './reassign-dialog.component.html',
  styleUrls: ['./reassign-dialog.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    SomethingWentWrongComponent,
    MatFormFieldModule,
    MatSelectModule,
    ErrorHintComponent,
    TranslateModule,
    LoadingButtonComponent,
    NgxSkeletonLoaderModule,
  ],
})
export class ReassignDialogComponent implements OnInit {
  public isLoading = true;
  public isLoadingFolders = true;
  public cannotLoad = false;
  public saving = false;
  public cannotSave = false;

  public folders: MailFolderContract[] = [];
  public accounts: MailAccountGetByOrganizationIdContract[] = [];
  public selectedFolderId?: number;
  public selectedMailAccountId: number;

  constructor(
    public matchError: MatchError,
    private mailFolderService: MailFolderService,
    private mailAccountService: MailAccountService,
    public dialogRef: MatDialogRef<MailFolderSelectDialogComponent>,
    private emailClient: EmailClient,
    @Inject(MAT_DIALOG_DATA)
    public data: { mailAccountId: number; ids: number[] }
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadData();
  }

  async loadData(): Promise<void> {
    this.cannotLoad = false;
    try {
      await this.loadAccounts(this.data.mailAccountId);
      if (this.accounts.length > 0) {
        await this.loadFolders(this.accounts[0].mailAccountId);
      }
    } catch (e) {
      this.cannotLoad = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  async loadFolders(mailAccountId: number): Promise<void> {
    try {
      this.isLoadingFolders = true;
      this.folders =
        await this.mailFolderService.getFoldersForAccountReassign(
          mailAccountId
        );
      if (this.folders.length) {
        this.selectedFolderId = this.folders.find(
          e => e.folderType === MailFolderType.Inbox && e.creationType === 1
        )?.mailFolderId;
      }
    } catch (error) {
      this.matchError.errorHandler(error);
      this.cannotLoad = true;
    } finally {
      this.isLoadingFolders = false;
    }
  }

  async loadAccounts(mailAccountId: number): Promise<void> {
    this.accounts =
      await this.mailAccountService.getAccountsForAccountOrganization(
        mailAccountId
      );

    if (this.accounts.length > 0) {
      this.selectedMailAccountId = this.accounts[0].mailAccountId;
    }
  }

  cancel(): void {
    this.dialogRef.close({ confirmed: false });
  }

  async onAccountChange(): Promise<void> {
    if (this.selectedMailAccountId) {
      await this.loadFolders(this.selectedMailAccountId);
    }
  }

  async confirm(): Promise<void> {
    this.saving = true;
    this.cannotSave = false;
    try {
      this.selectedFolderId &&
        (await this.emailClient.reassign({
          emailIds: this.data.ids,
          mailAccountId: this.selectedMailAccountId,
          mailFolderId: this.selectedFolderId,
        }));
      this.dialogRef.close({
        confirmed: true,
        mailAccounts: [this.data.mailAccountId, this.selectedMailAccountId],
      });
    } catch (e) {
      this.cannotSave = true;
      this.matchError.logError(e);
    } finally {
      this.saving = false;
    }
  }
}
