import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatchError } from '../../../services/errors/error-matcher';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingButtonComponent } from '../loading-button/loading-button.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-drawer',
  standalone: true,
  imports: [
    TranslateModule,
    LoadingButtonComponent,
    MatButtonModule,
    MatDialogModule,
  ],
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
})
export class DeleteDialogComponent {
  isLoading = false;
  constructor(
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    public matchError: MatchError,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      subTitles?: { title: string; subTitle: string }[];
      onDelete: () => void;
    },
    public dialog: MatDialog
  ) {}

  onCancelBtnClicked(): void {
    this.dialogRef.close({ isDeleted: false });
  }

  async onDeleteBtnClicked(): Promise<void> {
    try {
      this.isLoading = true;
      await this.data.onDelete();
      this.dialogRef.close({ isDeleted: true });
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
