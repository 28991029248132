import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-something-went-wrong',
  standalone: true,
  imports: [MatButtonModule, TranslateModule],
  templateUrl: './something-went-wrong.component.html',
  styleUrls: ['./something-went-wrong.component.scss'],
})
export class SomethingWentWrongComponent {
  @Input() description: string | null = null;
  @Input() title: string | null = null;
  @Input() showRetryButton = true;
  @Input() showPicture = true;
  @Output() retryEmitter = new EventEmitter();

  constructor() {}

  onRetryBtnClicked(): void {
    this.retryEmitter.emit();
  }
}
