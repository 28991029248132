import { environment } from '@app-environments/environment';
import { Injectable } from '@angular/core';
import { ApiRequest } from '../api.request';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import {
  ImportEml,
  ImportEmlImportRequest,
  ImportEmlUploadRequest,
} from '@app-types/api/importEml';

const API_URL = environment.apiUrl;
const { importEML } = API_ROUTES;

@Injectable({
  providedIn: 'root',
})
export class ImportEmlClient {
  constructor(private apiRequest: ApiRequest) {}

  async importEML(request: ImportEmlImportRequest): Promise<ImportEml> {
    return this.apiRequest.post<ImportEmlImportRequest, ImportEml>(
      request,
      `${API_URL}${importEML.import}`
    );
  }

  async uploadEML(request: ImportEmlUploadRequest): Promise<ImportEml> {
    const formData = new FormData();
    Object.entries(request).forEach(([key, value]) => {
      if (value instanceof File) {
        formData.append(key, value, value.name);
      } else {
        formData.append(key, JSON.stringify(value));
      }
    });
    return this.apiRequest.post<FormData, ImportEml>(
      formData,
      `${API_URL}${importEML.upload}`
    );
  }
}
