import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  MAT_MENU_DEFAULT_OPTIONS,
  MatMenuModule,
  MatMenuTrigger,
} from '@angular/material/menu';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { EmailClient } from '@app-services/api/clients/email-client';
import { EmailTagClient } from '@app-services/api/clients/email-tag.client';
import { MailFolderClient } from '@app-services/api/clients/mail-folder.client';
import {
  BaseGetByFileIdRequest,
  BaseGetByIdRequest,
} from '@app-types/base/base';
import { EmailIcons } from '@app-types/enums/email-icons';
import { EmailState } from '@app-types/enums/email-state';
import { EmailType } from '@app-types/enums/email-type';
import {
  EmailContract,
  EmailListItemContract,
  EmailSearchByWidgetRequest,
  EmailSearchRequest,
} from '@app-types/api/email';
import { EmailValidationState } from '@app-types/enums/email.validation-state';
import { MailFolderType } from '@app-types/enums/mail-folder.type';
import { MobileObserverService } from '@app-services/adaptive/mobile-observer.service';
import { SnackbarService } from '@app-services/snackbar.service';
import { EmailAddGroupsDialogComponent } from '../email/email-add-groups-dialog/email-add-groups-dialog.component';
import { EmailAddTagsDialogComponent } from '../email/email-add-tags-dialog/email-add-tags-dialog.component';
import { ErrorDialogComponent } from '../common/error-dialog/error-dialog.component';
import { MailFolderSelectDialogComponent } from '../mail-account-folders/mail-folder-select-dialog/mail-folder-select-dialog.component';
import { ReassignDialogComponent } from '../reassign-dialog/reassign-dialog.component';
import { BaseGetByEmailFileIdRequest } from 'src/app/types/base/base';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { FormatEmailIconsByFolderType } from 'src/app/shared/pipes/format-email-icons-by-folder-type.pipe';
import { FormatSearchOrders } from 'src/app/shared/pipes/format-search-orders.pipe';
import { FileClient } from '@app-services/api/clients/file.client';
import { TempFileClient } from '@app-services/api/clients/temp-file.client';
import { EmailSearchOrder } from '@app-types/enums/email.search-order';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { MatSelectModule } from '@angular/material/select';
import { SearchInfo, SearchParams } from '@app-types/search';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoaderComponent } from '../common/loader/loader.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SomethingWentWrongComponent } from '../common/error/something-went-wrong/something-went-wrong.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MAT_CHIPS_DEFAULT_OPTIONS,
  MatChipsModule,
} from '@angular/material/chips';
import {
  EMAIL_QUERY_PARAM,
  STORAGE_NAMES,
  WEB_CODE,
} from '@app-shared/constants/constants';
import { filter } from 'rxjs/operators';
import {
  decodeInfoMailAccount,
  encodeInfoMailAccount,
} from '@app-utils/search-params';
import { UserWorkspaceService } from '@app-services/user-workspace-service';
import { SearchTypeEnum } from '@app-types/enums/filters';
import {
  getAllFoldersMailAccount,
  getFoldersAndSubfoldersId,
} from '@app-utils/folders';
import { PermissionType } from '@app-types/enums/permission-type';
import { DatePipe, LowerCasePipe } from '@angular/common';
import { LoadingIconButtonComponent } from '../common/loading-icon-button/loading-icon-button.component';
import { EmailExportClient } from '@app-services/api/clients/email-export-client';
import { CloseUploadDialogComponent } from '../import-eml/close-upload-dialog/close-upload-dialog.component';
import { DrawerService } from '@app-services/drawer.service';
import { LocalStorageService } from '@app-services/local-storage.service';
import { EmailNoteListComponent } from '../email/email-note-list/email-note-list.component';
import { EmailAuditComponent } from '../common/email/email-audit/email-audit.component';
import { EmailCreateOrUpdateComponent } from '../email/email-create-or-update/email-create-or-update.component';
import { PaginatorComponent } from '../common/paginator/paginator.component';
import { ImportEmlComponent } from '../import-eml/import-eml.component';
import { EmailNotificationsDialogComponent } from '../common/email/email-notifications-dialog/email-notifications-dialog.component';
import { EmailDetailDialogComponent } from '../email/email-detail-dialog/email-detail-dialog.component';
import {
  ALL,
  NOT_VALIDATED,
  VALIDATED,
} from '@app-shared/constants/validatedEmail';
import { Subscription } from 'rxjs';
import { DeleteDialogComponent } from '../common/delete-dialog/delete-dialog.component';
import { OptionsEnum } from '@app-types/common';
import { EmailMetaComponent } from '@app-components/email/email-meta/email-meta.component';
import { MatCardModule } from '@angular/material/card';
import { SearchOrderComponent } from '@app-components/common/search-order/search-order.component';
import { SkeletonTableComponent } from '@app-components/common/skeletons/skeleton-table/skeleton-table.component';
const { PAGE_SIZE } = STORAGE_NAMES;

@Component({
  selector: 'app-mails-content',
  templateUrl: './emails-content.component.html',
  styleUrls: ['./emails-content.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    LoaderComponent,
    MatFormFieldModule,
    MatSelectModule,
    MatPaginatorModule,
    SomethingWentWrongComponent,
    MatCheckboxModule,
    MatChipsModule,
    MatTableModule,
    LowerCasePipe,
    DatePipe,
    LoadingIconButtonComponent,
    MatMenuModule,
    EmailNoteListComponent,
    EmailAuditComponent,
    ErrorDialogComponent,
    EmailCreateOrUpdateComponent,
    PaginatorComponent,
    ImportEmlComponent,
    CloseUploadDialogComponent,
    EmailNotificationsDialogComponent,
    EmailDetailDialogComponent,
    EmailMetaComponent,
    MatCardModule,
    SearchOrderComponent,
    SkeletonTableComponent,
  ],
  providers: [
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: { hideSingleSelectionIndicator: true },
    },
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'panelClassMenu' },
    },
  ],
})
export class EmailsContentComponent implements OnInit, OnDestroy {
  isAllFoldersSelected: boolean;

  public emailIcons = EmailIcons;
  public emailValidationState = EmailValidationState;
  public webCode: string;
  public showLoader = true;
  public isError = false;
  public emails: EmailListItemContract[] = [];
  public dataSource = new MatTableDataSource<EmailListItemContract>(
    this.emails
  );
  public displayedColumns: string[] = [
    'select',
    'attachment',
    'from',
    'to',
    'subject',
    'date',
    'source',
    'state',
    'meta',
    'notes',
    'folder',
  ];
  public pageNumber = 1;
  public pageSize = 25;
  public selection = new SelectionModel<number>(true, []);
  public searchWidgetId: number;
  public canNavigateBack = false;
  public canNavigateForward = false;
  public useMobileView = false;
  public useTabletView = false;
  private useMobileViewSubscription: Subscription;
  private useTabletViewSubscription: Subscription;
  public updatedEmail: EmailContract | EmailListItemContract | null;
  public menuTitle: string | null;
  public openedEmail: EmailListItemContract | null;
  public emailStates = EmailState;
  private selectedMailFolderIds: number[] = [];
  public searchParams: SearchParams = new SearchParams();
  public selectedMailFolderId: number | null;
  public menuTopLeftPosition = { x: '0', y: '0' };
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;
  public openedEmailInfo: any;
  public mailFolderType = MailFolderType;
  public notificationMailId: number;
  public notesMailId: number;
  public auditMailId: number;
  public metaMailId: number;
  public searchOrder: EmailSearchOrder;
  public isShowFolderColumn: boolean;
  public isExporting = false;
  public isDownloadingEML = false;
  public isDownloadingSingleEML = false;
  public validatingEmailIds: number[] = [];
  public downloadingEMLEmailId: number | null;
  public downloadingSingleEMLEmailId: number | null;
  public folderCount: number;
  public hasAttachments = false;
  public searchOrders: OptionsEnum[];
  public mouseCoordinatesX: number;
  public mouseCoordinatesY: number;
  public replyingEmailId: number | null = null;
  public replyingAllEmailId: number | null = null;
  public forwardingEmailId: number | null = null;
  public canFinalDelete = false;
  public isLoadingEML = false;
  routeSubscribe$: Subscription;
  infoOpenedMailAccount: SearchInfo;
  successDeleteEmailIds: number[] = [];

  @ViewChild('metaDrawer') metaDrawer: TemplateRef<any>;
  @ViewChild('notesDrawer') notesDrawer: TemplateRef<any>;
  @ViewChild('auditDrawer') auditDrawer: TemplateRef<any>;
  @ViewChild('createOrUpdateDrawer') createOrUpdateDrawer: TemplateRef<any>;
  @ViewChild('importEMLIsDrawer') importEMLIsDrawer: TemplateRef<any>;
  @ViewChild('notificationDrawer') notificationDrawer: TemplateRef<any>;
  @ViewChild('emailDrawer') emailDrawer: TemplateRef<any>;
  private formatSearchOrders = new FormatSearchOrders();
  private formatEmailIconsByFolderType = new FormatEmailIconsByFolderType();

  constructor(
    private activateRoute: ActivatedRoute,
    private router: Router,
    private emailClient: EmailClient,
    private snackbarHelper: SnackbarService,
    private mailFolderClient: MailFolderClient,
    public dialog: MatDialog,
    private translate: TranslateService,
    private mobileObserverService: MobileObserverService,
    private emailExportClient: EmailExportClient,
    private fileClient: FileClient,
    private tempFileClient: TempFileClient,
    private matchError: MatchError,
    private emailTagClient: EmailTagClient,
    private permissionService: PermissionService,
    public userWorkspaceService: UserWorkspaceService,
    private drawerService: DrawerService,
    private localStorageService: LocalStorageService
  ) {}

  async ngOnInit(): Promise<void> {
    this.useMobileViewSubscription = this.mobileObserverService
      .mobileObserver()
      .subscribe(isMobile => (this.useMobileView = isMobile));
    this.useTabletViewSubscription = this.mobileObserverService
      .tabletObserver()
      .subscribe(isTablet => (this.useTabletView = isTablet));

    this.webCode =
      this.activateRoute.parent?.snapshot.paramMap.get(WEB_CODE) ?? '';

    if (!this.userWorkspaceService.mailAccounts.length) return;

    await this.setInfoOpenMAilAccount(window.location.href, true, true);

    this.routeSubscribe$ = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.selection = new SelectionModel<number>(true, []);
        this.setInfoOpenMAilAccount(window.location.href, true);
      });
  }

  async setInfoOpenMAilAccount(
    url: string,
    getFolders = false,
    getCount?: boolean
  ): Promise<void> {
    const newUrl = new URL(url);
    const search: string = newUrl.searchParams.get(EMAIL_QUERY_PARAM) ?? '';
    const info: SearchInfo = decodeInfoMailAccount(search);
    const showLoader = info.type !== this.infoOpenedMailAccount?.type;
    this.infoOpenedMailAccount = info;
    this.isAllFoldersSelected = info?.searchType === SearchTypeEnum.allFolders;
    this.pageNumber = info?.page ?? 1;
    this.pageSize = info?.pageSize
      ? info.pageSize
      : this.localStorageService.getData(PAGE_SIZE) || 25;

    this.searchParams = info?.filter ?? new SearchParams();
    this.searchParams.hideNotifications = info?.hideNotifications !== false;
    this.searchParams.validated =
      info?.tab === 0 || (!info?.tab && info.type === MailFolderType.Inbox)
        ? VALIDATED
        : info?.tab === 1
          ? NOT_VALIDATED
          : ALL;
    this.searchParams.reassigned =
      this.searchParams?.reassignedId && this.isAllFoldersSelected
        ? true
        : this.isAllFoldersSelected
          ? null
          : info?.tab === 2;
    this.searchParams.fullText = info?.search ?? null;

    if (info.type === this.mailFolderType.Spam) {
      this.searchParams.isSpam = true;
    }
    if (info.type === this.mailFolderType.Archive) {
      this.searchParams.isArchive = true;
    }
    if (info.type === this.mailFolderType.Trash) {
      this.searchParams.deleted = true;
    }

    this.searchOrder = info?.order ? info.order : this.searchParams.searchOrder;

    const mailAccountFolders = this.userWorkspaceService.userFolders.filter(
      el =>
        el.mailAccountId ===
        (info?.acc ?? this.userWorkspaceService.mailAccounts[0].mailAccountId)
    );

    if (
      info?.folder &&
      (!info?.searchType || info?.searchType === SearchTypeEnum.currSelections)
    ) {
      this.selectedMailFolderIds = [info.folder];
      this.selectedMailFolderId = info.folder;
    }

    if (
      info?.folder &&
      info?.searchType === SearchTypeEnum.currSelectionsPlusSubfolders
    ) {
      this.selectedMailFolderIds = getFoldersAndSubfoldersId(
        info.folder,
        mailAccountFolders
      );
      this.selectedMailFolderId = info.folder;
    }

    if (info?.searchType === SearchTypeEnum.allFolders) {
      info?.acc &&
        (this.selectedMailFolderIds = getAllFoldersMailAccount(
          info.acc,
          mailAccountFolders
        ));
      this.selectedMailFolderId = null;
    }

    this.isShowFolderColumn = this.selectedMailFolderIds?.length > 1;

    this.canFinalDelete = await this.permissionService.hasPermissionOver(
      null,
      this.infoOpenedMailAccount?.acc ?? null,
      PermissionType.CanFinalDelete
    );

    await this.loadData(
      getFolders,
      getCount !== undefined ? getCount : this.pageNumber === 1,
      showLoader
    );
  }

  public showIconById(mailFolderId: number, iconType: EmailIcons): boolean {
    const emailFolderType = this.userWorkspaceService?.userFolders?.find(
      e =>
        e.mailFolderId === mailFolderId &&
        e.mailAccountId === this.infoOpenedMailAccount.acc
    )?.folderType;
    return (
      !!emailFolderType &&
      this.formatEmailIconsByFolderType.transform(emailFolderType, iconType)
    );
  }

  public showIconByIdSelected(iconType: EmailIcons): boolean {
    const selectedMailFolderIds = this.dataSource?.data
      ?.filter(e => this.selection.selected.includes(e.emailId))
      .map(e => e.mailFolderId);
    return (
      !!selectedMailFolderIds &&
      selectedMailFolderIds.every(e => this.showIconById(e, iconType))
    );
  }

  public isSelectedRetrySendingNeededState(): boolean {
    const selectedMailFolderState = this.dataSource?.data
      ?.filter(e => this.selection.selected.includes(e.emailId))
      .map(e => e.state);
    return (
      !!selectedMailFolderState && selectedMailFolderState.every(e => e === 5)
    );
  }

  public folderName(mailFolderId: number): string {
    const emailFolder = this.userWorkspaceService.userFolders.find(
      e =>
        e.mailFolderId === mailFolderId &&
        e.mailAccountId === this.infoOpenedMailAccount.acc
    );
    return emailFolder
      ? emailFolder.creationType === 1
        ? 'folderType' + emailFolder.folderType
        : emailFolder.mailFolderName
      : '';
  }

  onRightClick(event: MouseEvent, item: any): void {
    // preventDefault avoids to show the visualization of the right-click menu of the browser
    event.preventDefault();

    // we record the mouse position in our object
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';

    if (this.matMenuTrigger) {
      // we open the menu
      // we pass to the menu the information about our object
      this.matMenuTrigger.menuData = { item };

      // we open the menu
      this.matMenuTrigger.openMenu();
    }
  }

  sourceRaw(email: EmailListItemContract): string {
    if (
      this.infoOpenedMailAccount.type === MailFolderType.Archive ||
      this.infoOpenedMailAccount.type === MailFolderType.Trash
    ) {
      return 'folderType' + email.sourceFolderType;
    } else {
      return '';
    }
  }

  public isRowSelected(id: number): boolean {
    return this.selection.selected.includes(id);
  }

  public isRowWithError(email: EmailListItemContract): boolean {
    const emailState = email.state;
    const emailType = email.type;
    const emailStates = [
      EmailState.DeliveryError,
      EmailState.VirusDetected,
      EmailState.DispatchError,
      EmailState.NotDispatched,
      EmailState.NotAccepted,
      EmailState.SendError,
    ];

    const emailTypes = [EmailType.DeliveryError, EmailType.NonAcceptance];

    return emailStates.includes(emailState) || emailTypes.includes(emailType);
  }

  public isSentStates(emailState: number): boolean {
    const emailStates = [
      EmailState.Sent,
      EmailState.Delivered,
      EmailState.DeliveryError,
      EmailState.Sending,
      EmailState.Downloaded,
      EmailState.NotAccepted,
      EmailState.Accepted,
    ];
    return emailStates.includes(emailState);
  }

  public getInfoAboutOpenedEmail(openedEmail: any): void {
    this.openedEmailInfo = openedEmail;
  }

  public setSeenOpened(obj: any): void {
    this.dataSource.data = this.dataSource.data.map(e =>
      e.emailId === obj.emailId ? { ...e, seen: obj.b } : e
    );
    this.infoOpenedMailAccount?.acc &&
      this.userWorkspaceService.refreshUnreadForFolder(
        obj.folderId,
        this.infoOpenedMailAccount.acc
      );
  }

  public setValidationStateForEmail(emailId: number): void {
    this.dataSource.data = this.dataSource.data.map(e =>
      e.emailId === emailId
        ? { ...e, validationState: EmailValidationState.Validated }
        : e
    );
  }

  async setSeen(b: boolean, email?: EmailListItemContract): Promise<any> {
    const ids = email ? [email.emailId] : this.selection.selected;
    try {
      await this.emailClient.setSeen({ emailIds: ids, seen: b });
      this.dataSource.data = this.dataSource.data.map(e =>
        ids.includes(e.emailId) ? { ...e, seen: b } : e
      );
      if (email) {
        this.userWorkspaceService.refreshUnreadForFolder(
          email.mailFolderId,
          email.mailAccountId
        );
      } else {
        this.infoOpenedMailAccount.acc &&
          (await this.userWorkspaceService.refreshUnread({
            mailAccountId: this.infoOpenedMailAccount.acc,
          }));
      }
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  async restoreMoveFolderChoose(
    ids: number[],
    operationType: string
  ): Promise<any> {
    if (ids.length > 0) {
      const dialogRef = this.dialog.open(MailFolderSelectDialogComponent, {
        width: '350px',
        autoFocus: false,
        data: {
          mailAccountId: this.infoOpenedMailAccount.acc,
          ids,
          isRestore: true,
          folderType: this.infoOpenedMailAccount.type,
          title: 'Select the folder to restore',
        },
      });
      dialogRef
        .afterClosed()
        .subscribe((e: { confirmed: boolean; folderId: number }) => {
          if (e?.confirmed) {
            if (operationType === 'restoreOneDeleted') {
              this.restore(e.folderId, ids);
            }
            if (operationType === 'restoreOneSpam') {
              this.setNotSpam(ids, e.folderId);
            }
            if (operationType === 'restoreSelectedSpam') {
              this.setSelectedNotSpam(e.folderId);
            }
            if (operationType === 'restoreSelectedDeleted') {
              this.restore(e.folderId);
            }
          }
        });
    }
  }

  async restore(folderId: number, id?: number[]): Promise<any> {
    const ids = id ? id : this.selection.selected;
    try {
      await this.emailClient.restore({ emailIds: ids, mailFolderId: folderId });
      this.dataSource.data = this.dataSource.data.filter(
        e => !ids.includes(e.emailId)
      );
      id && this.selection.deselect(id[0]);
      !id && this.selection.clear();
      this.infoOpenedMailAccount.acc &&
        (await this.userWorkspaceService.refreshUnread({
          mailAccountId: this.infoOpenedMailAccount.acc,
        }));
      this.reloadDataOnDemand();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  async setSpam(id: number): Promise<any> {
    try {
      await this.emailClient.setIsSpam({ emailIds: [id] });
      this.dataSource.data = this.dataSource.data.filter(e => e.emailId !== id);
      this.selection.deselect(id);
      this.infoOpenedMailAccount.acc &&
        (await this.userWorkspaceService.refreshUnread({
          mailAccountId: this.infoOpenedMailAccount.acc,
        }));
      this.reloadDataOnDemand();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  public async moveToDraft(id: number): Promise<void> {
    try {
      await this.emailClient.moveToDraft({ emailId: id });
      this.dataSource.data = this.dataSource.data.filter(e => e.emailId !== id);
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  public async retrySend(id?: number): Promise<void> {
    const ids = id ? [id] : this.selection.selected;
    try {
      await this.emailClient.retrySend({ list: ids });
      this.dataSource.data = this.dataSource.data.map(e =>
        ids.includes(e.emailId) ? { ...e, state: 3 } : e
      );
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  async reload(): Promise<void> {
    this.selection.clear();
    await this.loadData();
    this.infoOpenedMailAccount.acc &&
      (await this.userWorkspaceService.refreshUnread({
        mailAccountId: this.infoOpenedMailAccount.acc,
      }));
  }

  async setValidated(
    newStatus: EmailValidationState,
    emailId?: number
  ): Promise<void> {
    const ids = emailId ? [emailId] : this.selection.selected;
    this.validatingEmailIds = ids;
    try {
      await this.emailClient.setValidated({
        emailsId: ids,
        validated: newStatus,
      });
      if (emailId) {
        if (
          this.searchParams.validated?.[0] === EmailValidationState.NotValidated
        ) {
          this.dataSource.data = this.dataSource.data.filter(
            e => !ids.includes(e.emailId)
          );
          this.reloadDataOnDemand();
        } else {
          this.setValidationStateForEmail(emailId);
        }
      } else {
        this.dataSource.data = this.dataSource.data.filter(
          e => !ids.includes(e.emailId)
        );
        this.reloadDataOnDemand();
      }
      emailId && this.selection.deselect(emailId);
      !emailId && this.selection.clear();
      this.snackbarHelper.openSnackBar(
        this.translate.instant('PECWasValidatedSuccessfully')
      );
      this.infoOpenedMailAccount.acc &&
        (await this.userWorkspaceService.refreshUnread({
          mailAccountId: this.infoOpenedMailAccount.acc,
        }));
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.validatingEmailIds = [];
    }
  }

  public isEmailValidating(emailId: number): boolean {
    return this.validatingEmailIds.includes(emailId);
  }

  async setNotSpam(id: number[], folderId: number): Promise<any> {
    try {
      await this.emailClient.setNotSpam({
        emailIds: id,
        mailFolderId: folderId,
      });
      this.dataSource.data = this.dataSource.data.filter(
        e => e.emailId !== id[0]
      );
      this.selection.deselect(id[0]);
      this.infoOpenedMailAccount.acc &&
        (await this.userWorkspaceService.refreshUnread({
          mailAccountId: this.infoOpenedMailAccount.acc,
        }));
      this.reloadDataOnDemand();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  async restoreFromArchive(id: number | null = null): Promise<any> {
    const ids = id ? [id] : this.selection.selected;
    const emails = this.dataSource.data.filter(e => ids.includes(e.emailId));
    if (
      !emails.every(
        (e, i, arr) => arr[0].sourceFolderType === e.sourceFolderType
      )
    ) {
      this.openErrorDialog(
        'errorPopupTitle',
        'sourceFoldersMustBeTheSameErrorPopupSubtitle'
      );
      return null;
    }
    if (ids.length > 0) {
      const dialogRef = this.dialog.open(MailFolderSelectDialogComponent, {
        width: '350px',
        autoFocus: false,
        data: {
          mailAccountId: this.infoOpenedMailAccount.acc,
          ids,
          isRestore: true,
          folderType: this.infoOpenedMailAccount.type,
          isArchive: true,
          sourceFoldersType: emails[0].sourceFolderType,
          title: 'restoreFromArchive',
        },
      });

      dialogRef
        .afterClosed()
        .subscribe(async (e: { confirmed: boolean; folderId: number }) => {
          if (e?.confirmed) {
            try {
              await this.emailClient.restoreFromArchive({
                emailIds: ids,
                mailFolderId: e.folderId,
              });
              this.dataSource.data = this.dataSource.data.filter(
                el => !ids.includes(el.emailId)
              );
              this.selection.clear();
              this.infoOpenedMailAccount.acc &&
                (await this.userWorkspaceService.refreshUnread({
                  mailAccountId: this.infoOpenedMailAccount.acc,
                }));
              this.reloadDataOnDemand();
            } catch (e) {
              this.matchError.errorHandler(e);
              this.matchError.logError(e);
            }
          }
        });
    }
  }

  async restoreFromTrash(emailId?: number): Promise<any> {
    const ids = emailId ? [emailId] : this.selection.selected;
    const emails = this.dataSource.data.filter(e => ids.includes(e.emailId));
    if (
      !emails.every(
        (e, i, arr) => arr[0].sourceFolderType === e.sourceFolderType
      )
    ) {
      this.openErrorDialog(
        'error',
        'sourceFoldersMustBeTheSameErrorPopupSubtitle'
      );
      return null;
    }
    if (ids.length > 0) {
      const dialogRef = this.dialog.open(MailFolderSelectDialogComponent, {
        width: '350px',
        autoFocus: false,
        data: {
          mailAccountId: this.infoOpenedMailAccount.acc,
          ids,
          isRestore: true,
          folderType: this.infoOpenedMailAccount.type,
          isTrash: true,
          sourceFoldersType: emails[0].sourceFolderType,
          title: 'restoreEmail',
        },
      });

      dialogRef
        .afterClosed()
        .subscribe(async (e: { confirmed: boolean; folderId: number }) => {
          if (e?.confirmed) {
            try {
              await this.emailClient.restore({
                emailIds: ids,
                mailFolderId: e.folderId,
              });
              this.dataSource.data = this.dataSource.data.filter(
                el => !ids.includes(el.emailId)
              );
              this.selection.clear();
              this.infoOpenedMailAccount.acc &&
                (await this.userWorkspaceService.refreshUnread({
                  mailAccountId: this.infoOpenedMailAccount.acc,
                }));
              this.reloadDataOnDemand();
            } catch (e) {
              this.matchError.errorHandler(e);
              this.matchError.logError(e);
            }
          }
        });
    }
  }

  public reloadDataOnDemand(): void {
    if (!this.dataSource.data.length) {
      this.reload();
    }
  }

  async setSelectedSpam(): Promise<any> {
    const ids = this.selection.selected;
    try {
      await this.emailClient.setIsSpam({ emailIds: ids });
      this.dataSource.data = this.dataSource.data.filter(
        e => !ids.includes(e.emailId)
      );
      this.selection.clear();
      this.infoOpenedMailAccount.acc &&
        (await this.userWorkspaceService.refreshUnread({
          mailAccountId: this.infoOpenedMailAccount.acc,
        }));
      this.reloadDataOnDemand();
    } catch (e: any) {
      this.openErrorDialog(
        'errorPopupTitle',
        e.Description || 'cannotSetSpamSelectedErrorPopupSubtitle'
      );
      this.matchError.logError(e);
    }
  }

  async setSelectedArchive(id: number | null = null): Promise<any> {
    const ids = id ? [id] : this.selection.selected;
    if (ids.length > 0) {
      const dialogRef = this.dialog.open(MailFolderSelectDialogComponent, {
        width: '350px',
        autoFocus: false,
        data: {
          mailAccountId: this.infoOpenedMailAccount.acc,
          ids,
          isRestore: true,
          folderType: this.infoOpenedMailAccount.type,
          isArchive: true,
          sourceFoldersType: MailFolderType.Archive,
          title: 'moveToArchive',
        },
      });

      dialogRef
        .afterClosed()
        .subscribe(async (e: { confirmed: boolean; folderId: number }) => {
          if (e?.confirmed) {
            try {
              await this.emailClient.archive({
                emailIds: ids,
                mailFolderId: e.folderId,
              });
              this.dataSource.data = this.dataSource.data.filter(
                el => !ids.includes(el.emailId)
              );
              this.selection.clear();
              this.infoOpenedMailAccount.acc &&
                (await this.userWorkspaceService.refreshUnread({
                  mailAccountId: this.infoOpenedMailAccount.acc,
                }));
              this.reloadDataOnDemand();
            } catch (e) {
              this.matchError.errorHandler(e);
              this.matchError.logError(e);
            }
          }
        });
    }
  }

  async setSelectedNotSpam(folderId: number): Promise<any> {
    const ids = this.selection.selected;
    try {
      await this.emailClient.setNotSpam({
        emailIds: ids,
        mailFolderId: folderId,
      });
      this.dataSource.data = this.dataSource.data.filter(
        e => !ids.includes(e.emailId)
      );
      this.selection.clear();
      this.infoOpenedMailAccount.acc &&
        (await this.userWorkspaceService.refreshUnread({
          mailAccountId: this.infoOpenedMailAccount.acc,
        }));
      this.reloadDataOnDemand();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  async delete(id: number): Promise<any> {
    try {
      await this.emailClient.delete({ emailIds: [id] });
      this.dataSource.data = this.dataSource.data.filter(e => e.emailId !== id);
      this.selection.deselect(id);
      this.infoOpenedMailAccount.acc &&
        (await this.userWorkspaceService.refreshUnread({
          mailAccountId: this.infoOpenedMailAccount.acc,
        }));
      this.reloadDataOnDemand();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  async deleteAllSelected(): Promise<any> {
    const ids = this.selection.selected;
    try {
      await this.emailClient.delete({ emailIds: ids });
      this.dataSource.data = this.dataSource.data.filter(
        e => !ids.includes(e.emailId)
      );
      this.selection.clear();
      this.infoOpenedMailAccount.acc &&
        (await this.userWorkspaceService.refreshUnread({
          mailAccountId: this.infoOpenedMailAccount.acc,
        }));
      this.reloadDataOnDemand();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  async onDelete(ids: number[]): Promise<void> {
    const result = await this.emailClient.finalDelete({ emailIds: ids });
    if (result.countUnsuccessDelete && result.countUnsuccessDelete > 0) {
      this.openErrorDialog(
        'error',
        'messagesNotDeleted',
        result.countUnsuccessDelete
      );
    }
    this.successDeleteEmailIds = result.successDeleteEmailIds ?? [];
  }

  async finalDelete(id?: number): Promise<any> {
    const ids = id ? [id] : this.selection.selected;
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '450px',
      autoFocus: false,
      data: {
        title: 'permanentlyDelete',
        subTitles: [
          {
            title: 'attention',
            subTitle: 'selectedMessagePermanentlyDeleted',
          },
          {
            title: 'trashUpperCase',
            subTitle: 'andNoPossibleRecover',
          },
        ],
        onDelete: async () => await this.onDelete(ids),
      },
    });

    dialogRef.afterClosed().subscribe(x => {
      if (x.isDeleted) {
        if (this.successDeleteEmailIds?.length) {
          this.dataSource.data = this.dataSource.data.filter(
            e => !this.successDeleteEmailIds.includes(e.emailId)
          );
          this.selection.clear();
          this.infoOpenedMailAccount.acc &&
            this.userWorkspaceService.refreshUnread({
              mailAccountId: this.infoOpenedMailAccount.acc,
            });
          this.reloadDataOnDemand();
          this.successDeleteEmailIds = [];
        }
      }
    });
  }

  async reply(emails?: EmailListItemContract): Promise<any> {
    const needEmail = emails
      ? emails
      : this.dataSource.data.filter(
          e => e.emailId === this.selection.selected[0]
        )[0];

    try {
      this.replyingEmailId = needEmail.emailId;
      const newEmail = await this.emailClient.reply({
        emailId: needEmail.emailId,
      });
      const replyEmail = { ...needEmail };
      replyEmail.emailId = newEmail.id;

      this.openCreateOrUpdateModalForm(replyEmail, 'Reply');
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.replyingEmailId = null;
    }
  }

  async replyAll(emails?: EmailListItemContract): Promise<any> {
    const needEmail = emails
      ? emails
      : this.dataSource.data.filter(
          e => e.emailId === this.selection.selected[0]
        )[0];

    try {
      this.replyingAllEmailId = needEmail.emailId;
      const newEmail = await this.emailClient.replyAll({
        emailId: needEmail.emailId,
      });
      const replyEmail = { ...needEmail };
      replyEmail.emailId = newEmail.id;

      this.openCreateOrUpdateModalForm(replyEmail, 'Reply all');
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.replyingAllEmailId = null;
    }
  }

  async exportExcel(): Promise<void> {
    this.isExporting = true;
    try {
      const request = {
        page: 1,
        pageSize: 1,
        filter: this.searchParams,
        mailFolderIds: this.selectedMailFolderIds,
      };

      const fileResponse = await this.emailExportClient.exportExcel(request);
      const downloadRequest = new BaseGetByFileIdRequest(
        fileResponse.result.fileId
      );
      await this.tempFileClient.download(downloadRequest);
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isExporting = false;
    }
  }

  importEML(): void {
    this.drawerService.openDrawer(
      this.importEMLIsDrawer,
      () => {
        this.closeImportEml();
      },
      'create-email'
    );
  }

  onStartRecording(e: MouseEvent): void {
    if (e.button === 0) {
      this.mouseCoordinatesX = e.x;
      this.mouseCoordinatesY = e.y;
    }
  }

  onFinishRecording(e: MouseEvent, row: EmailListItemContract): void {
    if (e.button === 0) {
      this.mouseCoordinatesX === e.x &&
        this.mouseCoordinatesY === e.y &&
        this.emailDetails(row);
    }
  }

  async loadData(
    getFolders: boolean = false,
    getCount: boolean = false,
    showLoader: boolean = false
  ): Promise<void> {
    const loaderTimeout = setTimeout(() => {
      !showLoader && (this.showLoader = true);
    }, 500);
    showLoader && (this.showLoader = true);

    this.isError = false;
    try {
      getFolders && (await this.getFolderType());
      this.searchParams.searchOrder = this.searchOrder;
      await this.getEmails(this.pageNumber, this.pageSize, getCount);
    } catch (e) {
      this.isError = true;
      this.matchError.logError(e);
    } finally {
      clearTimeout(loaderTimeout);
      this.showLoader = false;
    }
  }

  public async getFolderType(): Promise<void> {
    let searchOrders = Object.keys(EmailSearchOrder)
      .filter(k => !(parseInt(k, 10) >= 0))
      .map(key => ({
        id: EmailSearchOrder[key as keyof typeof EmailSearchOrder],
        name: this.formatSearchOrders.transform(
          EmailSearchOrder[key as keyof typeof EmailSearchOrder]
        ),
      }));
    if (
      this.infoOpenedMailAccount.type === this.mailFolderType.Inbox ||
      this.infoOpenedMailAccount.type === this.mailFolderType.Spam
    ) {
      searchOrders = searchOrders.filter(
        e => e.id !== EmailSearchOrder.OrderByTo
      );
    }
    if (
      this.infoOpenedMailAccount.type === this.mailFolderType.Validation ||
      this.infoOpenedMailAccount.type === this.mailFolderType.Draft ||
      this.infoOpenedMailAccount.type === this.mailFolderType.Outbox
    ) {
      if (
        this.searchOrder === EmailSearchOrder.OrderBySentDateTime ||
        this.searchOrder === EmailSearchOrder.OrderByStateDateTime
      ) {
        this.searchOrder = EmailSearchOrder.OrderByStateDateTime;
      }
      searchOrders = searchOrders.filter(
        e => e.id !== EmailSearchOrder.OrderBySentDateTime
      );
    } else {
      searchOrders = searchOrders.filter(
        e => e.id !== EmailSearchOrder.OrderByStateDateTime
      );
    }
    if (
      this.infoOpenedMailAccount.type &&
      this.infoOpenedMailAccount.type >= this.mailFolderType.Sent &&
      this.infoOpenedMailAccount?.type <= this.mailFolderType.Validation
    ) {
      searchOrders = searchOrders.filter(
        e => e.id !== EmailSearchOrder.OrderByFrom
      );
    }
    searchOrders = searchOrders.filter(
      e =>
        e.id !==
        EmailSearchOrder.OrderBySentDateTimeOrStateDateTimeIfSentDateTimeIsNull
    );
    if (
      this.infoOpenedMailAccount?.type &&
      this.infoOpenedMailAccount.type === this.mailFolderType.Trash
    ) {
      searchOrders = searchOrders.map(e =>
        e.id === EmailSearchOrder.OrderBySentDateTime
          ? {
              ...e,
              id: EmailSearchOrder.OrderBySentDateTimeOrStateDateTimeIfSentDateTimeIsNull,
            }
          : e
      );
      if (this.searchOrder === EmailSearchOrder.OrderBySentDateTime) {
        this.searchOrder =
          EmailSearchOrder.OrderBySentDateTimeOrStateDateTimeIfSentDateTimeIsNull;
      }
    }
    this.searchOrders = searchOrders;
  }

  public showIcon(iconTypes: number[]): boolean {
    return (
      !!this.infoOpenedMailAccount?.type &&
      iconTypes.includes(this.infoOpenedMailAccount.type)
    );
  }

  public isColumnShow(isTo: boolean, isClass = false): boolean {
    if (
      (this.infoOpenedMailAccount.type &&
        this.infoOpenedMailAccount.type === this.mailFolderType.Inbox) ||
      this.infoOpenedMailAccount.type === this.mailFolderType.Spam
    ) {
      return isTo;
    }
    if (
      this.infoOpenedMailAccount.type &&
      this.infoOpenedMailAccount?.type >= this.mailFolderType.Inbox &&
      this.infoOpenedMailAccount?.type <= this.mailFolderType.Validation
    ) {
      return !isTo;
    } else {
      return isClass;
    }
  }

  public countFrom(): string {
    return (
      this.folderCount
        ? this.pageNumber === 1
          ? 1
          : this.pageSize * (this.pageNumber - 1) + 1
        : '1'
    ).toString();
  }

  public countTo(): string {
    return (
      this.folderCount
        ? this.pageSize * this.pageNumber < this.folderCount
          ? this.pageSize * this.pageNumber
          : this.folderCount
        : '0'
    ).toString();
  }

  public countTotal(): string {
    return this.folderCount ? `${this.folderCount}` : '0';
  }

  async getEmails(
    pageNumber: number,
    pageSize: number,
    getCount: boolean
  ): Promise<void> {
    this.canNavigateBack = false;
    this.canNavigateForward = false;
    try {
      const hasWidget = this.searchWidgetId && this.searchWidgetId > 0;
      const request = {
        page: pageNumber,
        pageSize,
        mailFolderIds: this.selectedMailFolderIds,
      };
      !hasWidget &&
        getCount &&
        ((request as EmailSearchRequest).getCount = getCount);
      hasWidget
        ? ((request as EmailSearchByWidgetRequest).searchWidgetId =
            this.searchWidgetId)
        : ((request as EmailSearchRequest).filter = this.searchParams);

      const result = hasWidget
        ? await this.emailClient.searchByWidget(
            request as EmailSearchByWidgetRequest
          )
        : await this.emailClient.search(request as EmailSearchRequest);

      !hasWidget &&
        (this.folderCount = getCount ? result.count : this.folderCount);
      this.emails = result.data;

      this.dataSource.data = this.emails;
      this.canNavigateBack = this.emails && this.pageNumber > 1;
      this.canNavigateForward =
        this.emails && this.emails.length === this.pageSize;
      this.hasAttachments = this.dataSource.data.some(e => e.hasAttachments);
      this.selection.clear();
    } catch (e) {
      this.isError = true;
      this.emails = [];
      this.dataSource.data = [];
      this.matchError.logError(e);
    }
  }

  async emailDetails(row: EmailListItemContract): Promise<void> {
    if (
      row.state === EmailState.Draft ||
      row.state === EmailState.ValidationRejected ||
      this.infoOpenedMailAccount?.type === MailFolderType.Draft
    ) {
      this.editEmail(row);
      return;
    }
    this.openEmailDetails(row);
  }

  public closeImportEml(): void {
    if (this.isLoadingEML) {
      const dialogRef = this.dialog.open(CloseUploadDialogComponent, {
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe(async ({ isClose }) => {
        if (isClose) {
          this.drawerService.closeDrawer();
          this.isLoadingEML = false;
        }
      });
    } else {
      this.drawerService.closeDrawer();
    }
  }

  public openNotifications(id: number): void {
    this.notificationMailId = id;
    this.drawerService.openDrawer(this.notificationDrawer, null, 'small');
  }

  public refreshNotes(): void {
    this.dataSource.data = this.dataSource.data.map(e =>
      e.emailId === this.notesMailId
        ? { ...e, notesCount: e.notesCount + 1 }
        : e
    );
  }

  public openNotes(id: number): void {
    this.notesMailId = id;
    this.drawerService.openDrawer(this.notesDrawer, null, 'small');
  }

  public openAudit(auditMailId: number | null = null): void {
    if (auditMailId) {
      this.auditMailId = auditMailId;
    } else {
      this.auditMailId = this.selection.selected[0];
    }
    this.drawerService.openDrawer(this.auditDrawer, null, 'small');
  }

  public openMeta(id: number): void {
    this.metaMailId = id;
    this.drawerService.openDrawer(this.metaDrawer);
  }

  changePage(): void {
    if (
      this.infoOpenedMailAccount?.acc &&
      this.infoOpenedMailAccount?.folder &&
      this.infoOpenedMailAccount?.type
    ) {
      const data: SearchInfo = {};
      this.pageNumber !== 1 && (data.page = this.pageNumber);
      this.searchOrder !== EmailSearchOrder.OrderBySentDateTime &&
        (data.order = this.searchOrder);
      this.pageSize !== 25 && (data.pageSize = this.pageSize);
      this.infoOpenedMailAccount?.hideNotifications === false &&
        (data.hideNotifications = this.infoOpenedMailAccount.hideNotifications);
      this.infoOpenedMailAccount?.search &&
        (data.search = this.infoOpenedMailAccount.search);
      this.infoOpenedMailAccount?.searchType &&
        (data.searchType = this.infoOpenedMailAccount.searchType);
      this.infoOpenedMailAccount?.filter &&
        (data.filter = this.infoOpenedMailAccount.filter);
      this.infoOpenedMailAccount?.tab &&
        (data.tab = this.infoOpenedMailAccount.tab);

      const infoMailAcc = encodeInfoMailAccount(
        this.infoOpenedMailAccount.acc,
        this.infoOpenedMailAccount.folder,
        this.infoOpenedMailAccount.type,
        data
      );

      this.webCode &&
        this.router.navigate([this.webCode, 'emails'], {
          queryParams: infoMailAcc
            ? {
                [EMAIL_QUERY_PARAM]: infoMailAcc,
              }
            : {},
        });
    }
  }

  async navigateBack(): Promise<void> {
    this.pageNumber = this.pageNumber - 1;
    await this.changePage();
  }

  async navigateForward(): Promise<void> {
    this.pageNumber = this.pageNumber + 1;
    await this.changePage();
  }

  isAllSelected(): boolean {
    if (!this.selection?.selected?.length || !this.dataSource?.data?.length) {
      return false;
    }
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row.emailId));
  }

  openAddGroupDialog(email?: EmailListItemContract): void {
    const ids = email ? [email.emailId] : this.selection.selected;
    const emails = email
      ? [email]
      : this.dataSource.data.filter(e => ids.includes(e.emailId));

    const dialogRef = this.dialog.open(EmailAddGroupsDialogComponent, {
      width: '600px',
      minHeight: '600px',
      maxHeight: '600px',
      autoFocus: false,
      data: { ids, emails, mailAccountId: this.infoOpenedMailAccount.acc },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (email) {
          email.groups = result;
        } else {
          this.dataSource.data = this.dataSource.data.map(e =>
            ids.includes(e.emailId) ? { ...e, groups: result } : e
          );
        }
      }
    });
  }

  openAddTagDialog(email?: EmailListItemContract): void {
    const ids = email ? [email.emailId] : this.selection.selected;
    const emails = email
      ? [email]
      : this.dataSource.data.filter(e => ids.includes(e.emailId));
    const dialogRef = this.dialog.open(EmailAddTagsDialogComponent, {
      width: '600px',
      minHeight: '270px',
      autoFocus: false,
      data: { ids, emails, mailAccountId: this.infoOpenedMailAccount.acc },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (email) {
          email.tags = result.tags;
        } else {
          this.dataSource.data = this.dataSource.data.map(e =>
            result.ids.includes(e.emailId) ? { ...e, tags: result.tags } : e
          );
        }
      }
    });
  }

  openErrorDialog(title: string, description: string, value?: number): void {
    this.dialog.open(ErrorDialogComponent, {
      width: '300px',
      autoFocus: false,
      data: { title, description, value },
    });
  }

  async forward(id: number | null = null): Promise<any> {
    const ids = id ? [id] : this.selection.selected;
    if (ids.length > 0) {
      try {
        let createResponse;
        if (ids.length === 1) {
          this.forwardingEmailId = ids[0];
          createResponse = await this.emailClient.forward({ emailId: ids[0] });
        } else {
          this.forwardingEmailId = ids[0];
          this.infoOpenedMailAccount.acc &&
            (createResponse = await this.emailClient.forwardMultiple({
              emailIds: ids,
              mailAccountId: this.infoOpenedMailAccount.acc,
            }));
        }
        const newEmailResponse = await this.emailClient.getById(
          new BaseGetByIdRequest(createResponse?.id ?? null)
        );
        this.openCreateOrUpdateModalForm(newEmailResponse.email, 'Forward');
      } catch (e) {
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
      } finally {
        this.forwardingEmailId = null;
      }
    }
  }

  async move(id: number | null = null): Promise<any> {
    const ids = id ? [id] : this.selection.selected;
    if (!ids?.length) {
      return;
    }
    const dialogRef = this.dialog.open(MailFolderSelectDialogComponent, {
      width: '350px',
      autoFocus: false,
      data: {
        mailAccountId: this.infoOpenedMailAccount.acc,
        ids,
        isRestore: false,
        folderType: this.infoOpenedMailAccount.type,
      },
    });

    dialogRef.afterClosed().subscribe(async (e: { confirmed: boolean }) => {
      if (e?.confirmed) {
        await this.loadData();
        this.infoOpenedMailAccount.acc &&
          (await this.userWorkspaceService.refreshUnread({
            mailAccountId: this.infoOpenedMailAccount.acc,
          }));
      }
    });
  }

  async reassign(id?: number): Promise<any> {
    const ids = id ? [id] : this.selection.selected;
    if (!ids?.length) {
      return;
    }
    const dialogRef = this.dialog.open(ReassignDialogComponent, {
      width: '350px',
      autoFocus: false,
      data: {
        mailAccountId: this.infoOpenedMailAccount.acc,
        ids,
      },
    });

    dialogRef
      .afterClosed()
      .subscribe(async (e: { confirmed: boolean; mailAccounts?: number[] }) => {
        if (e?.confirmed) {
          await this.loadData();
          e.mailAccounts?.length &&
            (await this.userWorkspaceService.getBadgesForMailAccounts(
              e.mailAccounts
            ));
        }
      });
  }

  public async pageSizeChange(pageSize: number): Promise<void> {
    this.localStorageService.setData(PAGE_SIZE, pageSize);
    this.pageSize = pageSize;
    this.pageNumber = 1;
    await this.changePage();
  }

  public async searchOrderChange($event: any): Promise<void> {
    this.searchOrder = $event as EmailSearchOrder;
    this.pageNumber = 1;
    await this.changePage();
  }

  public async createEmail(): Promise<any> {
    this.openCreateOrUpdateModalForm(null, 'Create email');
  }

  public editEmail(email: EmailListItemContract): void {
    this.openCreateOrUpdateModalForm(email, 'Edit email');
  }

  public getDate(email: EmailListItemContract): string {
    const emailFolderType = this.userWorkspaceService.userFolders.find(
      e =>
        e.mailFolderId === email.mailFolderId &&
        e.mailAccountId === email.mailAccountId
    )?.folderType;
    return emailFolderType === MailFolderType.Outbox ||
      emailFolderType === MailFolderType.Draft ||
      emailFolderType === MailFolderType.Validation
      ? email.stateDateTime
      : email.sourceFolderType === MailFolderType.Draft ||
          email.sourceFolderType === MailFolderType.Outbox
        ? email.stateDateTime
        : email.sentDateTime;
  }

  public formatTo(to: string | null): string {
    if (!to) {
      return '';
    }
    return to.split(';').join('; ');
  }

  public selectedOneEmail(): boolean {
    return this.selection.selected.length === 1;
  }

  public openCreateOrUpdateModalForm(
    email: EmailContract | EmailListItemContract | null,
    title: string
  ): void {
    this.updatedEmail = email;
    this.menuTitle = title;
    this.drawerService.openDrawer(
      this.createOrUpdateDrawer,
      null,
      'create-email'
    );
  }

  public async closeCreateOrUpdateModalForm(data?: {
    emailsId: number[];
    tags: string[];
  }): Promise<any> {
    data &&
      this.updatedEmail?.emailId &&
      (await this.emailTagClient.saveTags(data));
    if (this.infoOpenedMailAccount.type === this.mailFolderType.Draft) {
      this.reload();
    }
    if (
      this.updatedEmail?.emailId &&
      this.infoOpenedMailAccount.type !== this.mailFolderType.Draft
    ) {
      this.getTags(true, this.updatedEmail.emailId);
    }
    this.updatedEmail = null;
    this.menuTitle = null;
  }

  public openEmailDetails(email: EmailListItemContract): void {
    this.openedEmail = email;
    this.drawerService.closeDrawer();
    this.drawerService.openDrawer(this.emailDrawer, null, 'create-email');
  }

  public async getTags(isSeen: boolean, emailId: number): Promise<void> {
    try {
      const tagsResponse = await this.emailTagClient.getEmailTags(
        new BaseGetByIdRequest(emailId)
      );
      this.dataSource.data = this.dataSource.data.map(e =>
        e.emailId === emailId
          ? { ...e, seen: isSeen, tags: tagsResponse.data }
          : e
      );
    } catch (e) {
      this.matchError.logError(e);
    }
  }

  public deleteFromData(id: number): void {
    this.dataSource.data = this.dataSource.data.filter(e => e.emailId !== id);
  }

  public changeDraftData(data: any): void {
    if (this.infoOpenedMailAccount.type === this.mailFolderType.Draft) {
      this.dataSource.data = this.dataSource.data.map(e =>
        e.emailId === data.emailId
          ? {
              ...e,
              to: data.updateRequest.to,
              subject: data.updateRequest.subject,
              notesCount: data.isNotesChanged ? 1 : e.notesCount,
              hasAttachments: !!data.updateRequest.attachmentFiles.length,
            }
          : e
      );
    }
  }

  public async closeEmailModalForm(data?: {
    emailsId: number[];
    tags: string[];
  }): Promise<any> {
    const isDeleted =
      this.openedEmailInfo?.deleted === this.openedEmail?.deleted;
    const isSpam = this.openedEmailInfo?.isSpam === this.openedEmail?.isSpam;
    const isSeen = this.openedEmailInfo?.seen;

    if (isDeleted && isSpam) {
      data && (await this.emailTagClient.saveTags(data));
      this.openedEmail?.emailId &&
        this.getTags(isSeen, this.openedEmail.emailId);
    } else {
      this.infoOpenedMailAccount.acc &&
        this.userWorkspaceService.refreshUnread({
          mailAccountId: this.infoOpenedMailAccount.acc,
        });
      if (this.openedEmailInfo) {
        this.dataSource.data = this.dataSource.data.filter(
          e => e.emailId !== this.openedEmail?.emailId
        );
        this.reloadDataOnDemand();
      }
    }
    this.openedEmail = null;
    this.openedEmailInfo = null;
  }

  public async downloadEML(id: number): Promise<void> {
    this.downloadingEMLEmailId = id;
    try {
      const fileResponse = await this.emailClient.download({ emailIds: [id] });
      const request = new BaseGetByFileIdRequest(fileResponse.fileId);
      await this.tempFileClient.download(request);
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.downloadingEMLEmailId = null;
    }
  }

  public async downloadSingleEML(id: number): Promise<void> {
    this.downloadingSingleEMLEmailId = id;
    try {
      const fileResponse = await this.emailClient.singleDownload({
        emailIds: [id],
      });
      const request = new BaseGetByEmailFileIdRequest(fileResponse.fileId, id);
      await this.fileClient.download(request);
    } catch (error) {
      this.matchError.errorHandler(error);
    } finally {
      this.downloadingSingleEMLEmailId = null;
    }
  }

  public async downloadEMLSelection(): Promise<void> {
    this.isDownloadingEML = true;
    try {
      const ids = this.selection.selected;
      const fileResponse = await this.emailClient.download({ emailIds: ids });
      const request = new BaseGetByFileIdRequest(fileResponse.fileId);
      await this.tempFileClient.download(request);
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isDownloadingEML = false;
    }
  }

  public async downloadSingleEMLSelection(): Promise<void> {
    this.isDownloadingSingleEML = true;
    try {
      const ids = this.selection.selected;
      const fileResponse = await this.emailClient.singleDownload({
        emailIds: ids,
      });
      if (ids.length === 1) {
        const request = new BaseGetByEmailFileIdRequest(
          fileResponse.fileId,
          ids[0]
        );
        await this.fileClient.download(request);
      } else {
        const request = new BaseGetByFileIdRequest(fileResponse.fileId);
        await this.tempFileClient.download(request);
      }
    } catch (error) {
      this.matchError.errorHandler(error);
    } finally {
      this.isDownloadingSingleEML = false;
    }
  }

  public selectedOneFolder(): boolean {
    return this.selectedMailFolderIds.length === 1;
  }

  public getMailAccountName(email: EmailListItemContract): string {
    return (
      this.userWorkspaceService.userFolders.find(
        x =>
          x.mailFolderId === email.mailFolderId &&
          x.mailAccountId === this.infoOpenedMailAccount.acc
      )?.mailAccountName ?? ''
    );
  }

  public getMailFolderName(email: EmailListItemContract): string {
    return (
      this.userWorkspaceService.userFolders.find(
        x =>
          x.mailFolderId === email.mailFolderId &&
          x.mailAccountId === this.infoOpenedMailAccount.acc
      )?.mailFolderName ?? ''
    );
  }

  public get multipleReassignIsAvailable(): boolean {
    return (
      !!this.selectedMailFolderId &&
      this.infoOpenedMailAccount.type === this.mailFolderType.Inbox
    );
  }

  public validateIsAvailable(): boolean {
    const emails = this.dataSource.data.filter(e =>
      this.selection.selected.includes(e.emailId)
    );
    return emails.every(
      e =>
        e.validationState === EmailValidationState.New ||
        e.validationState === EmailValidationState.NotValidated
    );
  }

  public loadingImportEML($event: boolean): void {
    this.isLoadingEML = $event;
  }

  ngOnDestroy(): void {
    this.routeSubscribe$?.unsubscribe();
    this.useMobileViewSubscription?.unsubscribe();
    this.useTabletViewSubscription?.unsubscribe();
  }
}
