@if(isLoading) {
  <div class="w-full h-full">
    <div>
      <ngx-skeleton-loader
        [count]="1"
        [theme]="{
          'margin-bottom': '16px',
          height: '32px',
          'max-width': '60%'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div class="h-[50%]">
      <ngx-skeleton-loader
        [count]="1"
        [theme]="{
          'margin-bottom': 0,
          height: '100%',
          width: '100%',
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>
}

@if(cannotLoadMeta) {
  <div class="w-full h-full">
    <app-something-went-wrong
      [showPicture]="false"
      description="Cannot load notes"
      (retryEmitter)="getEmailDetail()"
    ></app-something-went-wrong>
  </div>
}

@if(!isLoading && !cannotLoadMeta) {
  <div class="w-full h-full drawer-content-screen">
    @if(title) {
      <div class="drawer-content-screen__title">{{ title | translate }}</div>
    }
    <div class="flex-none drawer-content-screen__body" style="overflow-wrap: anywhere">
      {{ meta }}
    </div>
  </div>
}

