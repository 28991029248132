<div class="editor-create h-full min-h-[200px] h-full" style="width: calc(100% - 1px); overflow: hidden">
  <ckeditor
    tagName="textarea"
    [disabled]="disabled"
    (change)="onChange($event)"
    [editor]="editor"
    [config]="config"
    [(ngModel)]="body"
    (ready)="disabled && onEditorReady($event)"
  ></ckeditor>
</div>
