import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { UserOrganizationClient } from '@app-services/api/clients/user-organization.client';
import { MatDialog } from '@angular/material/dialog';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { OrganizationSettingsClient } from '@app-services/api/clients/organization-settings.client';
import { OrganizationSettingsContract } from '@app-types/api/Settings';
import { BaseGetByIdRequest } from '@app-types/base/base';
import { LoadingButtonComponent } from '../../../../common/loading-button/loading-button.component';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { SomethingWentWrongComponent } from '../../../../common/error/something-went-wrong/something-went-wrong.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DrawerService } from '@app-services/drawer.service';
import {
  AddUserToOrganizationRequest,
  Identity,
  UpdateUserRequest,
  User,
  UserOrganizationGetOrganizationUsersContract,
} from '@app-types/api/user-organization';
import { AuthenticationType } from '@app-types/enums/authentication-type';
import { UserOrganizationState } from '@app-types/enums/user-organization-state.enum';
import {
  hasAtLeastOneDigit,
  hasAtLeastOneLowerCharacter,
  hasAtLeastOneSpecialSymbol,
  hasAtLeastOneUpperCharacter,
} from '@app-utils/custom-validator';
import { SkeletonFormDrawerComponent } from '@app-components/common/skeletons/skeleton-form-drawer/skeleton-form-drawer.component';

@Component({
  selector: 'app-organization-user-create-dialog',
  templateUrl: './organization-user-create-dialog.component.html',
  styleUrls: ['./organization-user-create-dialog.component.scss'],
  standalone: true,
  imports: [
    LoadingButtonComponent,
    MatButtonModule,
    TranslateModule,
    SomethingWentWrongComponent,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule,
    SkeletonFormDrawerComponent,
  ],
})
export class OrganizationUserCreateDialogComponent
  implements OnInit, OnDestroy
{
  public isLoading = false;
  @Input() public organizationId: number;
  @Input() public user: UserOrganizationGetOrganizationUsersContract | null;
  @Output() public creationSubmit = new EventEmitter();
  @Output() public closeEventEmitter = new EventEmitter();
  public gawOidcAuthType = false;
  public googleAuthType = false;

  public passwordValidators = [
    Validators.required,
    Validators.minLength(8),
    Validators.maxLength(15),
    hasAtLeastOneUpperCharacter(),
    hasAtLeastOneLowerCharacter(),
    hasAtLeastOneDigit(),
    hasAtLeastOneSpecialSymbol(),
  ];
  public failedSettingsLoading = false;
  public settingsIsLoading = false;
  public isUploaded = false;
  public settings: OrganizationSettingsContract;

  public userIdentityTypes: Identity[] = [];
  public isUserHasOidc = false;

  public form: UntypedFormGroup = new UntypedFormGroup({
    firstName: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(128),
    ]),
    lastName: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(128),
    ]),
    email: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(256),
      Validators.email,
    ]),
    nickName: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(256),
    ]),
    password: new UntypedFormControl('', this.passwordValidators),
  });

  constructor(
    private userOrganizationClient: UserOrganizationClient,
    private organizationSettingsClient: OrganizationSettingsClient,
    public matchError: MatchError,
    public dialog: MatDialog,
    private drawerService: DrawerService
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.user) {
      this.form.controls.email.setValue(this.user.email);
      this.form.controls.firstName.setValue(this.user.firstName);
      this.form.controls.lastName.setValue(this.user.lastName);
    }
    this.user ? await this.loadAuthTypes() : await this.loadSettings();
  }

  public changeGawOidcAuthType(value: boolean): void {
    this.gawOidcAuthType = value;
    if (!this.isUserHasOidc) {
      if (!value) {
        this.form.controls.password.disable();
        this.form.controls.nickName.disable();
      } else {
        this.form.controls.password.enable();
        this.form.controls.nickName.enable();
      }
    }
  }
  public changeGoogleAuthType(value: boolean): void {
    this.googleAuthType = value;
  }

  public async loadSettings(): Promise<void> {
    this.failedSettingsLoading = false;
    this.isUploaded = false;
    const loaderTimeout = setTimeout(() => {
      this.settingsIsLoading = true;
    }, 500);
    try {
      this.form.controls.password.disable();
      this.form.controls.nickName.disable();
      const response = await this.organizationSettingsClient.getByOrganization(
        new BaseGetByIdRequest(this.organizationId)
      );
      this.settings = response.result;
    } catch (e) {
      this.failedSettingsLoading = true;
      this.matchError.logError(e);
    } finally {
      clearTimeout(loaderTimeout);
      this.isUploaded = true;
      this.settingsIsLoading = false;
    }
  }

  public async loadAuthTypes(): Promise<void> {
    this.failedSettingsLoading = false;
    this.isUploaded = false;
    const loaderTimeout = setTimeout(() => {
      this.settingsIsLoading = true;
    }, 500);
    try {
      this.form.controls.password.disable();
      this.form.controls.nickName.disable();
      const response = await this.userOrganizationClient.getUserIdentityTypes(
        new BaseGetByIdRequest(this.user?.userId ?? null)
      );
      this.userIdentityTypes = response.userIdentity;
      this.isCheckedAuth();
      if (
        this.userIdentityTypes.some(
          e => e.identityType === AuthenticationType.Oidc
        )
      ) {
        this.isUserHasOidc = true;
        this.form.controls.nickName.setValue(this.user?.oidcIdentity ?? '');
      }
    } catch (e) {
      this.failedSettingsLoading = true;
      this.matchError.logError(e);
    } finally {
      clearTimeout(loaderTimeout);
      this.isUploaded = true;
      this.settingsIsLoading = false;
    }
  }

  public isCheckedAuth(): void {
    this.userIdentityTypes.forEach(e => {
      if (e.identityType === AuthenticationType.Oidc) {
        this.gawOidcAuthType = e.state === UserOrganizationState.Active;
      }
      if (e.identityType === AuthenticationType.Google) {
        this.googleAuthType = e.state === UserOrganizationState.Active;
      }
    });
  }

  checkIsValid(): boolean {
    return !(this.googleAuthType || this.gawOidcAuthType);
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

  async onSubmit(): Promise<void> {
    if (this.form.valid) {
      this.isLoading = true;
      this.drawerService.disabledDrawer(true);
      const values = this.form.value;
      let request: User = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password:
          this.user || this.gawOidcAuthType
            ? this.form.get('password')?.value
            : null,
      };

      this.user &&
        ((request as UpdateUserRequest) = {
          ...request,
          userId: this.user.userId,
          userOrganizationId: this.user.userOrganizationId,
          oidcState: this.gawOidcAuthType
            ? UserOrganizationState.Active
            : UserOrganizationState.Disabled,
          googleState: this.googleAuthType
            ? UserOrganizationState.Active
            : UserOrganizationState.Disabled,
          nickname: this.user?.oidcIdentity ?? '',
        });

      !this.user &&
        ((request as AddUserToOrganizationRequest) = {
          ...request,
          oidcAuth: this.gawOidcAuthType,
          googleAuth: this.googleAuthType,
          nickName: this.gawOidcAuthType ? values.nickName : null,
        });
      try {
        this.user
          ? await this.userOrganizationClient.updateUser(
              request as UpdateUserRequest
            )
          : await this.userOrganizationClient.addUserToOrganization(
              request as AddUserToOrganizationRequest
            );
        this.drawerService.disabledDrawer(false);
        this.creationSubmit.emit();
        this.drawerService.closeDrawer();
      } catch (e) {
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
        this.drawerService.disabledDrawer(false);
      } finally {
        this.isLoading = false;
      }
    }
  }

  ngOnDestroy(): void {
    this.closeEventEmitter.emit();
  }
}
