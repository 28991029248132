<div class="drawer-content-screen">
  @if(cannotLoadProviders) {
    <div class="h-full">
      <app-something-went-wrong
        description="Cannot load providers"
        (retryEmitter)="loadProviders()"
        [showPicture]="false"
      ></app-something-went-wrong>
    </div>
  }

  @if(providersIsLoading) {
    <div class="h-full">
      <app-skeleton-form-drawer></app-skeleton-form-drawer>
    </div>
  }

  @if(!cannotLoadProviders && !providersIsLoading && isUploaded) {
    <div class="drawer-content-screen__title">
      {{ "createNewMailAccount" | translate }}
    </div>
    <div class="drawer-content-screen__body">
      <form [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="onSubmit(form.value)">
        <mat-form-field>
          <mat-label>{{ "alias" | translate }}</mat-label>
          <input matInput id="name" formControlName="name" required />
          @if(hasError('name', 'required')) {
            <mat-error>{{ "aliasIsRequired" | translate }}</mat-error>
          }
          @if(hasError('name', 'maxlength')) {
            <mat-error>{{ "moreThan256Char" | translate }}</mat-error>
          }
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ "license" | translate }}</mat-label>
          <input matInput id="license" formControlName="license" />
          @if(hasError('license', 'maxlength')) {
            <mat-error>{{ "moreThan256Char" | translate }}</mat-error>
          }
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ "codeSia" | translate }}</mat-label>
          <input matInput id="codeSia" formControlName="codeSia" />
          @if(hasError('codeSia', 'maxlength')) {
            <mat-error>{{ "moreThan256Char" | translate }}</mat-error>
          }
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ "anagId" | translate }}</mat-label>
          <input matInput id="anagId" formControlName="anagId" />
          @if(hasError('anagId', 'maxlength')) {
            <mat-error>{{ "moreThan256Char" | translate }}</mat-error>
          }
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ "email" | translate }}</mat-label>
          <input matInput id="email" formControlName="email" required />
          @if(hasError('email', 'required')) {
            <mat-error>{{ "emailIsRequired" | translate }}</mat-error>
          }
          @if(hasError('email', 'maxlength')) {
            <mat-error>{{ "moreThan256Char" | translate }}</mat-error>
          }
          @if(hasError('email', 'email')) {
            <mat-error>{{ "incorrectEmail" | translate }}</mat-error>
          }
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ "password" | translate }}</mat-label>
          <input matInput type="password" id="password" formControlName="password" />
          @if(hasError('password', 'maxlength')) {
             <mat-error>{{ "moreThan256Char" | translate }}</mat-error>
          }
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ "providerLabel" | translate }}</mat-label>
          <mat-select formControlName="provider" required>
          @for(provider of providers; track provider) {
            <mat-option [value]="provider.providerId">
              {{ provider.name }}
            </mat-option>
          }
          </mat-select>
        </mat-form-field>
      </form>
    </div>
    <div class="flex justify-end w-full">
      <button mat-flat-button color="primary" [disabled]="isLoading || !form.valid" (click)="onSubmit(form.value)">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
      </button>
    </div>
  }
</div>
