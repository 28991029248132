import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MobileObserverService } from '@app-services/adaptive/mobile-observer.service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-skeleton-table',
  standalone: true,
  imports: [MatTableModule, NgxSkeletonLoaderModule],
  templateUrl: './skeleton-table.component.html',
  styleUrls: ['./skeleton-table.component.scss'],
})
export class SkeletonTableComponent implements OnInit, OnDestroy {
  private useMobileViewSubscription: Subscription;
  public useMobileView = false;
  dataSource = new MatTableDataSource<any>(new Array(12).fill({}));
  displayedColumns: string[] = ['1', '2', '3', '4', '5', '6'];

  constructor(private mobileObserverService: MobileObserverService) {}

  ngOnInit(): void {
    this.useMobileViewSubscription = this.mobileObserverService
      .mobileObserver()
      .subscribe(isMobile => (this.useMobileView = isMobile));
  }

  ngOnDestroy(): void {
    this.useMobileViewSubscription?.unsubscribe();
  }
}
