import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { Group, GroupCreateUpdateRequest } from '@app-types/api/group';
import { GroupClient } from '@app-services/api/clients/group-settings.client';
import { LoadingButtonComponent } from '../../../../common/loading-button/loading-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { DrawerService } from '@app-services/drawer.service';

@Component({
  selector: 'app-organization-group-create-dialog',
  templateUrl: './organization-group-create-dialog.component.html',
  styleUrls: ['./organization-group-create-dialog.component.scss'],
  standalone: true,
  imports: [
    LoadingButtonComponent,
    TranslateModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
  ],
})
export class OrganizationGroupCreateDialogComponent
  implements OnInit, OnDestroy
{
  @Output() public closeEventEmitter = new EventEmitter();
  @Output() public createEventEmitter = new EventEmitter();
  @Input() public organizationId: number;
  @Input() public group: Group | null;

  form = this.fb.group({
    name: ['', [Validators.required, Validators.maxLength(255)]],
  });

  isLoading = false;
  hasError = false;

  constructor(
    private fb: UntypedFormBuilder,
    private groupClient: GroupClient,
    public matchError: MatchError,
    private drawerService: DrawerService
  ) {}

  ngOnInit(): void {
    this.group?.group &&
      this.form.patchValue({
        name: this.group.group,
      });
  }

  public async save(): Promise<void> {
    if (this.form.valid) {
      this.drawerService.disabledDrawer(true);
      const req: GroupCreateUpdateRequest = this.group
        ? {
            group: this.form.value.name,
            groupId: this.group.groupId,
          }
        : {
            organizationId: this.organizationId,
            group: this.form.value.name,
          };
      try {
        this.isLoading = true;
        this.group
          ? await this.groupClient.update(req)
          : await this.groupClient.create(req);
        this.drawerService.disabledDrawer(false);
        this.createEventEmitter.emit();
      } catch (e) {
        this.hasError = true;
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
        this.drawerService.disabledDrawer(false);
      } finally {
        this.isLoading = false;
      }
    }
  }

  ngOnDestroy(): void {
    this.closeEventEmitter.emit();
  }
}
