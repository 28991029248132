<div class="h-screen w-screen flex flex-wrap content-center justify-center relative">
  <div class="sign-in-form">
    @if(isLoading) {
      <app-skeleton-sign-in></app-skeleton-sign-in>
    }
    @if(!isLoading) {
      <div class="drawer-content-screen__title text-center">
        {{ "login" | translate }}
      </div>
      <mat-form-field>
        <mat-label>{{ "authenticationType" | translate }}</mat-label>
        <mat-select [(value)]="selectedAuthType">
          @for(authType of authTypes; track authType) {
            <mat-option [value]="authType">
              {{ getAuthTypeDescription(authType) | translate }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <div class="flex justify-center">
        <button mat-flat-button color="primary" (click)="onLogin()">{{ "login" | translate }}</button>
      </div>
    }
  </div>
</div>
