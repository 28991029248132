import { Component, OnInit } from '@angular/core';
import { OrganizationContract } from '@app-types/api/organization';
import { MatButtonModule } from '@angular/material/button';
import { LocalStorageService } from '@app-services/local-storage.service';
import { STORAGE_NAMES } from '@app-shared/constants/constants';
import { MatFormFieldModule } from '@angular/material/form-field';

const { ORGANISATIONS } = STORAGE_NAMES;

@Component({
  selector: 'app-default-page',
  standalone: true,
  imports: [MatButtonModule, MatFormFieldModule],
  templateUrl: './default-page.component.html',
  styleUrls: ['./default-page.component.scss'],
})
export class DefaultPageComponent implements OnInit {
  public links: OrganizationContract[] = [];
  authError: boolean;

  constructor(private localStorageService: LocalStorageService) {}

  ngOnInit(): void {
    const parameters = new URLSearchParams(window.location.search);
    const authError = parameters.get('status');
    this.authError = !!authError && authError === 'auth_error';
    this.links = this.localStorageService.getData(ORGANISATIONS);
  }

  public get currentUrl(): string {
    const urlObj = new URL(document.URL);
    urlObj.search = '';
    return urlObj.toString();
  }
}
