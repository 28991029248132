import { Component } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-skeleton-sign-in',
  standalone: true,
  imports: [MatTableModule, NgxSkeletonLoaderModule],
  templateUrl: './skeleton-sign-in.component.html',
  styleUrls: ['./skeleton-sign-in.component.scss'],
})
export class SkeletonSignInComponent {
  constructor() {}
}
