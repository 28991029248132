import { RemoteFolder } from './remote-folder';
import { Group } from './group';
import { TreeItem } from '../tree.item';
import { MailFolderContract } from './mail-folder';
import { EmailClass } from '../enums/email-class';
import { ExpressionOperation } from '../enums/expression.operation';

export interface RemoteMailFolderDefinition {
  name: string;
  isOutbox: boolean;
}

export class EmailExpression {
  conditions: EmailCondition[] = [];
  emailClasses: EmailClass[];
}

export class EmailCondition {
  paramName: string;
  patterns: string[];
  operation: ExpressionOperation;
}

export interface DispatchingActionArgument {
  name: string;
  value: string;
}

export interface DispatchingAction {
  name?: string;
  args?: DispatchingActionArgument[];
}

export interface DispatchingActionReq {
  name: string;
  args: DispatchingActionArgument[];
}

export interface DispatchingRule {
  dispatchingRuleId?: number;
  mailAccountId?: number;
  dispatchingRule?: DispatchingRuleObject;
}

export interface DispatchingRuleCreateRequest {
  mailAccountId: number;
  dispatchingRule: DispatchingRuleObject;
}

export interface DispatchingRuleUpdateRequest {
  dispatchingRuleId: number;
  dispatchingRule: DispatchingRuleObject;
}

export interface DispatchingRuleOptions {
  continueNextRulesExecutionOnMatch: boolean;
}

export interface DispatchingRuleAvailableDataResponse {
  availableFolders: RemoteFolder[];
  availableGroups: Group[];
  availableActions: string[];
  availableConditions: string[];
  availableLocalFolders: TreeItem<number, MailFolderContract>[];
}

export class DispatchingRuleObject {
  priority = 1;
  name = '';
  options: DispatchingRuleOptions = {
    continueNextRulesExecutionOnMatch: false,
  };
  folders: RemoteMailFolderDefinition[] = [];
  matchExpression: EmailExpression = new EmailExpression();
  actions: DispatchingAction[] = [];
}
