import { environment } from '@app-environments/environment';
import { Injectable } from '@angular/core';
import { ApiRequest } from '../api.request';
import {
  EmailBaseRequest,
  EmailCreateRequest,
  EmailGetByIdResponse,
  EmailId,
  EmailIdsBaseArrayRequest,
  EmailListItemNotificationsContract,
  EmailMoveRequest,
  EmailMultipleForwardRequest,
  EmailReassignRequest,
  EmailSearchByWidgetRequest,
  EmailSearchRequest,
  EmailSearchResponse,
  EmailSetSeenRequest,
  EmailSetValidatedRequest,
  EmailUpdateRequest,
} from '@app-types/api/email';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import {
  BaseGetByIdRequest,
  BaseGetCollectionResponse,
  BaseId,
  BaseIdListRequest,
  BaseIdsList,
  BasePostCommentRequest,
} from '@app-types/base/base';
import { BaseFinalDeleteListResponse } from '@app-types/common';

const API_URL = environment.apiUrl;
const { email } = API_ROUTES;
const {
  search,
  archive,
  get,
  createDraft,
  moveToDraft,
  move,
  download,
  finalDelete,
  forward,
  forwardMultiple,
  markForSend,
  singleDownload,
  retrySend,
  searchByWidget,
  getNotifications,
  update,
  reply,
  replyAll,
  restore,
  validationReject,
  setIsSpam,
  restoreFromArchive,
  setNotSpam,
  setSeen,
  setValidation,
  reassign,
  readyToValidate,
  validationApprove,
} = email;

@Injectable({ providedIn: 'root' })
export class EmailClient {
  constructor(private apiRequest: ApiRequest) {}

  async search(request: EmailSearchRequest): Promise<EmailSearchResponse> {
    return this.apiRequest.post(request, `${API_URL}${search}`);
  }

  async searchByWidget(
    request: EmailSearchByWidgetRequest
  ): Promise<EmailSearchResponse> {
    return this.apiRequest.post(request, `${API_URL}${searchByWidget}`);
  }

  async getById(request: BaseGetByIdRequest): Promise<EmailGetByIdResponse> {
    return this.apiRequest.get(`${API_URL}${get}?${request.format()}`);
  }

  async getNotifications(
    request: BaseGetByIdRequest
  ): Promise<BaseGetCollectionResponse<EmailListItemNotificationsContract>> {
    return this.apiRequest.get(
      `${API_URL}${getNotifications}?${request.format()}`
    );
  }

  async download(request: { emailIds: number[] }): Promise<any> {
    return this.apiRequest.post(request, `${API_URL}${download}`);
  }

  async singleDownload(request: { emailIds: number[] }): Promise<any> {
    return this.apiRequest.post(request, `${API_URL}${singleDownload}`);
  }

  async createDraft(request: EmailCreateRequest): Promise<BaseId> {
    return this.apiRequest.post<EmailCreateRequest, BaseId>(
      request,
      `${API_URL}${createDraft}`
    );
  }

  async update(request: EmailUpdateRequest): Promise<BaseId> {
    return this.apiRequest.post<EmailUpdateRequest, BaseId>(
      request,
      `${API_URL}${update}`
    );
  }

  async markForSend(request: BaseGetByIdRequest): Promise<void> {
    await this.apiRequest.get(`${API_URL}${markForSend}?${request.format()}`);
  }

  async reply(request: EmailId): Promise<BaseId> {
    return this.apiRequest.post<EmailId, BaseId>(request, `${API_URL}${reply}`);
  }

  async replyAll(request: EmailId): Promise<BaseId> {
    return this.apiRequest.post<EmailId, BaseId>(
      request,
      `${API_URL}${replyAll}`
    );
  }

  async forward(request: EmailId): Promise<BaseId> {
    return this.apiRequest.post<EmailId, BaseId>(
      request,
      `${API_URL}${forward}`
    );
  }

  async forwardMultiple(request: EmailMultipleForwardRequest): Promise<BaseId> {
    return this.apiRequest.post<EmailMultipleForwardRequest, BaseId>(
      request,
      `${API_URL}${forwardMultiple}`
    );
  }

  async delete(request: EmailIdsBaseArrayRequest): Promise<BaseIdsList> {
    return this.apiRequest.post<EmailIdsBaseArrayRequest, BaseIdsList>(
      request,
      `${API_URL}${email.delete}`
    );
  }

  async finalDelete(
    request: EmailIdsBaseArrayRequest
  ): Promise<BaseFinalDeleteListResponse> {
    return this.apiRequest.post<
      EmailIdsBaseArrayRequest,
      BaseFinalDeleteListResponse
    >(request, `${API_URL}${finalDelete}`);
  }

  async restore(request: EmailBaseRequest): Promise<BaseIdsList> {
    return this.apiRequest.post<EmailBaseRequest, BaseIdsList>(
      request,
      `${API_URL}${restore}`
    );
  }

  async setIsSpam(request: EmailIdsBaseArrayRequest): Promise<BaseIdsList> {
    return this.apiRequest.post<EmailIdsBaseArrayRequest, BaseIdsList>(
      request,
      `${API_URL}${setIsSpam}`
    );
  }

  async archive(request: EmailBaseRequest): Promise<BaseIdsList> {
    return this.apiRequest.post<EmailBaseRequest, BaseIdsList>(
      request,
      `${API_URL}${archive}`
    );
  }

  async restoreFromArchive(request: EmailBaseRequest): Promise<BaseIdsList> {
    return this.apiRequest.post<EmailBaseRequest, BaseIdsList>(
      request,
      `${API_URL}${restoreFromArchive}`
    );
  }

  async setNotSpam(request: EmailBaseRequest): Promise<BaseIdsList> {
    return this.apiRequest.post<EmailBaseRequest, BaseIdsList>(
      request,
      `${API_URL}${setNotSpam}`
    );
  }

  async setSeen(request: EmailSetSeenRequest): Promise<BaseIdsList> {
    return this.apiRequest.post<EmailSetSeenRequest, BaseIdsList>(
      request,
      `${API_URL}${setSeen}`
    );
  }

  async setValidated(request: EmailSetValidatedRequest): Promise<void> {
    return this.apiRequest.post<EmailSetValidatedRequest, any>(
      request,
      `${API_URL}${setValidation}`
    );
  }

  async move(request: EmailMoveRequest): Promise<void> {
    return this.apiRequest.post<EmailMoveRequest, any>(
      request,
      `${API_URL}${move}`
    );
  }

  async reassign(request: EmailReassignRequest): Promise<void> {
    return this.apiRequest.post<EmailReassignRequest, any>(
      request,
      `${API_URL}${reassign}`
    );
  }

  async readyToValidate(request: BaseGetByIdRequest): Promise<void> {
    await this.apiRequest.get(
      `${API_URL}${readyToValidate}?${request.format()}`
    );
  }

  async validationApprove(request: BaseGetByIdRequest): Promise<void> {
    await this.apiRequest.get(
      `${API_URL}${validationApprove}?${request.format()}`
    );
  }

  async validationReject(request: BasePostCommentRequest): Promise<void> {
    await this.apiRequest.post(request, `${API_URL}${validationReject}`);
  }

  async retrySend(request: BaseIdListRequest): Promise<void> {
    await this.apiRequest.post(request, `${API_URL}${retrySend}`);
  }

  async moveToDraft(request: EmailId): Promise<void> {
    await this.apiRequest.post(request, `${API_URL}${moveToDraft}`);
  }
}
