<div class="flex flex-col gap-4">
  <div class="flex flex-wrap content-center">
    <div class="flex flex-wrap content-center drawer-content-screen__sub-title">
      {{ "actions" | translate }}
    </div>
  </div>
  @if(!!dispatchingRule.dispatchingRule?.actions?.length) {
    @for(action of dispatchingRule.dispatchingRule?.actions; track action; let i = $index) {
      <div class="action-box">
      <div class="action-container p-2">
        <div class="w-full flex">
          <mat-form-field  class="mat-input-without-padding">
            <mat-label>{{ "action" | translate }}</mat-label>
            <mat-select name="action" [(value)]="action.name" (valueChange)="onActionTypeChange($event, i)" required>
              @for(actionName of availableActions; track actionName) {
                <mat-option
                  [value]="actionName"
                  [disabled]="filteredAvailableActions(actionName)"
                >{{
                  actionName === "MoveMailToFolder"
                    ? ("moveMailToFolder" | translate)
                    : actionName === "ForwardMail"
                    ? ("forwardMail" | translate)
                    : actionName === "ReassignMailToMailAccount"
                      ? ("reassignMailToMailAccount" | translate)
                      : actionName === "AssignToGroups"
                        ? ("assignToGroups" | translate)
                        : actionName
                  }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="action">
          <div class="w-full flex">
            <div class="w-full">
              @if(action.name === 'MoveMailToFolder') {
                <app-move-to-folder-action
                  [dispatchingAction]="action"
                  [availableLocalFolders]="availableLocalFolders">
                </app-move-to-folder-action>
              }
               @if(action.name === 'ForwardMail') {
                <app-forward-action [dispatchingAction]="action"></app-forward-action>
              }
              @if(action.name === 'ReassignMailToMailAccount') {
                <app-reassign-action
                  [dispatchingAction]="action"
                  [mailAccounts]="mailAccounts">
                </app-reassign-action>
              }
              @if(action.name === 'AssignToGroups') {
                <app-assign-to-group-action
                  [dispatchingAction]="action"
                  [checkBoxGroups]="checkBoxGroups">
                </app-assign-to-group-action>
              }
            </div>
          </div>
        </div>
        <div class="action-delete">
          <button
            matTooltip="{{ 'deleteAction' | translate }}"
            mat-flat-button
            color="primary"
            aria-label="Delete action"
            (click)="onDelete(i)"
            class="flex flex-wrap content-center no-hover-effect">
            {{ "deleteAction" | translate }}
          </button>
        </div>
      </div>
    </div>
    }
  }
  @if(existingActionTypes.length) {
    <div class="flex justify-center items-center">
      <button color="primary" matTooltip="{{ 'createAction' | translate }}" mat-flat-button aria-label="Create" (click)="onCreate()">
      {{ "addAction" | translate }}
      </button>
    </div>
  }

</div>
