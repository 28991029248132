<div class="close-button">
  <button mat-icon-button (click)="download()">
    <mat-icon>cloud_download</mat-icon>
  </button>
  <button tabIndex="-1" mat-icon-button (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>

@if(data.isPdf) {
  <div class="w-full h-full pt-[40px]">
    <ng2-pdfjs-viewer
      #pdfViewer
      [pdfSrc]="data.url"
      [download]="false"
      [openFile]="false"
      [locale]="locale ?? ''"
    ></ng2-pdfjs-viewer>
  </div>
} @else {
  <div class="w-full mt-[40px] flex justify-center align-center container-img" style="height: calc(100% - 40px)">
    <img [src]="data.url" class="max-h-full m-[auto]" alt="{{ 'unsupportedFormat' | translate }}" />
  </div>
}
