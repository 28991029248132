<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<mat-dialog-content class="mat-mdc-dialog-content mdc-dialog__content mat-container">
  <p>
    @for(item of data.subTitles; track item) {
      {{ item.title | translate }} <span class="font-bold italic">{{ item.subTitle | translate }}</span>
    } ?
  </p>
</mat-dialog-content>
<mat-dialog-actions class="mat-mdc-dialog-actions mdc-dialog__actions">
  <div class="w-full flex justify-between">
    <button mat-button (click)="onCancelBtnClicked()">{{ "no" | translate }}</button>
    <button mat-flat-button color="primary" [disabled]="isLoading" (click)="onDeleteBtnClicked()">
      <app-loading-button text="{{ 'yes' | translate }}" [loading]="isLoading"></app-loading-button>
    </button>
  </div>
</mat-dialog-actions>
