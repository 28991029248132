<div class="h-screen w-screen flex justify-center flex-wrap content-center p-5">
  <div class="h-2/3 text-center">
    <div class="font-medium text-3xl mb-5">{{ (error[errorCode].title || 'Error') | translate }}</div>
    <div class="text-gray-700 text-lg mb-5">
      @if(error[errorCode].subTitle) {
        <span>{{ error[errorCode].subTitle | translate }}</span>
        <br />
      }
      @if(canRedirectToHomePage || returnUrl) {
        <div class="flex justify-center">
          {{ "youCanGoBack" | translate }}&nbsp;
          @if(canRedirectToHomePage && returnUrl) {
            <div>
              <a class="error__link" [routerLink]="returnUrl">{{ "previous" | translate }}</a>
              {{ "or" | translate }}
              <a class="error__link" [routerLink]="homeUrl">{{ "home" | translate }}</a>
              {{ "pages" | translate }}
            </div>
          }
          @if(canRedirectToHomePage && !returnUrl) {
            <div>
              <a class="error__link" [routerLink]="homeUrl">{{ "home" | translate }}</a>
              {{ "page" | translate }}
            </div>
          }
          @if(!canRedirectToHomePage && returnUrl) {
            <div>
              <a class="error__link" [routerLink]="returnUrl">{{ "previous" | translate }}</a>
              {{ "page" | translate }}
            </div>
          }
        </div>
      }
    </div>
    <img class="error-img" [src]="error[errorCode].src" alt="access-denied" />
    @if(errorCode === '401' || errorCode === '403') {
      <button (click)="signout()" mat-raised-button color="primary">logout</button>
    }
  </div>
</div>
