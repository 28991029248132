import { environment } from '@app-environments/environment';
import { Injectable } from '@angular/core';
import { ApiRequest } from '../api.request';
import { EmailSearchRequest } from '@app-types/api/email';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import { BaseResponse } from '@app-types/base/base';
import { FileRefContract } from '@app-types/api/file';

const API_URL = environment.apiUrl;
const { exportExcel } = API_ROUTES.emailExport;

@Injectable({
  providedIn: 'root',
})
export class EmailExportClient {
  constructor(private apiRequest: ApiRequest) {}

  async exportExcel(
    request: EmailSearchRequest
  ): Promise<BaseResponse<FileRefContract>> {
    return this.apiRequest.post<
      EmailSearchRequest,
      BaseResponse<FileRefContract>
    >(request, `${API_URL}${exportExcel}`);
  }
}
