import { Component, Input } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-skeleton-email',
  standalone: true,
  imports: [NgxSkeletonLoaderModule],
  templateUrl: './skeleton-email.component.html',
  styleUrls: ['./skeleton-email.component.scss'],
})
export class SkeletonEmailComponent {
  @Input() hasTitle = false;
  constructor() {}
}
