@if(isError) {
  <div class="h-[80%]">
    <app-something-went-wrong
      description="Cannot load companies"
      [showPicture]="false"
      (retryEmitter)="fetchOrganizations(true)"
    ></app-something-went-wrong>
  </div>
}
@if(!isError) {
  <div class="flex-col-container-1">
    <div class="search-panel">
      <div class="button-group h-[40px]">
        @if(!useMobileView) {
          <button
           mat-raised-button
           color="primary"
           class="page-block-button"
           [attr.aria-label]="'createCompany' | translate"
            (click)="onCreateOrganizationBtnClicked()">
            <mat-icon>add</mat-icon>{{ "newCompany" | translate }}
          </button>
        }
        @if(useMobileView) {
          <button
            class="settings-mobile-button"
            mat-mini-fab
            color="primary"
            [attr.aria-label]="'createCompany' | translate"
            (click)="onCreateOrganizationBtnClicked()">
            <mat-icon class="settings-mobile-button">add</mat-icon>
          </button>
        }
      </div>
      <div class="search-bar">
        <app-search-bar
          (searchEventEmitter)="fetchOrganizations(true)"
          (valueChangedEventEmitter)="onSearchValueChanged($event)"
          [search]="searchValue"
        ></app-search-bar>
        @if(!useMobileView) {
          <mat-paginator
            [pageSize]="pageSize"
            [pageSizeOptions]="[25, 50, 100]"
            [length]="totalSize"
            [pageIndex]="pageIndex"
            (page)="handlePage($event)">
          </mat-paginator>
        }
      </div>
    </div>
  </div>
}
@if(!isLoading && !isError && isUploaded) {
  @if(useMobileView) {
    <div>
      <mat-paginator
        [pageSize]="pageSize"
        [pageSizeOptions]="[25, 50, 100]"
        [length]="totalSize"
        [pageIndex]="pageIndex"
        (page)="handlePage($event)">
      </mat-paginator>
      <div class="mobile-container-cards">
        @for(organization of organizations; track organization) {
          <div>
            <mat-card>
              <mat-card-content class="cursor-pointer">
                <div class="card-table-row">
                  <div class="card-table-row-header">{{ "name" | translate }}:</div>
                  <div class="card-table-row-value">{{ organization.name }}</div>
                </div>
                <div class="card-table-row">
                  <div class="card-table-row-header">{{ "webCode" | translate }}:</div>
                  <div class="card-table-row-value">{{ organization.webCode }}</div>
                </div>
                <div class="card-table-row">
                  <div class="card-table-row-header">{{ "licenseId" | translate }}:</div>
                  <div class="card-table-row-value">{{ organization.licenseId }}</div>
                </div>
                <div class="card-table-row">
                  <div class="card-table-row-header">{{ "codeSia" | translate }}:</div>
                  <div class="card-table-row-value">{{ organization.codeSia }}</div>
                </div>
                <div class="card-table-row">
                  <div class="card-table-row-header">{{ "anagId" | translate }}:</div>
                  <div class="card-table-row-value">{{ organization.anagId }}</div>
                </div>
              </mat-card-content>
              <mat-card-actions class="flex gap-2 justify-end w-full">
                <button
                  mat-flat-button
                  color="primary"
                  [attr.aria-label]="'details' | translate"
                  (click)="openUpdateOrganizationDialog(organization)">
                  <mat-icon class="!m-0">edit_outline</mat-icon>
                </button>
              </mat-card-actions>
            </mat-card>
          </div>
        }
      </div>
      @if(organizations.length === 0) {
        <app-no-data></app-no-data>
      }
    </div>
  }
  @if(!useMobileView) {
    <div class="table-container">
      <table mat-table [dataSource]="dataSource" class="w-full">
        <ng-container matColumnDef="web-code">
          <th mat-header-cell *matHeaderCellDef>{{ "webCode" | translate }}</th>
          <td mat-cell *matCellDef="let organization">{{ organization.webCode }}</td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>{{ "name" | translate }}</th>
          <td mat-cell *matCellDef="let organization">{{ organization.name }}</td>
        </ng-container>
        <ng-container matColumnDef="license-id">
          <th mat-header-cell *matHeaderCellDef>{{ "licenseId" | translate }}</th>
          <td mat-cell *matCellDef="let organization">{{ organization.licenseId }}</td>
        </ng-container>
        <ng-container matColumnDef="code-sia">
          <th mat-header-cell *matHeaderCellDef>{{ "codeSia" | translate }}</th>
          <td mat-cell *matCellDef="let organization">{{ organization.codeSia }}</td>
        </ng-container>
        <ng-container matColumnDef="anagId">
          <th mat-header-cell *matHeaderCellDef>{{ "anagId" | translate }}</th>
          <td mat-cell *matCellDef="let organization">{{ organization.anagId }}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>{{ "actions" | translate }}</th>
          <td mat-cell *matCellDef="let organization">
            <button mat-icon-button [attr.aria-label]="'details' | translate" (click)="openUpdateOrganizationDialog(organization)">
              <mat-icon>edit_outline</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      @if(organizations.length === 0) {
        <app-no-data></app-no-data>
      }
  </div>
  }
}

@if(isLoading) {
  <div class="overflow-hidden flex-1">
    <app-skeleton-table></app-skeleton-table>
  </div>
}

<ng-template #createDrawer>
  <app-organization-create-dialog
    [organization]="organization"
    (closeEventEmitter)="onCloseCreateModalForm()"
    (creationSubmit)="organization ? onUpdateOrganization($event) : onCreateOrganization()">
  </app-organization-create-dialog>
</ng-template>
