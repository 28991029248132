<div class="drawer-content-screen">
  @if(cannotLoadUsers) {
    <div class="flex-col-center">
      <app-something-went-wrong
        description="Cannot load the users. Something went wrong"
        (retryEmitter)="fetchUsers()"
        [showPicture]="false"
      ></app-something-went-wrong>
    </div>
  }

  @if(isLoadingUsers) {
    <div class="h-full">
      <app-skeleton-form-drawer></app-skeleton-form-drawer>
    </div>
  }
  @if(!cannotLoadUsers && !isLoadingUsers && isUploaded) {
    <div class="drawer-content-screen__title">{{ "addGroupToUsers" | translate }}</div>
    <div class="h-full w-full overflow-auto">
      <app-checkable-search-filter
        (filterSelectionChange)="onFilterSelectionChange($event)"
        [search]="search"
        (searchChange)="onSearchChange($event)"
      ></app-checkable-search-filter>
      <div>
        <mat-checkbox
          color="primary"
          class="mb-2"
          [checked]="hasSelected() && isAllSelected()"
          [indeterminate]="hasSelected() && !isAllSelected()"
          (change)="$event ? masterToggle() : null"
        >
          {{ "selectAll" | translate }}
        </mat-checkbox>
        <ul>
          @for(user of filteredUsers | filter : search : 'name'; track user) {
            <li>
              <mat-checkbox color="primary" class="mb-1" [(ngModel)]="user._checked">
                {{ user.name }}
              </mat-checkbox>
            </li>
          }
        </ul>
      </div>
    </div>
    <div class="w-full flex justify-end">
      <button mat-flat-button color="primary" [disabled]="isLoading" (click)="onSubmit()">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
      </button>
    </div>
  }
</div>
