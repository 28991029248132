import { SearchTypeEnum } from './enums/filters';
import { EmailValidationState } from './enums/email.validation-state';
import { DateTimePeriod } from './base/datetime.period';
import { EmailSearchOrder } from './enums/email.search-order';
import { OrderDirection } from './enums/order-direction';

export class SearchParams {
  hideNotifications: boolean = true;
  parentId: number | null = null;
  reassignedId: number | null = null;
  reassigned: boolean | null = null;
  deleted: boolean | null = null;
  isSpam: boolean | null = null;
  isArchive: boolean | null = null;
  seen: boolean | null = null;
  validated: EmailValidationState[] | null = [];
  sentDateTime: DateTimePeriod = new DateTimePeriod(null, null);
  downloadDateTime: DateTimePeriod = new DateTimePeriod(null, null);
  withAttachments: boolean | null = null;
  source: boolean | null = null;
  subject: string | null = null;
  body: string | null = null;
  from: string | null = null;
  to: string | null = null;
  fullText: string | null = null;
  attachmentName: string | null = null;
  tags: string[] = [];
  searchOrder: EmailSearchOrder = EmailSearchOrder.OrderBySentDateTime;
  orderDirection: OrderDirection = OrderDirection.Descending;
}

export interface SearchEvent {
  filter?: SearchParams;
  searchType?: SearchTypeEnum;
  hideNotificationsEmitter?: true;
}

export interface SearchInfo {
  order?: EmailSearchOrder;
  page?: number;
  pageSize?: number;
  hideNotifications?: boolean;
  search?: string;
  searchType?: SearchTypeEnum;
  filter?: SearchParams;
  tab?: number;
  acc?: number;
  folder?: number;
  type?: number; // typeFolder
  [key: string]: any;
}
