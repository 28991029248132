import {
  Component,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DrawerService } from '@app-services/drawer.service';

@Component({
  selector: 'app-drawer',
  standalone: true,
  imports: [MatSidenavModule, MatDrawer, MatButtonModule, MatIconModule],
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
})
export class DrawerComponent {
  @ViewChild('drawer') drawer: any;
  @ViewChild('drawerContent', { read: ViewContainerRef })
  drawerContent!: ViewContainerRef;

  disableClose: boolean = false;

  constructor(public drawerService: DrawerService) {}

  public closeDrawer(): void {
    !this.disableClose && this.drawer?.close();
    this.drawerService.callbackClose &&
      (this.drawerService.callbackClose = null);
    this.drawerContent?.clear();
  }

  openDrawer(template: TemplateRef<any>): void {
    this.drawerContent?.createEmbeddedView(template);
    this.drawer?.toggle();
  }

  disableCloseDrawer(isDisable: boolean): void {
    this.disableClose = isDisable;
  }
}
