import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserOrganizationGetOrganizationUsersContract } from '@app-types/api/user-organization';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { MailAccountClient } from 'src/app/services/api/clients/mail-account.client';
import { UserMailAccountClient } from 'src/app/services/api/clients/user-mail-account.client';
import {
  UserMailAccountGetListRequest,
  UserMailAccountGetListResponse,
} from 'src/app/types/api/user-mail-account';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { SomethingWentWrongComponent } from '../../../../common/error/something-went-wrong/something-went-wrong.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LoadingButtonComponent } from '../../../../common/loading-button/loading-button.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { FilterPipe } from '@app-pipes/filter.pipe';
import { CheckableSearchFilterComponent } from '../../../../common/сheckable-search-filter/сheckable-search-filter.component';
import { ROLES } from '@app-shared/constants/constants';
import { DrawerService } from '@app-services/drawer.service';
import { MailAccountCheckboxes } from '@app-types/common';
import { SkeletonFormDrawerComponent } from '@app-components/common/skeletons/skeleton-form-drawer/skeleton-form-drawer.component';

@Component({
  selector: 'app-add-users-to-mail-accounts-form',
  templateUrl: './add-users-to-mail-accounts-form.component.html',
  styleUrls: ['./add-users-to-mail-accounts-form.component.scss'],
  standalone: true,
  imports: [
    SomethingWentWrongComponent,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    LoadingButtonComponent,
    MatCheckboxModule,
    SkeletonFormDrawerComponent,
    TranslateModule,
    FilterPipe,
    CheckableSearchFilterComponent,
    MatTableModule,
  ],
})
export class AddUsersToMailAccountsFormComponent implements OnInit, OnDestroy {
  @Input() public users: UserOrganizationGetOrganizationUsersContract[];
  @Input() public organizationID: number;
  @Output() private closeDrawer = new EventEmitter();
  @Output() private submitDrawer = new EventEmitter();
  public cannotLoadMailAccounts = false;
  public isLoading = false;
  public isLoadingMailAccounts = false;

  public roles = ROLES;
  public search = '';

  public mailAccounts: MailAccountCheckboxes[] = [];
  public filteredMailAccounts: MailAccountCheckboxes[] = [];

  public dataSource = new MatTableDataSource<MailAccountCheckboxes>(
    this.filteredMailAccounts
  );
  public displayedColumns: string[] = ['select', 'mailAccount', 'role'];

  constructor(
    private mailAccountClient: MailAccountClient,
    private userMailAccountClient: UserMailAccountClient,
    private matchError: MatchError,
    private drawerService: DrawerService
  ) {}

  ngOnInit(): void {
    this.fetchMailAccounts();
  }

  public async onSubmit(): Promise<void> {
    this.isLoading = true;
    this.drawerService.disabledDrawer(true);
    const users = this.users.map(user => user.userId);
    const usersRoles = this.mailAccounts
      .filter(item => item._checked)
      .map(item => {
        return {
          mailAccountId: item.mailAccountId,
          role: item.role,
        };
      });

    try {
      await this.userMailAccountClient.setUserMailAccounts({
        users,
        usersRoles,
      });
      this.drawerService.disabledDrawer(false);
      this.submitDrawer.emit();
      this.drawerService.closeDrawer();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
      this.drawerService.disabledDrawer(false);
    } finally {
      this.isLoading = false;
    }
  }

  async fetchMailAccounts(): Promise<void> {
    this.isLoadingMailAccounts = true;
    try {
      const request = {
        organizationId: this.organizationID,
        page: 1,
        pageSize: 500,
        filter: {
          search: '',
          ProviderId: null,
          isActive: null,
          isArchive: null,
          searchOrder: 1,
          orderDirection: 1,
        },
      };
      const result = await this.mailAccountClient.getSearch(request);

      if (this.users.length === 1) {
        const { userMailAccounts } = await this.getUserMailAccounts();

        this.mailAccounts = result.data.map(mailAccount => {
          const currItem = userMailAccounts.find(
            item => mailAccount.mailAccountId === item.mailAccountId
          );
          const newRole = this.roles.find(
            item => item.value === currItem?.roleId
          );
          return {
            ...mailAccount,
            role: currItem && newRole ? newRole.value : this.roles[0].value,
            _checked: !!currItem,
          };
        });
      } else {
        this.mailAccounts = result.data.map(mailAccount => {
          return { ...mailAccount, role: this.roles[0].value, _checked: false };
        });
      }

      this.filteredMailAccounts = this.mailAccounts;
      this.dataSource.data = this.filteredMailAccounts;
      this.cannotLoadMailAccounts = false;
    } catch (e) {
      this.cannotLoadMailAccounts = true;
      this.matchError.logError(e);
    } finally {
      this.isLoadingMailAccounts = false;
    }
  }

  private async getUserMailAccounts(): Promise<UserMailAccountGetListResponse> {
    const req = new UserMailAccountGetListRequest(this.users[0].userId);
    return await this.userMailAccountClient.getUserAccountsById(req);
  }

  isAllSelected(): boolean {
    const numSelected = this.mailAccounts.filter(
      mailAccount => mailAccount._checked
    ).length;
    const numRows = this.mailAccounts.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected()
      ? this.mailAccounts.forEach(mailAccount => (mailAccount._checked = false))
      : this.mailAccounts.forEach(mailAccount => (mailAccount._checked = true));
  }

  public hasSelected(): boolean {
    let hasSelected = false;
    for (let i = 0; i < this.mailAccounts.length; i++) {
      if (this.mailAccounts[i]._checked) {
        hasSelected = true;
        break;
      }
    }
    return hasSelected;
  }

  onFilterSelectionChange(value: number): void {
    this.filteredMailAccounts =
      value < 0
        ? this.mailAccounts
        : this.mailAccounts.filter(mailAccount =>
            value === 0 ? !mailAccount._checked : mailAccount._checked
          );
    this.dataSource.data = this.filteredMailAccounts;
  }

  onSearchChange(value: string): void {
    this.search = value;
    this.dataSource.filter = value.trim().toLowerCase();
  }

  ngOnDestroy(): void {
    this.closeDrawer.emit();
  }
}
