import { environment } from 'src/environments/environment';

export class FerreroWebCodes {
  codes = environment.pluginWebCodes || [];
}

export interface FerreroAttachmentContract {
  fileName: string;
  mimeType: string;
  fileContent: string;
}

export interface EmailFerreroCreateRequest {
  from: string;
  subject: string;
  body: string;
  attachments: FerreroAttachmentContract[];
}
