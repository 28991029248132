<div class="breadcrumbs">
  @for(item of sortedItems; track item.index) {
    <div class="flex flex-wrap content-center h-5">
      @if(item.route) {
        <a
          class="link"
          [class.accessible-link]="!isLastItem(item.index)"
          [routerLink]="isLastItem(item.index) ? [] : [item.route]"
        >
          {{ item.label | translate }}
        </a>
      } @else {
          <span class="link">
             {{ item.label | translate }}
          </span>
      }

      @if(!isLastItem(item.index)) {
        <mat-icon class="nav-icon" [inline]="true">navigate_next</mat-icon>
      }
    </div>
  }
</div>
