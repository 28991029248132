import { Component } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-skeleton-form-drawer',
  standalone: true,
  imports: [NgxSkeletonLoaderModule],
  templateUrl: './skeleton-form-drawer.component.html',
  styleUrls: ['./skeleton-form-drawer.component.scss'],
})
export class SkeletonFormDrawerComponent {
  constructor() {}
}
