import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseGetByIdRequest } from '@app-types/base/base';
import { TemplateClient } from '@app-services/api/clients/template.client';
import { TemplateContract } from '@app-types/api/template';
import { MatDialog } from '@angular/material/dialog';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { SearchBarComponent } from '../../common/search-bar/search-bar.component';
import { SomethingWentWrongComponent } from '../../common/error/something-went-wrong/something-went-wrong.component';
import { LoadingButtonComponent } from '../../common/loading-button/loading-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { SkeletonCardListComponent } from '@app-components/common/skeletons/skeleton-card-list/skeleton-card-list.component';

@Component({
  selector: 'app-email-templates',
  templateUrl: './email-templates.component.html',
  styleUrls: ['./email-templates.component.scss'],
  standalone: true,
  imports: [
    SearchBarComponent,
    SomethingWentWrongComponent,
    LoadingButtonComponent,
    TranslateModule,
    MatButtonModule,
    SkeletonCardListComponent,
  ],
})
export class EmailTemplatesComponent implements OnInit {
  @Input() mailAccountId: number;
  @Output() templateSelected = new EventEmitter();
  public isAnyTemplateDownloading = false;
  public downloadingTemplateId: number | null;
  public isLoading = false;
  public isError = false;
  public searchValue = '';
  public templates: TemplateContract[] = [];
  public searchedTemplates: TemplateContract[] = [];
  public useMobileView = false;

  constructor(
    public matchError: MatchError,
    private templateClient: TemplateClient,
    public dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadTemplates();
  }

  public searchTemplates(): void {
    if (this.searchValue) {
      this.searchedTemplates = this.templates.filter(
        e =>
          e.name.toLowerCase().includes(this.searchValue.toLowerCase()) ||
          e.description.toLowerCase().includes(this.searchValue.toLowerCase())
      );
    } else {
      this.searchedTemplates = this.templates;
    }
  }

  public async loadTemplates(): Promise<void> {
    this.isLoading = true;
    this.isError = false;
    const request = new BaseGetByIdRequest(this.mailAccountId);
    try {
      const response = await this.templateClient.getForMailAccount(request);
      this.templates = response.data;
      this.searchedTemplates = response.data;
    } catch (e) {
      this.isError = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  public async loadTemplate(templateId: number): Promise<void> {
    this.downloadingTemplateId = templateId;
    this.isAnyTemplateDownloading = true;
    const request = new BaseGetByIdRequest(templateId);
    try {
      const response = await this.templateClient.getById(request);
      this.templateSelected.emit(response.template);
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.downloadingTemplateId = null;
      this.isAnyTemplateDownloading = false;
    }
  }

  public onSearchValueChanged(newSearchValue: string): void {
    this.searchValue = newSearchValue;
  }
}
