import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { GroupClient } from '@app-services/api/clients/group-settings.client';
import { OrganizationClient } from '@app-services/api/clients/organization.client';
import {
  BaseCollectionSearchByIdRequest,
  BaseGetSearchCountByIdRequest,
} from '@app-types/base/base';
import { Group } from '@app-types/api/group';
import { MobileObserverService } from '@app-services/adaptive/mobile-observer.service';
import { PermissionNavTabHelper } from '@app-services/permission/permission-nav-tab-helper';
import { PermissionService } from '@app-services/permission/permission.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SearchBarComponent } from '@app-components/common/search-bar/search-bar.component';
import { NoDataComponent } from '@app-components/common/no-data/no-data.component';
import { SomethingWentWrongComponent } from '@app-components/common/error/something-went-wrong/something-went-wrong.component';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DrawerService } from '@app-services/drawer.service';
import { OrganizationGroupCreateDialogComponent } from '@app-components/settings/organization/organization-groups/organization-group-create-dialog/organization-group-create-dialog.component';
import { DatePipe } from '@angular/common';
import { AddGroupsToUsersFormComponent } from '@app-components/settings/organization/organization-groups/add-groups-to-users-form/add-groups-to-users-form.component';
import { LocalStorageService } from '@app-services/local-storage.service';
import {
  ORGANIZATION_ID,
  STORAGE_NAMES,
} from '@app-shared/constants/constants';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { DeleteDialogComponent } from '@app-components/common/delete-dialog/delete-dialog.component';
import { Subscription } from 'rxjs';
import { SkeletonTableComponent } from '@app-components/common/skeletons/skeleton-table/skeleton-table.component';

const { PAGE_SIZE_SETTINGS } = STORAGE_NAMES;

@Component({
  selector: 'app-organization-groups',
  templateUrl: './organization-groups.component.html',
  styleUrls: ['./organization-groups.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    SearchBarComponent,
    NoDataComponent,
    SomethingWentWrongComponent,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    OrganizationGroupCreateDialogComponent,
    DatePipe,
    AddGroupsToUsersFormComponent,
    MatTooltipModule,
    MatCardModule,
    SkeletonTableComponent,
  ],
})
export class OrganizationGroupsComponent implements OnInit, OnDestroy {
  @HostBinding('class') className = 'setting-container';
  public organizationName: string;
  public organizationId: number;

  public isLoading = false;
  isUploaded = false;
  public hasError = false;

  public useMobileView = false;

  public searchValue = '';
  public pageSize = 25;
  public pageIndex = 0;
  public totalSize = 0;

  public organizationGroups: Group[] = [];
  public displayedColumns: string[] = [
    'select',
    'name',
    'createdAt',
    'actions',
  ];

  public editingGroup: Group | null;
  public selectGroup: Group | null;
  private useMobileViewSubscription: Subscription;
  public dataSource = new MatTableDataSource<Group>();
  public selection = new SelectionModel<Group>(true, []);

  @ViewChild('createDrawer') createDrawer: TemplateRef<any>;
  @ViewChild('addGroupsToUsersDrawer') addGroupsToUsersDrawer: TemplateRef<any>;

  constructor(
    private organizationClient: OrganizationClient,
    private permissionService: PermissionService,
    private permissionNavTabHelper: PermissionNavTabHelper,
    private route: ActivatedRoute,
    private mobileObserverService: MobileObserverService,
    private groupClient: GroupClient,
    private matchError: MatchError,
    public dialog: MatDialog,
    private drawerService: DrawerService,
    private localStorageService: LocalStorageService
  ) {}

  async ngOnInit(): Promise<void> {
    this.useMobileViewSubscription = this.mobileObserverService
      .mobileObserver()
      .subscribe(isMobile => (this.useMobileView = isMobile));

    this.pageSize = this.localStorageService.getData(PAGE_SIZE_SETTINGS) || 25;
    this.organizationId = parseInt(
      this.route.snapshot.paramMap.get(ORGANIZATION_ID) as string,
      10
    );
    await this.fetchGroups();
  }

  public async fetchGroups(fetchSearch = false): Promise<void> {
    this.isUploaded = false;
    const loaderTimeout = setTimeout(() => {
      this.isLoading = true;
    }, 500);
    this.hasError = false;
    if (fetchSearch) {
      this.pageIndex = 0;
    }
    const req = new BaseCollectionSearchByIdRequest(
      this.organizationId,
      this.pageIndex + 1,
      this.pageSize,
      this.searchValue
    );
    try {
      const searchCountReq = new BaseGetSearchCountByIdRequest(
        this.organizationId,
        this.searchValue
      );
      const totalSize = await this.groupClient.getSearchCounter(searchCountReq);
      this.totalSize = totalSize.result;

      const { data } = await this.groupClient.getAllByOrganization(req);
      this.organizationGroups = data;
      this.selection.clear();
      this.dataSource.data = this.organizationGroups;
      this.drawerService.closeDrawer();
    } catch (e) {
      this.hasError = true;
      this.matchError.logError(e);
    } finally {
      clearTimeout(loaderTimeout);
      this.isLoading = false;
      this.isUploaded = true;
    }
  }

  public handlePage($event: PageEvent): void {
    this.localStorageService.setData(PAGE_SIZE_SETTINGS, $event.pageSize);
    this.pageIndex = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.fetchGroups();
  }

  public onSearchValueChanged(newSearchValue: string): void {
    this.searchValue = newSearchValue;
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  public onCreateGroupBtnClicked(): void {
    this.drawerService.openDrawer(this.createDrawer);
  }

  public onCloseCreateUpdateForm(): void {
    this.editingGroup = null;
  }

  public onUpdateGroupBtnClicked(group: Group): void {
    this.editingGroup = group;
    this.onCreateGroupBtnClicked();
  }

  public onGroupUpdated(): void {
    this.fetchGroups();
    this.onCloseCreateUpdateForm();
  }

  public onAddGroupsToUsersBtnClicked(): void {
    this.drawerService.openDrawer(this.addGroupsToUsersDrawer);
  }

  public onAddGroupToUsersBtnClicked(group: Group): void {
    this.selectGroup = group;
    this.drawerService.openDrawer(this.addGroupsToUsersDrawer);
  }

  public onHideAddGroupsToUsersForm(): void {
    !!this.selectGroup && (this.selectGroup = null);
  }

  public onAddGroupsToUsers(): void {
    !!this.selection.selected?.length && this.selection.clear();
    !!this.selectGroup && (this.selectGroup = null);
  }

  async onDelete(groups: number[]): Promise<void> {
    await this.groupClient.delete({ ids: groups });
  }

  public openConfirmDeleteDialog(group: Group): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '450px',
      autoFocus: false,
      data: {
        title: 'groupDeleteTitle',
        subTitles: [
          {
            title: 'groupDelete',
            subTitle: group.group,
          },
        ],
        onDelete: async () => await this.onDelete([group.groupId]),
      },
    });

    dialogRef.afterClosed().subscribe(x => {
      if (x.isDeleted) {
        this.fetchGroups();
      }
    });
  }

  ngOnDestroy(): void {
    this.useMobileViewSubscription?.unsubscribe();
  }
}
