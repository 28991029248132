import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MAT_SELECT_CONFIG, MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    TranslateModule,
    MatTooltipModule,
  ],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'select-overlay-pane' },
    },
  ],
})
export class PaginatorComponent {
  @Input() hasPreviousPage: boolean;
  @Input() countFrom: string;
  @Input() countTotal: string;
  @Input() countTo: string;
  @Input() hasNextPage: boolean;
  @Input() pageSize: number;
  @Input() pageSizeOptions: number[];
  @Output() pageSizeChange = new EventEmitter<number>();
  @Output() goToPreviousPage = new EventEmitter<void>();
  @Output() goToNextPage = new EventEmitter<void>();

  constructor() {}

  public onChangePageSize(pageSize: number): void {
    this.pageSize = pageSize;
    this.pageSizeChange.emit(this.pageSize);
  }

  public previous(): void {
    this.goToPreviousPage.emit();
  }

  public next(): void {
    this.goToNextPage.emit();
  }
}
