import { Component, OnInit } from '@angular/core';
import { BaseGetByIdRequest } from '@app-types/base/base';
import { ApiException } from '@app-services/errors/api.exception';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '@app-services/snackbar.service';
import { EmailClient } from '@app-services/api/clients/email-client';
import { EmailContract } from '@app-types/api/email';
import { MatchError } from '@app-services/errors/error-matcher';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-email-print',
  templateUrl: './email-print.component.html',
  styleUrls: ['./email-print.component.scss'],
  standalone: true,
  imports: [MatProgressBarModule, TranslateModule],
})
export class EmailPrintComponent implements OnInit {
  public emailId: number;
  public mailAccountId: number;
  public organizationId?: number;
  public mailFolderId: number;
  public showLoader: boolean;
  public emailDetails: EmailContract;
  public body: string;

  constructor(
    public matchError: MatchError,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    private snackbarHelper: SnackbarService,
    private emailClient: EmailClient
  ) {
    this.mailAccountId = parseInt(
      activatedRoute.snapshot.params.mailAccountId,
      10
    );
    this.mailFolderId = parseInt(
      activatedRoute.snapshot.params.mailFolderId,
      10
    );
    this.emailId = parseInt(activatedRoute.snapshot.params.emailId, 10);
    this.organizationId = this.activatedRoute.snapshot.queryParams?.id
      ? +this.activatedRoute.snapshot.queryParams?.id
      : undefined;
  }

  async ngOnInit(): Promise<any> {
    this.organizationId && (await this.getEmailDetail());
  }

  async getEmailDetail(): Promise<void> {
    this.showLoader = true;
    const request = new BaseGetByIdRequest(this.emailId, this.organizationId);
    try {
      const result = await this.emailClient.getById(request);
      this.emailDetails = result.email;
      this.showLoader = false;
      await this.print();
    } catch (e) {
      if (e instanceof ApiException) {
        this.showLoader = false;
        this.snackbarHelper.openSnackBar(e.format());
      } else {
        this.showLoader = false;
        throw e;
      }
      this.matchError.logError(e);
    }
  }

  private async sleep(ms: number): Promise<any> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private async print(): Promise<any> {
    await this.sleep(3000);
    const printContent = document.getElementById('print-div-id');
    const WindowPrt = window.open(
      '',
      '',
      'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0'
    );
    if (WindowPrt && printContent) {
      WindowPrt.document.write(printContent.innerHTML);
      WindowPrt.document.close();
      WindowPrt.focus();
      WindowPrt.print();
      WindowPrt.close();
    }
  }
}
