import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { OrganizationClient } from '@app-services/api/clients/organization.client';
import { UserOrganizationClient } from '@app-services/api/clients/user-organization.client';
import {
  BaseCollectionSearchByIdRequest,
  BaseGetByIdRequest,
  BaseGetSearchCountByIdRequest,
} from '@app-types/base/base';
import { RoleType } from '@app-types/enums/role-type';
import { UserOrganizationGetOrganizationUsersContract } from '@app-types/api/user-organization';
import { UserOrganizationState } from '@app-types/enums/user-organization-state.enum';
import { MobileObserverService } from '@app-services/adaptive/mobile-observer.service';
import { PermissionNavTabHelper } from '@app-services/permission/permission-nav-tab-helper';
import { PermissionService } from '@app-services/permission/permission.service';
import { ConfirmDialogComponent } from '@app-components/common/confirm-dialog/confirm-dialog.component';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { OrganizationSettingsClient } from 'src/app/services/api/clients/organization-settings.client';
import { SomethingWentWrongComponent } from '@app-components/common/error/something-went-wrong/something-went-wrong.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { NoDataComponent } from '@app-components/common/no-data/no-data.component';
import { SearchBarComponent } from '@app-components/common/search-bar/search-bar.component';
import { DrawerService } from '@app-services/drawer.service';
import { LocalStorageService } from '@app-services/local-storage.service';
import {
  ORGANIZATION_ID,
  STORAGE_NAMES,
} from '@app-shared/constants/constants';
import { OrganizationUserCreateDialogComponent } from '@app-components/settings/organization/organization-users/organization-user-create-dialog/organization-user-create-dialog.component';
import { AddUsersToGroupsFormComponent } from '@app-components/settings/organization/organization-users/add-users-to-groups-form/add-users-to-groups-form.component';
import { FormatUserStatePipe } from '@app-pipes/format-user-state.pipe';
import { AddUsersToMailAccountsFormComponent } from '@app-components/settings/organization/organization-users/add-users-to-mail-accounts-form/add-users-to-mail-accounts-form.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Subscription } from 'rxjs';
import { SkeletonTableComponent } from '@app-components/common/skeletons/skeleton-table/skeleton-table.component';

const { PAGE_SIZE_SETTINGS } = STORAGE_NAMES;

@Component({
  selector: 'app-organization-users',
  templateUrl: './organization-users.component.html',
  styleUrls: ['./organization-users.component.scss'],
  standalone: true,
  imports: [
    SomethingWentWrongComponent,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatPaginator,
    MatCardModule,
    NoDataComponent,
    MatTableModule,
    SearchBarComponent,
    OrganizationUserCreateDialogComponent,
    AddUsersToGroupsFormComponent,
    FormatUserStatePipe,
    AddUsersToMailAccountsFormComponent,
    MatTooltipModule,
    SkeletonTableComponent,
  ],
})
export class OrganizationUsersComponent implements OnInit, OnDestroy {
  @HostBinding('class') className = 'setting-container';
  public organizationId: number;
  public organizationName: string;
  public organizationUsers: UserOrganizationGetOrganizationUsersContract[] = [];
  public dataSource =
    new MatTableDataSource<UserOrganizationGetOrganizationUsersContract>(
      this.organizationUsers
    );
  public displayedColumns: string[] = [
    'select',
    'firstName',
    'lastName',
    'googleIdentity',
    'oidcIdentity',
    'adfsIdentity',
    'email',
    'role',
    'state',
    'actions',
  ];
  public isLoading = false;
  isUploaded = false;
  public hasError = false;
  public iscredemISAMSupport = true;
  public roleTypes = RoleType;

  public pageSize = 25;
  public pageIndex = 0;
  public totalSize = 0;

  public searchValue = '';
  public useMobileView = false;

  public isDrawerOpen = false;

  public showAddUsersToMailAccountsForm = false;

  public selection =
    new SelectionModel<UserOrganizationGetOrganizationUsersContract>(true, []);
  selectedUser: UserOrganizationGetOrganizationUsersContract | null;
  private useMobileViewSubscription: Subscription;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('createDrawer') createDrawer: TemplateRef<any>;
  @ViewChild('addUsersToGroupsDrawer') addUsersToGroupsDrawer: TemplateRef<any>;
  @ViewChild('addUsersToMailAccountsDrawer')
  addUsersToMailAccountsDrawer: TemplateRef<any>;

  constructor(
    private route: ActivatedRoute,
    private userOrganizationClient: UserOrganizationClient,
    private permissionService: PermissionService,
    private organizationClient: OrganizationClient,
    private permissionNavTabHelper: PermissionNavTabHelper,
    private matchError: MatchError,
    private organizationSettingsClient: OrganizationSettingsClient,
    public dialog: MatDialog,
    private mobileObserverService: MobileObserverService,
    private drawerService: DrawerService,
    private localStorageService: LocalStorageService
  ) {}

  async ngOnInit(): Promise<void> {
    this.useMobileViewSubscription = this.mobileObserverService
      .mobileObserver()
      .subscribe(isMobile => (this.useMobileView = isMobile));

    this.pageSize = this.localStorageService.getData(PAGE_SIZE_SETTINGS) || 25;
    this.organizationId = parseInt(
      this.route.snapshot.paramMap.get(ORGANIZATION_ID) as string,
      10
    );
    await this.loadOrganizationUsers(true);
  }

  public async changeState(
    userOrganizationId: number,
    state: UserOrganizationState
  ): Promise<void> {
    try {
      if (state === UserOrganizationState.Disabled) {
        await this.userOrganizationClient.changeState({
          userOrganizationId,
          state: UserOrganizationState.Active,
        });
      } else {
        await this.userOrganizationClient.changeState({
          userOrganizationId,
          state: UserOrganizationState.Disabled,
        });
      }
      await this.loadOrganizationUsers(false);
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  public openConfirmDialog(
    userOrganizationId: number,
    state: UserOrganizationState,
    user: UserOrganizationGetOrganizationUsersContract
  ): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      autoFocus: false,
      data: {
        name: 'stateChange',
        isDontNeedConfirmWord: true,
        title:
          state === UserOrganizationState.Active
            ? 'stateActiveTitle'
            : 'stateDisabledTitle',
        itemName: `${user.firstName} ${user.lastName}`,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.changeState(userOrganizationId, state);
      }
    });
  }

  public async loadOrganizationUsers(
    fetchSearchCounter: boolean
  ): Promise<void> {
    this.isUploaded = false;
    const loaderTimeout = setTimeout(() => {
      this.isLoading = true;
    }, 500);
    this.hasError = false;
    if (fetchSearchCounter) {
      this.pageIndex = 0;
    }

    const nullableSearchValue =
      this.searchValue.length > 0 ? this.searchValue : null;
    const request = new BaseCollectionSearchByIdRequest(
      this.organizationId,
      this.pageIndex + 1,
      this.pageSize,
      nullableSearchValue
    );
    try {
      const organizationSettings =
        await this.organizationSettingsClient.getByOrganization(
          new BaseGetByIdRequest(this.organizationId)
        );
      this.iscredemISAMSupport = organizationSettings.result.credemISAMSupport;

      const response = await this.userOrganizationClient.search(request);
      this.selection.clear();
      this.organizationUsers = response.data.filter(e =>
        this.permissionService.hasPermissionToSeeUserByUserEmail(e.email)
      );

      this.organizationUsers = this.organizationUsers.filter(
        e => e.roleId !== 1
      );
      this.dataSource.data = this.organizationUsers;
      if (fetchSearchCounter) {
        const countRequest = new BaseGetSearchCountByIdRequest(
          this.organizationId,
          nullableSearchValue
        );
        const counterResponse =
          await this.userOrganizationClient.getSearchCount(countRequest);
        this.totalSize = counterResponse.result;
      }
    } catch (e) {
      this.hasError = true;
      this.matchError.logError(e);
    } finally {
      clearTimeout(loaderTimeout);
      this.isUploaded = true;
      this.isLoading = false;
    }
  }

  public async handlePage(e: any): Promise<void> {
    this.localStorageService.setData(PAGE_SIZE_SETTINGS, e.pageSize);
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    await this.loadOrganizationUsers(false);
  }

  public onSearchValueChanged(newSearchValue: string): void {
    this.searchValue = newSearchValue;
  }

  async onCreateOrganizationUserBtnClicked(): Promise<void> {
    this.drawerService.openDrawer(this.createDrawer);
  }

  async onUpdateOrganizationUserBtnClicked(
    user: UserOrganizationGetOrganizationUsersContract
  ): Promise<void> {
    this.selectedUser = user;
    this.drawerService.openDrawer(this.createDrawer);
  }

  onCloseCreateModalForm(): void {
    this.selectedUser && (this.selectedUser = null);
  }

  async onCreateUpdateOrganizationUser(): Promise<void> {
    this.totalSize += 1;
    await this.loadOrganizationUsers(false);
  }

  public isRta(organizationUserRole: RoleType): boolean {
    return organizationUserRole === RoleType.Rta;
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  public onAddUserToGroupsBtnClicked(
    user?: UserOrganizationGetOrganizationUsersContract
  ): void {
    user && (this.selectedUser = user);
    this.drawerService.openDrawer(this.addUsersToGroupsDrawer);
  }

  public onHideAddUsersToGroupForm(): void {
    this.selectedUser && (this.selectedUser = null);
  }

  public onAddUsersToGroups(): void {
    this.selectedUser && (this.selectedUser = null);
    this.selection.selected && this.selection.clear();
  }

  public onAddUserToMailAccountsBtnClicked(
    user: UserOrganizationGetOrganizationUsersContract
  ): void {
    this.selectedUser = user;
    this.drawerService.openDrawer(this.addUsersToMailAccountsDrawer);
  }

  public onHideAddUsersToMailAccountsForm(): void {
    this.selectedUser && (this.selectedUser = null);
  }

  ngOnDestroy(): void {
    this.useMobileViewSubscription?.unsubscribe();
  }
}
