import { environment } from '@app-environments/environment';
import { Injectable } from '@angular/core';
import { ApiRequest } from '../api.request';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import { BaseGetByIdRequest } from '@app-types/base/base';
import { AttachmentGetByIdResponse } from '@app-types/api/email';

const API_URL = environment.apiUrl;
const { get } = API_ROUTES.attachment;

@Injectable({ providedIn: 'root' })
export class AttachmentClient {
  constructor(private apiRequest: ApiRequest) {}

  async getById(
    request: BaseGetByIdRequest
  ): Promise<AttachmentGetByIdResponse> {
    return this.apiRequest.get(`${API_URL}${get}?${request.format()}`);
  }
}
