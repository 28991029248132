import { Injectable } from '@angular/core';
import { environment } from '@app-environments/environment';
import { ApiRequest } from '../api.request';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import { ReportCreateRequest, ReportResponse } from '@app-types/api/report';

const API_URL = environment.apiUrl;
const { exportExcel } = API_ROUTES.report;

@Injectable({
  providedIn: 'root',
})
export class ReportClient {
  constructor(private apiRequest: ApiRequest) {}

  async create(request: ReportCreateRequest): Promise<ReportResponse> {
    return this.apiRequest.post<ReportCreateRequest, ReportResponse>(
      request,
      `${API_URL}${exportExcel}`
    );
  }
}
