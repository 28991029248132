<div class="close-button">
  <button tabIndex="-1" class="" mat-icon-button (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<h1 mat-dialog-title>{{ "contacts" | translate }}</h1>
<mat-dialog-content class="mat-mdc-dialog-content mdc-dialog__content mat-container">
  <div class="relative flex flex-wrap lg:flex-nowrap items-end gap-4">
    <mat-form-field class="example-full-width">
      <mat-icon class="absolute right-0 bottom-[4px] text-[22px]">search</mat-icon>
      <mat-label>{{ "search" | translate }}</mat-label>
      <input
        type="text"
        #input
        placeholder="{{ 'searchContacts' | translate }}"
        aria-label="Search contacts"
        matInput
        [formControl]="myControl" />
    </mat-form-field>
    <app-paginator
      class="ml-[auto]"
      [pageSize]="pageSize"
      [pageSizeOptions]="[5, 10, 25, 100]"
      (pageSizeChange)="pageSizeChange($event)"
      [hasPreviousPage]="canNavigateBack()"
      [hasNextPage]="canNavigateForward()"
      (goToPreviousPage)="navigateBack()"
      (goToNextPage)="navigateForward()">
    </app-paginator>
  </div>
  @if(isLoading) {
    <div class="overflow-hidden pt-4">
       <div class="w-full flex gap-3">
         <div class="w-[90%]">
            <ngx-skeleton-loader
              [count]="1"
              [theme]="{
                height: '24px',
                width: '20%',
              }"
            ></ngx-skeleton-loader>
          </div>
          <div class="w-[10%]">
            <ngx-skeleton-loader
              [count]="1"
              [theme]="{
                height: '24px',
                width: '30%',
              }"
            ></ngx-skeleton-loader>
          </div>
       </div>
       <div>
          <ngx-skeleton-loader
            [count]="8"
            [theme]="{
                height: '24px',
                width: '100%',
            }"
          ></ngx-skeleton-loader>
        </div>
    </div>
  }
  @if(!isLoading && isError) {
    <div class="h-full w-full">
      <app-something-went-wrong
        [showPicture]="false"
        (retryEmitter)="ngOnInit()"
      ></app-something-went-wrong>
    </div>
  }

  @if(!isLoading && !isError) {
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef style="width: 36px">
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              color="primary"
              [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row" style="width: 36px">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              color="primary">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th class="flex-1" mat-header-cell *matHeaderCellDef>{{ "email" | translate }}</th>
          <td class="flex-1" mat-cell style="cursor: pointer" *matCellDef="let contact" (click)="addContact(contact.email)">
            {{ contact.email }}
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th style="width: 80px" mat-header-cell *matHeaderCellDef>{{ "delete" | translate }}</th>
          <td style="width: 80px" mat-cell *matCellDef="let contact" class="action-link">
            <button mat-icon-button (click)="deleteContact(contact)">
              <mat-icon>close</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  }
</mat-dialog-content>
<mat-dialog-actions class="mat-mdc-dialog-actions mdc-dialog__actions">
  <div class="flex justify-end w-full">
    <button [disabled]="selection.isEmpty()" color="primary" mat-raised-button (click)="openConfirmDialog()">
      {{ "delete" | translate }}
    </button>
    <button [disabled]="selection.isEmpty()" color="primary" mat-raised-button (click)="addSelectedContacts()">
      {{ "select" | translate }}
    </button>
  </div>
</mat-dialog-actions>
