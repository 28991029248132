<div class="drawer-content-screen">
  @if(cannotLoadSetting) {
    <div class="flex-col-center">
     <app-something-went-wrong
       [showPicture]="false"
       (retryEmitter)="ngOnInit()">
     </app-something-went-wrong>
   </div>
  }
  @if(isLoading) {
     <div class="h-full">
       <app-skeleton-form-drawer></app-skeleton-form-drawer>
     </div>
  }

  @if(!isLoading && !cannotLoadSetting && isUploaded) {
    <div class="drawer-content-screen__title text-wrap">
      {{ "folderSettingsFor" | translate }} {{ mailFolderName }}
    </div>

    <div class="drawer-content-screen__body flex flex-col gap-3">
      <form [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="onSubmit()">
        @if(isShowPignorCheckbox && folderType === mailFolderType.Inbox) {
          <div class="flex flex-col gap-3">
            <div class="drawer-content-screen__sub-title">{{ "pignor" | translate }}</div>
            <mat-checkbox color="primary" [(ngModel)]="pignor" [ngModelOptions]="{ standalone: true }">{{
              "pignor" | translate
            }}</mat-checkbox>
          </div>
        }
        <div class="flex flex-col gap-3">
          <div class="drawer-content-screen__sub-title">{{ "archive" | translate }}</div>
          <mat-checkbox color="primary" [disableRipple]="true" formControlName="archive">{{ "archive" | translate }}</mat-checkbox>
        </div>
      </form>
    </div>

    <div class="flex justify-end w-full">
      <button mat-flat-button color="primary" [disabled]="isSaving || !form.valid" (click)="onSubmit()">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isSaving"></app-loading-button>
      </button>
    </div>
  }
</div>
