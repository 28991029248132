<div class="drawer-content-screen">
  @if(cannotLoadAvailableData) {
    <div class="flex-col-center">
      <app-something-went-wrong
        [title]="errorText | translate"
        [showPicture]="false"
        (retryEmitter)="ngOnInit()"
      ></app-something-went-wrong>
    </div>
  }

  @if(loadingAvailableData) {
    <div class="h-full">
      <app-skeleton-form-drawer></app-skeleton-form-drawer>
    </div>
  }

  @if(!loadingAvailableData && !cannotLoadAvailableData && isUploaded) {
    <div class="drawer-content-screen__title">{{ mailFolderName ? ("modifyFolder" | translate) :  ("createFolder" | translate) }}</div>
    <div class="drawer-content-screen__body">
      <form [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="onSubmit(form.value)">
        @if(mailFolderCreationType !== mailCreationType.System) {
          <mat-form-field>
            <mat-label>{{ "name" | translate }}</mat-label>
            <input matInput id="name" formControlName="name" />
            @if(hasError('name', 'required')) {
              <mat-error>{{ "nameIsRequired" | translate }}</mat-error>
            }
            @if(hasError('name', 'maxlength')) {
              <mat-error>{{ "moreThan256Char" | translate }}</mat-error>
            }
          </mat-form-field>
        }

        @if((parentMailFolderType === 2 || parentMailFolderType === 3) && isSettings) {
          <mat-form-field>
            <mat-label>{{ "providerFolder" | translate }}</mat-label>
            <mat-select [(value)]="selectedProviderFolder">
              @for(folder of providerFolders; track folder) {
                <mat-option [value]="folder">
                  {{ folder }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        }
      </form>
    </div>
  }

  @if(!errorText && !loadingAvailableData && isUploaded) {
    <div class="flex justify-end w-full">
      <button mat-flat-button color="primary" [disabled]="isLoading || !form.valid" (click)="onSubmit(form.value)">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
        </button>
     </div>
  }
</div>
