<div class="close-button">
  <button tabIndex="-1" class="" mat-icon-button (click)="cancel()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<h1 mat-dialog-title>{{ "addGroups" | translate }}</h1>
<mat-dialog-content class="mat-mdc-dialog-content mdc-dialog__content mat-container">
  @if(isLoading) {
    <div class="pt-2">
      <ngx-skeleton-loader
        [count]="11"
        [theme]="{
          height: '24px',
          width: '100%',
        }"
      ></ngx-skeleton-loader>
    </div>
  }

  @if(isError) {
    <div class="h-full w-full">
      <app-something-went-wrong
        [showPicture]="false"
        (retryEmitter)="ngOnInit()"
      ></app-something-went-wrong>
    </div>
  }

  @if(!isLoading && !isError) {
    <div>
      <span class="example-list-section">
        <ul>
          @if(!existedGroups.length) {
            <li>No groups</li>
          }

          @for(group of existedGroups; track group) {
            <li>
              <mat-checkbox
                color="primary"
                [checked]="isChecked(group.groupId)"
                (change)="addGroupToEmails($event.checked, group.groupId)"
              >
                {{ group.group }}
              </mat-checkbox>
            </li>
          }
        </ul>
      </span>
    </div>
  }
</mat-dialog-content>
<mat-dialog-actions align="end" class="mat-mdc-dialog-actions mdc-dialog__actions">
  <button
    mat-flat-button
    [disabled]="isSavingGroups"
    color="primary"
    (click)="ok()"
  >
    <app-loading-button text="{{ 'save' | translate }}" [loading]="isSavingGroups"></app-loading-button>
  </button>
</mat-dialog-actions>
