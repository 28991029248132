<div class="conditions-box">
  <div class="conditions-header-box mb-2">
    <div class="conditions-header">
      <div>
        @if(header !== 'subject') {
          <span class="font-normal lowercase">{{ "and" | translate }}</span>
        }
        {{ header | translate }}
      </div>
    </div>
  </div>

  <div class="conditions-content">
    <div class="w-full xs:w-[125px] xs:pr-4">
      <mat-form-field class="mat-input-without-padding">
        <mat-select name="action" [(value)]="emailCondition.operation" required>
          @for(operation of availableOperations; track operation) {
            <mat-option [value]="operation">
              {{ operationToText(operation) | translate }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="condition-patterns">
      <form [formGroup]="form">
        @for(pattern of emailCondition.patterns; let i = $index; track trackByIndex) {
          <div>
            <div class="w-full flex flex-1">
              <mat-form-field class="mat-input-without-padding">
                @if(i > 0) {
                  <div class="italic" style="position: absolute; top: 2px; left: -22px">{{ "or" | translate }}</div>
                }
                <input
                  matInput
                  [id]="paramName + '-pattern-' + i"
                  [formControlName]="paramName + '-pattern-' + i"
                  (input)="onChangePattern(i, $event)"
                />
                @if(hasError(paramName + '-pattern-' + i, 'maxlength')) {
                  <mat-error>{{ "moreThan256Char" | translate }} </mat-error>
                }
                </mat-form-field>
                <div class="flex flex-wrap content-center justify-center">
                  <button
                    [disabled]="emailCondition.patterns.length === 1"
                    mat-icon-button
                    matTooltip="{{ 'deleteCondition' | translate }}"
                    aria-label="Delete condition"
                    (click)="onDelete(i)"
                    class="flex flex-wrap content-center">
                    <mat-icon color="primary">remove_circle_outline</mat-icon>
                  </button>
                </div>
                <div class="flex flex-wrap content-center justify-center">
                  <button
                    mat-icon-button
                    matTooltip="{{ 'addConditionOr' | translate }}"
                    [attr.aria-label]="'addConditionOr' | translate"
                    (click)="onCreate()"
                    class="flex flex-wrap content-center">
                    <mat-icon color="primary">add_circle_outline</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          }
        </form>
    </div>
  </div>
</div>
