import { environment } from '@app-environments/environment';
import { Injectable } from '@angular/core';
import { ApiRequest } from '../api.request';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import { BaseId } from '@app-types/base/base';
import { EmailFerreroCreateRequest } from '@app-types/api/ferrero-webcodes';

const API_URL = environment.apiUrl;
const { create } = API_ROUTES.ferreroPlugin;

@Injectable({ providedIn: 'root' })
export class PluginsClient {
  constructor(private apiRequest: ApiRequest) {}

  async create(request: EmailFerreroCreateRequest): Promise<BaseId> {
    return this.apiRequest.post<EmailFerreroCreateRequest, BaseId>(
      request,
      `${API_URL}${create}`
    );
  }
}
