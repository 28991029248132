import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ErrorCode } from '../../../../../../types/enums/error-code';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MailAccountContract } from '../../../../../../types/api/mail-account';
import { MailAccountClient } from '@app-services/api/clients/mail-account.client';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { LoadingButtonComponent } from '../../../../../common/loading-button/loading-button.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { DrawerService } from '@app-services/drawer.service';

@Component({
  selector: 'app-password-dialog',
  templateUrl: './password-dialog.component.html',
  styleUrls: ['./password-dialog.component.scss'],
  standalone: true,
  imports: [
    LoadingButtonComponent,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
  ],
})
export class MailAccountChangePasswordDialogComponent implements OnInit {
  @Input() public mailAccount: MailAccountContract;
  @Output() public submitEventEmitter = new EventEmitter<MailAccountContract>();
  public isLoading = false;
  public errorCode: ErrorCode | null = null;
  public form = new UntypedFormGroup({});

  constructor(
    private mailAccountClient: MailAccountClient,
    public matchError: MatchError,
    public drawerService: DrawerService
  ) {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.mailAccount.name),
      providerName: new UntypedFormControl(this.mailAccount.providerName),
      email: new UntypedFormControl(this.mailAccount.email),
      newPassword: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(256),
      ]),
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

  public async onSubmit(): Promise<void> {
    if (this.form.valid) {
      this.isLoading = true;
      this.drawerService.disabledDrawer(true);
      this.errorCode = null;
      try {
        await this.mailAccountClient.changePassword({
          mailAccountId: this.mailAccount.mailAccountId,
          newPassword: this.form.value.newPassword,
        });
        this.drawerService.disabledDrawer(false);
        this.submitEventEmitter.emit();
        this.drawerService.closeDrawer();
      } catch (e) {
        this.drawerService.disabledDrawer(false);
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
      } finally {
        this.isLoading = false;
      }
    }
  }
}
