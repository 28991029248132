<div class="flex flex-col gap-10 max-w-[400px]">
  <div class="flex flex-col gap-4">
    @if(hasTitle) {
      <div>
        <ngx-skeleton-loader
          [count]="1"
          [theme]="{
          'margin-bottom': 0,
          height: '40px',
          'max-width': '85%'
        }"
        ></ngx-skeleton-loader>
      </div>
    }
    <div class="ml-[0.375rem]">
      @for(item of field; track item) {
        <ngx-skeleton-loader
         [count]="1"
         [theme]="{
          'margin-bottom': 0,
          height: '18px',
          'max-width': item
        }"
        ></ngx-skeleton-loader>
      }
    </div>
  </div>
  <div>
    @if(hasSubTitle) {
      <div>
        <ngx-skeleton-loader
          [count]="1"
          [theme]="{
          'margin-bottom': '16px',
          height: '32px',
          'max-width': '60%'
        }"
        ></ngx-skeleton-loader>
      </div>
    }
    @if(hasButtons) {
      <div class="flex gap-3">
        <div class="w-[45%]">
          <ngx-skeleton-loader
            [count]="1"
            [theme]="{
              'margin-bottom': 0,
              height: '36px',
              width: '100%',
              }"
            ></ngx-skeleton-loader>
          </div>
          <div class="w-[45%]">
            <ngx-skeleton-loader
              [count]="1"
              [theme]="{
                'margin-bottom': 0,
                width: '100%',
                height: '36px'
                }"
            ></ngx-skeleton-loader>
          </div>
        </div>
    }
  </div>
</div>
