import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { PermissionService } from '@app-services/permission/permission.service';
import { MailAccountContract } from '@app-types/api/mail-account';
import { BaseGetByIdRequest } from '@app-types/base/base';
import { PermissionNavTabHelper } from '@app-services/permission/permission-nav-tab-helper';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MailAccountClient } from '@app-services/api/clients/mail-account.client';
import { MailAccountSettingsClient } from '@app-services/api/clients/mail-account-settings.client';
import { MatchError } from 'src/app/services/errors/error-matcher';
import {
  CONNECTION_STATUS,
  CONNECTION_STATUS_INFO,
} from '@app-shared/constants/connection-status';
import {
  MAIL_ACCOUNT_ID,
  ORGANIZATION_ID,
} from '@app-shared/constants/constants';
import { SomethingWentWrongComponent } from '@app-components/common/error/something-went-wrong/something-went-wrong.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DrawerService } from '@app-services/drawer.service';
import { MailAccountUpdateDialogComponent } from '@app-components/settings/organization/mail-account/mail-account-general-settings/mail-account-update-dialog/mail-account-update-dialog.component';
import { MailAccountSettingsDialogComponent } from '@app-components/settings/organization/mail-account/mail-account-general-settings/mail-account-settings-dialog/mail-account-settings-dialog.component';
import { MailAccountChangePasswordDialogComponent } from '@app-components/settings/organization/mail-account/mail-account-general-settings/password-dialog/password-dialog.component';
import { MailAccountQuickSetupDialogComponent } from '@app-components/settings/organization/mail-account/mail-account-general-settings/mail-account-quick-setup-dialog/mail-account-quick-setup-dialog.component';
import { GeneralSettingsSkeletonComponent } from '@app-components/common/skeletons/general-settings-skeleton/general-settings-skeleton.component';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-mail-account-general-settings',
  templateUrl: './mail-account-general-settings.component.html',
  styleUrls: ['./mail-account-general-settings.component.scss'],
  standalone: true,
  imports: [
    SomethingWentWrongComponent,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MailAccountUpdateDialogComponent,
    MailAccountSettingsDialogComponent,
    MailAccountChangePasswordDialogComponent,
    MailAccountQuickSetupDialogComponent,
    GeneralSettingsSkeletonComponent,
  ],
})
export class MailAccountGeneralSettingsComponent implements OnInit, OnDestroy {
  @HostBinding('class') className = 'setting-container';
  public organizationId: number;
  public mailAccountId: number;
  public mailAccount: MailAccountContract;
  public isLoading = false;
  public isUploaded = false;
  public hasError = false;

  public isActive: boolean;
  connectionStatusName = CONNECTION_STATUS;
  connectionStatusInfo = CONNECTION_STATUS_INFO;
  configurationCompleted: boolean;
  routeSubscribe$: Subscription;
  @ViewChild('updateDrawer') updateDrawer: TemplateRef<any>;
  @ViewChild('settingsDrawer') settingsDrawer: TemplateRef<any>;
  @ViewChild('changePasswordDrawer') changePasswordDrawer: TemplateRef<any>;
  @ViewChild('quickSetupDrawer') quickSetupDrawer: TemplateRef<any>;

  constructor(
    public matchError: MatchError,
    private permissionService: PermissionService,
    private permissionNavTabHelper: PermissionNavTabHelper,
    private route: ActivatedRoute,
    private router: Router,
    private mailAccountSettingsClient: MailAccountSettingsClient,
    private mailAccountClient: MailAccountClient,
    public drawerService: DrawerService
  ) {}

  async ngOnInit(): Promise<void> {
    this.routeSubscribe$ = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.init();
      });

    this.init();
  }

  async init(): Promise<void> {
    const mailAccId = parseInt(
      this.route.snapshot.paramMap.get(MAIL_ACCOUNT_ID) as string,
      10
    );
    if (mailAccId !== this.mailAccountId) {
      this.mailAccountId = mailAccId;
      this.organizationId = parseInt(
        this.route.snapshot.paramMap.get(ORGANIZATION_ID) as string,
        10
      );
      await this.loadMailAccount();
    }
  }

  public async loadMailAccount(): Promise<void> {
    this.hasError = false;
    this.isUploaded = false;
    const loaderTimeout = setTimeout(() => {
      this.isLoading = true;
    }, 500);
    try {
      const response = await this.mailAccountClient.getById(
        new BaseGetByIdRequest(this.mailAccountId)
      );
      const responseSettings =
        await this.mailAccountSettingsClient.getByMailAccount(
          new BaseGetByIdRequest(this.mailAccountId)
        );
      this.configurationCompleted =
        responseSettings.result.configurationCompleted;
      this.isActive = responseSettings.result.active;
      this.mailAccount = response.mailAccount;
    } catch (e) {
      this.hasError = true;
      this.matchError.logError(e);
    } finally {
      clearTimeout(loaderTimeout);
      this.isUploaded = true;
      this.isLoading = false;
    }
  }

  public onOpenUpdatingModalForm(): void {
    this.drawerService.openDrawer(this.updateDrawer);
  }

  public onUpdateMailAccount({
    name,
    codeSia,
    license,
    anagId,
  }: {
    name: string;
    codeSia: string;
    license: string;
    anagId: string;
  }): void {
    this.mailAccount.name = name;
    this.mailAccount.codeSia = codeSia;
    this.mailAccount.license = license;
    this.mailAccount.anagId = anagId;
  }

  public openChangePasswordModalForm(): void {
    this.drawerService.openDrawer(this.changePasswordDrawer);
  }

  public onChangedPassword(): void {
    this.loadMailAccount();
  }

  public openSettingsModalForm(): void {
    this.drawerService.openDrawer(this.settingsDrawer);
  }

  public onUpdateSettings({ isActive }: { isActive: boolean }): void {
    this.isActive = isActive;
  }

  public onOpenQuickSetupForm(): void {
    this.drawerService.openDrawer(this.quickSetupDrawer);
  }

  ngOnDestroy(): void {
    this.routeSubscribe$?.unsubscribe();
  }
}
