import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { DrawerComponent } from '../common/drawer/drawer.component';
import { MatInputModule } from '@angular/material/input';
import { DrawerService } from '@app-services/drawer.service';
import { EmailSearchAdvancedDialogComponent } from '../email-search-advanced-dialog/email-search-advanced-dialog.component';
import { SearchEvent } from '@app-types/search';
import { UserWorkspaceService } from '@app-services/user-workspace-service';

@Component({
  selector: 'app-email-search-input',
  standalone: true,
  imports: [
    TranslateModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatIconModule,
    DrawerComponent,
    MatInputModule,
    EmailSearchAdvancedDialogComponent,
  ],
  templateUrl: './email-search-input.component.html',
  styleUrls: ['./email-search-input.component.scss'],
})
export class EmailSearchInputComponent {
  @ViewChild('topInput', { static: false }) topInput: ElementRef;
  @Input() public fullWidth = false;
  @Input() public search = '';
  @Input() public hasFilters = false;
  @Input() public isDefaultSearchParams = true;
  @Output() public valueChangedEventEmitter = new EventEmitter<string>();
  @Output() public hideNotificationsEmitter = new EventEmitter<boolean>();
  @Output() public searchEventEmitter = new EventEmitter<SearchEvent>();

  @ViewChild('advancedSearch') advancedSearch!: TemplateRef<any>;

  constructor(
    private drawerService: DrawerService,
    public userWorkspaceService: UserWorkspaceService
  ) {}

  onValueChanged($event: Event): void {
    this.valueChangedEventEmitter.emit(
      ($event.target as HTMLInputElement).value
    );
  }

  onHideNotificationsChanged($event: boolean): void {
    this.hideNotificationsEmitter.emit($event);
    if (this.search === null) {
      this.search = '';
      this.valueChangedEventEmitter.emit('');
    }
    this.searchEventEmitter.emit({ hideNotificationsEmitter: true });
  }

  onSearchBtnClicked(): void {
    if (this.search === null) {
      this.search = '';
      this.valueChangedEventEmitter.emit('');
    }
    this.searchEventEmitter.emit();
  }

  onXClicked(): void {
    this.search = '';
    this.valueChangedEventEmitter.emit('');
    this.searchEventEmitter.emit();
  }

  onSearchFilters(data: SearchEvent): void {
    this.searchEventEmitter.emit(data);
    this.closeAdvancedSearch();
  }

  public get widthClass(): string {
    return this.fullWidth ? ' w-full' : ' w-40';
  }

  public openAdvancedSearch(): void {
    this.drawerService.openDrawer(this.advancedSearch);
  }

  public closeAdvancedSearch(): void {
    this.drawerService.closeDrawer();
    this.topInput.nativeElement.focus();
  }

  isAnyFolder(): boolean {
    return (
      !!this.userWorkspaceService.mailAccounts?.length &&
      !!this.userWorkspaceService.userFolders?.length
    );
  }
}
