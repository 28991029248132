<div class="flex flex-col gap-3">
  <div class="drawer-content-screen__sub-title">{{ "webMailFolders" | translate }} *</div>

  <form [formGroup]="form" autocomplete="off" novalidate>
    <mat-form-field class="w-full">
      <mat-label>{{ "selectMailFolders" | translate }}</mat-label>
      <mat-select [(ngModel)]="selected" formControlName="mailFolders" #select multiple>
        @for(folder of checkBoxFolders; track folder) {
          <mat-option
            color="primary"
            (click)="onChange(folder)"
            [value]="folder.value.name">
            {{ folder.value.name }}
          </mat-option>
        }
      </mat-select>
      @if(form.controls.mailFolders.hasError('required')) {
        <mat-error>{{ "selectAtLeastOneFolder" | translate }}</mat-error>
      }
    </mat-form-field>
  </form>
</div>
