<div class="drawer-content-screen">
  @if(settingsIsLoading) {
    <div class="h-full">
      <app-skeleton-form-drawer></app-skeleton-form-drawer>
    </div>
  }

  @if(failedSettingsLoading) {
    <div class="flex-col-center">
      <app-something-went-wrong
        [showPicture]="false"
        description="{{ 'cannotLoadCompanySettings' | translate }}"
        (retryEmitter)="user ? loadAuthTypes() : loadSettings()">
      </app-something-went-wrong>
    </div>
  }

  @if(!failedSettingsLoading && !settingsIsLoading && isUploaded) {
    <div class="drawer-content-screen__title">{{ user ? ("updateUser" | translate) : ("addUserToCompany" | translate) }}</div>
    <div class="drawer-content-screen__body">
      <form [formGroup]="form" autocomplete="off" novalidate class="flex flex-col gap-2">
        <div class="flex flex-col w-full">
          <mat-form-field>
            <mat-label>{{ "firstName" | translate }}</mat-label>
            <input
              matInput id="firstName"
              formControlName="firstName"
              required
            />
            @if(hasError('firstName', 'required')) {
              <mat-error>{{ "firstNameIsRequired" | translate }}</mat-error>
            }
            @if(hasError('firstName', 'maxlength')) {
              <mat-error>{{ "moreThan128Char" | translate }}</mat-error>
            }
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ "lastName" | translate }}</mat-label>
            <input
              matInput
              id="lastName"
              formControlName="lastName"
              required
            />
            @if(hasError('lastName', 'required')) {
              <mat-error>{{ "lastNameIsRequired" | translate }}</mat-error>
            }
            @if(hasError('lastName', 'maxlength')) {
              <mat-error>{{ "moreThan128Char" | translate }}</mat-error>
            }
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ "email" | translate }}</mat-label>
            <input matInput id="email" formControlName="email" required />
            @if(hasError('email', 'required')) {
              <mat-error>{{ "emailIsRequired" | translate }}</mat-error>
            }
            @if(hasError('email', 'maxlength')) {
              <mat-error>{{ "moreThan256Char" | translate }}</mat-error>
            }
            @if(hasError('email', 'email')) {
              <mat-error>{{ "incorrectEmail" | translate }}</mat-error>
            }
          </mat-form-field>
        </div>
        @if(settings?.oidcSupport || user) {
          <div class="flex-col-container-1">
            <mat-checkbox color="primary" [checked]="gawOidcAuthType" (change)="changeGawOidcAuthType($event.checked)">
              {{ "GAW" | translate }}
            </mat-checkbox>
            <mat-form-field>
              <mat-label>{{ "nickname" | translate }}</mat-label>
              <input matInput id="nickName" formControlName="nickName" required />
              @if(hasError('nickName', 'required')) {
                <mat-error>{{ "nicknameIsRequired" | translate }}</mat-error>
              }
              @if(hasError('nickName', 'maxlength')) {
                <mat-error>{{ "moreThan256Char" | translate }}</mat-error>
              }
            </mat-form-field>
            @if(settings?.oidcSupport || !isUserHasOidc) {
              <mat-form-field>
                <mat-label>{{ "password" | translate }}</mat-label>
                <input type="password" matInput id="password" formControlName="password" required />
                @if(hasError('password', 'required')) {
                  <mat-error>{{ "passIsRequired" | translate }}</mat-error>
                }
                @if(hasError('password', 'minlength')) {
                  <mat-error>{{ "lessThanEightChar" | translate }}</mat-error>
                }
                @if(hasError('password', 'maxlength')) {
                  <mat-error>{{ "moreThanFifteenChar" | translate }}</mat-error>
                }
                @if(hasError('password', 'hasAtLeastOneUpperCharacter')) {
                  <mat-error>{{ "atLeastOneUpperLetter" | translate }}</mat-error>
                }
                @if(hasError('password', 'hasAtLeastOneLowerCharacter')) {
                  <mat-error>{{ "atLeastOneLowerLetter" | translate }}</mat-error>
                }
                @if(hasError('password', 'hasAtLeastOneDigit')) {
                  <mat-error>{{ "atLeastOneDigit" | translate }}</mat-error>
                }
                @if(hasError('password', 'hasAtLeastOneSpecialSymbol')) {
                  <mat-error>{{ "atLeastOneSpecialSym" | translate }}</mat-error>
                }
              </mat-form-field>
            }
          </div>
        }
        @if(settings?.googleOAuthSupport || user) {
          <mat-checkbox
            color="primary"
            [checked]="googleAuthType"
            (change)="changeGoogleAuthType($event.checked)"
          >{{ "google" | translate }}</mat-checkbox>
        }
        @if(checkIsValid()) {
          <mat-error>
            {{ "selectAtLeast1AuthenticationType" | translate }}
          </mat-error>
        }
      </form>
    </div>
    <div class="w-full">
      <div class="flex justify-end">
        <button
          mat-flat-button
          color="primary"
          [disabled]="isLoading || !form.valid || !(this.googleAuthType || this.gawOidcAuthType)"
          (click)="onSubmit()">
          <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
        </button>
      </div>
    </div>
  }
</div>
