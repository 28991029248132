<div class="menu__attachments">
  @for(file of emailDetails.attachmentFiles; track file) {
    <div
      [style.cursor]="!isFileDownloading(file?.fileId) ? 'pointer' : 'default'"
      (click)="!isFileDownloading(file?.fileId) && getAttachmentFile(file)"
      class="menu__attachment"
    >
    <!--TODO -->
<!--      <file-icon set="classic" size="md" [type]="getFileExtension(file.fileName)"></file-icon>-->
      <div
        [style.color]="!isFileDownloading(file?.fileId) ? 'black' : 'grey'"
        [style.cursor]="!isFileDownloading(file?.fileId) ? 'pointer' : 'default'"
        class="menu__filename filename-label"
      >
         {{ file.fileName }}
      </div>
      @if(isFileDownloading(file?.fileId)) {
        <mat-progress-spinner
          class="w-[24px] ml-[3px]"
          mode="indeterminate"
          diameter="16"
        ></mat-progress-spinner>
      }
    </div>
  }
</div>
