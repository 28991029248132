import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmailNoteClient } from '@app-services/api/clients/email-note.client';
import { EmailNoteContract } from '@app-types/api/email-note';
import { BaseGetByIdRequest } from '@app-types/base/base';
import { UserClient } from '@app-services/api/clients/user.client';
import { UserContract } from '@app-types/api/user';
import { MatDialog } from '@angular/material/dialog';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { SomethingWentWrongComponent } from '../../common/error/something-went-wrong/something-went-wrong.component';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoadingButtonComponent } from '@app-components/common/loading-button/loading-button.component';
import { SkeletonCardListComponent } from '@app-components/common/skeletons/skeleton-card-list/skeleton-card-list.component';

@Component({
  selector: 'app-email-note-list',
  templateUrl: './email-note-list.component.html',
  styleUrls: ['./email-note-list.component.scss'],
  standalone: true,
  imports: [
    SomethingWentWrongComponent,
    TranslateModule,
    DatePipe,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatTooltipModule,
    LoadingButtonComponent,
    SkeletonCardListComponent,
  ],
})
export class EmailNoteListComponent implements OnInit {
  @Input() emailId: number;
  @Input() title: string;
  @Output() refreshNotes = new EventEmitter();
  public isLoading = false;
  public isLoadingCreate = false;
  public cannotLoadNotes = false;
  public notes: EmailNoteContract[] = [];
  public editingNoteIds: number[] = [];
  public editInProgressNoteIds: number[] = [];
  public deleteInProgressNoteIds: number[] = [];
  public user: UserContract;

  constructor(
    private emailNoteClient: EmailNoteClient,
    private route: ActivatedRoute,
    private userClient: UserClient,
    private matchError: MatchError,
    public dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadAvailableData();
  }

  async loadAvailableData(needLoading: boolean = true): Promise<void> {
    needLoading && (this.isLoading = true);
    this.cannotLoadNotes = false;
    try {
      const response = await this.emailNoteClient.getForEmail(
        new BaseGetByIdRequest(this.emailId)
      );
      this.notes = response.data;
      const responseUser = await this.userClient.getUser();
      this.user = responseUser.result;
    } catch (e) {
      this.cannotLoadNotes = true;
      this.matchError.logError(e);
    } finally {
      needLoading && (this.isLoading = false);
    }
  }

  editNote(noteId: number): void {
    this.editingNoteIds.push(noteId);
  }

  noteIsBeingEdited(noteId: number): boolean {
    return this.editingNoteIds.includes(noteId);
  }

  async updateNote(note: EmailNoteContract): Promise<void> {
    this.editInProgressNoteIds.push(note.emailNoteId);
    note.updating = false;
    try {
      await this.emailNoteClient.update({
        emailNoteId: note.emailNoteId,
        note: note.note,
      });
      this.editingNoteIds = this.editingNoteIds.filter(
        id => id !== note.emailNoteId
      );
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.editInProgressNoteIds = this.editInProgressNoteIds.filter(
        id => id !== note.emailNoteId
      );
    }
  }

  noteIsUpdating(noteId: number): boolean {
    return this.editInProgressNoteIds.some(id => id === noteId);
  }

  async deleteNote(note: EmailNoteContract): Promise<void> {
    this.deleteInProgressNoteIds.push(note.emailNoteId);
    try {
      await this.emailNoteClient.delete({ id: note.emailNoteId });
      this.notes = this.notes.filter(n => n.emailNoteId !== note.emailNoteId);
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.deleteInProgressNoteIds = this.deleteInProgressNoteIds.filter(
        id => id !== note.emailNoteId
      );
    }
  }

  noteIsDeleting(noteId: number): boolean {
    return this.deleteInProgressNoteIds.some(id => id === noteId);
  }

  async createNewNote(value: string): Promise<void> {
    if (value.length === 0) return;
    this.isLoadingCreate = true;
    try {
      await this.emailNoteClient.create({
        emailId: this.emailId,
        note: value,
      });
      this.refreshNotes.emit();
      await this.loadAvailableData(false);
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isLoadingCreate = false;
    }
  }

  canManageNote(noteUserId: number): boolean {
    return this.user.userId === noteUserId;
  }
}
