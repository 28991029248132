<form class="mat-form-tags-wrapper" [formGroup]="form">
  <mat-form-field
    (keydown)="moveCell($event)"
    [class.cc-open]="showCc"
    [class.tags-wrapper-no-margin]="!isNeedMarginLeft"
    class="example-full-width flex tags-wrapper"
    >
    <div class="flex items-center gap-1 min-h-[40px] flex-wrap">
      @for(tagTo of toList; track tagTo) {
        <button
         (keydown)="onTagKeyDown($event, tagTo)"
         (focus)="setFocusedTag(tagTo)"
         style="border-radius: 100px"
         [style.height]="tagTo === clickedTagValueTo ? '40px' : '100%'"
         (click)="onTagToClick($event, tagTo)"
        >
          @if(tagTo !== clickedTagValueTo) {
            <div
              class="tag-wrapper chip"
              [style.background]="isTagShiftSelected(tagTo) ? '#0000004d' : !isValidTag(tagTo) ? '#ff0b0b57' : ''"
            >
              <span class="tags-wrapper-span">
                {{ tagTo }}
              </span>
              <mat-icon class="chip-close" (click)="deleteTagTo(tagTo)">cancel</mat-icon>
            </div>
          }
          @if(tagTo === clickedTagValueTo) {
            <div class="chip w-auto h-[32px]">
              <label class="input-sizer input-label tags-wrapper-span">
                <input
                  class="!h-[22px]"
                  (focusout)="onSubmitTagTo($event)"
                  (keydown.enter)="onSubmitTagTo($event)"
                  #tagInputTo
                  type="text"
                  onInput="this.parentNode.dataset.value = this.value"
                  onfocus="this.parentNode.dataset.value = this.value"
                  [value]="tagTo"
                />
              </label>
            </div>
          }
        </button>
      }

      <input
        type="text"
        [placeholder]="'+ ' + (placeHolder | translate)"
        aria-label="Number"
        matInput
        (keydown.enter)="onSubmitTagToEnter($event)"
        (focusout)="selectedTo($event, true)"
        style="height: 40px; flex: 1 0 100px"
        #tagInputSelectTo
        formControlName="to"
        [matAutocomplete]="auto"
      />
    </div>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedTo($event.option.value)">
      @for(option of filteredToList(); track option) {
        <mat-option [value]="option">
          {{ option }}
        </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
</form>
