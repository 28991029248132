import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EmailListItemNotificationsContract } from '@app-types/api/email';
import { Router } from '@angular/router';
import { BaseGetByIdRequest } from '@app-types/base/base';
import { EmailClient } from '@app-services/api/clients/email-client';
import { EmailState } from '@app-types/enums/email-state';
import { EmailType } from '@app-types/enums/email-type';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { NoDataComponent } from '../../no-data/no-data.component';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { EmailTypePipe } from '@app-pipes/email-type-pipe';
import { SomethingWentWrongComponent } from '@app-components/common/error/something-went-wrong/something-went-wrong.component';
import { SkeletonCardListComponent } from '@app-components/common/skeletons/skeleton-card-list/skeleton-card-list.component';

@Component({
  selector: 'app-email-notifications-dialog',
  templateUrl: './email-notifications-dialog.component.html',
  styleUrls: ['./email-notifications-dialog.component.scss'],
  standalone: true,
  imports: [
    NoDataComponent,
    MatCardModule,
    TranslateModule,
    DatePipe,
    EmailTypePipe,
    SomethingWentWrongComponent,
    SkeletonCardListComponent,
  ],
})
export class EmailNotificationsDialogComponent implements OnInit {
  public notifications: EmailListItemNotificationsContract[] = [];
  public isLoading = false;
  public cannotLoadNotifications = false;
  @Input() webCode: string;
  @Input() emailId: number;
  @Input() title: string;
  @Output() openEmailDetails = new EventEmitter();

  constructor(
    public matchError: MatchError,
    private router: Router,
    private emailClient: EmailClient
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadNotifications();
  }

  async loadNotifications(): Promise<void> {
    this.isLoading = true;
    this.cannotLoadNotifications = false;
    const request = new BaseGetByIdRequest(this.emailId);
    try {
      const result = await this.emailClient.getNotifications(request);
      this.notifications = result.data;
    } catch (e) {
      this.cannotLoadNotifications = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  public isRowWithError(email: EmailListItemNotificationsContract): boolean {
    const emailState = email.state;
    const emailType = email.type;
    return (
      emailState === EmailState.DeliveryError ||
      emailState === EmailState.VirusDetected ||
      emailState === EmailState.DispatchError ||
      emailState === EmailState.NotDispatched ||
      emailState === EmailState.NotAccepted ||
      emailState === EmailState.SendError ||
      emailType === EmailType.NonAcceptance ||
      emailType === EmailType.DeliveryError
    );
  }

  async emailDetails(email: EmailListItemNotificationsContract): Promise<void> {
    this.openEmailDetails.emit(email);
  }
}
