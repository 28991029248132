<div class="drawer-content-screen">
  <div class="drawer-content-screen__title">
    {{ "changeMailAccountPassword" | translate }}
  </div>
  <div class="drawer-content-screen__body">
    <form [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="onSubmit()">
      <mat-form-field >
        <mat-label>{{ "name" | translate }}</mat-label>
        <input matInput id="name" formControlName="name" readonly required />
      </mat-form-field>
      <mat-form-field >
        <mat-label>{{ "providerLabel" | translate }}</mat-label>
        <input matInput id="providerName" formControlName="providerName" readonly required />
      </mat-form-field>
      <mat-form-field >
        <mat-label>{{ "email" | translate }}</mat-label>
        <input matInput id="email" formControlName="email" readonly required />
      </mat-form-field>
      <mat-form-field >
        <mat-label>{{ "newPassword" | translate }}</mat-label>
        <input type="password" matInput id="newPassword" formControlName="newPassword" required />
        @if(hasError('newPassword', 'required')) {
          <mat-error>{{ "newPasswordIsRequired" | translate }}</mat-error>
        }
        @if(hasError('newPassword', 'maxlength')) {
          <mat-error>{{ "moreThan256Char" | translate }}</mat-error>
        }
      </mat-form-field>
    </form>
  </div>
  <div class="flex justify-end w-full">
    <button mat-flat-button color="primary" [disabled]="isLoading || !form.valid" (click)="onSubmit()">
      <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
    </button>
  </div>
</div>
