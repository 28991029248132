import { Injectable } from '@angular/core';
import { environment } from '@app-environments/environment';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import { ApiRequest } from '../api.request';
import { BaseGetByIdRequest } from '@app-types/base/base';
import { MailAccountSamlClaims, SamlClaims } from '@app-types/api/mail-account';

const API_URL = environment.apiUrl;
const {
  createOrUpdate,
  createOrUpdateRtaClaimByOrganizationId,
  getRtaClaimByOrganizationId,
  getForAccount,
} = API_ROUTES.claim;

@Injectable({ providedIn: 'root' })
export class ClaimClient {
  constructor(private apiRequest: ApiRequest) {}

  async createOrUpdate(request: MailAccountSamlClaims): Promise<any> {
    return this.apiRequest.post<any, any>(
      request,
      `${API_URL}${createOrUpdate}`
    );
  }

  async createOrUpdateRtaClaimByOrganizationId(
    request: SamlClaims
  ): Promise<any> {
    return this.apiRequest.post<any, any>(
      request,
      `${API_URL}${createOrUpdateRtaClaimByOrganizationId}`
    );
  }

  async getByIdForAccount(
    request: BaseGetByIdRequest
  ): Promise<{ data: SamlClaims[] }> {
    return this.apiRequest.get(
      `${API_URL}${getForAccount}?${request.format()}`
    );
  }

  async getByIdRtaClaimByOrganizationId(
    request: BaseGetByIdRequest
  ): Promise<{ result: SamlClaims }> {
    return this.apiRequest.get(
      `${API_URL}${getRtaClaimByOrganizationId}?${request.format()}`
    );
  }
}
