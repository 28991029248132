import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { DispatchingRule } from '@app-types/api/Dispatching';
import { DispatchingRuleClient } from '@app-services/api/clients/dispatchingrule.client';
import { PermissionNavTabHelper } from '@app-services/permission/permission-nav-tab-helper';
import { OrganizationClient } from '@app-services/api/clients/organization.client';
import { PermissionService } from '@app-services/permission/permission.service';
import { MailAccountContract } from '@app-types/api/mail-account';
import { MailAccountClient } from '@app-services/api/clients/mail-account.client';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import {
  BaseCollectionSearchByIdRequest,
  BaseGetSearchCountByIdRequest,
} from '@app-types/base/base';
import { MobileObserverService } from '@app-services/adaptive/mobile-observer.service';
import { MatchError } from 'src/app/services/errors/error-matcher';
import {
  MAIL_ACCOUNT_ID,
  STORAGE_NAMES,
} from '@app-shared/constants/constants';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SearchBarComponent } from '@app-components/common/search-bar/search-bar.component';
import { NoDataComponent } from '@app-components/common/no-data/no-data.component';
import { SomethingWentWrongComponent } from '@app-components/common/error/something-went-wrong/something-went-wrong.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { LocalStorageService } from '@app-services/local-storage.service';
import { DrawerService } from '@app-services/drawer.service';
import { DispatchingRuleCreateOrUpdateDialogComponent } from '@app-components/settings/organization/mail-account/dispatching-rule/dispatching-rule-create-or-update-dialog/dispatching-rule-create-or-update-dialog.component';
import { DeleteDialogComponent } from '@app-components/common/delete-dialog/delete-dialog.component';
import { Subscription } from 'rxjs';
import { SkeletonTableComponent } from '@app-components/common/skeletons/skeleton-table/skeleton-table.component';

const { PAGE_SIZE_SETTINGS } = STORAGE_NAMES;

@Component({
  selector: 'app-dispatching-rule',
  templateUrl: './dispatching-rule.component.html',
  styleUrls: ['./dispatching-rule.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    SearchBarComponent,
    NoDataComponent,
    SomethingWentWrongComponent,
    MatTableModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatCardModule,
    DispatchingRuleCreateOrUpdateDialogComponent,
    SkeletonTableComponent,
  ],
})
export class DispatchingRuleComponent implements OnInit, OnDestroy {
  @HostBinding('class') className = 'setting-container';
  public organizationId: number;
  public mailAccountId: number;
  public isLoading = false;
  public isUploaded = false;
  public isError = false;
  public dispatchingRules: DispatchingRule[] = [];
  public dataSource = new MatTableDataSource<DispatchingRule>(
    this.dispatchingRules
  );
  public displayedColumns: string[] = ['name', 'priority', 'actions'];
  public mailAccount: MailAccountContract;
  public searchValue = '';
  public pageSize = 25;
  public pageIndex = 0;
  public totalSize = 0;

  public editingDispatchingRule: DispatchingRule | null = null;
  public useMobileView = false;
  private useMobileViewSubscription: Subscription;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('createDrawer') createDrawer: TemplateRef<any>;

  constructor(
    public matchError: MatchError,
    protected route: ActivatedRoute,
    private dispatchingRuleClient: DispatchingRuleClient,
    private permissionNavTabHelper: PermissionNavTabHelper,
    private organizationClient: OrganizationClient,
    private permissionService: PermissionService,
    private mailAccountClient: MailAccountClient,
    public dialog: MatDialog,
    private mobileObserverService: MobileObserverService,
    private localStorageService: LocalStorageService,
    private drawerService: DrawerService
  ) {}

  async ngOnInit(): Promise<void> {
    this.useMobileViewSubscription = this.mobileObserverService
      .mobileObserver()
      .subscribe(isMobile => (this.useMobileView = isMobile));
    this.pageSize = this.localStorageService.getData(PAGE_SIZE_SETTINGS) || 25;
    this.mailAccountId = parseInt(
      this.route.snapshot.paramMap.get(MAIL_ACCOUNT_ID) as string,
      10
    );
    await this.loadDispatchingRules(true);
  }

  async loadDispatchingRules(fetchSearchCount: boolean): Promise<any> {
    this.isUploaded = false;
    const loaderTimeout = setTimeout(() => {
      this.isLoading = true;
    }, 500);
    this.isError = false;
    if (fetchSearchCount) {
      this.pageIndex = 0;
    }
    const nullableSearchValue =
      this.searchValue.length > 0 ? this.searchValue : null;
    const request = new BaseCollectionSearchByIdRequest(
      this.mailAccountId,
      this.pageIndex + 1,
      this.pageSize,
      nullableSearchValue
    );
    try {
      const response =
        await this.dispatchingRuleClient.searchForAccount(request);
      this.dispatchingRules = response.data;
      this.dataSource.data = this.dispatchingRules;
      if (fetchSearchCount) {
        const countRequest = new BaseGetSearchCountByIdRequest(
          this.mailAccountId,
          nullableSearchValue
        );
        const counterResponse =
          await this.dispatchingRuleClient.getSearchForAccountCount(
            countRequest
          );
        this.totalSize = counterResponse.result;
      }
    } catch (e) {
      this.isError = true;
      this.matchError.logError(e);
    } finally {
      clearTimeout(loaderTimeout);
      this.isLoading = false;
      this.isUploaded = true;
    }
  }

  ruleIsDefault(dispatchingRule: DispatchingRule): boolean {
    return (
      !dispatchingRule.dispatchingRule?.matchExpression?.conditions ||
      dispatchingRule.dispatchingRule?.matchExpression?.conditions?.length === 0
    );
  }

  async onDeleteBtnClicked(dispatchingRuleId: number): Promise<void> {
    await this.dispatchingRuleClient.delete({ id: dispatchingRuleId });
  }

  async deleteDispatchingRule(
    dispatchingRuleId?: number,
    dispatchingRuleName?: string
  ): Promise<void> {
    if (!dispatchingRuleName || !dispatchingRuleId) return;
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '450px',
      autoFocus: false,
      data: {
        title: 'confirmDispatchingRuleDeletion',
        subTitles: [
          {
            title: 'deleteDispatchingRule',
            subTitle: dispatchingRuleName,
          },
        ],
        onDelete: async () => await this.onDeleteBtnClicked(dispatchingRuleId),
      },
    });

    dialogRef.afterClosed().subscribe(async x => {
      if (x.isDeleted) {
        this.totalSize -= 1;
        await this.loadDispatchingRules(false);
      }
    });
  }

  public onSearchValueChanged(newSearchValue: string): void {
    this.searchValue = newSearchValue;
  }

  public async handlePage(e: any): Promise<void> {
    this.localStorageService.setData(PAGE_SIZE_SETTINGS, e.pageSize);
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    await this.loadDispatchingRules(false);
  }

  public openCreateModalForm(): void {
    this.drawerService.openDrawer(this.createDrawer);
  }

  public async onCreateDispatchingRule(): Promise<void> {
    this.totalSize += 1;
    await this.loadDispatchingRules(false);
  }

  public openEditModalForm(editingDispatchingRule: DispatchingRule): void {
    this.editingDispatchingRule = editingDispatchingRule;
    this.drawerService.openDrawer(this.createDrawer);
  }

  public onCloseEditModalForm(): void {
    this.editingDispatchingRule && (this.editingDispatchingRule = null);
  }

  public async onEditDispatchingRule(): Promise<void> {
    this.editingDispatchingRule = null;
    await this.loadDispatchingRules(false);
  }

  ngOnDestroy(): void {
    this.useMobileViewSubscription?.unsubscribe();
  }
}
