<div class="drawer-content-screen">
  @if(cannotLoadAvailableOrganizationUsers) {
    <div class="flex-col-center">
      <app-something-went-wrong
        description="{{ 'cannotLoadOrgUsers' | translate }}"
        [showPicture]="false"
        (retryEmitter)="loadAvailableOrganizationUsers()"
      ></app-something-went-wrong>
    </div>
  }

  @if(availableOrganizationUsersIsLoading) {
    <div class="h-full">
      <app-skeleton-form-drawer></app-skeleton-form-drawer>
    </div>
  }

  @if(!cannotLoadAvailableOrganizationUsers && !availableOrganizationUsersIsLoading && isUploaded) {
    <div class="drawer-content-screen__title">
      {{ userMailAccount ? ("changeUserRole" | translate) : ("addUserToMailAccount" | translate) }}
    </div>
    <div class="drawer-content-screen__body">
      <form class="flex flex-col w-full" [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="onSubmit()">
        @if(!userMailAccount) {
          <mat-form-field>
            <mat-label>{{ "user" | translate }}</mat-label>
            <mat-select id="user" [(value)]="selectedUser" required>
              @for(user of availableUsers; track user) {
              <mat-option [value]="user">{{ user.firstName + " " + user.lastName }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        } @else {
          <mat-form-field >
            <mat-label>{{ "user" | translate }}</mat-label>
            <input matInput id="name" formControlName="name" readonly required />
          </mat-form-field>
        }
        <mat-form-field>
          <mat-label>{{ "role" | translate }}</mat-label>
          <mat-select id="role" [(value)]="selectedRole" required>
            @for(role of availableRoles; track role) {
              <mat-option [value]="role">{{ roleTypes[role] | translate }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </form>
    </div>
    <div class="flex justify-end w-full">
      <button mat-flat-button color="primary" [disabled]="isLoading || !formIsValid()" (click)="onSubmit()">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
      </button>
    </div>
  }
</div>
