import { Pipe, PipeTransform } from '@angular/core';
import { EmailType } from '@app-types/enums/email-type';

@Pipe({
  name: 'emailType',
  standalone: true,
})
export class EmailTypePipe implements PipeTransform {
  transform(value: number): string {
    switch (value) {
      case null:
        return 'Not state';
      case EmailType.Email:
        return 'emailStateType1';
      case EmailType.NonAcceptance:
        return 'emailStateType2';
      case EmailType.ServerUserAcceptance:
        return 'emailStateType3';
      case EmailType.DeliveryErrorAdvanceNotice:
        return 'emailStateType4';
      case EmailType.ServerServerAcceptance:
        return 'emailStateType5';
      case EmailType.VirusDetection:
        return 'emailStateType6';
      case EmailType.DeliveryError:
        return 'emailStateType7';
      case EmailType.MessageDelivered:
        return 'emailStateType8';
    }
    return 'Unknown type';
  }
}
