import { Injectable } from '@angular/core';
import { environment } from '@app-environments/environment';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import { BaseGetByFileIdRequest } from '@app-types/base/base';
import { ApiRequest } from '@app-services/api/api.request';
import { FileService } from '@app-services/files.servis';

const API_URL = environment.apiUrl;
const { get } = API_ROUTES.tempFile;

@Injectable({
  providedIn: 'root',
})
export class TempFileClient {
  constructor(
    private apiRequest: ApiRequest,
    public fileService: FileService
  ) {}

  async download(request: BaseGetByFileIdRequest): Promise<void> {
    const url = `${API_URL}${get}?${request.format()}`;
    const response = await this.apiRequest.download(url);
    if (response && response.type) {
      this.fileService.downloadWithPreview(response);
    }
  }
}
