import {
  AddUserToOrganizationRequest,
  ChangeStateRequest,
  UpdateUserRequest,
  UserIdentityTypes,
  UserOrganizationGetOrganizationUsersContract,
  UserOrganizationGetOrganizationUsersResponse,
  UserOrganizationGetUserOrganizationsResponse,
} from '@app-types/api/user-organization';
import { environment } from '@app-environments/environment';
import { Injectable } from '@angular/core';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import { ApiRequest } from '../api.request';
import {
  BaseCollectionSearchByIdRequest,
  BaseGetByIdRequest,
  BaseGetCollectionResponse,
  BaseGetSearchCountByIdRequest,
  BaseId,
  BaseResponse,
} from '@app-types/base/base';

const API_URL = environment.apiUrl;
const { userOrganization } = API_ROUTES;
const {
  getSearchCount,
  search,
  changeState,
  addUserToOrganization,
  getOrganizationUsers,
  getUserOrganizations,
  updateUser,
  getUserIdentityTypes,
} = userOrganization;

@Injectable({
  providedIn: 'root',
})
export class UserOrganizationClient {
  constructor(private apiRequest: ApiRequest) {}

  async getOrganizationUsers(
    request: BaseGetByIdRequest
  ): Promise<UserOrganizationGetOrganizationUsersResponse> {
    return this.apiRequest.get(
      `${API_URL}${getOrganizationUsers}?${request.format()}`
    );
  }

  async getUserOrganizations(): Promise<UserOrganizationGetUserOrganizationsResponse> {
    return this.apiRequest.get(`${API_URL}${getUserOrganizations}`);
  }

  async search(
    request: BaseCollectionSearchByIdRequest
  ): Promise<
    BaseGetCollectionResponse<UserOrganizationGetOrganizationUsersContract>
  > {
    return this.apiRequest.get(`${API_URL}${search}?${request.format()}`);
  }

  async getSearchCount(
    request: BaseGetSearchCountByIdRequest
  ): Promise<BaseResponse<number>> {
    return this.apiRequest.get(
      `${API_URL}${getSearchCount}?${request.format()}`
    );
  }

  async addUserToOrganization(
    request: AddUserToOrganizationRequest
  ): Promise<BaseId> {
    return this.apiRequest.post(request, `${API_URL}${addUserToOrganization}`);
  }

  async updateUser(request: UpdateUserRequest): Promise<BaseId> {
    return this.apiRequest.post(request, `${API_URL}${updateUser}`);
  }

  async getUserIdentityTypes(
    request: BaseGetByIdRequest
  ): Promise<UserIdentityTypes> {
    return this.apiRequest.get(
      `${API_URL}${getUserIdentityTypes}?${request.format()}`
    );
  }

  async changeState(request: ChangeStateRequest): Promise<void> {
    return this.apiRequest.post(request, `${API_URL}${changeState}`);
  }
}
