import { Injectable } from '@angular/core';
import { environment } from '@app-environments/environment';
import { ApiRequest } from '../api.request';
import {
  BaseGetByIdRequest,
  BaseGetCollectionResponse,
} from '@app-types/base/base';
import { UserGroupsResponse } from '@app-types/api/user-group';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import { GroupsRequest } from '@app-types/api/group';

const API_URL = environment.apiUrl;
const { setGroupsUsers, setUsersGroups, getGroupUsers, getUserGroups } =
  API_ROUTES.userGroup;

@Injectable({
  providedIn: 'root',
})
export class UserGroupClient {
  constructor(private apiClient: ApiRequest) {}

  public async setUsersGroups(request: GroupsRequest) {
    return this.apiClient.post<GroupsRequest, any>(
      request,
      `${API_URL}${setUsersGroups}`
    );
  }

  public async setGroupsUsers(request: GroupsRequest) {
    return this.apiClient.post<GroupsRequest, any>(
      request,
      `${API_URL}${setGroupsUsers}`
    );
  }

  public async getUserGroups(
    request: BaseGetByIdRequest
  ): Promise<BaseGetCollectionResponse<number>> {
    return this.apiClient.get<UserGroupsResponse>(
      `${API_URL}${getUserGroups}?${request.format()}`
    );
  }

  public async getGroupUsers(request: BaseGetByIdRequest) {
    return this.apiClient.get<UserGroupsResponse>(
      `${API_URL}${getGroupUsers}?${request.format()}`
    );
  }
}
