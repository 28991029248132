import { Injectable } from '@angular/core';
import { environment } from '@app-environments/environment';
import { ApiRequest } from '../api.request';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import {
  BaseCollectionSearchByIdRequest,
  BaseGetByIdRequest,
  BaseGetCollectionResponse,
  BaseGetSearchCountByIdRequest,
  BaseId,
  BaseResponse,
} from '@app-types/base/base';
import { UserContract } from '@app-types/api/user';
import {
  MailAccountUserRequest,
  UserMailAccountAssignRoleRequest,
  UserMailAccountContract,
  UserMailAccountCreateRequest,
  UserMailAccountCreateResponse,
  UserMailAccountGetListRequest,
  UserMailAccountGetListResponse,
  UserMailAccountRequest,
} from '@app-types/api/user-mail-account';

const API_URL = environment.apiUrl;
const { userOrganization, userMailAccount } = API_ROUTES;
const {
  searchMailAccountUsers,
  addMailAccountUser,
  deleteMailAccountUser,
  getSearchMailAccountUsersCount,
  getMailAccountUsers,
  setUserMailAccounts,
  getLoggedUserAccounts,
  getUserAccountsById,
  setMailAccountUsers,
  assignRole,
} = userMailAccount;

@Injectable({
  providedIn: 'root',
})
export class UserMailAccountClient {
  constructor(private apiRequest: ApiRequest) {}

  public async setUserMailAccounts(request: UserMailAccountRequest) {
    return this.apiRequest.post<UserMailAccountRequest, any>(
      request,
      `${API_URL}${setUserMailAccounts}`
    );
  }

  public async setMailAccountUsers(request: MailAccountUserRequest) {
    return this.apiRequest.post<MailAccountUserRequest, any>(
      request,
      `${API_URL}${setMailAccountUsers}`
    );
  }

  async addMailAccountUser(
    request: UserMailAccountCreateRequest
  ): Promise<UserMailAccountCreateResponse> {
    return this.apiRequest.post<
      UserMailAccountCreateRequest,
      UserMailAccountCreateResponse
    >(request, `${API_URL}${addMailAccountUser}`);
  }

  async deleteMailAccountUser(request: BaseId): Promise<BaseId> {
    return this.apiRequest.post<BaseId, BaseId>(
      request,
      `${API_URL}${deleteMailAccountUser}`
    );
  }

  async assignRole(request: UserMailAccountAssignRoleRequest): Promise<void> {
    return this.apiRequest.post<UserMailAccountAssignRoleRequest, void>(
      request,
      `${API_URL}${assignRole}`
    );
  }

  async getMailAccountUsers(
    request: BaseGetByIdRequest
  ): Promise<UserMailAccountGetListResponse> {
    return this.apiRequest.get<UserMailAccountGetListResponse>(
      `${API_URL}${getMailAccountUsers}?${request.format()}`
    );
  }

  async getUserAccountsById(
    request: UserMailAccountGetListRequest
  ): Promise<UserMailAccountGetListResponse> {
    return this.apiRequest.get<UserMailAccountGetListResponse>(
      `${API_URL}${getUserAccountsById}?${request.format()}`
    );
  }

  async getAvailableUsers(
    request: BaseGetByIdRequest
  ): Promise<BaseGetCollectionResponse<UserContract>> {
    return this.apiRequest.get<BaseGetCollectionResponse<UserContract>>(
      `${API_URL}${userOrganization.getAvailableUsers}?${request.format()}`
    );
  }

  async getLoggedUserAccounts(): Promise<UserMailAccountGetListResponse> {
    return this.apiRequest.get<UserMailAccountGetListResponse>(
      `${API_URL}${getLoggedUserAccounts}`
    );
  }

  async searchMailAccountUsers(
    request: BaseCollectionSearchByIdRequest
  ): Promise<BaseGetCollectionResponse<UserMailAccountContract>> {
    return this.apiRequest.get<
      BaseGetCollectionResponse<UserMailAccountContract>
    >(`${API_URL}${searchMailAccountUsers}?${request.format()}`);
  }

  async getSearchMailAccountUsersCount(
    request: BaseGetSearchCountByIdRequest
  ): Promise<BaseResponse<number>> {
    return this.apiRequest.get<BaseResponse<number>>(
      `${API_URL}${getSearchMailAccountUsersCount}?${request.format()}`
    );
  }
}
