import { Injectable } from '@angular/core';
import { TabItem } from '@app-types/common';
import { PermissionService } from './permission.service';
import { PermissionType } from '@app-types/enums/permission-type';

@Injectable({ providedIn: 'root' })
export class PermissionNavTabHelper {
  constructor(private permissionService: PermissionService) {}

  public async getTabsForMailAccountsPage(
    organizationId: number,
    webCode: string
  ): Promise<TabItem[]> {
    const canManageOrganization =
      await this.permissionService.hasPermissionOver(
        organizationId,
        null,
        PermissionType.CanManageOrganization
      );

    if (canManageOrganization) {
      return this.getOrganizationPageTabs(organizationId, webCode);
    }

    return this.getMailAccountPageTabs(organizationId, webCode);
  }

  public getMailAccountPageTabs(
    organizationId: number,
    webCode: string
  ): TabItem[] {
    return [
      {
        index: 0,
        label: 'mailAccountsNavTab',
        route: `/${webCode}/settings/organization/${organizationId}/mail-accounts`,
      },
    ];
  }

  public getSettingPageTabs(webCode: string): TabItem[] {
    return [
      {
        index: 0,
        label: 'companiesNavTab',
        route: `/${webCode}/settings/organizations`,
      },
      {
        index: 1,
        label: 'providersNavTab',
        route: `/${webCode}/settings/providers`,
      },
    ];
  }

  public getOrganizationPageTabs(
    organizationId: number,
    webCode: string
  ): TabItem[] {
    return [
      {
        index: 0,
        label: 'generalNavTab',
        route: `/${webCode}/settings/organization/${organizationId}/general`,
      },
      {
        index: 1,
        label: 'mailAccountsNavTab',
        route: `/${webCode}/settings/organization/${organizationId}/mail-accounts`,
      },
      {
        index: 2,
        label: 'usersNavTab',
        route: `/${webCode}/settings/organization/${organizationId}/users`,
      },
      {
        index: 3,
        label: 'groupsNavTab',
        route: `/${webCode}/settings/organization/${organizationId}/groups`,
      },
    ];
  }

  public getTabsForMailAccountPage(
    organizationId: number,
    mailAccountId: number,
    webCode: string
  ): TabItem[] {
    return [
      {
        index: 0,
        label: 'generalNavTab',
        route: `/${webCode}/settings/organization/${organizationId}/mail-account/${mailAccountId}/general`,
      },
      {
        index: 1,
        label: 'usersNavTab',
        route: `/${webCode}/settings/organization/${organizationId}/mail-account/${mailAccountId}/users`,
      },
      {
        index: 2,
        label: 'dispatchingRulesNavTab',
        route: `/${webCode}/settings/organization/${organizationId}/mail-account/${mailAccountId}/dispatching-rules`,
      },
      {
        index: 3,
        label: 'tagsNavTab',
        route: `/${webCode}/settings/organization/${organizationId}/mail-account/${mailAccountId}/tags`,
      },
      {
        index: 4,
        label: 'templates',
        route: `/${webCode}/settings/organization/${organizationId}/mail-account/${mailAccountId}/templates`,
      },
      {
        index: 5,
        label: 'foldersNavTab',
        route: `/${webCode}/settings/organization/${organizationId}/mail-account/${mailAccountId}/folders`,
      },
    ];
  }
}
