import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { OrganizationContract } from '@app-types/api/organization';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationClient } from '@app-services/api/clients/organization.client';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import {
  BaseCollectionSearchRequest,
  BaseGetSearchCountRequest,
} from '@app-types/base/base';
import { MobileObserverService } from '@app-services/adaptive/mobile-observer.service';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { TranslateModule } from '@ngx-translate/core';
import { SomethingWentWrongComponent } from '@app-components/common/error/something-went-wrong/something-went-wrong.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NoDataComponent } from '@app-components/common/no-data/no-data.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { LocalStorageService } from '@app-services/local-storage.service';
import { STORAGE_NAMES } from '@app-shared/constants/constants';
import { SearchBarComponent } from '@app-components/common/search-bar/search-bar.component';
import { DrawerService } from '@app-services/drawer.service';
import { OrganizationCreateDialogComponent } from '@app-components/settings/organization/organization-create-dialog/organization-create-dialog.component';
import { Subscription } from 'rxjs';
import { SkeletonTableComponent } from '@app-components/common/skeletons/skeleton-table/skeleton-table.component';

const { PAGE_SIZE_SETTINGS } = STORAGE_NAMES;

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    SomethingWentWrongComponent,
    MatButtonModule,
    MatIconModule,
    NoDataComponent,
    SomethingWentWrongComponent,
    MatTableModule,
    MatTooltipModule,
    MatCardModule,
    SearchBarComponent,
    MatPaginatorModule,
    OrganizationCreateDialogComponent,
    SkeletonTableComponent,
  ],
})
export class OrganizationComponent implements OnInit, OnDestroy {
  @HostBinding('class') className = 'setting-container';
  public organizations: OrganizationContract[] = [];
  public dataSource = new MatTableDataSource<OrganizationContract>(
    this.organizations
  );
  public displayedColumns: string[] = [
    'name',
    'web-code',
    'license-id',
    'code-sia',
    'anagId',
    'actions',
  ];
  public isLoading = false;
  public isUploaded = false;
  public isError = false;
  public pageSize = 25;
  public pageIndex = 0;
  public totalSize = 0;
  public searchValue = '';
  public useMobileView = false;
  public organization: OrganizationContract | null;
  private useMobileViewSubscription: Subscription;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('createDrawer') createDrawer: TemplateRef<any>;

  constructor(
    public matchError: MatchError,
    private organizationClient: OrganizationClient,
    protected router: Router,
    private mobileObserverService: MobileObserverService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private drawerService: DrawerService
  ) {}

  async ngOnInit(): Promise<void> {
    this.useMobileViewSubscription = this.mobileObserverService
      .mobileObserver()
      .subscribe(isMobile => (this.useMobileView = isMobile));
    this.pageSize = this.localStorageService.getData(PAGE_SIZE_SETTINGS) || 25;
    await this.fetchOrganizations(true);
  }

  public async fetchOrganizations(fetchSearchCounter: boolean): Promise<void> {
    this.isUploaded = false;
    const loaderTimeout = setTimeout(() => {
      this.isLoading = true;
    }, 500);
    this.isError = false;
    const nullableSearchValue =
      this.searchValue.length > 0 ? this.searchValue : null;
    const request = new BaseCollectionSearchRequest(
      this.pageIndex + 1,
      this.pageSize,
      nullableSearchValue
    );

    try {
      const response = await this.organizationClient.search(request);
      this.organizations = response.data;
      this.dataSource.data = this.organizations;
      if (fetchSearchCounter) {
        const counterRequest = new BaseGetSearchCountRequest(
          nullableSearchValue
        );
        const counterResponse =
          await this.organizationClient.getSearchCount(counterRequest);
        this.totalSize = counterResponse.result;
      }
      this.dataSource.paginator = this.paginator;
    } catch (e) {
      this.isError = true;
      this.matchError.logError(e);
    } finally {
      clearTimeout(loaderTimeout);
      this.isLoading = false;
      this.isUploaded = true;
    }
  }

  public async handlePage(e: any): Promise<void> {
    this.localStorageService.setData(PAGE_SIZE_SETTINGS, e.pageSize);
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    await this.fetchOrganizations(false);
  }

  public onSearchValueChanged(newSearchValue: string): void {
    this.searchValue = newSearchValue;
  }

  async onCreateOrganizationBtnClicked(): Promise<void> {
    this.drawerService.openDrawer(this.createDrawer);
  }

  onCloseCreateModalForm(): void {
    this.organization && (this.organization = null);
  }

  async onCreateOrganization(): Promise<void> {
    this.totalSize += 1;
    await this.fetchOrganizations(false);
  }

  openUpdateOrganizationDialog(organization: OrganizationContract): void {
    this.organization = organization;
    this.drawerService.openDrawer(this.createDrawer);
  }

  async onUpdateOrganization(
    organizationContract: OrganizationContract
  ): Promise<void> {
    this.dataSource.data = this.organizations.map(el => {
      if (el.organizationId === organizationContract.organizationId) {
        return {
          ...el,
          name: organizationContract.name,
          code: organizationContract.code,
          type: organizationContract.type,
          codeSia: organizationContract.codeSia,
          licenseId: organizationContract.licenseId,
          webCode: organizationContract.webCode,
          anagId: organizationContract.anagId,
          pignorState: organizationContract.pignorState,
          spaceUsedPaymentPlanType:
            organizationContract.spaceUsedPaymentPlanType,
        };
      }
      return el;
    });
  }

  ngOnDestroy(): void {
    this.useMobileViewSubscription?.unsubscribe();
  }
}
