import { Injectable } from '@angular/core';
import { environment } from '@app-environments/environment';
import { ApiRequest } from '../api.request';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import { FileCreateRequest } from '@app-types/api/file';
import { BaseGetByEmailFileIdRequest } from '@app-types/base/base';
import { Observable } from 'rxjs';
import { UserWorkspaceService } from '@app-services/user-workspace-service';
import { FileService } from '@app-services/files.servis';

const API_URL = environment.apiUrl;
const { create, get } = API_ROUTES.emailFile;

@Injectable({
  providedIn: 'root',
})
export class FileClient {
  constructor(
    private apiRequest: ApiRequest,
    public userWorkspaceService: UserWorkspaceService,
    public fileService: FileService
  ) {}

  async download(request: BaseGetByEmailFileIdRequest): Promise<void> {
    const url = `${API_URL}${get}?${request.format()}&organizationId=${this.userWorkspaceService.currentOrganization?.organizationId}`;
    const response = await this.apiRequest.download(url);
    if (response && !!response.type) {
      this.fileService.downloadWithPreview(response);
    }
  }

  upload(request: FileCreateRequest): Observable<any> {
    return this.apiRequest.upload<FileCreateRequest, Observable<any>>(
      request,
      `${API_URL}${create}`
    );
  }
}
