import { Injectable } from '@angular/core';
import { environment } from '@app-environments/environment';
import { ApiRequest } from '../api.request';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import {
  MailAccountGetUnreadCountRequest,
  MailFolderContract,
  MailFolderCreateRequest,
  MailFolderCreateResponse,
  MailFolderGetUnreadCountRequest,
  MailFolderUnreadCount,
  MailFolderUpdateRequest,
  UserFolderContract,
} from '@app-types/api/mail-folder';
import {
  BaseGetByIdRequest,
  BaseGetCollectionResponse,
  BaseId,
  BaseResponse,
} from '@app-types/base/base';
import { TreeItem } from '@app-types/tree.item';

const API_URL = environment.apiUrl;
const { mailFolder } = API_ROUTES;
const {
  get,
  create,
  getForAccount,
  getForReassign,
  getFolderUnreadCount,
  getUnreadCount,
  getUserFolders,
  update,
} = mailFolder;

@Injectable({
  providedIn: 'root',
})
export class MailFolderClient {
  constructor(private apiRequest: ApiRequest) {}

  async get(
    request: BaseGetByIdRequest
  ): Promise<BaseResponse<MailFolderContract>> {
    return this.apiRequest.get<BaseResponse<MailFolderContract>>(
      `${API_URL}${get}?${request.format()}`
    );
  }

  async getForAccount(
    request: BaseGetByIdRequest
  ): Promise<BaseGetCollectionResponse<TreeItem<number, MailFolderContract>>> {
    return this.apiRequest.get<
      BaseGetCollectionResponse<TreeItem<number, MailFolderContract>>
    >(`${API_URL}${getForAccount}?${request.format()}`);
  }

  async getForReassign(
    request: BaseGetByIdRequest
  ): Promise<BaseGetCollectionResponse<TreeItem<number, MailFolderContract>>> {
    return this.apiRequest.get<
      BaseGetCollectionResponse<TreeItem<number, MailFolderContract>>
    >(`${API_URL}${getForReassign}?${request.format()}`);
  }

  async create(
    request: MailFolderCreateRequest
  ): Promise<MailFolderCreateResponse> {
    return this.apiRequest.post<
      MailFolderCreateRequest,
      MailFolderCreateResponse
    >(request, `${API_URL}${create}`);
  }

  async update(request: MailFolderUpdateRequest): Promise<BaseId> {
    return this.apiRequest.post<MailFolderUpdateRequest, BaseId>(
      request,
      `${API_URL}${update}`
    );
  }

  async delete(request: BaseId): Promise<BaseId> {
    return this.apiRequest.post<BaseId, BaseId>(
      request,
      `${API_URL}${mailFolder.delete}`
    );
  }

  async getUnreadCount(
    request: MailAccountGetUnreadCountRequest
  ): Promise<BaseGetCollectionResponse<MailFolderUnreadCount>> {
    return this.apiRequest.get<
      BaseGetCollectionResponse<MailFolderUnreadCount>
    >(`${API_URL}${getUnreadCount}?${request.format()}`);
  }

  async getFolderUnreadCount(
    request: MailFolderGetUnreadCountRequest
  ): Promise<MailFolderUnreadCount> {
    return this.apiRequest.get<MailFolderUnreadCount>(
      `${API_URL}${getFolderUnreadCount}?${request.format()}`
    );
  }

  async getUserFolders(): Promise<
    BaseGetCollectionResponse<UserFolderContract>
  > {
    return this.apiRequest.get<BaseGetCollectionResponse<UserFolderContract>>(
      `${API_URL}${getUserFolders}`
    );
  }
}
