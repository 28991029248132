import { Injectable } from '@angular/core';
import { environment } from '@app-environments/environment';
import { ApiRequest } from '../api.request';
import { BaseResponse } from '@app-types/base/base';
import { UserContract, UserUpdateRequest } from '@app-types/api/user';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class UserClient {
  constructor(private apiRequest: ApiRequest) {}

  async getUser(): Promise<BaseResponse<UserContract>> {
    return await this.apiRequest.get(`${API_URL}${API_ROUTES.user.getUser}`);
  }

  async update(request: UserUpdateRequest): Promise<void> {
    await this.apiRequest.post<UserUpdateRequest, void>(
      request,
      `${API_URL}${API_ROUTES.user.update}`
    );
  }
}
