
@if(!data.isCreate) {
  <div>
    <h1 mat-dialog-title>{{ data.title || "confirmation" | translate }}</h1>
    <mat-dialog-content class="mat-mdc-dialog-content mdc-dialog__content mat-container">
      <p>
        @if(!data.isDontNeedConfirmWord) {
          <span>{{ "doYouConfirm" | translate }}</span>
        }
        {{ data.name | translate }}
        @if(data.itemName) {
          <span class="font-bold italic">{{ data.itemName }}</span>
        }
        ?
      </p>
    </mat-dialog-content>
    <mat-dialog-actions class="mat-mdc-dialog-actions mdc-dialog__actions">
      <div class="w-full flex justify-between">
        <button mat-button (click)="onNoClick()">{{ "no" | translate }}</button>
        <button mat-flat-button color="primary" [disabled]="isLoading" (click)="onOkClick()">
          <app-loading-button text="{{ 'yes' | translate }}" [loading]="isLoading"></app-loading-button>
        </button>
      </div>
    </mat-dialog-actions>
  </div>
}

@if(data.isCreate) {
  <div>
<!--    <app-create-contact [mailAccountId]="data.mailAccountId"></app-create-contact>-->
    <button mat-raised-button color="primary" (click)="onNoClick()">{{ "cancel" | translate }}</button>
  </div>
}

