import { Component, Input, OnInit } from '@angular/core';
import { DispatchingAction } from '@app-types/api/Dispatching';
import { MailFolderContract } from '@app-types/api/mail-folder';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-move-to-folder-action',
  templateUrl: './move-to-folder-action.component.html',
  styleUrls: ['./move-to-folder-action.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
  ],
})
export class MoveToFolderActionComponent implements OnInit {
  @Input() dispatchingAction: DispatchingAction;
  @Input() availableLocalFolders: MailFolderContract[];
  public form: UntypedFormGroup;
  constructor() {}

  ngOnInit(): void {
    this.setFolderValue(
      this.dispatchingAction.args?.[0]?.value ||
        this.availableLocalFolders[0].mailFolderId.toString()
    );

    this.form = new UntypedFormGroup({
      folder: new UntypedFormControl(
        parseInt(this.dispatchingAction.args?.[0].value ?? '', 10)
      ),
    });
  }

  setFolderValue(folder: string): void {
    this.dispatchingAction.args = [];
    this.dispatchingAction.args[0] = {
      name: 'FolderId',
      value: folder.toString(),
    };
  }
}
