import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@app-environments/environment';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import { ApiRequest } from '../api.request';
import { BaseGetByIdRequest } from '@app-types/base/base';
import {
  MailAccountSimpleConfigurationCanSetupResponse,
  MailAccountSimpleConfigurationRequest,
} from '@app-types/api/mail-account-simple-configuration';

const API_URL = environment.apiUrl;
const { mailAccountSimpleConfiguration } = API_ROUTES;
const { create, canSetup } = mailAccountSimpleConfiguration;

@Injectable({
  providedIn: 'root',
})
export class MailAccountSimpleConfigurationClient {
  constructor(
    private http: HttpClient,
    private apiRequest: ApiRequest
  ) {}

  async canSetup(
    request: BaseGetByIdRequest
  ): Promise<MailAccountSimpleConfigurationCanSetupResponse> {
    return this.apiRequest.post<
      BaseGetByIdRequest,
      MailAccountSimpleConfigurationCanSetupResponse
    >(request, `${API_URL}${canSetup}`);
  }
  async create(request: MailAccountSimpleConfigurationRequest): Promise<any> {
    return this.apiRequest.post<MailAccountSimpleConfigurationRequest, any>(
      request,
      `${API_URL}${create}`
    );
  }
}
