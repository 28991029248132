@if(!useMobileView) {
  <div class="sidebar pl-2" [@slideInOut]="menuIsOpened ? 'in' : 'out'">
    <div class="w-full h-full flex flex-col gap-[1rem]">
      <div class="w-full flex bg-white">
        <div class="flex cursor-pointer items-center" (click)="closeMenu.emit()">
          <button mat-icon-button class="menu-close-btn pr-3 mt-[2px]">
            <mat-icon>navigate_before</mat-icon>
          </button>
          <div class="h3">{{ menuHeader | translate }}</div>
        </div>
       </div>

        <div class="w-full h-full">
          @if(emailId && menuContent === menuContentTypes.Attachments) {
            <app-email-attachments
              [emailId]="emailId"
              [emailDetails]="emailDetails"
            ></app-email-attachments>
          }

          @if(emailId && menuContent === menuContentTypes.Notes) {
            <div class="h-full w-full">
              <app-email-note-list
                [emailId]="emailId"
                (refreshNotes)="refreshNotes.emit($event)"
              ></app-email-note-list>
            </div>
          }
          @if(emailId && menuContent === menuContentTypes.Templates) {
            <div class="h-full w-full">
              <app-email-templates
                (templateSelected)="templateSelected.emit($event)"
                [mailAccountId]="mailAccountId"
              ></app-email-templates>
            </div>
          }
          @if(emailId && menuContent === menuContentTypes.Audit) {
            <div class="h-full w-full">
              <app-email-audit [emailId]="emailId"></app-email-audit>
            </div>
          }
          @if(emailId && menuContent === menuContentTypes.Notifications) {
            <div class="h-full">
              <app-email-notifications-dialog
                [emailId]="emailId"
                [webCode]="webCode"
                (openEmailDetails)="openEmailDetails.emit($event)"
              ></app-email-notifications-dialog>
            </div>
          }
      </div>
    </div>
  </div>
}

@if(useMobileView) {
  <div
    class="w-full h-full flex flex-col gap-[1rem] fixed right-0 top-0 bg-white z-20 py-[0.5rem]"
    [@slideFixedInOut]="menuIsOpened ? 'in' : 'out'"
  >
    <div class="w-full flex bg-white z-20">
      <div class="flex cursor-pointer items-center" (click)="closeMenu.emit()">
        <button mat-icon-button class="menu-close-btn pr-3 mt-[2px]">
          <mat-icon>navigate_before</mat-icon>
        </button>
        <div class="h3">{{ menuHeader | translate }}</div>
      </div>
    </div>

    <div class="flex-grow w-full px-1 overflow-y-auto">
      @if(emailId && menuContent === menuContentTypes.Notes) {
        <div class="h-full">
          <app-email-note-list
            [emailId]="emailId"
            (refreshNotes)="refreshNotes.emit($event)"
          ></app-email-note-list>
        </div>
      }

      @if(emailId && menuContent === menuContentTypes.Templates) {
        <div class="h-full">
          <app-email-templates
            (templateSelected)="templateSelected.emit($event)"
            [mailAccountId]="mailAccountId"
          ></app-email-templates>
        </div>
      }

      @if(emailId && menuContent === menuContentTypes.Audit) {
        <div class="h-full">
          <app-email-audit [emailId]="emailId"></app-email-audit>
        </div>
      }

      @if(emailId && menuContent === menuContentTypes.Notifications) {
        <div class="h-full">
          <app-email-notifications-dialog
            [emailId]="emailId"
            [webCode]="webCode"
            (openEmailDetails)="openEmailDetails.emit($event)"
          ></app-email-notifications-dialog>
        </div>
      }

      @if(emailId && menuContent === menuContentTypes.Attachments) {
        <app-email-attachments
          [emailId]="emailId"
          [emailDetails]="emailDetails"
        ></app-email-attachments>
      }
    </div>
  </div>
}
