@if(isVisible) {
  <div [class.w-full]="useMobileView" @slideDown class="p-[0.5rem] block md:hidden overflow-hidden bg-white">
    <app-email-search-input
      (searchEventEmitter)="search($event)"
      (valueChangedEventEmitter)="onSearchValueChanged($event)"
      (hideNotificationsEmitter)="onHideNotificationsChanged($event)"
      (rightMenuClicked)="rightMenuClicked()"
      [search]="searchValue"
      [isDefaultSearchParams]="isDefaultSearchParams"
      [fullWidth]="useMobileView"
      [hasFilters]="!!infoOpenedMailAccount?.filter"
    ></app-email-search-input>
  </div>
}
<div class="toolbar relative">
  <div class="flex-none">
    <button (click)="leftMenuClicked()" mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div class="ml-auto block md:hidden">
    <button
      mat-icon-button
      type="submit"
      (click)="toggleVisibility()">
      <mat-icon>search</mat-icon>
    </button>
  </div>
  <div class="lg:mx-[10px] text-start">
    @if(isVisibleFolderName()) {
      <app-email-folder-name></app-email-folder-name>
    }
  </div>
  <div class="flex justify-center px-1 md:px-0 md:block hidden">
    <div [class.w-full]="useMobileView">
      <app-email-search-input
        (searchEventEmitter)="search($event)"
        (valueChangedEventEmitter)="onSearchValueChanged($event)"
        (hideNotificationsEmitter)="onHideNotificationsChanged($event)"
        [search]="searchValue"
        [isDefaultSearchParams]="isDefaultSearchParams"
        [fullWidth]="useMobileView"
        [hasFilters]="!!infoOpenedMailAccount?.filter"
      ></app-email-search-input>
    </div>
  </div>
  <div class="flex items-center gap-2">
    @if(isVisibleFolderName() && isInbox && isAnyFolders && !isAllFoldersSelected) {
      <mat-tab-group
        [(selectedIndex)]="selectedTabIndex"
        (selectedTabChange)="changeTab($event)"
        class="!hidden sm:!flex ml-[auto]"
        mat-align-tabs="end"
        animationDuration="0s"
      >
        @for (tab of ['inInboxTabItem', 'toValidateInboxTabItem', 'ReassignedInboxTabItem']; track tab) {
          <mat-tab [label]="tab | translate"></mat-tab>
        }
      </mat-tab-group>
      <mat-select [value]="selectedTabIndex" (selectionChange)="selectionChange($event)">
        @for(item of ['inInboxTabItem', 'toValidateInboxTabItem', 'ReassignedInboxTabItem']; track item; let index = $index) {
          <mat-option [value]="index">{{ item | translate }}</mat-option>
        }
      </mat-select>
    }
  </div>
  <div class="flex-none flex">
    <button mat-icon-button (click)="rightMenuClicked()">
      <mat-icon>settings</mat-icon>
    </button>
  </div>
</div>

