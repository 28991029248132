import { Component, Input, OnInit } from '@angular/core';
import { DispatchingAction } from '@app-types/api/Dispatching';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { EmailTagsComponent } from '@app-components/common/email/email-tags/email-tags.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { TAGS_REGEX } from '@app-shared/constants/regex';

@Component({
  selector: 'app-forward-action',
  templateUrl: './forward-action.component.html',
  styleUrls: ['./forward-action.component.scss'],
  standalone: true,
  imports: [
    EmailTagsComponent,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class ForwardActionComponent implements OnInit {
  @Input() dispatchingAction: DispatchingAction;
  public form: UntypedFormGroup;
  public list: string[];
  constructor() {}

  ngOnInit(): void {
    this.setToValue(
      this.dispatchingAction.args?.[0]?.value
        ? [this.dispatchingAction.args[0].value]
        : []
    );

    this.form = new UntypedFormGroup({
      forwardTo: new UntypedFormControl(
        this.dispatchingAction.args?.[0]?.value ?? '',
        [Validators.required]
      ),
    });
    this.list = this.dispatchingAction.args?.[0].value
      ? this.dispatchingAction.args[0].value.split(';')
      : [];
  }
  public isValidTag(value: string): boolean {
    return !!String(value).toLowerCase().match(TAGS_REGEX);
  }
  public isValidEmails(): boolean {
    const isAllValid =
      this.dispatchingAction.args?.[0].value ??
      ''
        .replace(/\s+/g, '')
        .split(';')
        .every(e => this.isValidTag(e));
    return !!isAllValid;
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName]?.hasError(errorName);
  };

  setToValue(event: string[]): void {
    const emailsString = event.join(';');
    if (this.dispatchingAction.args) {
      this.dispatchingAction.args[0] = {
        name: 'to',
        value: emailsString.replace(/\s+/g, ''),
      };
    }
  }
}
