import { RoleType } from '../enums/role-type';
import { BaseGetRequest } from '../base/base-get.request';

export interface RoleContract {
  roleId: number;
  name: string;
}

export interface MailAccountUserRequest {
  mailAccounts: number[];
  usersRoles: UsersRole[];
}

export interface UsersRole {
  userId: number;
  role: number;
}

export interface UserMailAccountAssignRoleRequest {
  userMailAccountId: number;
  roleId: number;
}

export interface UserMailAccountContract {
  userMailAccountId: number;
  userId?: number;
  mailAccountId: number;
  roleId: RoleType;
  userFirstName: string;
  userLastName: string;
  userEmail?: string;
  mailAccountName: string;
  mailAccountEmail?: string;
  spaceUsed?: number;
  oidcIdentity?: number;
  googleIdentity?: number;
  adfsIdentity?: number;
}

export interface UserMailAccountCreateRequest {
  userId: number;
  mailAccountId: number;
  roleId: number;
}

export interface UserMailAccountCreateResponse {
  userMailAccountId: number;
}

export interface UserMailAccountGetListResponse {
  userMailAccounts: UserMailAccountContract[];
}

export interface UserMailAccountRequest {
  users: number[];
  usersRoles: UsersMailRole[];
}

interface UsersMailRole {
  mailAccountId: number;
  role: number;
}

export class UserMailAccountGetListRequest extends BaseGetRequest {
  userId: number;

  constructor(id: number) {
    super();
    this.userId = id;
  }
}
