import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingButtonComponent } from '../loading-button/loading-button.component';

@Component({
  selector: 'app-confirm-dialog',
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogModule,
    TranslateModule,
    LoadingButtonComponent,
  ],
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  isLoading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      isCreate?: boolean;
      title: string;
      itemName: string;
      isDontNeedConfirmWord: boolean;
      submit?: () => { error: string };
      mailAccountId: number;
      name: string;
    }
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  async onOkClick(): Promise<void> {
    if (this.data.submit) {
      this.isLoading = true;
      await this.data.submit();
      this.isLoading = false;
    }
    this.dialogRef.close(true);
  }
}
