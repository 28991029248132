import { Pipe, PipeTransform } from '@angular/core';
import { SslProtocolEnum } from '@app-types/enums/ssl-protocol';

@Pipe({
  name: 'formatTlsVersion',
  standalone: true,
})
export class FormatTlsVersionPipe implements PipeTransform {
  transform(protocol: SslProtocolEnum): string {
    switch (protocol) {
      case 1:
        return 'Tls';
      case 2:
        return 'Tls11';
      case 3:
        return 'Tls12';
      case 4:
        return 'Tls13';
      default:
        return '';
    }
  }
}
