import { ErrorHandler, Injectable } from '@angular/core';
import * as ErrorStackParser from 'error-stack-parser';
import StackFrame from 'stackframe';
import { LoggingHandler } from 'src/app/services/errors/logging.service';
import StackTraceGPS from 'stacktrace-gps';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private loggingHandler: LoggingHandler) {}

  transformInfoStack(result: string, info: StackFrame): string {
    const { functionName, fileName, lineNumber } = info;
    return `${result}\n at ${functionName || ''} (${fileName}:${lineNumber})`;
  }

  async getErrorStackString(stackFrames: any): Promise<string> {
    const gps = new StackTraceGPS();

    return await stackFrames.reduce(async (promise: any, frame: StackFrame) => {
      const result = await promise;
      try {
        const info = await gps.getMappedLocation(frame);
        // const info = await gps.pinpoint(frame);
        return this.transformInfoStack(result, info);
      } catch (err) {
        console.log(err);
        return `${result}\n at Unable to get data from source map for ${frame}`;
      }
    }, '');
  }

  async handleError(error: any): Promise<void> {
    try {
      const stackFrames = ErrorStackParser.parse(error);
      const stack = await this.getErrorStackString(stackFrames);

      try {
        this.loggingHandler.create('error', error.message, stack);
      } catch (logError) {
        console.error('Error logging failed:', logError);
      }
    } catch (parsingError) {
      console.error('Error handling failed:', parsingError);
    }
  }
}
