import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './auth/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class IdleTimeoutService {
  private timeoutId: any;
  private webCode: string;
  private readonly timeoutDuration = environment.userActivityTimeoutMs;

  constructor(private authenticationService: AuthenticationService) {
    this.startIdleTimer();
  }

  private startIdleTimer(): void {
    this.timeoutId = setTimeout(() => {
      this.authenticationService.logout(this.webCode);
    }, this.timeoutDuration);
  }

  private resetIdleTimer(): void {
    clearTimeout(this.timeoutId);
    this.startIdleTimer();
  }

  public userActivityDetected(webCode: string): void {
    this.webCode = webCode;
    this.resetIdleTimer();
  }
}
