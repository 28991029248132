import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CheckboxItem } from '../../../../../../types/checkbox.item';
import { RemoteFolder } from '../../../../../../types/api/remote-folder';
import { DispatchingRule } from '../../../../../../types/api/Dispatching';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-dispatching-folders',
  templateUrl: './dispatching-folders.component.html',
  styleUrls: ['./dispatching-folders.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatCheckboxModule,
  ],
})
export class DispatchingFoldersComponent implements OnInit, OnChanges {
  @Input() public checkBoxFolders: CheckboxItem<RemoteFolder>[] = [];
  @Input() public dispatchingRule: DispatchingRule;
  @Input() public isShowError: boolean;

  public form: UntypedFormGroup = new UntypedFormGroup({});
  public selected: string[] = [];
  constructor() {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      mailFolders: new UntypedFormControl('', Validators.required),
    });
    this.selected =
      this.dispatchingRule.dispatchingRule?.folders.map(e => e.name) ?? [];
  }

  ngOnChanges(): void {
    if (this.isShowError) {
      this.form.controls.mailFolders.markAsTouched();
    }
  }

  onChange(folder: CheckboxItem<RemoteFolder>): void {
    const currFolder = this.checkBoxFolders.find(
      item => item.label === folder.label
    );
    currFolder && (currFolder.checked = !currFolder.checked);

    this.dispatchingRule.dispatchingRule?.folders &&
      (this.dispatchingRule.dispatchingRule.folders = this.checkBoxFolders
        .filter(c => c.checked)
        .map(c => ({
          isOutbox: false,
          name: c.value.name,
        })));
  }
}
