import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {
  Alignment,
  AutoLink,
  Autoformat,
  Base64UploadAdapter,
  BlockQuote,
  Bold,
  ClassicEditor,
  Code,
  Essentials,
  Font,
  Heading,
  HorizontalLine,
  Image,
  ImageCaption,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  Mention,
  Paragraph,
  RemoveFormat,
  Strikethrough,
  Subscript,
  Superscript,
  Table,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  Underline,
  Undo,
} from 'ckeditor5';
// @ts-ignore
import it from 'ckeditor5/translations/it.js';
// @ts-ignore
import en from 'ckeditor5/translations/en.js';
import { CKEditorModule, ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-email-body',
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    CKEditorModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './email-body.component.html',
  styleUrls: ['./email-body.component.scss'],
})
export class EmailBodyComponent {
  @Input() body: string;
  @Input() disabled = false;
  @Output() public valueChangedEventEmitter = new EventEmitter<string>();

  public editor = ClassicEditor;
  public config: any = {
    language: localStorage.getItem('userLocale'),
    toolbar: {
      items: [
        'undo',
        'redo',
        '|',
        'heading',
        '|',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'subscript',
        'superscript',
        '|',
        'alignment',
        '|',
        'outdent',
        'indent',
        '|',
        'bulletedList',
        'numberedList',
        '|',
        'insertTable',
        '|',
        'fontFamily',
        'fontSize',
        '|',
        'fontColor',
        'fontBackgroundColor',
        '|',
        'link',
        'horizontalLine',
        '|',
        'removeFormat',
        'code',
      ],
    },
    image: {
      toolbar: [
        'imageStyle:inline',
        'imageStyle:wrapText',
        'imageStyle:breakText',
        '|',
        'toggleImageCaption',
        'imageTextAlternative',
      ],
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells',
        'tableProperties',
        'tableCellProperties',
      ],
    },
    link: {
      addTargetToExternalLinks: true,
      decorators: [
        {
          mode: 'manual',
          label: 'Downloadable',
          attributes: {
            download: 'download',
          },
        },
      ],
    },
    plugins: [
      Bold,
      Essentials,
      Italic,
      Mention,
      Paragraph,
      Undo,
      Font,
      List,
      Heading,
      Strikethrough,
      Subscript,
      Superscript,
      Underline,
      Alignment,
      Indent,
      IndentBlock,
      Table,
      TableToolbar,
      TableProperties,
      TableCellProperties,
      Link,
      AutoLink,
      HorizontalLine,
      RemoveFormat,
      Code,
      Image,
      ImageResize,
      ImageToolbar,
      ImageStyle,
      ImageTextAlternative,
      ImageCaption,
      ImageUpload,
      Base64UploadAdapter,
      BlockQuote,
      Autoformat,
      TableColumnResize,
    ],
    translations: [it, en],
  };
  constructor() {}

  public onChange({ editor }: ChangeEvent): void {
    const data = editor.getData();
    this.valueChangedEventEmitter && this.valueChangedEventEmitter.emit(data);
  }

  public onEditorReady(editor: any): void {
    const toolbarElement = editor.ui.view.toolbar.element;
    toolbarElement.style.display = 'none';
  }
}
