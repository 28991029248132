import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MailAccountGetByOrganizationIdContract } from '@app-types/api/mail-account';
import { MailAccountClient } from '@app-services/api/clients/mail-account.client';
import { PermissionService } from '@app-services/permission/permission.service';
import { PermissionType } from '@app-types/enums/permission-type';
import { PermissionNavTabHelper } from '@app-services/permission/permission-nav-tab-helper';
import {
  BreadcrumbItem,
  FilterFields,
  Options,
  OptionsEnum,
} from '@app-types/common';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import {
  BaseGetByIdRequest,
  BaseGetSearchCountByIdRequest,
} from '@app-types/base/base';
import { OrganizationClient } from '@app-services/api/clients/organization.client';
import { MatDialog } from '@angular/material/dialog';
import { MobileObserverService } from '@app-services/adaptive/mobile-observer.service';
import { OrganizationSettingsClient } from 'src/app/services/api/clients/organization-settings.client';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { MailAccountSearchOrder } from 'src/app/types/enums/MailAccountSearchOrder';
import { FormatMailAccountSearchOrders } from 'src/app/shared/pipes/format-mail-account-search-orders.pipe';
import { ProviderClient } from 'src/app/services/api/clients/provider.client';
import {
  MENU_STATE,
  ORGANIZATION_ID,
  STORAGE_NAMES,
  WEB_CODE,
  defaultFilterFields,
} from '@app-shared/constants/constants';
import {
  CONNECTION_STATUS,
  CONNECTION_STATUS_INFO,
} from '@app-shared/constants/connection-status';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SearchBarComponent } from '@app-components/common/search-bar/search-bar.component';
import { NoDataComponent } from '@app-components/common/no-data/no-data.component';
import { SomethingWentWrongComponent } from '@app-components/common/error/something-went-wrong/something-went-wrong.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { OrganizationGroupCreateDialogComponent } from '@app-components/settings/organization/organization-groups/organization-group-create-dialog/organization-group-create-dialog.component';
import { DatePipe } from '@angular/common';
import { AddGroupsToUsersFormComponent } from '@app-components/settings/organization/organization-groups/add-groups-to-users-form/add-groups-to-users-form.component';
import {
  MAT_MENU_DEFAULT_OPTIONS,
  MatMenuModule,
} from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { DrawerService } from '@app-services/drawer.service';
import { LocalStorageService } from '@app-services/local-storage.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AddMailAccountsToUsersFormComponent } from '@app-components/settings/organization/mail-account/add-mail-accounts-to-users-form/add-mail-accounts-to-users-form.component';
import { MailAccountCreateDialogComponent } from '@app-components/settings/organization/mail-account/mail-account-create-dialog/mail-account-create-dialog.component';
import { UserWorkspaceService } from '@app-services/user-workspace-service';
import { MatCardModule } from '@angular/material/card';
import { SearchOrderComponent } from '@app-components/common/search-order/search-order.component';
import { Subscription } from 'rxjs';
import { SkeletonTableComponent } from '@app-components/common/skeletons/skeleton-table/skeleton-table.component';

const { PAGE_SIZE_SETTINGS } = STORAGE_NAMES;

@Component({
  selector: 'app-mail-account',
  templateUrl: './mail-account.component.html',
  styleUrls: ['./mail-account.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    SearchBarComponent,
    NoDataComponent,
    SomethingWentWrongComponent,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    OrganizationGroupCreateDialogComponent,
    DatePipe,
    AddGroupsToUsersFormComponent,
    MatMenuModule,
    MatSelectModule,
    MatTooltipModule,
    AddMailAccountsToUsersFormComponent,
    MailAccountCreateDialogComponent,
    RouterModule,
    MatCardModule,
    SearchOrderComponent,
    SkeletonTableComponent,
  ],
  providers: [
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'panelClassMenu' },
    },
  ],
})
export class MailAccountComponent implements OnInit, OnDestroy {
  @HostBinding('class') className = 'setting-container';
  public organizationId: number | null;
  public isLoading = false;
  isUploaded = false;
  public mailAccounts: MailAccountGetByOrganizationIdContract[] = [];
  public dataSource =
    new MatTableDataSource<MailAccountGetByOrganizationIdContract>(
      this.mailAccounts
    );
  public displayedColumns: string[] = [
    'name',
    'license',
    'codeSia',
    'anagId',
    'email',
    'provider-name',
    'is-active',
    'archive',
    'space',
    'connect',
    'actions',
  ];
  public hasError = false;
  public breadcrumbItems: BreadcrumbItem[] = [];
  public searchValue = '';
  public pageNumber = 1;
  public pageSize = 25;
  public pageIndex = 0;
  public totalSize = 0;
  public createDrawerIsOpen = false;
  public canManageMailAccounts = false;
  public useMobileView = false;
  public useTabletView = false;
  public webCode: string;
  public iscredemISAMSupport = true;
  public spaceUsed = 0;

  public isDrawerOpen = false;
  public searchOrder: MailAccountSearchOrder | 0 = 0;
  public searchOrders: OptionsEnum[];
  public provider = -1;
  public filter: FilterFields = { ...defaultFilterFields };
  public providers: Options[] = [];
  connectionStatusName = CONNECTION_STATUS;
  connectionStatusInfo = CONNECTION_STATUS_INFO;
  menuConnectionStatus = Object.values(CONNECTION_STATUS);
  menuStateList = MENU_STATE;

  selectedMailAccount: MailAccountGetByOrganizationIdContract | null;
  private formatMailAccountSearchOrders = new FormatMailAccountSearchOrders();
  private useMobileViewSubscription: Subscription;
  private useTabletViewSubscription: Subscription;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('addMailAccountsToUsersDrawer')
  addMailAccountsToUsersDrawer: TemplateRef<any>;
  @ViewChild('createDrawer') createDrawer: TemplateRef<any>;

  constructor(
    public matchError: MatchError,
    protected router: Router,
    protected route: ActivatedRoute,
    private mailAccountClient: MailAccountClient,
    private permissionService: PermissionService,
    private permissionNavTabHelper: PermissionNavTabHelper,
    private organizationSettingsClient: OrganizationSettingsClient,
    private organizationClient: OrganizationClient,
    private providerClient: ProviderClient,
    public dialog: MatDialog,
    private mobileObserverService: MobileObserverService,
    private drawerService: DrawerService,
    private localStorageService: LocalStorageService,
    private userWorkspaceService: UserWorkspaceService
  ) {}

  async ngOnInit(): Promise<void> {
    this.useMobileViewSubscription = this.mobileObserverService
      .mobileObserver()
      .subscribe(isMobile => (this.useMobileView = isMobile));
    this.useTabletViewSubscription = this.mobileObserverService
      .tabletObserver()
      .subscribe(isTablet => (this.useTabletView = isTablet));

    this.pageSize = this.localStorageService.getData(PAGE_SIZE_SETTINGS) || 25;
    this.webCode = this.route.parent?.snapshot?.paramMap.get(WEB_CODE) ?? '';
    this.organizationId = this.route.snapshot.paramMap.get(ORGANIZATION_ID)
      ? parseInt(
          this.route.snapshot.paramMap.get(ORGANIZATION_ID) as string,
          10
        )
      : null;

    const searchOrders = Object.keys(MailAccountSearchOrder)
      .filter(k => !(parseInt(k, 10) >= 0))
      .map(key => ({
        id: MailAccountSearchOrder[key as keyof typeof MailAccountSearchOrder],
        name: this.formatMailAccountSearchOrders.transform(
          MailAccountSearchOrder[key as keyof typeof MailAccountSearchOrder]
        ),
      }));

    this.searchOrders = searchOrders.filter(
      e => e.id !== MailAccountSearchOrder.OrderByMailAccountId
    );
    await this.loadCanManageMailAccounts();
    await this.loadMailAccounts(true);
  }

  private async loadCanManageMailAccounts(): Promise<void> {
    this.canManageMailAccounts = await this.permissionService.hasPermissionOver(
      this.organizationId,
      null,
      PermissionType.CanManageMailAccounts
    );
  }

  async reload(): Promise<void> {
    this.filter = { ...defaultFilterFields };
    this.searchOrder = 0;

    await this.loadMailAccounts(true);
  }

  async changeOrder(): Promise<void> {
    this.filter.orderDirection = this.filter.orderDirection === 1 ? 2 : 1;
    await this.loadMailAccounts(true);
  }

  public async retry(): Promise<void> {
    await this.loadMailAccounts(true);
  }

  public async loadMailAccounts(fetchSearchCounter: boolean): Promise<void> {
    this.isUploaded = false;
    const loaderTimeout = setTimeout(() => {
      this.isLoading = true;
    }, 500);

    this.hasError = false;
    const nullableSearchValue =
      this.searchValue.length > 0 ? this.searchValue : null;
    this.filter.search = this.searchValue.length > 0 ? this.searchValue : null;

    if (fetchSearchCounter) {
      this.pageIndex = 0;
    }
    try {
      const organizationSettings =
        await this.organizationSettingsClient.getByOrganization(
          new BaseGetByIdRequest(this.organizationId)
        );
      this.iscredemISAMSupport = organizationSettings.result.credemISAMSupport;
      const request = {
        organizationId: this.organizationId,
        page: this.pageIndex + 1,
        pageSize: this.pageSize,
        filter: this.filter,
      };
      const result = await this.mailAccountClient.getSearch(request);

      this.mailAccounts = result.data;
      this.spaceUsed = result.spaceUsed ?? 0;
      if (this.spaceUsed === undefined || this.spaceUsed === null) {
        this.spaceUsed = 0;
      } // if something goes wrong

      this.dataSource.data = this.mailAccounts;
      if (fetchSearchCounter) {
        const counterRequest = new BaseGetSearchCountByIdRequest(
          this.organizationId,
          nullableSearchValue
        );
        const counterResponse =
          await this.mailAccountClient.getSearchCount(counterRequest);
        this.totalSize = counterResponse.result;
      }

      this.providers = (
        await this.providerClient.getByOrganization(
          new BaseGetByIdRequest(this.organizationId)
        )
      ).providerContracts.map(elem => {
        return {
          val: elem.providerId,
          name: elem.name,
        };
      });
    } catch (e) {
      this.hasError = true;
      this.matchError.logError(e);
    } finally {
      clearTimeout(loaderTimeout);
      this.isLoading = false;
      this.isUploaded = true;
    }
  }

  public onSearchValueChanged(newSearchValue: string): void {
    this.searchValue = newSearchValue;
  }

  public getDetailsLink(mailAccountId: number): string {
    return `/${this.webCode}/settings/organization/${this.organizationId}/mail-account/${mailAccountId}/general`;
  }

  public async handlePage(e: any): Promise<void> {
    this.localStorageService.setData(PAGE_SIZE_SETTINGS, e.pageSize);
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    await this.loadMailAccounts(false);
  }

  async onCreateMailAccountBtnClicked(): Promise<void> {
    this.drawerService.openDrawer(this.createDrawer);
  }

  public async searchOrderChange($event: any): Promise<void> {
    this.searchOrder = $event as MailAccountSearchOrder;
    this.filter.searchOrder = this.searchOrder;
    this.filter.orderDirection = 1;
    await this.loadMailAccounts(true);
  }

  public async searchProviderChange(provider: {
    val: number;
    name: string;
  }): Promise<void> {
    this.filter.ProviderId = provider.val;
    await this.loadMailAccounts(true);
  }

  public async setFilterChange(
    name: string,
    val: any,
    fieldRemove?: string
  ): Promise<void> {
    this.filter[name as keyof FilterFields] = val;
    if (fieldRemove) {
      delete this.filter[fieldRemove as keyof FilterFields];
    }
    await this.loadMailAccounts(true);
  }

  async onCreateMailAccount(): Promise<void> {
    this.totalSize += 1;
    await this.loadMailAccounts(false);
  }

  public async redirectToDetails(mailAccountId: number): Promise<void> {
    await this.router.navigateByUrl(this.getDetailsLink(mailAccountId));
  }

  public onAddMailAccountToUsersBtnClicked(
    mailAccount: MailAccountGetByOrganizationIdContract
  ): void {
    this.selectedMailAccount = mailAccount;
    this.drawerService.openDrawer(this.addMailAccountsToUsersDrawer);
  }

  public async onHideAddMailAccountsToUsersForm(): Promise<void> {
    this.selectedMailAccount = null;
    // TODO
    // await this.userWorkspaceService.loadFolders();
    // await this.userWorkspaceService.getMailAccounts();
  }

  ngOnDestroy(): void {
    this.useMobileViewSubscription?.unsubscribe();
    this.useTabletViewSubscription?.unsubscribe();
  }
}
