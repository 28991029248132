import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@app-environments/environment';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import { ApiRequest } from '../api.request';
import {
  BaseCollectionSearchByIdRequest,
  BaseGetByIdRequest,
  BaseGetCollectionResponse,
  BaseGetSearchCountByIdRequest,
  BaseId,
  BaseResponse,
} from '@app-types/base/base';
import {
  CreateTemplateRequest,
  TemplateContract,
  TemplateGetByIdResponse,
  UpdateTemplateRequest,
} from '@app-types/api/template';

const API_URL = environment.apiUrl;
const { template } = API_ROUTES;
const {
  getById,
  create,
  getForMailAccount,
  searchForMailAccount,
  getSearchCountForMailAccount,
  update,
} = template;

@Injectable({
  providedIn: 'root',
})
export class TemplateClient {
  constructor(
    private http: HttpClient,
    private apiRequest: ApiRequest
  ) {}

  async getForMailAccount(
    request: BaseGetByIdRequest
  ): Promise<BaseGetCollectionResponse<TemplateContract>> {
    return this.apiRequest.get<BaseGetCollectionResponse<TemplateContract>>(
      `${API_URL}${getForMailAccount}?${request.format()}`
    );
  }

  async getById(request: BaseGetByIdRequest): Promise<TemplateGetByIdResponse> {
    return this.apiRequest.get<TemplateGetByIdResponse>(
      `${API_URL}${getById}?${request.format()}`
    );
  }

  async searchForMailAccount(
    request: BaseCollectionSearchByIdRequest
  ): Promise<BaseGetCollectionResponse<TemplateContract>> {
    return this.apiRequest.get<BaseGetCollectionResponse<TemplateContract>>(
      `${API_URL}${searchForMailAccount}?${request.format()}`
    );
  }

  async getSearchCountForMailAccount(
    request: BaseGetSearchCountByIdRequest
  ): Promise<BaseResponse<number>> {
    return this.apiRequest.get<BaseResponse<number>>(
      `${API_URL}${getSearchCountForMailAccount}?${request.format()}`
    );
  }

  async create(request: CreateTemplateRequest): Promise<void> {
    await this.apiRequest.post<CreateTemplateRequest, void>(
      request,
      `${API_URL}${create}`
    );
  }

  async update(request: UpdateTemplateRequest): Promise<void> {
    await this.apiRequest.post<UpdateTemplateRequest, void>(
      request,
      `${API_URL}${update}`
    );
  }

  async delete(request: BaseId): Promise<void> {
    await this.apiRequest.post<BaseId, void>(
      request,
      `${API_URL}${template.delete}`
    );
  }
}
