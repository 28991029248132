<div>
  <form [formGroup]="form">
    <mat-form-field  class="mat-input-without-padding">
      <mat-label>{{ "mailAccounts" | translate }}</mat-label>
      <mat-select
        formControlName="mailAccount"
        required
        [(value)]="selected.value"
        (selectionChange)="setMailAccountValue($event.value)"
      >
        @for(mailAccount of mailAccounts; track mailAccount) {
          <mat-option [value]="mailAccount.email">{{ mailAccount.email }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </form>
</div>
