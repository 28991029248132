<div class="flex flex-col gap-10">
  @if(hasTitle) {
    <div>
       <ngx-skeleton-loader
         [count]="1"
         [theme]="{
          height: '40px',
          'max-width': '65%'
          }"
       ></ngx-skeleton-loader>
    </div>
  }
  <div class="flex flex-col gap-1 overflow-hidden">
    @if(hasHeader) {
      <div>
        <ngx-skeleton-loader
          [count]="1"
          [theme]="{
            height: '152px',
          }"
        ></ngx-skeleton-loader>
      </div>
    }
    @for(field of fields; track field) {
      <div>
        <ngx-skeleton-loader
         [count]="1"
         [theme]="{
          'margin-bottom': 0,
          height: height,
        }"
        ></ngx-skeleton-loader>
      </div>
    }
  </div>
</div>
