import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { TranslateModule } from '@ngx-translate/core';
import { EmailNoteListComponent } from '../../../email/email-note-list/email-note-list.component';
import { EmailAuditComponent } from '../email-audit/email-audit.component';
import { EmailTemplatesComponent } from '../../../email/email-templates/email-templates.component';
import { EmailMenuContent } from '@app-types/enums/emails';
import { EmailNotificationsDialogComponent } from '../email-notifications-dialog/email-notifications-dialog.component';
import { WEB_CODE } from '@app-shared/constants/constants';
import { ActivatedRoute } from '@angular/router';
import { EmailAttachmentsComponent } from '../../../email/email-attachments/email-attachments.component';
import { EmailContract } from '@app-types/api/email';

@Component({
  selector: 'app-email-sidenav',
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    EmailNoteListComponent,
    EmailAuditComponent,
    EmailTemplatesComponent,
    EmailNotificationsDialogComponent,
    EmailAttachmentsComponent,
  ],
  templateUrl: './email-sidenav.component.html',
  styleUrls: ['./email-sidenav.component.scss'],
  animations: [
    trigger('slideFixedInOut', [
      state(
        'in',
        style({
          transform: 'translateX(0)',
        })
      ),
      state(
        'out',
        style({
          transform: 'translateX(100%)',
        })
      ),
      transition('in => out', [animate('300ms ease-in-out')]),
      transition('out => in', [animate('300ms ease-in-out')]),
    ]),
    trigger('slideInOut', [
      state(
        'in',
        style({
          width: '350px',
          marginRight: '0px',
        })
      ),
      state(
        'out',
        style({
          width: '0',
          marginRight: '-350px',
        })
      ),
      transition('in => out', [animate('300ms ease-in-out')]),
      transition('out => in', [animate('300ms ease-in-out')]),
    ]),
  ],
})
export class EmailSidenavComponent implements OnInit {
  @Input() useMobileView: boolean;
  @Input() public emailId?: number;
  @Input() public mailAccountId: number;
  @Input() public menuIsOpened = false;
  @Input() public menuContent: EmailMenuContent | null;
  @Input() public emailDetails: EmailContract;
  @Output() public refreshNotes = new EventEmitter();
  @Output() public templateSelected = new EventEmitter();
  @Output() public closeMenu = new EventEmitter();
  @Output() public openEmailDetails = new EventEmitter();

  public webCode = '';
  public menuContentTypes = EmailMenuContent;

  constructor(private activateRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.webCode =
      this.activateRoute.parent?.snapshot.paramMap.get(WEB_CODE) ?? '';
  }

  public get menuHeader(): string {
    switch (this.menuContent) {
      case this.menuContentTypes.Attachments:
        return 'attachments';
      case this.menuContentTypes.Notifications:
        return 'notifications';
      case this.menuContentTypes.Notes:
        return 'notes';
      case this.menuContentTypes.Templates:
        return 'templates';
      case this.menuContentTypes.Audit:
        return 'audit';
      default:
        return '';
    }
  }
}
