import { ValidationError } from '@app-types/api-errors/validation.error';
import { ErrorCode } from '@app-types/enums/error-code';

export class ApiException extends Error {
  public Code?: ErrorCode;
  public Description?: string;
  public Meta?: any;
  public Errors: ValidationError[] = [];
  public Status?: number;

  constructor(
    message?: string,
    errorCode?: number,
    description?: string,
    meta?: any,
    status?: number
  ) {
    super(message);
    this.Code = errorCode;
    this.Description = description;
    this.Meta = meta;
    this.Status = status;
    // see: typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html
    Object.setPrototypeOf(this, ApiException.prototype); // restore prototype chain
    this.name = ApiException.name; // stack traces display correctly now

    if (meta && meta.errors) {
      const validationErrors = meta.errors as ValidationError[];
      if (validationErrors) {
        this.Errors = validationErrors;
      }
    }
  }

  public format(): string {
    let errorsToString = '';
    if (this.Errors.length > 0) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.Errors.length; i++) {
        const err = this.Errors[i];
        // errorsToString += `${err.errorMessage} ${err.propertyName} ${err.attemptedValue}\n`;
        // dont show attempted value (can be password)
        errorsToString += `${err.errorMessage} ${err.propertyName}\n`;
      }
    } else {
      if (this.Meta) {
        errorsToString = this.Meta.toString();
      }
    }
    let msg = `Error code ${this.Code}, ${this.Description}`;
    if (errorsToString !== '') {
      msg += `${errorsToString}`;
    }
    return msg;
  }
}
