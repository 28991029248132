import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
} from '@angular/forms';
import {
  BaseCollectionSearchByIdRequest,
  BaseGetByIdRequest,
} from '@app-types/base/base';
import { GroupClient } from '@app-services/api/clients/group-settings.client';
import { UserGroupClient } from '@app-services/api/clients/user-group.client';
import { UserOrganizationGetOrganizationUsersContract } from '@app-types/api/user-organization';
import { MatDialog } from '@angular/material/dialog';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { TranslateModule } from '@ngx-translate/core';
import { SomethingWentWrongComponent } from '../../../../common/error/something-went-wrong/something-went-wrong.component';
import { DrawerService } from '@app-services/drawer.service';
import { FilterPipe } from '@app-pipes/filter.pipe';
import { MatButtonModule } from '@angular/material/button';
import { LoadingButtonComponent } from '../../../../common/loading-button/loading-button.component';
import { CheckableSearchFilterComponent } from '../../../../common/сheckable-search-filter/сheckable-search-filter.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { GroupCheckboxes } from '@app-types/common';
import { SkeletonFormDrawerComponent } from '@app-components/common/skeletons/skeleton-form-drawer/skeleton-form-drawer.component';

@Component({
  selector: 'app-add-users-to-groups-form',
  templateUrl: './add-users-to-groups-form.component.html',
  styleUrls: ['./add-users-to-groups-form.component.scss'],
  standalone: true,
  imports: [
    SomethingWentWrongComponent,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    LoadingButtonComponent,
    MatCheckboxModule,
    SkeletonFormDrawerComponent,
    TranslateModule,
    FilterPipe,
    CheckableSearchFilterComponent,
  ],
})
export class AddUsersToGroupsFormComponent implements OnInit, OnDestroy {
  @Input() public users: UserOrganizationGetOrganizationUsersContract[];
  @Input() public organizationID: number;
  @Output() private closeDrawer = new EventEmitter();
  @Output() private submitDrawer = new EventEmitter();
  public cannotLoadGroups = false;
  public isLoading = false;
  public isLoadingGroups = false;
  public isUploaded = false;

  public search = '';
  public filterList = [
    { label: 'allSelect', value: -1 },
    { label: 'showOnlySelectedSelect', value: 1 },
    { label: 'showOnlyNonSelectedSelect', value: 0 },
  ];
  public filters = new UntypedFormControl(this.filterList[0].value);

  public groups: GroupCheckboxes[] = [];
  public filteredGroups: GroupCheckboxes[] = [];

  constructor(
    private groupClient: GroupClient,
    public matchError: MatchError,
    private userGroupClient: UserGroupClient,
    public dialog: MatDialog,
    private drawerService: DrawerService
  ) {}

  ngOnInit(): void {
    this.fetchGroups();
  }

  public async onSubmit(): Promise<void> {
    this.isLoading = true;
    this.drawerService.disabledDrawer(true);
    const sourceIds = this.users.map(user => user.userId);
    const toIds = this.groups
      .filter(group => group._checked)
      .map(group => group.groupId);

    try {
      await this.userGroupClient.setUsersGroups({
        sourceIds,
        toIds,
      });
      this.drawerService.disabledDrawer(false);
      this.submitDrawer.emit();
      this.drawerService.closeDrawer();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
      this.drawerService.disabledDrawer(false);
    } finally {
      this.isLoading = false;
    }
  }

  async fetchGroups(): Promise<void> {
    this.isUploaded = false;
    const loaderTimeout = setTimeout(() => {
      this.isLoadingGroups = true;
    }, 500);

    try {
      // const totalReq = new BaseGetSearchCountByIdRequest(
      //   this.organizationID,
      //   ''
      // );
      // await this.groupClient.getSearchCounter(totalReq);
      const req: BaseCollectionSearchByIdRequest =
        new BaseCollectionSearchByIdRequest(this.organizationID, 1, 500, '');
      const { data } = await this.groupClient.getAllByOrganization(req);

      if (this.users.length === 1) {
        const userGroups = await this.getUserGroups();

        this.groups = data.map(group => {
          return { ...group, _checked: userGroups.includes(group.groupId) };
        });
      } else {
        this.groups = data.map(group => {
          return { ...group, _checked: false };
        });
      }

      this.filteredGroups = this.groups;
      this.cannotLoadGroups = false;
    } catch (e) {
      this.cannotLoadGroups = true;
      this.matchError.logError(e);
    } finally {
      clearTimeout(loaderTimeout);
      this.isUploaded = true;
      this.isLoadingGroups = false;
    }
  }

  private async getUserGroups(): Promise<number[]> {
    const req = new BaseGetByIdRequest(this.users[0].userId);
    const userGroups = await this.userGroupClient.getUserGroups(req);
    return userGroups.data;
  }

  isAllSelected(): boolean {
    const numSelected = this.groups.filter(group => group._checked).length;
    const numRows = this.groups.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected()
      ? this.groups.forEach(group => (group._checked = false))
      : this.groups.forEach(group => (group._checked = true));
  }

  public hasSelected(): boolean {
    let hasSelected = false;
    for (let i = 0; i < this.groups.length; i++) {
      if (this.groups[i]._checked) {
        hasSelected = true;
        break;
      }
    }
    return hasSelected;
  }

  onFilterSelectionChange(value: number): void {
    this.filteredGroups =
      value < 0
        ? this.groups
        : this.groups.filter(group =>
            value === 0 ? !group._checked : group._checked
          );
  }

  onSearchChange(value: string): void {
    this.search = value;
  }

  ngOnDestroy(): void {
    this.closeDrawer.emit();
  }
}
