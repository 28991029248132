import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExpressionOperation } from '@app-types/enums/expression.operation';
import { DispatchingRule, EmailCondition } from '@app-types/api/Dispatching';
import { TranslateModule } from '@ngx-translate/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { DispatchingConditionComponent } from './dispatching-condition/dispatching-condition.component';
import { SHOWING_CONDITIONS } from '@app-shared/constants/constants';
import { EmailClasses } from '@app-types/common';
import { MatTooltipModule } from '@angular/material/tooltip';

interface ShowingCondition {
  header: string;
  paramName: string;
}

@Component({
  selector: 'app-dispatching-conditions',
  templateUrl: './dispatching-conditions.component.html',
  styleUrls: ['./dispatching-conditions.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    MatExpansionModule,
    DispatchingConditionComponent,
    MatTooltipModule,
  ],
})
export class DispatchingConditionsComponent {
  @Input() applyToEmails: boolean;
  @Input() applyToNotifications: boolean;
  @Input() emailConditions: EmailCondition[];
  @Input() dispatchingRule: DispatchingRule;
  @Output() changeEmailClassesEventEmitter = new EventEmitter<EmailClasses>();
  public showingConditions: ShowingCondition[] = SHOWING_CONDITIONS;
  panelOpenState = true;

  constructor() {}

  public getDispatchingConditions(paramName: string): EmailCondition {
    const index = this.emailConditions.findIndex(
      c => c.paramName === paramName
    );
    if (index === -1) {
      this.emailConditions.push({
        paramName,
        operation: ExpressionOperation.Like,
        patterns: [],
      });
      return this.emailConditions[this.emailConditions.length - 1];
    }
    return this.emailConditions[index];
  }

  public onChangeEmailClasses(): void {
    this.changeEmailClassesEventEmitter.emit({
      applyToEmails: this.applyToEmails,
      applyToNotifications: this.applyToNotifications,
    });
  }

  public onPatternChange({ index, pattern }: any, paramName: string): void {
    const conditionIndex = this.emailConditions.findIndex(
      c => c.paramName === paramName
    );
    this.emailConditions[conditionIndex].patterns[index] = pattern;
  }
}
