@if(isLoading) {
  <app-skeleton-card-list [hasTitle]="!!title"></app-skeleton-card-list>
}

@if(cannotLoadNotes) {
  <div class="w-full h-full">
    <app-something-went-wrong
      [showPicture]="false"
      description="Cannot load notes"
      (retryEmitter)="loadAvailableData()"
    ></app-something-went-wrong>
  </div>
}

@if(!isLoading && !cannotLoadNotes) {
  <div class="drawer-content-screen notes">
    @if(title) {
      <div class="drawer-content-screen__title">{{ title | translate }}</div>
    }

    <div class="flex-none note mb-3">
      <div class="note-header justify-between">
        <div>
          <div class="p-1.5 flex flex-col text-gray-600">
            <div class="text-sm font-medium">{{ user.firstName + " " + user.lastName }}</div>
          </div>
        </div>
      </div>
      <mat-form-field class="notes-wrapper">
        <mat-label>{{ "newNote" | translate }}</mat-label>
        <textarea matInput #box></textarea>
      </mat-form-field>
      <div class="flex justify-end p-1.5 note-border">
        <button
          mat-flat-button
          color="primary"
          (click)="createNewNote(box.value); box.value = ''"
          matTooltip="{{ 'save' | translate }}"
          [disabled]="isLoadingCreate"
        >
          <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoadingCreate"></app-loading-button>
        </button>
      </div>
    </div>

    <div class="notes">
      @for(note of notes; track note) {
        <div
          class="note"
          [class.mat-elevation-z4]="noteIsBeingEdited(note.emailNoteId)"
          [class.animate-pulse]="noteIsUpdating(note.emailNoteId) || noteIsDeleting(note.emailNoteId)"
        >
          <div class="note-header" [class.justify-between]="canManageNote(note.userId)">
            <div class="p-1.5 flex flex-col text-gray-600">
              <div class="text-sm font-medium">{{ note?.userFirstName ?? '' }} {{ note?.userLastName ?? '' }}</div>
              <div class="text-xs">{{ note.noteDateTime | date: "dd/MM/yyyy HH:mm:ss" }}</div>
            </div>
          </div>
          <div class="note-border p-1.5">{{ note.note }}</div>
        </div>
      }
    </div>
  </div>
}


