<div class="drawer-content-screen">
  <div class="drawer-content-screen__title">
    {{ "createNewCompany" | translate }}
  </div>

  @if(cannotLoadParentOrganizations) {
    <div class="flex-col-center">
      <app-something-went-wrong
        [showPicture]="false"
        (retryEmitter)="ngOnInit()"
      ></app-something-went-wrong>
    </div>
  }

  @if(loadingParentOrganizations) {
    <div class="h-full">
      <app-skeleton-form-drawer></app-skeleton-form-drawer>
    </div>
  }

  @if(!loadingParentOrganizations && !cannotLoadParentOrganizations) {
    <div class="drawer-content-screen__body">
      <form class="flex flex-col w-full gap-6" [formGroup]="form" autocomplete="off" novalidate>
        <div class="flex flex-col w-full">
          <mat-form-field>
            <mat-label>{{ "code" | translate }}</mat-label>
            <input matInput id="code" formControlName="code" required />
            @if(hasError('code', 'required')) {
              <mat-error>{{ "codeIsRequired" | translate }}</mat-error>
            }
            @if(hasError('code', 'maxlength')) {
              <mat-error>{{ "moreThan265Char" | translate }}</mat-error>
            }
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ "name" | translate }}</mat-label>
            <input matInput id="name" formControlName="name" required />
            @if(hasError('name', 'required')) {
              <mat-error>{{ "nameIsRequired" | translate }}</mat-error>
            }
            @if(hasError('name', 'maxlength')) {
              <mat-error>{{ "moreThan265Char" | translate }}</mat-error>
            }
          </mat-form-field>
          <mat-form-field >
            <mat-label>{{ "webCode" | translate }}</mat-label>
            <input matInput id="webCode" formControlName="webCode" required placeholder="company or company-name" />
            @if(hasError('webCode', 'required')) {
              <mat-error>{{ "webCodeIsRequired" | translate }}</mat-error>
            }
            @if(hasError('webCode', 'maxlength')) {
              <mat-error>{{ "moreThan32Char" | translate }}</mat-error>
            }
            @if(hasError('webCode', 'pattern')) {
              <mat-error>{{ 'mustContainsOnlyLowercaseLetters' | translate }}</mat-error>
            }
          </mat-form-field>
          <mat-form-field >
            <mat-label>{{ "licenseId" | translate }}</mat-label>
            <input matInput id="licenseId" formControlName="licenseId" required />
            @if(hasError('licenseId', 'required')) {
              <mat-error>{{ "licenseIdIsRequired" | translate }}</mat-error>
            }
            @if(hasError('licenseId', 'maxlength')) {
              <mat-error>{{ "moreThan128Char" | translate }}</mat-error>
            }
          </mat-form-field>
          <mat-form-field >
            <mat-label>{{ "codeSia" | translate }}</mat-label>
            <input matInput id="codeSia" formControlName="codeSia" required />
            @if(hasError('codeSia', 'required')) {
              <mat-error>{{ "codeSiaIsRequired" | translate }}</mat-error>
            }
            @if(hasError('codeSia', 'maxlength')) {
              <mat-error>{{ "moreThanFifteenChar" | translate }}</mat-error>
            }
          </mat-form-field>
          <mat-form-field >
            <mat-label>{{ "anagId" | translate }}</mat-label>
            <input matInput id="anagId" formControlName="anagId" required />
            @if(hasError('anagId', 'required')) {
              <mat-error>{{ "anagIdIsRequired" | translate }}</mat-error>
            }
          </mat-form-field>
        </div>
        <div class="flex flex-col gap-3">
          <div class="drawer-content-screen__sub-title">{{ "authSettings" | translate }}</div>
          <div class="flex flex-col">
            <mat-checkbox
              [disabled]="credemISAMSupport"
              color="primary"
              [(ngModel)]="oidcSupport"
              [ngModelOptions]="{ standalone: true }"
            >
              {{ "GAW" | translate }}
            </mat-checkbox>
            <mat-checkbox
              [disabled]="credemISAMSupport"
              color="primary"
              [(ngModel)]="googleOAuthSupport"
              [ngModelOptions]="{ standalone: true }"
            >
              {{  "google" | translate }}
            </mat-checkbox>
            <mat-checkbox
              color="primary"
              (change)="onSamlChange($event.checked)"
              [(ngModel)]="credemISAMSupport"
              [ngModelOptions]="{ standalone: true }"
            >
              {{ "oidcCredem" | translate }}
            </mat-checkbox>
          </div>
          <mat-form-field class="w-full">
            <mat-label>{{ "oidcCredemRtaClaim" | translate }}</mat-label>
            <input matInput id="samlRtaClaim" formControlName="samlRtaClaim" />
            @if(hasError('oidcCredemRtaClaim', 'required')) {
              <mat-error>{{ "oidcCredemRtaClaimIsRequired" | translate }}</mat-error>
            }
            @if(hasError('oidcCredemRtaClaim', 'maxlength')) {
              <mat-error>{{ "moreThan265Char" | translate }}</mat-error>
            }
          </mat-form-field>
          @if(!authTypeIsSelected) {
            <app-error-hint
              errorText="{{ 'selectAtLeastOneTypeOfAuthenticationErrorHint' | translate }}"
              [centerAlign]="false"
            ></app-error-hint>
          }
        </div>
        <div class="flex flex-col gap-3">
          <div class="drawer-content-screen__sub-title">{{ "pignor" | translate }}</div>
          <mat-checkbox color="primary" [(ngModel)]="pignor" [ngModelOptions]="{ standalone: true }">{{ "pignor" | translate }}</mat-checkbox>
        </div>

        <div class="space-alert">
          <div class="drawer-content-screen__sub-title" >{{ "spacePaymentPlan" | translate }}</div>
          <div class="space-alert__body">
            <div class="space-alert__line">
              <span> {{ "totalSpaceOccupiesPerCompany" | translate }}</span>
              <mat-form-field>
                <mat-label>{{ "spacePaymentPlan" | translate }}</mat-label>
                <mat-select formControlName="spaceUsedPaymentPlanType">
                  @for(item of spaceUsedPaymentPlanType; track item) {
                    <mat-option [value]="item.value">{{ item.viewValue }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
            <div class="space-alert__subtext">
              {{ "thresholdForExtraGB" | translate }}
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="flex justify-end w-full">
      <button mat-flat-button color="primary" [disabled]="isLoading || !form.valid || !authTypeIsSelected" (click)="onSubmit(form.value)">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
      </button>
    </div>
  }
</div>
