import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@app-environments/environment';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import { ApiRequest } from '../api.request';
import { PermissionsOverResources } from '@app-types/api/permissions';

const API_URL = environment.apiUrl;
const { get } = API_ROUTES.permission;

@Injectable({
  providedIn: 'root',
})
export class PermissionClient {
  constructor(
    private http: HttpClient,
    private apiRequest: ApiRequest
  ) {}
  async get(): Promise<PermissionsOverResources> {
    return this.apiRequest.get<PermissionsOverResources>(`${API_URL}${get}`);
  }
}
