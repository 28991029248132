<div>
  <form [formGroup]="form">
    <app-email-tags
      style="width: 100%"
      [placeHolder]="'emailDispatchingRules'"
      [toList]="list"
      (changeList)="setToValue($event)"
      [showCc]="false"
      [isNeedMarginLeft]="false"
      [existedContacts]="[]"
    ></app-email-tags>

    @if(!isValidEmails()) {
      <mat-error>{{ "incorrectEmailDispatchingRules" | translate }}</mat-error>
    }
  </form>
</div>
