<div class="close-button">
  <button tabIndex="-1" class="" mat-icon-button (click)="cancel()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<h1 mat-dialog-title>{{ "reassignEmails" | translate }}</h1>
<mat-dialog-content class="mat-mdc-dialog-content mdc-dialog__content mat-container">
  @if(isLoading) {
    <div>
      <ngx-skeleton-loader
        [count]="1"
        [theme]="{
          'margin-bottom': 0,
          height: '82px',
          width: '100%',
        }"
      ></ngx-skeleton-loader>
    </div>
  }

  @if(cannotLoad) {
    <div>
      <app-something-went-wrong
        [showPicture]="false"
        (retryEmitter)="loadData()"
      ></app-something-went-wrong>
    </div>
  }

  @if(!isLoading && !cannotLoad) {
    <div>
      @if(selectedMailAccountId) {
        <mat-form-field>
          <mat-label>{{ "accountForReassign" | translate }}</mat-label>
          <mat-select [(value)]="selectedMailAccountId" (selectionChange)="onAccountChange()">
            @for(account of accounts; track account) {
              <mat-option [value]="account.mailAccountId">
                {{ account.name }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      }

      @if(cannotSave) {
        <app-error-hint
          errorText="{{ 'cannotReassignErrorPopupSubtitle' | translate }}"
          [centerAlign]="false"
        ></app-error-hint>
      }
    </div>
  }
</mat-dialog-content>
<div mat-dialog-actions align="end" class="mat-mdc-dialog-actions mdc-dialog__actions">
  <button
    mat-flat-button
    color="primary"
    (click)="confirm()"
    [disabled]="saving || isLoading || cannotLoad || isLoadingFolders"
  >
    <app-loading-button text="{{ 'moveReassign' | translate }}" [loading]="saving"></app-loading-button>
  </button>
</div>
