import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-checkable-search-filter',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './сheckable-search-filter.component.html',
  styleUrls: ['./сheckable-search-filter.component.scss'],
})
export class CheckableSearchFilterComponent {
  public filterList = [
    { label: 'allSelect', value: -1 },
    { label: 'showOnlySelectedSelect', value: 1 },
    { label: 'showOnlyNonSelectedSelect', value: 0 },
  ];
  public filters = new UntypedFormControl(this.filterList[0].value);

  @Input() search = '';
  @Output() filterSelectionChange = new EventEmitter<number>();
  @Output() searchChange = new EventEmitter<string>();

  constructor() {}

  onFilter($event: { value: number }): void {
    this.filterSelectionChange.emit($event.value);
  }

  onSearch($event?: Event): void {
    this.searchChange.emit(
      $event ? ($event.target as HTMLInputElement).value : ''
    );
  }
}
