import { environment } from '@app-environments/environment';
import { Injectable } from '@angular/core';
import { ApiRequest } from '../api.request';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import {
  BaseGetByIdRequest,
  BaseGetCollectionResponse,
} from '@app-types/base/base';
import { EmailTagSaveRequest, TagContract } from '@app-types/api/tag';

const API_URL = environment.apiUrl;
const { saveTags, getEmailTags } = API_ROUTES.emailTag;

@Injectable({ providedIn: 'root' })
export class EmailTagClient {
  constructor(private apiRequest: ApiRequest) {}

  async saveTags(request: EmailTagSaveRequest): Promise<void> {
    await this.apiRequest.post(request, `${API_URL}${saveTags}`);
  }

  async getEmailTags(
    request: BaseGetByIdRequest
  ): Promise<BaseGetCollectionResponse<TagContract>> {
    return await this.apiRequest.get(
      `${API_URL}${getEmailTags}?${request.format()}`
    );
  }
}
