import { Injectable } from '@angular/core';
import { environment } from '@app-environments/environment';
import { ApiRequest } from '../api.request';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import {
  BaseGetByIdRequest,
  BaseGetCollectionResponse,
} from '@app-types/base/base';
import { RemoteFolder } from '@app-types/api/remote-folder';

const API_URL = environment.apiUrl;
const { fetchRemoteFolders } = API_ROUTES.remote;

@Injectable({
  providedIn: 'root',
})
export class RemoteClient {
  constructor(private apiRequest: ApiRequest) {}

  async fetchRemoteFolders(
    request: BaseGetByIdRequest
  ): Promise<BaseGetCollectionResponse<RemoteFolder>> {
    return this.apiRequest.post<
      BaseGetByIdRequest,
      BaseGetCollectionResponse<RemoteFolder>
    >(request, `${API_URL}${fetchRemoteFolders}`);
  }
}
