import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { LoaderComponent } from '../loader/loader.component';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-loading-icon-button',
  templateUrl: './loading-icon-button.component.html',
  styleUrls: ['./loading-icon-button.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    LoaderComponent,
    MatIconModule,
    TranslateModule,
    MatTooltipModule,
  ],
})
export class LoadingIconButtonComponent {
  @Input() label: string;
  @Input() icon: string;
  @Input() showLoading: boolean;
  @Input() showButton: boolean;
  @Input() disabled: boolean;
  @Output() clickButton: EventEmitter<any> = new EventEmitter();

  constructor() {}

  clickEmit(): void {
    this.clickButton.emit();
  }
}
