export const CONNECTION_STATUS: {
  [ke: number]: {
    title: string;
    color: string;
    info: string;
  };
} = {
  1: {
    title: 'online',
    color: 'text-primary',
    info: '',
  },
  2: {
    title: 'offline',
    color: 'text-red-600',
    info: '',
  },
  3: {
    title: 'unknown',
    color: 'text-red-600',
    info: 'failedToCheckStatus',
  },
};

export const CONNECTION_STATUS_INFO: { [key: number]: string } = {
  1: 'failedConnect',
  2: 'failedImapConnect',
  3: 'failedSmtpConnect',
  4: 'failedAuthentication',
};
