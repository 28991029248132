import {
  Component,
  HostBinding,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { OrganizationClient } from '@app-services/api/clients/organization.client';
import { ActivatedRoute } from '@angular/router';
import { BaseGetByIdRequest } from '@app-types/base/base';
import { OrganizationContract } from '@app-types/api/organization';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { SomethingWentWrongComponent } from '@app-components/common/error/something-went-wrong/something-went-wrong.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DrawerService } from '@app-services/drawer.service';
import { OrganizationSettingsDialogComponent } from '@app-components/settings/organization/organization-settings-dialog/organization-settings-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ORGANIZATION_ID } from '@app-shared/constants/constants';
import { GeneralSettingsSkeletonComponent } from '@app-components/common/skeletons/general-settings-skeleton/general-settings-skeleton.component';

@Component({
  selector: 'app-organization-general-settings',
  templateUrl: './organization-general-settings.component.html',
  styleUrls: ['./organization-general-settings.component.scss'],
  standalone: true,
  imports: [
    SomethingWentWrongComponent,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    OrganizationSettingsDialogComponent,
    MatTooltipModule,
    GeneralSettingsSkeletonComponent,
  ],
})
export class OrganizationGeneralSettingsComponent implements OnInit {
  @HostBinding('class') className = 'setting-container';
  public isLoading = false;
  public isUploaded = false;
  public hasError = false;
  public statisticHasError = false;
  public organizationId: number;
  public organization: OrganizationContract;

  @ViewChild('settingsDrawer') settingsDrawer: TemplateRef<any>;

  constructor(
    public matchError: MatchError,
    private organizationClient: OrganizationClient,
    private route: ActivatedRoute,
    public drawerService: DrawerService
  ) {}

  async ngOnInit(): Promise<void> {
    this.organizationId = parseInt(
      this.route.snapshot.paramMap.get(ORGANIZATION_ID) as string,
      10
    );
    await this.loadOrganization();
  }

  public async retry(): Promise<void> {
    await this.loadOrganization();
  }

  public async loadOrganization(): Promise<void> {
    this.isUploaded = false;
    const loaderTimeout = setTimeout(() => {
      this.isLoading = true;
    }, 500);

    this.hasError = false;
    try {
      const response = await this.organizationClient.getById(
        new BaseGetByIdRequest(this.organizationId)
      );
      this.organization = response.organization;
    } catch (e) {
      this.hasError = true;
      this.matchError.logError(e);
    } finally {
      clearTimeout(loaderTimeout);
      this.isUploaded = true;
      this.isLoading = false;
    }
  }

  openOrganizationSettingsDialog(): void {
    this.drawerService.openDrawer(this.settingsDrawer);
  }
}
