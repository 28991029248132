import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BaseGetByIdRequest } from '@app-types/base/base';
import { CheckboxItem } from '@app-types/checkbox.item';
import { RemoteFolder } from '@app-types/api/remote-folder';
import { DispatchingRuleClient } from '@app-services/api/clients/dispatchingrule.client';
import { MailFolderContract } from '@app-types/api/mail-folder';
import { TreeItem } from '@app-types/tree.item';
import {
  DispatchingAction,
  DispatchingRule,
  DispatchingRuleObject,
  EmailCondition,
} from '@app-types/api/Dispatching';
import { EmailClass } from '@app-types/enums/email-class';
import { MatchError } from 'src/app/services/errors/error-matcher';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { MailFolderType } from 'src/app/types/enums/mail-folder.type';
import { MailAccountService } from 'src/app/services/mail-account/mail.account.service';
import { MailAccountGetByOrganizationIdContract } from 'src/app/types/api/mail-account';
import { Group } from '@app-types/api/group';
import { ErrorsPipe } from '@app-pipes/error-code.pipe';
import { LoadingButtonComponent } from '../../../../../common/loading-button/loading-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ErrorHintComponent } from '../../../../../common/error-hint/error-hint.component';
import { DispatchingFoldersComponent } from '../dispatching-folders/dispatching-folders.component';
import { DispatchingActionsComponent } from '../dispatching-actions/dispatching-actions.component';
import { DispatchingConditionsComponent } from '../dispatching-conditions/dispatching-conditions.component';
import { TAGS_REGEX } from '@app-shared/constants/regex';
import { DrawerService } from '@app-services/drawer.service';
import { EmailClasses } from '@app-types/common';
import { SomethingWentWrongComponent } from '@app-components/common/error/something-went-wrong/something-went-wrong.component';
import { SkeletonFormDrawerComponent } from '@app-components/common/skeletons/skeleton-form-drawer/skeleton-form-drawer.component';

@Component({
  selector: 'app-dispatching-rule-create-or-update-dialog',
  templateUrl: './dispatching-rule-create-or-update-dialog.component.html',
  styleUrls: ['./dispatching-rule-create-or-update-dialog.component.scss'],
  standalone: true,
  imports: [
    LoadingButtonComponent,
    TranslateModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    ErrorHintComponent,
    DispatchingFoldersComponent,
    DispatchingActionsComponent,
    DispatchingConditionsComponent,
    SkeletonFormDrawerComponent,
    SomethingWentWrongComponent,
  ],
})
export class DispatchingRuleCreateOrUpdateDialogComponent
  implements OnInit, OnDestroy
{
  @Input() public mailAccountId: number;
  @Input() public updatingDispatchingRule: DispatchingRule | null = null;
  @Output() public submitEventEmitter = new EventEmitter();
  @Output() public closeEventEmitter = new EventEmitter();
  public loadingAvailableData = false;
  public isUploaded = false;
  public cannotLoadAvailableData = false;
  public form = new UntypedFormGroup({});
  public availableFolders: RemoteFolder[];
  public availableActions: string[];
  public availableConditions: string[];
  public availableLocalFolders: MailFolderContract[] = [];
  public availableGroups: Group[] = [];
  public checkBoxGroups: CheckboxItem<Group>[] = [];
  public checkBoxFolders: CheckboxItem<RemoteFolder>[] = [];
  public dispatchingRule: DispatchingRule;
  public applyToEmails: boolean;
  public applyToNotifications: boolean;
  public isLoading = false;
  public errorText: string;
  public isShowError = false;
  public existingActionTypes: string[] = [];
  public condtionsBeforeSend: EmailCondition[] = [];
  public accounts: MailAccountGetByOrganizationIdContract[] = [];
  pipeErrors = new ErrorsPipe();
  constructor(
    private dispatchingRuleClient: DispatchingRuleClient,
    private mailAccountService: MailAccountService,
    public matchError: MatchError,
    private drawerService: DrawerService
  ) {}

  async ngOnInit(): Promise<void> {
    this.initDispatchingRule();
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(
        this.dispatchingRule.dispatchingRule?.name ?? '',
        [Validators.required, Validators.maxLength(256)]
      ),
      priority: new UntypedFormControl(
        this.dispatchingRule.dispatchingRule?.priority,
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.min(1),
          Validators.max(10000),
        ]
      ),
    });
    await this.loadAvailableData();
    this.applyToEmails =
      this.dispatchingRule.dispatchingRule?.matchExpression?.emailClasses?.find(
        value => value === EmailClass.Email
      ) !== undefined;
    this.applyToNotifications =
      this.dispatchingRule.dispatchingRule?.matchExpression?.emailClasses?.find(
        value => value === EmailClass.Notification
      ) !== undefined;
  }

  public get isCreation(): boolean {
    return this.updatingDispatchingRule == null;
  }

  private groupChecked(groupId: number): boolean {
    const assignToGroups = this.dispatchingRule.dispatchingRule?.actions.find(
      f => f.name === 'AssignToGroups'
    );
    return assignToGroups
      ? assignToGroups.args?.find(y => Number(y.value) === groupId) !==
          undefined
      : assignToGroups !== undefined;
  }

  private folderChecked(name: string): boolean {
    return (
      this.dispatchingRule.dispatchingRule?.folders?.find(
        f => f.name === name
      ) !== undefined
    );
  }

  public initDispatchingRule(): void {
    if (!this.updatingDispatchingRule) {
      this.dispatchingRule = {};
      this.dispatchingRule.dispatchingRule = new DispatchingRuleObject();
    } else {
      this.dispatchingRule = JSON.parse(
        JSON.stringify(this.updatingDispatchingRule)
      );
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

  async onSubmit(): Promise<void> {
    this.submitEventEmitter.emit();
  }

  async cancel(): Promise<void> {
    this.closeEventEmitter.emit();
  }

  async loadAccounts(mailAccountId: number): Promise<void> {
    this.accounts =
      await this.mailAccountService.getAccountsForAccountOrganization(
        mailAccountId
      );
  }

  async loadAvailableData(): Promise<any> {
    this.errorText = '';
    this.isUploaded = false;
    const loaderTimeout = setTimeout(() => {
      this.loadingAvailableData = true;
    }, 500);

    this.cannotLoadAvailableData = false;
    try {
      this.loadAccounts(this.mailAccountId);
      const data = await this.dispatchingRuleClient.getAvailableDataForAccount(
        new BaseGetByIdRequest(this.mailAccountId)
      );
      this.availableFolders = data.result.availableFolders;
      this.availableGroups = data.result.availableGroups;
      this.availableActions = data.result.availableActions;
      this.existingActionTypes = [...this.availableActions];
      const actions = this.dispatchingRule.dispatchingRule?.actions.map(
        e => e.name
      );
      this.existingActionTypes = this.availableActions.filter(
        e => !actions?.some(x => x === e)
      );
      this.availableConditions = data.result.availableConditions;
      data.result.availableLocalFolders.forEach(ti => {
        ti.data && this.availableLocalFolders.push(ti.data);
        if (ti.children && ti.children.length > 0) {
          this.addChildrenFolders(ti.children, 'ᅠ');
        }
      });
      const folderTypeOrder = [
        MailFolderType.Inbox,
        MailFolderType.Sent,
        MailFolderType.Archive,
        MailFolderType.Outbox,
        MailFolderType.Validation,
        MailFolderType.Draft,
        MailFolderType.Trash,
        MailFolderType.Spam,
      ];
      this.availableLocalFolders.sort(
        (a, b) =>
          folderTypeOrder.indexOf(a.folderType) -
          folderTypeOrder.indexOf(b.folderType)
      );

      this.availableGroups.forEach(f => {
        const newItem = new CheckboxItem<Group>(
          f,
          f.group,
          this.groupChecked(f.groupId)
        );
        this.checkBoxGroups.push(newItem);
      });

      this.availableFolders.forEach(f => {
        const newItem = new CheckboxItem<RemoteFolder>(
          f,
          f.name,
          this.folderChecked(f.name)
        );
        this.checkBoxFolders.push(newItem);
      });
    } catch (e: any) {
      this.errorText =
        this.pipeErrors.transform(e.Code) ||
        `Error code: ${e.Code}: ${e.Description || 'something went wrong'}`;
      this.cannotLoadAvailableData = true;
      this.matchError.logError(e);
    } finally {
      clearTimeout(loaderTimeout);
      this.isUploaded = true;
      this.loadingAvailableData = false;
    }
  }

  private addChildrenFolders(
    children: TreeItem<number, MailFolderContract>[],
    pad: string
  ): void {
    children.forEach(ti => {
      ti.data?.name && (ti.data.name = `${pad}${ti.data.name}`);
      ti.data && this.availableLocalFolders.push(ti.data);
      if (ti.children && ti.children.length > 0) {
        this.addChildrenFolders(ti.children, `${pad}ᅠ`);
      }
    });
  }

  public addAction(): void {
    this.existingActionTypesUpdate();
    if (this.existingActionTypes.length !== 0) {
      if (!this.dispatchingRule.dispatchingRule?.actions) {
        this.dispatchingRule.dispatchingRule &&
          (this.dispatchingRule.dispatchingRule.actions = []);
      }
      const action: DispatchingAction = {};
      if (this.availableActions[0]) {
        action.name = this.existingActionTypes[0];
      }
      action.args = [];
      this.dispatchingRule.dispatchingRule?.actions.push(action);
      this.existingActionTypesUpdate();
    }
  }

  public existingActionTypesUpdate(): void {
    if (this.dispatchingRule.dispatchingRule?.actions) {
      const actions = this.dispatchingRule.dispatchingRule.actions.map(
        e => e.name
      );
      this.existingActionTypes = this.availableActions.filter(
        e => !actions.some(x => x === e)
      );
    }
  }

  public deleteAction(index: number): void {
    this.dispatchingRule.dispatchingRule?.actions &&
      this.dispatchingRule.dispatchingRule.actions.splice(index, 1);
    this.existingActionTypesUpdate();
  }

  public onChangeEmailClasses({
    applyToEmails,
    applyToNotifications,
  }: EmailClasses): void {
    if (this.dispatchingRule.dispatchingRule?.matchExpression) {
      this.dispatchingRule.dispatchingRule.matchExpression.emailClasses = [];
      if (applyToEmails) {
        this.dispatchingRule.dispatchingRule.matchExpression.emailClasses.push(
          EmailClass.Email
        );
        this.applyToEmails = applyToEmails;
      }
      if (applyToNotifications) {
        this.dispatchingRule.dispatchingRule.matchExpression.emailClasses.push(
          EmailClass.Notification
        );
        this.applyToEmails = applyToNotifications;
      }
    }
  }

  public dispatchingRuleIsValid(): boolean {
    return (
      this.form.valid &&
      this.selectedAtLeastOneFolder() &&
      this.conditionsAreValid() &&
      this.selectedAtLeastOneAction() &&
      this.differentActionsIsValid() &&
      this.actionsIsValid()
    );
  }

  private conditionsAreValid(): boolean {
    const matchExpression =
      this.dispatchingRule.dispatchingRule?.matchExpression?.conditions;
    if (!matchExpression) return false;
    for (const condition of matchExpression) {
      for (const pattern of condition.patterns) {
        if (!this.patternIsValid(pattern)) {
          return false;
        }
      }
    }
    return true;
  }

  patternIsValid(pattern: string): boolean {
    return pattern.length <= 256;
  }

  selectedAtLeastOneFolder(): boolean {
    return !!this.dispatchingRule.dispatchingRule?.folders.length;
  }

  public selectedAtLeastOneAction(): boolean {
    return !!this.dispatchingRule.dispatchingRule?.actions.length;
  }

  public differentActionsIsValid(): boolean {
    const arr: string[] = [];

    this.dispatchingRule.dispatchingRule?.actions.forEach(rule => {
      if (rule.name && this.availableActions.includes(rule.name)) {
        arr.push(rule.name);
      }
    });

    return !!arr.length && arr[0] !== arr[1];
  }

  private actionsIsValid(): boolean {
    const actions = this.dispatchingRule.dispatchingRule?.actions;
    if (!actions) {
      return false;
    }
    for (const action of actions) {
      if (action.name === 'ForwardMail') {
        if (!action.args) {
          return false;
        }
        for (const forwardToArg of action.args) {
          const isAllValid = forwardToArg.value
            .replace(/\s+/g, '')
            .split(';')
            .every(e => this.isValidTag(e));

          if (!isAllValid) {
            return false;
          }
        }
      }
    }
    return true;
  }

  public isValidTag(value: string): boolean {
    return !!String(value).toLowerCase().match(TAGS_REGEX);
  }

  public async submit(): Promise<void> {
    if (!this.dispatchingRuleIsValid()) {
      this.isShowError = true;
      this.form.controls.name.markAsTouched();
      this.form.controls.priority.markAsTouched();
      return;
    }

    this.isLoading = true;
    this.drawerService.disabledDrawer(true);
    try {
      if (this.isCreation) {
        await this.createDispatchingRule();
      } else {
        await this.updateDispatchingRule();
      }
      this.drawerService.disabledDrawer(false);
      this.submitEventEmitter.emit();
      this.drawerService.closeDrawer();
    } catch (e) {
      this.dispatchingRule.dispatchingRule?.matchExpression &&
        (this.dispatchingRule.dispatchingRule.matchExpression.conditions =
          this.condtionsBeforeSend);
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
      this.drawerService.disabledDrawer(false);
    } finally {
      this.isLoading = false;
    }
  }

  public onChangeContinueNextRules(event: MatCheckboxChange): void {
    this.dispatchingRule?.dispatchingRule?.options &&
      (this.dispatchingRule.dispatchingRule.options.continueNextRulesExecutionOnMatch =
        event.checked);
  }

  private async createDispatchingRule(): Promise<void> {
    this.condtionsBeforeSend =
      this.dispatchingRule.dispatchingRule?.matchExpression?.conditions ?? [];
    this.dispatchingRule.dispatchingRule?.matchExpression &&
      (this.dispatchingRule.dispatchingRule.matchExpression.conditions =
        this.dispatchingRule.dispatchingRule.matchExpression.conditions.map(
          e => {
            return {
              ...e,
              patterns: e.patterns.filter(x => x !== '').map(el => el.trim()),
            };
          }
        ));
    this.mailAccountId &&
      this.dispatchingRule.dispatchingRule &&
      (await this.dispatchingRuleClient.create({
        mailAccountId: this.mailAccountId,
        dispatchingRule: this.dispatchingRule.dispatchingRule,
      }));
  }

  private async updateDispatchingRule(): Promise<void> {
    this.condtionsBeforeSend =
      this.dispatchingRule.dispatchingRule?.matchExpression?.conditions ?? [];
    this.dispatchingRule.dispatchingRule?.matchExpression &&
      (this.dispatchingRule.dispatchingRule.matchExpression.conditions =
        this.dispatchingRule.dispatchingRule.matchExpression.conditions.map(
          e => {
            return {
              ...e,
              patterns: e.patterns.filter(x => x !== '').map(el => el.trim()),
            };
          }
        ));
    this.dispatchingRule.dispatchingRuleId &&
      this.dispatchingRule.dispatchingRule &&
      (await this.dispatchingRuleClient.update({
        dispatchingRuleId: this.dispatchingRule.dispatchingRuleId,
        dispatchingRule: this.dispatchingRule.dispatchingRule,
      }));
  }

  ngOnDestroy(): void {
    this.closeEventEmitter.emit();
  }
}
