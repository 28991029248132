import { Injectable } from '@angular/core';
import { AuthenticationClient } from '../api/clients/authentication.client';
import { PermissionService } from '../permission/permission.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { LoggingHandler } from '../errors/logging.service';
import { AuthenticationType } from '@app-types/enums/authentication-type';
import { AuthenticationRequest } from '@app-types/api/authentication';
import { Router } from '@angular/router';
import { MatchError } from '../errors/error-matcher';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  constructor(
    private authenticationClient: AuthenticationClient,
    private permissionService: PermissionService,
    private cookieService: CookieService,
    protected loggingHandler: LoggingHandler,
    protected router: Router,
    protected matchError: MatchError
  ) {}

  public async loadAuthTypes(
    webCode: string
  ): Promise<AuthenticationType[] | void> {
    try {
      const request = new AuthenticationRequest(webCode);
      const response =
        await this.authenticationClient.getAuthenticationTypes(request);
      return response.data;
    } catch (e: any) {
      if (e.Code === 66) {
        await this.loggingHandler.create(
          'information',
          'redirect loadAuthTypes e.Code === 66'
        );
        await this.router.navigateByUrl(`/`);
      } else {
        await this.loggingHandler.create(
          'information',
          'redirect loadAuthTypes e.Code !== 66'
        );
        await this.router.navigateByUrl(`/${webCode}/500`);
      }
      this.matchError.logError(e);
    }
  }

  public redirectAuth(
    authTypes: AuthenticationType[],
    webCode: string
  ): boolean {
    if (authTypes.length === 1) {
      if (authTypes[0] === AuthenticationType.Oidc) {
        this.loggingHandler.create(
          'information',
          'redirect authTypes[0] === AuthenticationType.Oidc'
        );
        document.location.href = `${environment.oidcSignInUri}?webCode=${webCode}`;
      }
      if (authTypes[0] === AuthenticationType.Google) {
        this.loggingHandler.create(
          'information',
          'redirect authTypes[0] === AuthenticationType.Google'
        );
        document.location.href = `${environment.googleSignInUri}?webCode=${webCode}`;
      }
      if (authTypes[0] === AuthenticationType.OidcCredem) {
        this.loggingHandler.create(
          'information',
          'redirect authTypes[0] === AuthenticationType.OidcCredem'
        );
        document.location.href = `${environment.oidcCredemSignInUri}?webCode=${webCode}`;
      }
    } else {
      this.loggingHandler.create(
        'information',
        `redirect !authTypes redirectAuth ${authTypes} ${webCode} ${JSON.stringify(authTypes)}`
      );
      this.router.navigate([`/${webCode}/sign-in`], {
        queryParams: { otherData: JSON.stringify(authTypes) },
      });
    }
    return false;
  }

  async logout(webCode: string): Promise<void> {
    document.location.href = `${environment.feUrl}signout?webCode=${webCode}`;
  }

  getJwtTokenFromCookie(): boolean {
    const cookies = this.cookieService.get(environment.cookieAnchor);
    return cookies ? JSON.parse(cookies) : false;
  }

  isLoggedIn(): boolean {
    return this.getJwtTokenFromCookie();
  }
}
