<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<mat-dialog-content class="mat-mdc-dialog-content mdc-dialog__content mat-container">
  <p style="overflow-wrap: anywhere">
    @if(data.description==='messagesNotDeleted' && data.value) {
      <span>{{ data.value }}</span>
    }
    {{ data.description | translate }}</p>
</mat-dialog-content>
<mat-dialog-actions class="mat-mdc-dialog-actions mdc-dialog__actions">
  <div class="flex justify-end">
    <button
      mat-flat-button
      color="primary"
      (click)="ok()"
    >
      {{ "ok" | translate }}
    </button>
  </div>
</mat-dialog-actions>
