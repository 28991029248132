import { Injectable } from '@angular/core';
import { isJSON } from '@app-utils/common';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  getData(key: string): any {
    const data = localStorage.getItem(key);
    try {
      return (
        !!data &&
        (isJSON(data) ? JSON.parse(data) : JSON.parse(JSON.stringify(data)))
      );
    } catch (e) {
      console.log(e);
      return !!data && data;
    }
  }

  setData(key: string, value: any): any {
    const stringValue =
      typeof value === 'string' ? value : JSON.stringify(value);
    localStorage.setItem(key, stringValue);
  }

  removeData(key: string): any {
    localStorage.removeItem(key);
  }
}
