import { Injectable } from '@angular/core';
import { DocViewerComponent } from '@app-components/common/doc-viewer/doc-viewer.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(public dialog: MatDialog) {}

  createObjectURL(data: ArrayBuffer, type: string): string {
    const blob = new Blob([data], {
      type,
    });
    return URL.createObjectURL(blob);
  }

  downloadFile(fileURL: string, filename: string): void {
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = filename;
    link.click();
  }

  openDocViewer(fileURL: string, filename: string, type: string): void {
    this.dialog.open(DocViewerComponent, {
      height: 'calc(100% - 30px)',
      width: 'calc(100% - 30px)',
      maxWidth: '100%',
      maxHeight: '100%',
      autoFocus: false,
      data: {
        url: fileURL,
        filename,
        isPdf: type === 'application/pdf',
      },
    });
  }

  downloadWithPreview(response: {
    file: ArrayBuffer;
    filename: string;
    type: string;
  }): void {
    const fileURL = this.createObjectURL(response.file, response.type);

    if (
      response.type === 'application/pdf' ||
      response.type.includes('image')
    ) {
      this.openDocViewer(fileURL, response.filename, response.type);
      return;
    }
    this.downloadFile(fileURL, response.filename);
  }
}
