<div class="drawer-content-screen w-full">
  @if(hasTitle) {
    <div class="pr-2">
      <ngx-skeleton-loader
        [count]="1"
        [theme]="{
          'margin-bottom': 0,
          height: '40px',
          'max-width': '50%',
        }"
      ></ngx-skeleton-loader>
    </div>
  }
  <div class="flex flex-col pr-2">
    <div>
      <ngx-skeleton-loader
        [count]="1"
        [theme]="{
        'margin-bottom': 0,
        height: '32px',
      }"
      ></ngx-skeleton-loader>
    </div>
    <div>
      <ngx-skeleton-loader
         [count]="1"
         [theme]="{
          'margin-bottom': 0,
          height: '32px',
        }"
        ></ngx-skeleton-loader>
    </div>
    <div>
      <ngx-skeleton-loader
        [count]="1"
        [theme]="{
        'margin-bottom': 0,
         height: '32px',
      }"
      ></ngx-skeleton-loader>
    </div>
    <div>
      <ngx-skeleton-loader
        [count]="1"
        [theme]="{
        'margin-bottom': 0,
        height: '32px',
      }"
      ></ngx-skeleton-loader>
    </div>
    <div>
      <ngx-skeleton-loader
        [count]="1"
        [theme]="{
          'margin-bottom': 0,
          height: '32px',
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>
  <div class="drawer-content-screen__body !overflow-y-hidden">
    <ngx-skeleton-loader
      [count]="1"
      [theme]="{
          'margin-bottom': 0,
          height: '100%',
        }"
    ></ngx-skeleton-loader>
  </div>
  <div class="flex gap-3 justify-between pr-2">
    <div class="w-[25%]">
      <ngx-skeleton-loader
        [count]="1"
        [theme]="{
            'margin-bottom': 0,
            height: '36px',
            width: '100%',
            }"
      ></ngx-skeleton-loader>
    </div>
    <div class="w-[35%]">
      <ngx-skeleton-loader
        [count]="1"
        [theme]="{
              'margin-bottom': 0,
              width: '100%',
              height: '36px'
              }"
      ></ngx-skeleton-loader>
    </div>
  </div>
</div>
