<div class="w-full">
  <form [formGroup]="form" autocomplete="off" class="flex flex-col gap-4 w-full xl:w-[50%] py-[1rem]" novalidate>
    <h1 class="mb-4">{{ "reports" | translate }}</h1>
    <mat-form-field class="w-full">
      <mat-label>{{ "reportType" | translate }}</mat-label>
      <mat-select formControlName="reportType" required>
        @for(report of reportTypes; track report) {
          <mat-option [value]="report.value">
            {{ report.name | translate }}
          </mat-option>
        }
      </mat-select>
      @if(form.controls['reportType'].hasError('required')) {
        <mat-error>{{ "chooseAnReport" | translate }}</mat-error>
      }
    </mat-form-field>

    <mat-form-field  class="w-full">
      <mat-label>{{ "accountReports" | translate }}</mat-label>
      <mat-select formControlName="mailsControl" #select multiple>
        @if(accounts.length) {
          <mat-option #allSelected (click)="toggleAllSelection()" [value]="0">{{ "all" | translate }}</mat-option>
        }
        @for(account of accounts; track account) {
          <mat-option (click)="togglePerOne()" [value]="account.mailAccountEmail">
            {{ account.mailAccountEmail }}
          </mat-option>
        }
      </mat-select>
      @if(form.controls.mailsControl.hasError('required')) {
        <mat-error>{{ "chooseAnAccount" | translate }}</mat-error>
      }
    </mat-form-field>

    <mat-form-field  class="w-full">
      <mat-label>{{ "enterADateRange" | translate }}</mat-label>
      <mat-date-range-input formGroupName="range" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start date" />
        <input matEndDate formControlName="end" placeholder="End date" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
      @if(hasErrorReports('matStartDateInvalid')) {
        <mat-error>
          {{ "invalidStartDate" | translate }}
        </mat-error>
      }
      @if(hasErrorReports('matEndDateInvalid')) {
        <mat-error>{{ "invalidEndDate" | translate }}</mat-error>
      }
    </mat-form-field>
    <button
      class="self-end"
      mat-flat-button
      color="primary"
      [disabled]="isSendingRequest || !form.valid"
      (click)="onSubmit()"
    >
      <app-loading-button text="{{ 'generate' | translate }}" [loading]="isSendingRequest"></app-loading-button>
    </button>
  </form>
</div>
