@if(isError && !isLoading) {
  <div class="h-[80%]">
    <app-something-went-wrong
      [showPicture]="false"
      (retryEmitter)="loadTemplates(true)"
    ></app-something-went-wrong>
  </div>
}

@if(!isError) {
  <div class="flex-col-container-1">
    <div class="search-panel">
      <div class="button-group">
        <button
          mat-raised-button
          color="primary"
          class="page-block-button"
          aria-label="Add template"
          (click)="openCreateModelForm()"
        >
          <mat-icon class="{{useMobileView ? '!m-0' : '' }}">add</mat-icon>{{  useMobileView ? '' : ("newTemplate" | translate) }}
        </button>
      </div>
      <div class="search-bar">
        <app-search-bar
          [fullWidth]="useMobileView"
          (searchEventEmitter)="loadTemplates(true)"
          (valueChangedEventEmitter)="onSearchValueChanged($event)"
          [search]="searchValue"
        ></app-search-bar>
        @if(!useMobileView) {
          <mat-paginator
            [pageSize]="pageSize"
            [pageSizeOptions]="[25, 50, 100]"
            [length]="totalSize"
            [pageIndex]="pageIndex"
            (page)="handlePage($event)">
          </mat-paginator>
        }
      </div>
    </div>
  </div>
}
@if(useMobileView) {
  <div>
    <mat-paginator
      [pageSize]="pageSize"
      [pageSizeOptions]="[25, 50, 100]"
      [length]="totalSize"
      [pageIndex]="pageIndex"
      (page)="handlePage($event)">
    </mat-paginator>
    <div class="mobile-container-cards">
      @if(!isLoading && !isError && isUploaded) {
        @for(template of templates; track template) {
          <div>
            <div class="card">
              <mat-card class="mobile-row">
                <mat-card-content (click)="openEditModelForm(template)" class="flex flex-1">
                  <div class="card-table-row">
                    <div class="card-table-row-header">{{ "templateName" | translate }}:</div>
                    <div class="card-table-row-value">{{ template.name }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">{{ "templateDesc" | translate }}:</div>
                    <div class="card-table-row-value">{{ template.description }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">{{ "modifyDate" | translate }}:</div>
                    <div class="card-table-row-value">{{ template?.modifyDate ? (template.modifyDate | date : "dd/MM/yyyy HH:mm:ss") : '' }}</div>
                  </div>
                </mat-card-content>
                <div class="icons-mobile-block">
                  <button mat-icon-button aria-label="Edit" matTooltip="{{ 'edit' | translate }}" (click)="openEditModelForm(template)">
                    <mat-icon>edit_outline</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    matTooltip="{{ 'delete' | translate }}"
                    color="primary"
                    aria-label="Delete"
                    (click)="openConfirmDialog(template.templateId, template.name)">
                    <mat-icon>delete_outline</mat-icon>
                  </button>
                </div>
              </mat-card>
            </div>
          </div>
        }
      }
    </div>
    @if(templates.length === 0 && !isLoading && !isError && isUploaded) {
      <app-no-data></app-no-data>
    }
  </div>
}

@if(!useMobileView) {
  <div class="table-container">
    @if(!isLoading && !isError && isUploaded) {
      <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ "templateName" | translate }}</th>
        <td mat-cell *matCellDef="let template">{{ template.name }}</td>
      </ng-container>
      <ng-container matColumnDef="desc">
        <th mat-header-cell *matHeaderCellDef>{{ "templateDesc" | translate }}</th>
        <td mat-cell *matCellDef="let template">{{ template.description }}</td>
      </ng-container>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>{{ "modifyDate" | translate }}</th>
        <td mat-cell *matCellDef="let template">{{ template.modifyDate | date : "dd/MM/yyyy HH:mm:ss" }}</td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ "actions" | translate }}</th>
        <td mat-cell *matCellDef="let template">
          <button
            mat-icon-button
            [attr.aria-label]="'modifyTemplate' | translate"
            matTooltip="{{ 'modifyTemplate' | translate }}"
            (click)="openEditModelForm(template)"
          >
            <mat-icon>edit_outline</mat-icon>
          </button>
          <button
            mat-icon-button
            aria-label="Delete"
            matTooltip="{{ 'delete' | translate }}"
            (click)="openConfirmDialog(template.templateId, template.name)"
          >
            <mat-icon>delete_outline</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" class="cursor-default"></tr>
    </table>

      @if(templates.length === 0) {
        <app-no-data></app-no-data>
      }
    }
  </div>
}
@if(isLoading) {
  <div class="overflow-hidden flex-1">
    <app-skeleton-table></app-skeleton-table>
  </div>
}

<ng-template #createDrawer>
  <app-mail-account-template-create-dialog
    (closeEventEmitter)="onCloseCreateModalForm()"
    (submitEventEmitter)="editingTemplateId ? onEditTemplate() : onCreateTemplate()"
    [mailAccountId]="mailAccountId"
    [templateId]="editingTemplateId"
  ></app-mail-account-template-create-dialog>
</ng-template>
