import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  MatAutocomplete,
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { map, startWith } from 'rxjs/operators';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-email-search-tag-input',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatChipsModule,
    TranslateModule,
    AsyncPipe,
    ReactiveFormsModule,
  ],
  templateUrl: './email-search-tag-input.component.html',
  styleUrls: ['./email-search-tag-input.component.scss'],
})
export class EmailSearchTagInputComponent {
  @Input() existedTags: string[];
  @Input() selectedTags = new Set<string>();
  tagCtrl = new UntypedFormControl();
  filteredTags: Observable<string[]>;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  constructor() {
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) =>
        tag ? this._filter(tag) : this.existedTags.slice()
      )
    );
  }

  removeTag(tag: string): void {
    const hasTag = this.selectedTags.has(tag);
    if (hasTag) {
      this.selectedTags.delete(tag);
    }
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    if (input) {
      input.value = '';
    }
    this.tagCtrl.setValue(null);
  }

  selectedTag(event: MatAutocompleteSelectedEvent): void {
    this.selectedTags.add(event.option.value);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.existedTags.filter(
        tag => tag.toLowerCase().indexOf(filterValue) === 0
      );
    } else {
      const filterValue = value.toLowerCase();
      return this.existedTags.filter(
        tag => tag.toLowerCase().indexOf(filterValue) === 0
      );
    }
  }
}
