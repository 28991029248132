<div class="drawer-content-screen">
  <div class="flex flex-col gap-10">
    <div class="drawer-content-screen__title">{{ group ? ("updateGroup" | translate) : ("addGroupToCompany" | translate) }}</div>
    <form [formGroup]="form">
      <mat-form-field class="w-full">
        <mat-label>{{ "name" | translate }}: </mat-label>
        <input matInput id="name" formControlName="name" required />
        @if(form.get('name')?.hasError('required')) {
          <mat-error>{{ "nameIsRequired" | translate }}</mat-error>
        }
        @if(form.get('name')?.hasError('maxlength')) {
          <mat-error>{{ "moreThan255Char" | translate }}</mat-error>
        }
      </mat-form-field>
    </form>
  </div>

  <div class="flex justify-end w-full">
    <button mat-flat-button color="primary" [disabled]="isLoading || !form.valid" (click)="save()">
      <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
    </button>
  </div>
</div>
