<div class="drawer-content-screen">
  @if(isAuditLoadingError) {
    <div class="h-full w-full">
      <app-something-went-wrong
        [showPicture]="false"
        (retryEmitter)="ngOnInit()"
      ></app-something-went-wrong>
    </div>
  }
  @if(isAuditLoading) {
    <app-skeleton-card-list [hasTitle]="!!title" [hasHeader]="false"></app-skeleton-card-list>
  }

  @if(!isAuditLoading && !isAuditLoadingError) {
    @if(title) {
      <div class="drawer-content-screen__title">{{ title | translate }}</div>
    }
    <div class="drawer-content-screen__body">
      <div class="notes h-full">
        @for(event of events; track event) {
          <div class="note">
            <div [class.audit-error]="isAuditWithError(event)" class="note-header">
              <div class="p-1.5 flex flex-col text-gray-600">
                <div class="text-sm font-medium">{{ event.type | emailAuditEventType | translate }}</div>
                <div class="text-xs">{{ (event?.creationDateTime ?? '') | date : "dd/MM/yyyy HH:mm:ss" }}</div>
              </div>
            </div>
            @if(event.metadata) {
              <div
               [class.audit-error]="isAuditWithError(event)"
               style="border-bottom: none; max-height: 120px; overflow: auto; white-space: pre-line" class="audit-border p-1.5"
              >
                {{ event.metadata }}
              </div>
            }
            <div [class.audit-error]="isAuditWithError(event)" class="audit-border p-1.5">
              {{ (event?.userFirstName ?? '') + " " + (event?.userLastName ?? '') }}
            </div>
          </div>
        }
      </div>
    </div>
  }
</div>
