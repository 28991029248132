import { Injectable } from '@angular/core';
import { environment } from '@app-environments/environment';
import { ApiRequest } from '../api.request';
import {
  BaseCollectionSearchByIdRequest,
  BaseGetCollectionResponse,
  BaseGetSearchCountByIdRequest,
} from '@app-types/base/base';
import {
  GetSearchCounter,
  Group,
  GroupCreateUpdateRequest,
  GroupDeleteRequest,
  GroupGetForOrganizationResponse,
} from '@app-types/api/group';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';

const API_URL = environment.apiUrl;
const { groupSettings } = API_ROUTES;
const { getForOrganization, create, update, getSearchCounter } = groupSettings;

@Injectable({
  providedIn: 'root',
})
export class GroupClient {
  constructor(private apiClient: ApiRequest) {}

  public async getAllByOrganization(
    request: BaseCollectionSearchByIdRequest
  ): Promise<BaseGetCollectionResponse<Group>> {
    return this.apiClient.get<GroupGetForOrganizationResponse>(
      `${API_URL}${getForOrganization}?${request.format()}`
    );
  }

  public async create(request: GroupCreateUpdateRequest) {
    return this.apiClient.post<GroupCreateUpdateRequest, any>(
      request,
      `${API_URL}${create}`
    );
  }

  public async update(request: GroupCreateUpdateRequest) {
    return this.apiClient.post<GroupCreateUpdateRequest, any>(
      request,
      `${API_URL}${update}`
    );
  }

  public async delete(request: GroupDeleteRequest) {
    return this.apiClient.post<GroupDeleteRequest, any>(
      request,
      `${API_URL}${groupSettings.delete}`
    );
  }

  public async getSearchCounter(request: BaseGetSearchCountByIdRequest) {
    return this.apiClient.get<GetSearchCounter>(
      `${API_URL}${getSearchCounter}?${request.format()}`
    );
  }
}
