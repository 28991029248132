import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-error-hint',
  templateUrl: './error-hint.component.html',
  styleUrls: ['./error-hint.component.scss'],
  standalone: true,
  imports: [MatIconModule],
})
export class ErrorHintComponent {
  @Input() errorText = '';
  @Input() centerAlign = true;

  constructor() {}
}
