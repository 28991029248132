import { Injectable } from '@angular/core';
import { environment } from '@app-environments/environment';
import { ApiRequest } from '../api.request';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import { BaseId, BaseResponse } from '@app-types/base/base';
import {
  MailFolderSettingsContract,
  MailFolderSettingsGetByMailFolderRequest,
} from '@app-types/api/mail-folder';

const API_URL = environment.apiUrl;
const { getByMailFolder, createOrUpdate } = API_ROUTES.mailFolderSettings;

@Injectable({
  providedIn: 'root',
})
export class MailFolderSettingsClient {
  constructor(private apiRequest: ApiRequest) {}

  async getByMailFolder(
    request: MailFolderSettingsGetByMailFolderRequest
  ): Promise<BaseResponse<MailFolderSettingsContract>> {
    return this.apiRequest.get<BaseResponse<MailFolderSettingsContract>>(
      `${API_URL}${getByMailFolder}?${request.format()}`
    );
  }

  async createOrUpdate(request: MailFolderSettingsContract): Promise<BaseId> {
    return this.apiRequest.post<MailFolderSettingsContract, BaseId>(
      request,
      `${API_URL}${createOrUpdate}`
    );
  }
}
