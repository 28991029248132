@if(hasError) {
  <div class="h-[80%]">
    <app-something-went-wrong
      description="{{ 'cannotLoadMailAccount' | translate }}"
      [showPicture]="false"
      (retryEmitter)="loadMailAccountUsers(true)"
    ></app-something-went-wrong>
  </div>
}

@if(!hasError) {
  <div class="flex-col-container-1">
    <div class="search-panel">
      <div class="button-group h-[40px]">
        @if(!iscredemISAMSupport) {
          <button
            mat-raised-button
            color="primary"
            class="page-block-button"
            aria-label="Add user to mail account"
            (click)="onCreateMailAccountUserBtnClicked()">
            <mat-icon class="{{useMobileView ? '!m-0' : '' }}">add</mat-icon>{{ useMobileView ? '' : ( "assignUser" | translate) }}
          </button>
        }
      </div>
      <div class="search-bar">
        <app-search-bar
          [fullWidth]="useMobileView"
          (searchEventEmitter)="loadMailAccountUsers(true)"
          (valueChangedEventEmitter)="onSearchValueChanged($event)"
          [search]="searchValue"
        ></app-search-bar>
        @if(!useMobileView) {
          <mat-paginator
            [pageSize]="pageSize"
            [pageSizeOptions]="[25, 50, 100]"
            [length]="totalSize"
            [pageIndex]="pageIndex"
            (page)="handlePage($event)">
          </mat-paginator>
        }
      </div>
    </div>
  </div>
}

@if(useMobileView && !hasError) {
  <div>
    <mat-paginator
      [pageSize]="pageSize"
      [pageSizeOptions]="[25, 50, 100]"
      [length]="totalSize"
      [pageIndex]="pageIndex"
      (page)="handlePage($event)">
    </mat-paginator>
    <div class="mobile-container-cards">
      @if(!isLoading && isUploaded) {
        @if(!!userMailAccounts.length) {
          @for(userMailAccount of userMailAccounts; track userMailAccount) {
            <div class="card">
              <mat-card class="mobile-row">
                <mat-card-content (click)="onEditMailAccountUserBtnClicked(userMailAccount)" class="cursor-default flex flex-1">
                  <div class="card-table-row">
                    <div class="card-table-row-header">{{ "firstName" | translate }}:</div>
                    <div class="card-table-row-value">{{ userMailAccount.userFirstName }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">{{ "lastName" | translate }}:</div>
                    <div class="card-table-row-value">{{ userMailAccount.userLastName }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">Adfs identity:</div>
                    <div class="card-table-row-value">{{ userMailAccount.adfsIdentity }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">Google identity:</div>
                    <div class="card-table-row-value">{{ userMailAccount.googleIdentity }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">Oidc identity:</div>
                    <div class="card-table-row-value">{{ userMailAccount?.oidcIdentity ?? '' }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">{{ "email" | translate }}:</div>
                    <div class="card-table-row-value">{{ userMailAccount.userEmail }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">{{ "role" | translate }}:</div>
                    <div class="card-table-row-value">{{ roleTypes[userMailAccount.roleId] | translate }}</div>
                  </div>
                </mat-card-content>
                <div class="icons-mobile-block">
                  @if(!iscredemISAMSupport) {
                    <button
                      matTooltip="{{ 'changeRole' | translate }}"
                      mat-icon-button
                      color="primary"
                      aria-label="Change role"
                      (click)="onEditMailAccountUserBtnClicked(userMailAccount)">
                      <mat-icon>manage_accounts</mat-icon>
                    </button>
                    <button
                      matTooltip="{{ 'delete' | translate }}"
                      mat-icon-button
                      color="primary"
                      aria-label="Delete"
                      (click)="
                      deleteUserMailAccount(
                        userMailAccount.userMailAccountId,
                        userMailAccount.mailAccountName,
                        userMailAccount.userFirstName,
                        userMailAccount.userLastName
                      )
                    ">
                      <mat-icon>delete_outline</mat-icon>
                    </button>
                  }
                </div>
              </mat-card>
            </div>
          }
        }
      }
    </div>
    @if(userMailAccounts.length === 0 && !isLoading && isUploaded) {
      <app-no-data></app-no-data>
    }
  </div>
}

@if(!useMobileView) {
  <div class="table-container">
    @if(!isLoading && !hasError && isUploaded) {
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="first-name">
          <th mat-header-cell *matHeaderCellDef>{{ "firstName" | translate }}</th>
          <td mat-cell *matCellDef="let userMailAccount">{{ userMailAccount.userFirstName }}</td>
        </ng-container>
        <ng-container matColumnDef="last-name">
          <th mat-header-cell *matHeaderCellDef>{{ "lastName" | translate }}</th>
          <td mat-cell *matCellDef="let userMailAccount">{{ userMailAccount.userLastName }}</td>
        </ng-container>
        <ng-container matColumnDef="oidcIdentity">
          <th mat-header-cell *matHeaderCellDef>{{ "GAW" | translate }}</th>
          <td mat-cell *matCellDef="let organizationUser">{{ organizationUser.oidcIdentity }}</td>
        </ng-container>
        <ng-container matColumnDef="googleIdentity">
          <th mat-header-cell *matHeaderCellDef>{{ "google" | translate }}</th>
          <td mat-cell *matCellDef="let organizationUser">{{ organizationUser?.googleIdentity ?? '' }}</td>
        </ng-container>
        <ng-container matColumnDef="adfsIdentity">
          <th mat-header-cell *matHeaderCellDef>{{ "adfs" | translate }}</th>
          <td mat-cell *matCellDef="let organizationUser">{{ organizationUser?.adfsIdentity ?? '' }}</td>
        </ng-container>
        <ng-container matColumnDef="user-email">
          <th mat-header-cell *matHeaderCellDef>{{ "email" | translate }}</th>
          <td mat-cell *matCellDef="let userMailAccount">{{ userMailAccount.userEmail }}</td>
        </ng-container>
        <ng-container matColumnDef="user-mail-account-role-name">
          <th mat-header-cell *matHeaderCellDef>{{ "role" | translate }}</th>
          <td mat-cell *matCellDef="let userMailAccount">{{ roleTypes[userMailAccount.roleId] | translate }}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>{{ "actions" | translate }}</th>
          <td mat-cell *matCellDef="let userMailAccount">
            @if(!iscredemISAMSupport) {
              <button
                mat-icon-button
                [attr.aria-label]="'changeRole' | translate"
                matTooltip="{{ 'changeRole' | translate }}"
                (click)="onEditMailAccountUserBtnClicked(userMailAccount)">
                <mat-icon>manage_accounts</mat-icon>
              </button>
              <button
                mat-icon-button
                [attr.aria-label]="'delete' | translate"
                matTooltip="{{ 'delete' | translate }}"
                (click)="
                  deleteUserMailAccount(
                    userMailAccount.userMailAccountId,
                    userMailAccount.mailAccountName,
                    userMailAccount.userFirstName,
                    userMailAccount.userLastName
                  )
                ">
                <mat-icon>delete_outline</mat-icon>
              </button>
            }
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="cursor-default"></tr>
      </table>

      @if(userMailAccounts.length === 0) {
        <app-no-data></app-no-data>
      }
    }
  </div>
}

@if(isLoading) {
  <div class="overflow-hidden flex-1">
    <app-skeleton-table></app-skeleton-table>
  </div>
}

<ng-template #addDrawer>
  <app-mail-account-users-add
    (creationSubmit)="onAddUserToMailAccount()"
    [organizationId]="organizationId"
    (closeEventEmitter)="editingUserMailAccount ? onEditMailAccountUser() : onCloseEditModalForm()"
    [mailAccountId]="mailAccountId"
    [userMailAccount]="editingUserMailAccount"
  ></app-mail-account-users-add>
</ng-template>

