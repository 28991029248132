<div class="filters">
  <mat-form-field  class="filters-search mr-1">
    <mat-label class="filters-search-label">{{ "search" | translate }}</mat-label>
    <input
      matInput
      [(ngModel)]="search"
      class="filters-search-input"
      (input)="onSearch($event)"
    />
    @if(search) {
      <button matSuffix mat-icon-button aria-label="Clear" (click)="onSearch()">
        <mat-icon>close</mat-icon>
      </button>
    }
  </mat-form-field>
  <mat-form-field  class="filters-select">
    <mat-label>{{ "filter" | translate }}</mat-label>
    <mat-select [formControl]="filters" [value]="filterList[0].value" (selectionChange)="onFilter($event)">
      @for(filter of filterList; track filter) {
        <mat-option [value]="filter.value">{{ filter.label | translate }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
