@if(isError) {
  <div class="h-[80%]">
    <app-something-went-wrong
      description="Cannot load providers"
      [showPicture]="false"
      (retryEmitter)="loadProviders()"
    ></app-something-went-wrong>
  </div>
}

@if(!isError) {
  <div class="flex-col-container-1">
    <div class="search-panel">
      <div class="button-group h-[40px]">
        @if(!useMobileView) {
        <button
          mat-raised-button
          color="primary"
          class="page-block-button"
          aria-label="Create provider"
          (click)="onCreateProviderBtnClicked()">
          <mat-icon>add</mat-icon>{{ "newProvider" | translate }}
        </button>
        }
        @if(useMobileView) {
        <button
          class="settings-mobile-button"
          mat-mini-fab
          color="primary"
          class="page-block-button"
          aria-label="Create provider"
          (click)="onCreateProviderBtnClicked()">
          <mat-icon>add</mat-icon>
        </button>
        }
      </div>
    </div>
  </div>
}
@if(!isLoading && !isError && isUploaded) {
  @if(useMobileView) {
    <div class="mobile-container-cards">
      @for(provider of providers; track provider) {
        <div>
          <mat-card>
            <mat-card-content class="cursor-default">
              <div class="card-table-row">
                <div class="card-table-row-header">{{ "name" | translate }}:</div>
                <div class="card-table-row-value">{{ provider.name }}</div>
              </div>
              <div class="card-table-row">
                <div class="card-table-row-header">{{ "smtp" | translate }}:</div>
                <div class="card-table-row-value">{{ provider.smtp }}</div>
              </div>
              <div class="card-table-row">
                <div class="card-table-row-header">{{ "smtpPort" | translate }}:</div>
                <div class="card-table-row-value">{{ provider.smtpPort }}</div>
              </div>
              <div class="card-table-row">
                <div class="card-table-row-header">{{ "imap" | translate }}:</div>
                <div class="card-table-row-value">{{ provider.imap }}</div>
              </div>
              <div class="card-table-row">
                <div class="card-table-row-header">{{ "imapPort" | translate }}:</div>
                <div class="card-table-row-value">{{ provider.imapPort }}</div>
              </div>
               <div class="card-table-row">
                <div class="card-table-row-header">{{ "tlsVersion" | translate }}:</div>
                <div class="card-table-row-value">
                  {{ provider.sslProtocol ? (provider.sslProtocol | formatTlsVersion) : '' }}
                </div>
              </div>
            </mat-card-content>
            <mat-card-actions>
              <div class="flex justify-end gap-2 w-full">
                <button mat-flat-button color="primary" aria-label="Details" (click)="openUpdateProviderDialog(provider)">
                  <mat-icon class="!m-0">edit_outline</mat-icon>
                </button>
                <button mat-flat-button color="primary" aria-label="Delete" (click)="deleteProvider(provider.providerId, provider.name)">
                  <mat-icon class="!m-0">delete_outline</mat-icon>
                </button>
              </div>
            </mat-card-actions>
          </mat-card>
        </div>
      }
      @if(providers.length === 0 && isUploaded) {
        <app-no-data></app-no-data>
      }
    </div>
  }
  @if(!useMobileView) {
    <div class="table-container">
      <table mat-table [dataSource]="dataSource" class="w-full">
        <ng-container matColumnDef="name">
          <th class="flex-1" mat-header-cell *matHeaderCellDef>{{ "name" | translate }}</th>
          <td class="flex-1" mat-cell *matCellDef="let provider">{{ provider.name }}</td>
        </ng-container>
        <ng-container matColumnDef="smtp">
          <th mat-header-cell *matHeaderCellDef>{{ "smtp" | translate }}</th>
          <td mat-cell *matCellDef="let provider">{{ provider.smtp }}</td>
        </ng-container>
        <ng-container matColumnDef="smtp-port">
          <th mat-header-cell *matHeaderCellDef>{{ "smtpPort" | translate }}</th>
          <td mat-cell *matCellDef="let provider">{{ provider.smtpPort }}</td>
        </ng-container>
        <ng-container matColumnDef="imap">
          <th mat-header-cell *matHeaderCellDef>{{ "imap" | translate }}</th>
          <td mat-cell *matCellDef="let provider">{{ provider.imap }}</td>
        </ng-container>
        <ng-container matColumnDef="imap-port">
          <th mat-header-cell *matHeaderCellDef>{{ "imapPort" | translate }}</th>
          <td mat-cell *matCellDef="let provider">{{ provider.imapPort }}</td>
        </ng-container>
        <ng-container matColumnDef="tlsVersion">
          <th mat-header-cell *matHeaderCellDef class="text-nowrap">{{ "tlsVersion" | translate }}</th>
          <td mat-cell *matCellDef="let provider">
            {{ provider?.sslProtocol ? (provider.sslProtocol | formatTlsVersion) : '' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>{{ "actions" | translate }}</th>
          <td mat-cell *matCellDef="let provider" class="action-link">
            <button mat-icon-button aria-label="Details" (click)="openUpdateProviderDialog(provider)">
              <mat-icon>edit_outline</mat-icon>
            </button>
            <span class="mr-2"></span>
            <button mat-icon-button aria-label="Delete" (click)="deleteProvider(provider.providerId, provider.name)">
              <mat-icon>delete_outline</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="cursor-default"></tr>
      </table>
      @if(providers.length === 0) {
        <app-no-data></app-no-data>
      }
    </div>
  }
}


@if(isLoading && !isError) {
  <div class="overflow-hidden flex-1">
    <app-skeleton-table></app-skeleton-table>
  </div>
}


<ng-template #createDrawer>
  <app-provider-create-dialog
    [provider]="updatingProvider"
    (closeEventEmitter)="onCloseUpdatingModalForm()"
    (creationSubmit)="updatingProvider ? onUpdateProvider() : onCreateProvider()">
  </app-provider-create-dialog>
</ng-template>
