import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  standalone: true,
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], value: string, key?: string): any[] {
    if (!items) {
      return [];
    }

    if (!value) {
      return items;
    }

    if (key) {
      return items.filter(item =>
        item[key].toLowerCase().includes(value.toLowerCase())
      );
    }

    return items.filter(item =>
      item.toLowerCase().includes(value.toLowerCase())
    );
  }
}
