<div class="flex flex-col gap-5">
  <div class="w-full text-center">
    <ngx-skeleton-loader
      [count]="1"
      [theme]="{
        'max-width': '140px',
        'margin-bottom': 0,
        height: '32px',
      }"
    ></ngx-skeleton-loader>
  </div>
  <div>
    <ngx-skeleton-loader
      [count]="1"
      [theme]="{
        'margin-bottom': 0,
        height: '56px',
      }"
    ></ngx-skeleton-loader>
  </div>
  <div>
    <ngx-skeleton-loader
      [count]="1"
      [theme]="{
      'margin-top': 0,
        'margin-bottom': 0,
        height: '48px',
      }"
    ></ngx-skeleton-loader>
  </div>
</div>
