import { Injectable } from '@angular/core';
import { environment } from '@app-environments/environment';
import {
  GetOrganizationMailAccountsRequest,
  MailAccountChangePasswordRequest,
  MailAccountContract,
  MailAccountCreateRequest,
  MailAccountGetBadgeRequest,
  MailAccountGetBadgeResponse,
  MailAccountGetByIdResponse,
  MailAccountGetByOrganizationIdResponse,
  MailAccountUpdateRequest,
} from '@app-types/api/mail-account';
import { ApiRequest } from '../api.request';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import {
  BaseCollectionSearchByIdRequest,
  BaseGetByIdRequest,
  BaseGetCollectionResponse,
  BaseGetSearchCountByIdRequest,
  BaseId,
  BaseResponse,
} from '@app-types/base/base';
import { BaseCollectionFilteredSearchByIdRequest } from '@app-types/common';

const API_URL = environment.apiUrl;
const { mailAccount } = API_ROUTES;
const {
  get,
  create,
  getForReassign,
  update,
  updatePassword,
  getMailAccountsBadges,
  getOrganizationMailAccounts,
  getSearch,
  getSearchCount,
} = mailAccount;

@Injectable({ providedIn: 'root' })
export class MailAccountClient {
  constructor(private apiRequest: ApiRequest) {}

  async getSearch(
    request: BaseCollectionFilteredSearchByIdRequest
  ): Promise<MailAccountGetByOrganizationIdResponse> {
    return this.apiRequest.post(request, `${API_URL}${getSearch}`);
  }

  async getForReassign(
    request: BaseCollectionSearchByIdRequest
  ): Promise<MailAccountGetByOrganizationIdResponse> {
    return this.apiRequest.get(
      `${API_URL}${getForReassign}?${request.format()}`
    );
  }

  async create(request: MailAccountCreateRequest): Promise<BaseId> {
    return this.apiRequest.post<MailAccountCreateRequest, BaseId>(
      request,
      `${API_URL}${create}`
    );
  }

  async update(request: MailAccountUpdateRequest): Promise<BaseId> {
    return this.apiRequest.post<MailAccountUpdateRequest, BaseId>(
      request,
      `${API_URL}${update}`
    );
  }

  async getById(
    request: BaseGetByIdRequest
  ): Promise<MailAccountGetByIdResponse> {
    return this.apiRequest.get(`${API_URL}${get}?${request.format()}`);
  }

  async getMailAccountsBadges(
    request: MailAccountGetBadgeRequest
  ): Promise<BaseGetCollectionResponse<MailAccountGetBadgeResponse>> {
    return this.apiRequest.post<
      MailAccountGetBadgeRequest,
      BaseGetCollectionResponse<MailAccountGetBadgeResponse>
    >(request, `${API_URL}${getMailAccountsBadges}`);
  }

  async changePassword(
    request: MailAccountChangePasswordRequest
  ): Promise<BaseId> {
    return this.apiRequest.post<MailAccountChangePasswordRequest, BaseId>(
      request,
      `${API_URL}${updatePassword}`
    );
  }

  async getSearchCount(
    request: BaseGetSearchCountByIdRequest
  ): Promise<BaseResponse<number>> {
    return this.apiRequest.get(
      `${API_URL}${getSearchCount}?${request.format()}`
    );
  }

  async getOrganizationMailAccounts(
    request: GetOrganizationMailAccountsRequest
  ): Promise<BaseGetCollectionResponse<MailAccountContract>> {
    return this.apiRequest.get(
      `${API_URL}${getOrganizationMailAccounts}?${request.format()}`
    );
  }
}
