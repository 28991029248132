import { Component, Inject, ViewChild } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { FileService } from '@app-services/files.servis';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-doc-viewer',
  standalone: true,
  imports: [
    PdfJsViewerModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
  ],
  templateUrl: './doc-viewer.component.html',
  styleUrls: ['./doc-viewer.component.scss'],
})
export class DocViewerComponent {
  locale = localStorage.getItem('userLocale');
  @ViewChild('pdfViewer') public pdfViewer: any;
  constructor(
    public fileService: FileService,
    public dialogRef: MatDialogRef<DocViewerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      url: string;
      filename: string;
      isPdf: boolean;
    }
  ) {}

  close(): void {
    this.dialogRef.close();
  }

  download(): void {
    this.fileService.downloadFile(this.data.url, this.data.filename);
  }
}
