import { Injectable } from '@angular/core';
import { environment } from '@app-environments/environment';
import {
  ProviderCreateRequest,
  ProviderCreateResponse,
  ProviderGetAllResponse,
  ProviderUpdateRequest,
} from '@app-types/api/provider';
import { ApiRequest } from '../api.request';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import { BaseGetByIdRequest, BaseId } from '@app-types/base/base';

const API_URL = environment.apiUrl;
const { provider } = API_ROUTES;
const { create, getAll, search, update, getByOrganization } = provider;

@Injectable({ providedIn: 'root' })
export class ProviderClient {
  constructor(private apiRequest: ApiRequest) {}

  async getAll(): Promise<ProviderGetAllResponse> {
    return this.apiRequest.get(`${API_URL}${getAll}`);
  }

  async getByOrganization(
    request: BaseGetByIdRequest
  ): Promise<ProviderGetAllResponse> {
    return this.apiRequest.get(
      `${API_URL}${getByOrganization}?${request.format()}`
    );
  }

  async search(): Promise<ProviderGetAllResponse> {
    return this.apiRequest.get(`${API_URL}${search}`);
  }

  async create(
    request: ProviderCreateRequest
  ): Promise<ProviderCreateResponse> {
    return this.apiRequest.post<ProviderCreateRequest, ProviderCreateResponse>(
      request,
      `${API_URL}${create}`
    );
  }

  async update(request: ProviderUpdateRequest): Promise<BaseId> {
    return this.apiRequest.post<ProviderCreateRequest, BaseId>(
      request,
      `${API_URL}${update}`
    );
  }

  async delete(request: BaseId): Promise<BaseId> {
    return this.apiRequest.post<BaseId, BaseId>(
      request,
      `${API_URL}${provider.delete}`
    );
  }
}
