@if(isError && !isLoading) {
  <div class="h-[80%]">
    <app-something-went-wrong
      description="{{ 'cannotLoadTags' | translate }}"
      [showPicture]="false"
      (retryEmitter)="loadTags(true)"
    ></app-something-went-wrong>
  </div>
}

@if(!isError) {
  <div class="flex-col-container-1">
    <div class="search-panel">
      <div class="button-group">
        <button
          mat-raised-button
          color="primary"
          class="page-block-button"
          aria-label="Add tag"
          (click)="openCreateModelForm()"
        >
          <mat-icon class="{{useMobileView ? '!m-0' : '' }}">add</mat-icon>{{ useMobileView ? '' : ("newTag" | translate) }}
        </button>
      </div>
      <div class="search-bar">
        <app-search-bar
          [fullWidth]="useMobileView"
          (searchEventEmitter)="loadTags(true)"
          (valueChangedEventEmitter)="onSearchValueChanged($event)"
          [search]="searchValue"
        ></app-search-bar>

        @if(!useMobileView) {
          <mat-paginator
            [pageSize]="pageSize"
            [pageSizeOptions]="[25, 50, 100]"
            [length]="totalSize"
            [pageIndex]="pageIndex"
            (page)="handlePage($event)">
          </mat-paginator>
        }
      </div>
    </div>
  </div>
}
@if(useMobileView) {
  <div>
    <mat-paginator
      [pageSize]="pageSize"
      [pageSizeOptions]="[25, 50, 100]"
      [length]="totalSize"
      [pageIndex]="pageIndex"
      (page)="handlePage($event)">
    </mat-paginator>
    <div class="flex flex-col gap-2">
      @if(!isLoading && !isError && isUploaded) {
        @for(tag of tags; track tag) {
          <div>
            <div class="card">
              <mat-card class="mobile-row">
                <mat-card-content class="flex flex-1">
                  <div class="card-table-row">
                    <div class="card-table-row-header">{{ "tagLabel" | translate }}:</div>
                    <div class="card-table-row-value">{{ tag.tag }}</div>
                  </div>
                </mat-card-content>
                <div class="icons-mobile-block">
                  <button
                    mat-icon-button
                    matTooltip="{{ 'delete' | translate }}"
                    color="primary"
                    aria-label="Delete"
                    (click)="deleteTag(tag.tagId, tag.tag)">
                    <mat-icon>delete_outline</mat-icon>
                  </button>
                </div>
              </mat-card>
             </div>
          </div>
        }
      }
    </div>
    @if(tags.length === 0 && !isLoading && !isError && isUploaded) {
      <app-no-data></app-no-data>
    }
  </div>
}

@if(!useMobileView) {
  <div class="table-container">
    @if(!isLoading && !isError && isUploaded) {
       <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="tag">
          <th mat-header-cell *matHeaderCellDef>{{ "tagLabel" | translate }}</th>
          <td mat-cell *matCellDef="let tag">{{ tag.tag }}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>{{ "actions" | translate }}</th>
          <td mat-cell *matCellDef="let tag">
            <button mat-icon-button aria-label="Delete" matTooltip="{{ 'delete' | translate }}" (click)="deleteTag(tag.tagId, tag.tag)">
              <mat-icon>delete_outline</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="cursor-default"></tr>
      </table>
      @if(tags.length === 0) {
        <app-no-data></app-no-data>
      }
    }
  </div>
}

@if(isLoading) {
  <div class="overflow-hidden flex-1">
    <app-skeleton-table></app-skeleton-table>
  </div>
}

<ng-template #addDrawer>
  <app-mail-account-tag-create-dialog
    (submitEventEmitter)="onCreateTag()"
    [mailAccountId]="mailAccountId">
  </app-mail-account-tag-create-dialog>
</ng-template>
