import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@app-environments/environment';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import { ApiRequest } from '../api.request';
import {
  BaseCollectionSearchByIdRequest,
  BaseGetByIdRequest,
  BaseGetCollectionResponse,
  BaseGetSearchCountByIdRequest,
  BaseId,
  BaseResponse,
} from '@app-types/base/base';
import { InsertTagRequest, TagContract } from '@app-types/api/tag';

const API_URL = environment.apiUrl;
const { tag } = API_ROUTES;
const {
  insert,
  getForMailAccount,
  searchForMailAccount,
  getSearchCountForMailAccount,
} = tag;

@Injectable({
  providedIn: 'root',
})
export class TagClient {
  constructor(
    private http: HttpClient,
    private apiRequest: ApiRequest
  ) {}

  async getForMailAccount(
    request: BaseGetByIdRequest
  ): Promise<BaseGetCollectionResponse<TagContract>> {
    return this.apiRequest.get<BaseGetCollectionResponse<TagContract>>(
      `${API_URL}${getForMailAccount}?${request.format()}`
    );
  }

  async searchForMailAccount(
    request: BaseCollectionSearchByIdRequest
  ): Promise<BaseGetCollectionResponse<TagContract>> {
    return this.apiRequest.get<BaseGetCollectionResponse<TagContract>>(
      `${API_URL}${searchForMailAccount}?${request.format()}`
    );
  }

  async getSearchCountForMailAccount(
    request: BaseGetSearchCountByIdRequest
  ): Promise<BaseResponse<number>> {
    return this.apiRequest.get<BaseResponse<number>>(
      `${API_URL}${getSearchCountForMailAccount}?${request.format()}`
    );
  }

  async insert(request: InsertTagRequest): Promise<void> {
    await this.apiRequest.post<InsertTagRequest, void>(
      request,
      `${API_URL}${insert}`
    );
  }

  async delete(request: BaseId): Promise<void> {
    await this.apiRequest.post<BaseId, void>(
      request,
      `${API_URL}${tag.delete}`
    );
  }
}
