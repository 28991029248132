@if(hasError) {
  <div class="h-[80%]">
    <app-something-went-wrong
      [showPicture]="false"
      description="Cannot load company users"
      (retryEmitter)="loadOrganizationUsers(true)"
    ></app-something-went-wrong>
  </div>
}

@if(!hasError) {
  <div class="flex-col-container-1">
    <div class="search-panel">
      <div class="button-group h-[40px]">
        @if(!iscredemISAMSupport) {
          <button mat-raised-button color="primary" class="page-block-button" (click)="onCreateOrganizationUserBtnClicked()">
             <mat-icon class="{{useMobileView ? '!m-0' : '' }}">add</mat-icon>{{ useMobileView ? '' : ("newUser" | translate) }}
          </button>
        }

        @if(!selection.isEmpty()) {
          <div class="button-add">
            <button
              color="white"
              class="page-block-button"
              mat-icon-button
              [attr.aria-label]="'addGroupsToUsers' | translate"
              matTooltip="{{ 'addGroupsToUsers' | translate }}"
              (click)="onAddUserToGroupsBtnClicked()">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        }
      </div>
      <div class="search-bar">
        <app-search-bar
          [fullWidth]="useMobileView"
          (searchEventEmitter)="loadOrganizationUsers(true)"
          (valueChangedEventEmitter)="onSearchValueChanged($event)"
          [search]="searchValue"
        ></app-search-bar>
        @if(!useMobileView) {
          <mat-paginator
            [pageSize]="pageSize"
            [pageSizeOptions]="[25, 50, 100]"
            [length]="totalSize"
            [pageIndex]="pageIndex"
            (page)="handlePage($event)">
          </mat-paginator>
        }
      </div>
    </div>
  </div>
}
@if(useMobileView) {
  <div>
    <div class="mobile">
      <div class="buttons-mobile-container">
        <mat-checkbox
          color="primary"
          class="mb-2 mt-4"
          [checked]="selection.hasValue() && isAllSelected()"
          (change)="$event ? masterToggle() : null"
          [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
        <mat-paginator
          [pageSize]="pageSize"
          [pageSizeOptions]="[25, 50, 100]"
          [length]="totalSize"
          [pageIndex]="pageIndex"
          (page)="handlePage($event)">
        </mat-paginator>
      </div>

      @if(!isLoading && !hasError && isUploaded) {
        <div class="mobile-container-cards">
          @for(organizationUser of organizationUsers; track organizationUser) {
            <div class="card">
              <div class="checkbox-col">
                <mat-checkbox
                  color="primary"
                  class="mobile-card-title-checkbox"
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(organizationUser) : null"
                  [checked]="selection.isSelected(organizationUser)">
                </mat-checkbox>
              </div>
              <mat-card class="mobile-row">
                <mat-card-content (click)="onUpdateOrganizationUserBtnClicked(organizationUser)" class="cursor-pointer flex flex-1">
                  <div class="card-table-row">
                    <div class="card-table-row-header">First name:</div>
                    <div class="card-table-row-value">{{ organizationUser.firstName }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">Last name:</div>
                    <div class="card-table-row-value">{{ organizationUser.lastName }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">Adfs identity:</div>
                    <div class="card-table-row-value">{{ organizationUser.adfsIdentity }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">Google identity:</div>
                    <div class="card-table-row-value">{{ organizationUser.googleIdentity }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">Oidc identity:</div>
                    <div class="card-table-row-value">{{ organizationUser.oidcIdentity }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">Email:</div>
                    <div class="card-table-row-value">{{ organizationUser.email }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">Role:</div>
                    <div class="card-table-row-value">{{ roleTypes[organizationUser.roleId] | translate }}</div>
                  </div>
                </mat-card-content>

                <div class="icons-mobile-block">
                  @if(!iscredemISAMSupport) {
                    <button
                      mat-icon-button
                      color="primary"
                      aria-label="Update User"
                      matTooltip="Update User"
                      (click)="onUpdateOrganizationUserBtnClicked(organizationUser)">
                      <mat-icon>edit</mat-icon>
                    </button>
                  }
                  @if(!iscredemISAMSupport) {
                    <button
                      mat-icon-button
                      color="primary"
                      aria-label="Add User To Mail accounts"
                      matTooltip="Add Users To Mail accounts"
                      (click)="onAddUserToMailAccountsBtnClicked(organizationUser)">
                      <mat-icon>person_add</mat-icon>
                    </button>
                  }

                  <button
                    mat-icon-button
                    color="primary"
                    aria-label="Add User To Groups"
                    matTooltip="Add User To Groups"
                    (click)="onAddUserToGroupsBtnClicked(organizationUser)">
                    <mat-icon>groups</mat-icon>
                  </button>

                  @if(!isRta(organizationUser.roleId) && organizationUser.state === 1 && !iscredemISAMSupport) {
                    <button
                      mat-icon-button
                      color="primary"
                      [attr.aria-label]="'addUserToMailAccounts' | translate"
                      matTooltip="{{ 'diactivateUser' | translate }}"
                      (click)="openConfirmDialog(organizationUser.userOrganizationId, organizationUser.state, organizationUser)">
                      <mat-icon>dangerous</mat-icon>
                    </button>
                  }

                  @if(!isRta(organizationUser.roleId) && organizationUser.state === 2 && !iscredemISAMSupport) {
                    <button
                      mat-icon-button
                      color="primary"
                      [attr.aria-label]="'addUserToMailAccounts' | translate"
                      matTooltip="{{ 'activateUser' | translate }}"
                      (click)="openConfirmDialog(organizationUser.userOrganizationId, organizationUser.state, organizationUser)">
                      <mat-icon>power_settings_new</mat-icon>
                    </button>
                  }

                </div>
              </mat-card>
            </div>
          }
        </div>
        @if(organizationUsers.length === 0) {
          <app-no-data></app-no-data>
        }
      }
    </div>
  </div>
}

@if(!useMobileView) {
 @if(!isLoading && !hasError && isUploaded) {
  <div class="table-container">
   <table mat-table [dataSource]="dataSource">
     <!-- Select Column -->
     <ng-container matColumnDef="select">
       <th mat-header-cell *matHeaderCellDef>
         <mat-checkbox
           color="primary"
           (change)="$event ? masterToggle() : null"
           [checked]="selection.hasValue() && isAllSelected()"
           [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
       </th>
       <td mat-cell *matCellDef="let row">
         <mat-checkbox
           color="primary"
           (click)="$event.stopPropagation()"
           (change)="$event ? selection.toggle(row) : null"
           [checked]="selection.isSelected(row)">
         </mat-checkbox>
       </td>
     </ng-container>

     <!-- Name Column -->
     <ng-container matColumnDef="firstName">
       <th mat-header-cell *matHeaderCellDef>{{ "firstName" | translate }}</th>
        <td mat-cell *matCellDef="let organizationUser">{{ organizationUser.firstName }}</td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef>{{ "lastName" | translate }}</th>
        <td mat-cell *matCellDef="let organizationUser">{{ organizationUser.lastName }}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="oidcIdentity">
        <th mat-header-cell *matHeaderCellDef>{{ "GAW" | translate }}</th>
        <td mat-cell *matCellDef="let organizationUser">{{ organizationUser.oidcIdentity }}</td>
      </ng-container>
      <ng-container matColumnDef="googleIdentity">
        <th mat-header-cell *matHeaderCellDef>{{ "google" | translate }}</th>
        <td mat-cell *matCellDef="let organizationUser">{{ organizationUser.googleIdentity }}</td>
      </ng-container>
      <ng-container matColumnDef="adfsIdentity">
        <th mat-header-cell *matHeaderCellDef>{{ "adfs" | translate }}</th>
        <td mat-cell *matCellDef="let organizationUser">{{ organizationUser.adfsIdentity }}</td>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>{{ "email" | translate }}</th>
        <td mat-cell *matCellDef="let organizationUser">{{ organizationUser.email }}</td>
      </ng-container>

      <!-- Role Column -->
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef>{{ "role" | translate }}</th>
        <td mat-cell *matCellDef="let organizationUser">{{ roleTypes[organizationUser.roleId] | translate }}</td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef>{{ "state" | translate }}</th>
        <td mat-cell *matCellDef="let organizationUser">{{ organizationUser.state | formatUserState | translate }}</td>
      </ng-container>

      <!-- Actionc Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ "actions" | translate }}</th>
        <td mat-cell *matCellDef="let organizationUser" class="action-link">
          @if(!iscredemISAMSupport) {
            <button
              mat-icon-button
              color="primary"
              [attr.aria-label]="'updateUser' | translate"
              matTooltip="{{ 'updateUser' | translate }}"
              (click)="onUpdateOrganizationUserBtnClicked(organizationUser)">
              <mat-icon>edit</mat-icon>
            </button>
          }
          @if(!iscredemISAMSupport) {
            <button
              mat-icon-button
              color="primary"
              [attr.aria-label]="'addUserToMailAccounts' | translate"
              matTooltip="{{ 'addUserToMailAccounts' | translate }}"
              (click)="onAddUserToMailAccountsBtnClicked(organizationUser)">
              <mat-icon>person_add</mat-icon>
            </button>
          }

          <button
            mat-icon-button
            [attr.aria-label]="'addUserToGroups' | translate"
            matTooltip="{{ 'addUserToGroups' | translate }}"
            (click)="onAddUserToGroupsBtnClicked(organizationUser)">
            <mat-icon>groups</mat-icon>
          </button>

          @if(!isRta(organizationUser.roleId) && organizationUser.state === 1 && !iscredemISAMSupport) {
            <button
              mat-icon-button
              color="primary"
              [attr.aria-label]="'addUserToMailAccounts' | translate"
              matTooltip="{{ 'diactivateUser' | translate }}"
              (click)="openConfirmDialog(organizationUser.userOrganizationId, organizationUser.state, organizationUser)">
              <mat-icon>dangerous</mat-icon>
            </button>
          }

          @if(!isRta(organizationUser.roleId) && organizationUser.state === 2 && !iscredemISAMSupport) {
            <button
              mat-icon-button
              color="primary"
              [attr.aria-label]="'addUserToMailAccounts' | translate"
              matTooltip="{{ 'activateUser' | translate }}"
              (click)="openConfirmDialog(organizationUser.userOrganizationId, organizationUser.state, organizationUser)">
              <mat-icon>power_settings_new</mat-icon>
            </button>
          }

        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" class="cursor-default"></tr>
    </table>
  </div>
  }

  @if(!organizationUsers.length && !isLoading && !hasError && isUploaded) {
    <app-no-data></app-no-data>
  }
}


@if(isLoading && !hasError) {
  <div class="overflow-hidden flex-1">
    <app-skeleton-table></app-skeleton-table>
  </div>
}

<ng-template #createDrawer>
  <app-organization-user-create-dialog
    [user]="selectedUser"
    (closeEventEmitter)="onCloseCreateModalForm()"
    (creationSubmit)="onCreateUpdateOrganizationUser()"
    [organizationId]="organizationId">
  </app-organization-user-create-dialog>
</ng-template>

<ng-template #addUsersToGroupsDrawer>
  <app-add-users-to-groups-form
    (closeDrawer)="onHideAddUsersToGroupForm()"
    (submitDrawer)="onAddUsersToGroups()"
    [users]="selectedUser ? [selectedUser] : selection.selected"
    [organizationID]="organizationId">
  </app-add-users-to-groups-form>
</ng-template>

<ng-template #addUsersToMailAccountsDrawer>
  <app-add-users-to-mail-accounts-form
    (closeDrawer)="onHideAddUsersToMailAccountsForm()"
    (submitDrawer)="onHideAddUsersToMailAccountsForm()"
    [users]="selectedUser ? [selectedUser] : []"
    [organizationID]="organizationId">
  </app-add-users-to-mail-accounts-form>
</ng-template>
