import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ProviderContract } from '@app-types/api/provider';
import { ProviderClient } from '@app-services/api/clients/provider.client';
import { MailAccountCreateRequest } from '@app-types/api/mail-account';
import { MailAccountClient } from '@app-services/api/clients/mail-account.client';
import { ErrorCode } from '@app-types/enums/error-code';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { SomethingWentWrongComponent } from '../../../../common/error/something-went-wrong/something-went-wrong.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LoadingButtonComponent } from '../../../../common/loading-button/loading-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { DrawerService } from '@app-services/drawer.service';
import { SkeletonFormDrawerComponent } from '@app-components/common/skeletons/skeleton-form-drawer/skeleton-form-drawer.component';

@Component({
  selector: 'app-mail-account-create-dialog',
  templateUrl: './mail-account-create-dialog.component.html',
  styleUrls: ['./mail-account-create-dialog.component.scss'],
  standalone: true,
  imports: [
    SomethingWentWrongComponent,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    LoadingButtonComponent,
    TranslateModule,
    SkeletonFormDrawerComponent,
  ],
})
export class MailAccountCreateDialogComponent implements OnInit, OnDestroy {
  @Input() public organizationId: number | null;
  @Output() public creationSubmit = new EventEmitter();
  @Output() public closeEventEmitter = new EventEmitter();
  public isLoading = false;
  public isError = false;
  public errorCode: ErrorCode | null = null;

  public form: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(256),
    ]),
    codeSia: new UntypedFormControl('', [Validators.maxLength(255)]),
    anagId: new UntypedFormControl('', [Validators.maxLength(255)]),
    license: new UntypedFormControl('', [Validators.maxLength(255)]),
    email: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(256),
      Validators.email,
    ]),
    password: new UntypedFormControl('', [Validators.maxLength(256)]),
    provider: new UntypedFormControl(''),
  });
  public providersIsLoading = false;
  isUploaded = false;
  public cannotLoadProviders = false;
  public providers: ProviderContract[] = [];

  constructor(
    private providerClient: ProviderClient,
    public matchError: MatchError,
    private mailAccountClient: MailAccountClient,
    private drawerService: DrawerService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadProviders();
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

  async loadProviders(): Promise<void> {
    this.isUploaded = false;
    const loaderTimeout = setTimeout(() => {
      this.providersIsLoading = true;
    }, 500);

    this.cannotLoadProviders = false;
    try {
      const response = await this.providerClient.getAll();
      this.providers = response.providerContracts;
    } catch (e) {
      this.cannotLoadProviders = true;
      this.matchError.logError(e);
    } finally {
      clearTimeout(loaderTimeout);
      this.isUploaded = true;
      this.providersIsLoading = false;
    }
  }

  async onSubmit(mailAccountRequest: MailAccountCreateRequest): Promise<void> {
    if (this.form.valid) {
      this.isLoading = true;
      this.drawerService.disabledDrawer(true);
      this.isError = false;
      this.errorCode = null;
      try {
        this.organizationId &&
          (mailAccountRequest.organizationId = this.organizationId);
        mailAccountRequest.providerId = this.form.value.provider;
        await this.mailAccountClient.create(mailAccountRequest);
        this.drawerService.disabledDrawer(false);
        this.creationSubmit.emit();
        this.drawerService.closeDrawer();
      } catch (e) {
        this.isError = true;
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
        this.drawerService.disabledDrawer(false);
      } finally {
        this.isLoading = false;
      }
    }
  }

  ngOnDestroy(): void {
    this.closeEventEmitter && this.closeEventEmitter.emit();
  }
}
