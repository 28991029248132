import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { EMAIL_QUERY_PARAM, WEB_CODE } from '@app-shared/constants/constants';
import { decodeInfoMailAccount } from '@app-utils/search-params';
import { EmailsContentComponent } from '@app-components/emails-content/emails-content.component';
import { EmailFolderNameComponent } from '@app-components/common/email/email-folder-name/email-folder-name.component';
import { Subscription } from 'rxjs';
import { UserWorkspaceService } from '@app-services/user-workspace-service';

@Component({
  selector: 'app-emails-container',
  templateUrl: './emails-container.component.html',
  styleUrls: ['./emails-container.component.scss'],
  standalone: true,
  imports: [TranslateModule, EmailsContentComponent, EmailFolderNameComponent],
})
export class EmailsContainerComponent implements OnInit, OnDestroy {
  public isAnyFolders = true;
  webCode = '';
  router$: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public userWorkspaceService: UserWorkspaceService
  ) {}

  ngOnInit(): void {
    this.webCode = this.route.parent?.snapshot.paramMap.get(WEB_CODE) ?? '';

    if (!this.userWorkspaceService.mailAccounts.length) return;
    this.setInfoOpenMAilAccount(window.location.href);

    this.router$ = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.setInfoOpenMAilAccount(window.location.href);
      });
  }

  setInfoOpenMAilAccount(url: string): void {
    const newUrl = new URL(url);
    const search: string = newUrl.searchParams.get(EMAIL_QUERY_PARAM) ?? '';
    const data = decodeInfoMailAccount(search);
    this.isAnyFolders = !!data?.folder;
  }

  ngOnDestroy(): void {
    this.router$?.unsubscribe();
  }
}
