import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { PermissionNavTabHelper } from '@app-services/permission/permission-nav-tab-helper';
import { OrganizationClient } from '@app-services/api/clients/organization.client';
import { MailAccountClient } from '@app-services/api/clients/mail-account.client';
import { PermissionService } from '@app-services/permission/permission.service';
import { ActivatedRoute } from '@angular/router';
import { MailFolderClient } from '@app-services/api/clients/mail-folder.client';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MailFolderType } from '@app-types/enums/mail-folder.type';
import { Subscription } from 'rxjs';
import { MobileObserverService } from '@app-services/adaptive/mobile-observer.service';
import { MatDialog } from '@angular/material/dialog';
import { MailFolderService } from '@app-services/mail-folder/mail.folder.service';
import { MailFolderContractWithFullNameForResponse } from '@app-types/api/mail-folder';
import { MailCreationType } from 'src/app/types/enums/mail-folder-creation.type';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NoDataComponent } from '@app-components/common/no-data/no-data.component';
import { SomethingWentWrongComponent } from '@app-components/common/error/something-went-wrong/something-went-wrong.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import {
  MAT_MENU_DEFAULT_OPTIONS,
  MatMenuModule,
} from '@angular/material/menu';
import { DrawerService } from '@app-services/drawer.service';
import {
  MAIL_ACCOUNT_ID,
  ORGANIZATION_ID,
} from '@app-shared/constants/constants';
import { MailFolderCreateDialogComponent } from '@app-components/mail-account-folders/mail-folder-create-dialog/mail-folder-create-dialog.component';
import { MailFolderSettingsDialogComponent } from '@app-components/mail-account-folders/mail-folder-settings-dialog/mail-folder-settings-dialog.component';
import { DeleteDialogComponent } from '@app-components/common/delete-dialog/delete-dialog.component';
import { SkeletonTableComponent } from '@app-components/common/skeletons/skeleton-table/skeleton-table.component';

@Component({
  selector: 'app-mail-account-folders-settings',
  templateUrl: './mail-account-folders-settings.component.html',
  styleUrls: ['./mail-account-folders-settings.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    NoDataComponent,
    SomethingWentWrongComponent,
    MatTableModule,
    MatTooltipModule,
    MatCardModule,
    MatMenuModule,
    MailFolderCreateDialogComponent,
    MailFolderSettingsDialogComponent,
    SkeletonTableComponent,
  ],
  providers: [
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'panelClassMenu' },
    },
  ],
})
export class MailAccountFoldersSettingsComponent implements OnInit, OnDestroy {
  @HostBinding('class') className = 'setting-container';
  public organizationId: number;
  public mailAccountId: number;
  public isLoading = false;
  isUploaded = false;
  public cannotLoadData = false;
  public folders: MailFolderContractWithFullNameForResponse[] = [];
  public dataSource =
    new MatTableDataSource<MailFolderContractWithFullNameForResponse>(
      this.folders
    );
  public displayedColumns: string[] = [
    'name',
    'providerName',
    'archive',
    'actions',
  ];
  public mailFolderType = MailFolderType;
  public mailCreationType = MailCreationType;
  public useMobileView = false;
  public useMobileViewSubscription: Subscription;
  public parentMailFolderIdForCreation: number | null;
  public editingFolder: MailFolderContractWithFullNameForResponse | null;
  public parentMailFolderTypeForCreation: number;

  @ViewChild('createDrawer') createDrawer: TemplateRef<any>;
  @ViewChild('modifyDrawer') modifyDrawer: TemplateRef<any>;
  @ViewChild('settingsDrawer') settingsDrawer: TemplateRef<any>;

  constructor(
    public matchError: MatchError,
    private permissionNavTabHelper: PermissionNavTabHelper,
    private organizationClient: OrganizationClient,
    private mailAccountClient: MailAccountClient,
    private mailFolderService: MailFolderService,
    private permissionService: PermissionService,
    private route: ActivatedRoute,
    private mailFolderClient: MailFolderClient,
    private mobileObserverService: MobileObserverService,
    public dialog: MatDialog,
    private drawerService: DrawerService
  ) {}

  async ngOnInit(): Promise<void> {
    this.useMobileViewSubscription = this.mobileObserverService
      .mobileObserver()
      .subscribe(isMobile => (this.useMobileView = isMobile));
    this.organizationId = parseInt(
      this.route.snapshot.paramMap.get(ORGANIZATION_ID) as string,
      10
    );
    this.mailAccountId = parseInt(
      this.route.snapshot.paramMap.get(MAIL_ACCOUNT_ID) as string,
      10
    );
    await this.loadData();
  }

  ngOnDestroy(): void {
    this.useMobileViewSubscription?.unsubscribe();
  }

  public inInboxSentOrArchiveFolder(
    folder: MailFolderContractWithFullNameForResponse
  ): boolean {
    return (
      folder.folderType === MailFolderType.Inbox ||
      folder.folderType === MailFolderType.Sent ||
      folder.folderType === MailFolderType.Archive
    );
  }

  public async loadData(): Promise<void> {
    this.isUploaded = false;
    const loaderTimeout = setTimeout(() => {
      this.isLoading = true;
    }, 500);
    this.cannotLoadData = false;
    try {
      const response =
        await this.mailFolderService.getFoldersForAccountFlatForSettings(
          this.mailAccountId
        );
      const folderTypeOrder = [
        MailFolderType.Inbox,
        MailFolderType.Sent,
        MailFolderType.Archive,
        MailFolderType.Outbox,
        MailFolderType.Validation,
        MailFolderType.Draft,
        MailFolderType.Trash,
        MailFolderType.Spam,
      ];
      response.sort(
        (a, b) =>
          folderTypeOrder.indexOf(a.folderType) -
          folderTypeOrder.indexOf(b.folderType)
      );
      this.folders = response;
      this.dataSource.data = response;
    } catch (e) {
      this.cannotLoadData = true;
      this.matchError.logError(e);
    } finally {
      clearTimeout(loaderTimeout);
      this.isLoading = false;
      this.isUploaded = true;
    }
  }

  public createFolder(
    parentMailFolder: MailFolderContractWithFullNameForResponse
  ): void {
    this.parentMailFolderTypeForCreation = parentMailFolder.folderType;
    this.parentMailFolderIdForCreation = parentMailFolder.mailFolderId;
    this.drawerService.openDrawer(this.createDrawer);
  }

  public onCloseCreateModalForm(): void {
    this.parentMailFolderIdForCreation = null;
  }

  public async onSubmitCreation(): Promise<void> {
    this.onCloseCreateModalForm();
    await this.loadData();
  }

  public renameFolder(folder: MailFolderContractWithFullNameForResponse): void {
    this.parentMailFolderTypeForCreation = folder.folderType;
    this.editingFolder = folder;
    this.drawerService.openDrawer(this.modifyDrawer);
  }

  public onCloseRenameModalForm(): void {
    this.editingFolder = null;
  }

  public async onSubmitRename(): Promise<void> {
    this.onCloseRenameModalForm();
    await this.loadData();
  }

  public async onDeleteBtnClicked(mailFolderId: number): Promise<void> {
    await this.mailFolderClient.delete({ id: mailFolderId });
  }

  public deleteFolder(folder: MailFolderContractWithFullNameForResponse): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '450px',
      autoFocus: false,
      data: {
        title: 'confirmFolderDeletion',
        subTitles: [
          {
            title: 'folderDeletion',
            subTitle: folder.fullName,
          },
        ],
        onDelete: async () =>
          await this.onDeleteBtnClicked(folder.mailFolderId),
      },
    });

    dialogRef.afterClosed().subscribe(x => {
      if (x.isDeleted) {
        this.folders = this.folders.filter(
          f => f.mailFolderId !== folder.mailFolderId
        );
        this.dataSource.data = this.folders;
      }
    });
  }

  public openFolderSettings(
    folder: MailFolderContractWithFullNameForResponse
  ): void {
    this.editingFolder = folder;
    this.drawerService.openDrawer(this.settingsDrawer);
  }

  public onCloseSettingsModalForm(): void {
    this.editingFolder = null;
  }

  public async onSubmitSettings(): Promise<void> {
    this.onCloseSettingsModalForm();
    await this.loadData();
  }
}
