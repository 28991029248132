<div class="condtion-wrapper">
  <mat-accordion>
    <mat-expansion-panel class="mat-elevation-z0" [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="drawer-content-screen__sub-title">{{ "conditions" | translate }}</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="overflow-auto">
        @for(condition of showingConditions; track condition) {
          <app-dispatching-condition
            [header]="condition.header"
            [paramName]="condition.paramName"
            [emailCondition]="getDispatchingConditions(condition.paramName)">
          </app-dispatching-condition>
        }
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
