@if(createOrUpdateDrawerIsOpen && !!updatedEmailId) {
  <app-email-create-or-update
    [mailAccountId]="mailAccountId"
    [emailId]="updatedEmailId"
    [useMobileView]="useMobileView"
    (submitted)="closeEmailModalForm()">
  </app-email-create-or-update>
}

@if(emailDrawerIsOpen && !!openedEmail && !!openedEmail.emailId) {
  <app-email-detail-dialog
    [emailId]="openedEmail.emailId"
    (closeDrawer)="closeEmailModalForm()"
    [useMobileView]="useMobileView">
  </app-email-detail-dialog>
}

@if(cannotLoadEmailDetails) {
  <div class="h-full w-full">
    <app-something-went-wrong
    [showPicture]="false"
    (retryEmitter)="ngOnInit()"
    ></app-something-went-wrong>
  </div>
}

@if(isLoading) {
  <app-skeleton-email></app-skeleton-email>
}

@if(!createOrUpdateDrawerIsOpen && !emailDrawerIsOpen) {
  <div (click)="checkingTheActiveElement()" class="flex justify-between h-full">
    @if(!isLoading && !cannotLoadEmailDetails) {
      <div class="w-full h-full flex flex-col overflow-x-clip">
        <div class="flex relative">
          <span class="absolute custom-top">{{ "from" | translate }}</span>
          <span class="subject">{{ emailDetails.from }}</span>
        </div>
        <div class="flex relative">
          <span class="absolute custom-top">{{ "to" | translate }}</span>
          <span class="subject subject_break-all">{{ formatTo(emailDetails.to) }}</span>
        </div>
        @if(emailDetails.cc) {
          <div class="flex relative">
            <span class="absolute custom-top">{{ "cc" | translate }}</span>
            <span class="subject subject_break-all">{{ formatTo(emailDetails.cc) }}</span>
          </div>
        }
        @if(emailDetails.subject) {
          <div class="flex relative">
            <span class="absolute custom-top">{{ "subject" | translate }}</span>
            <span class="subject subject_break-all">{{ emailDetails.subject }}</span>
          </div>
        }
        <div class="flex relative">
          <span class="absolute custom-top">{{ "date" | translate }}</span>
          <span class="subject">{{ getDate(emailDetails) | date : "dd/MM/yyyy HH:mm:ss" }}</span>
        </div>

        @if(haveAttachments()) {
          <div class="flex relative min-h-[40px]">
            <span class="absolute custom-top">{{ "attach" | translate }}</span>
            <div class="subject attachments" type="text">
              <div class="flex flex-wrap">
                @for(file of emailDetails.attachmentFiles; track file) {
                  <div class="file">
                    @if(file.fileState === attachmentState.CheckInProgress || file.fileState === attachmentState.Uploaded) {
                      <mat-icon
                        [matTooltip]="'scaningForVirus' | translate"
                        style="transform: scale(0.65); color: rgb(233, 162, 21); cursor: default"
                        mat-icon-button
                      >
                        bolt
                      </mat-icon>
                    }
                    <span
                     (click)="!isFileDownloading(file?.fileId) && getAttachmentFile(file)"
                     [style.color]="!isFileDownloading(file?.fileId) ? '' : 'grey'"
                     class="attach-text"
                     [style.cursor]="!isFileDownloading(file?.fileId) ? 'pointer' : 'default'"
                    >
                      {{ file.fileName }}
                    </span>
                    @if(isFileDownloading(file?.fileId)) {
                      <mat-progress-spinner
                        class="w-[24px] ml-[3px]"
                        mode="indeterminate"
                        diameter="16"
                      ></mat-progress-spinner>
                    }
                  </div>
                }
              </div>
            </div>
          </div>
        }
        <div class="flex relative w-full">
          <span class="absolute custom-top custom-top_tags">{{ "tags" | translate }}</span>
          <div
            [ngStyle]="{ 'max-height': isCurrentElementTags ? '165px' : '60px' }"
            class="w-full app-tags tags-main"
          >
            <app-tags
              [emailId]="[emailId]"
              [existedTags]="existedTags"
              [thisTags]="thisTags"
              [tagCtrl]="tagCtrl"
            ></app-tags>
          </div>
        </div>
        <div class="drawer-content-screen__body mt-[2px]">
          <app-email-body
            [disabled]="true"
            [body]="body"
            class="h-full"
          ></app-email-body>
        </div>

        @if(!useMobileView) {
          <div
            style="overflow: initial"
            class="icon-button-group mr-2 flex justify-between items-center h-[80px] mt-2 overflow-hidden"
          >
            <div class="flex icon-button-group mr-2">
              <app-loading-icon-button
                label="reply"
                icon="reply_outline"
                [showButton]="
                  !isReplying &&
                  showIcon([
                    mailFolderType.Inbox,
                    mailFolderType.Sent,
                    mailFolderType.Validation,
                    mailFolderType.Spam,
                    mailFolderType.Trash,
                    mailFolderType.Archive
                  ]) &&
                  emailDetails.state !== 7
                "
                [showLoading]="isReplying"
                [disabled]="isForwarding || isReplyingAll"
                (clickButton)="reply()">
              </app-loading-icon-button>

              <app-loading-icon-button
                label="replyAll"
                icon="reply_all_outline"
                [showButton]="
                  !isReplyingAll &&
                  showIcon([
                    mailFolderType.Inbox,
                    mailFolderType.Sent,
                    mailFolderType.Validation,
                    mailFolderType.Spam,
                    mailFolderType.Trash,
                    mailFolderType.Archive
                  ]) &&
                  emailDetails.state !== 7
                "
                [showLoading]="isReplyingAll"
                [disabled]="isReplying || isForwarding"
                (clickButton)="replyAll()">
              </app-loading-icon-button>
              <app-loading-icon-button
                label="forward"
                icon="shortcut"
                [showButton]="
                  !isForwarding &&
                  showIcon([
                    mailFolderType.Inbox,
                    mailFolderType.Sent,
                    mailFolderType.Validation,
                    mailFolderType.Spam,
                    mailFolderType.Trash,
                    mailFolderType.Archive
                  ]) &&
                  emailDetails.state !== 7
                "
                [showLoading]="isForwarding"
                [disabled]="isReplying || isReplyingAll"
                (clickButton)="forward()">
              </app-loading-icon-button>
              @if(canValidate && emailDetails.state === 7) {
                <div>
                  <div class="button-group">
                    @if(canValidate && emailDetails.state === 7) {
                      <button
                        color="primary"
                        class="page-block-button"
                        mat-flat-button
                        aria-label="Approve"
                        (click)="approve()"
                        [disabled]="approveInProgress || rejectInProgress"
                        matTooltip="{{ 'approve' | translate }}">
                        @if(!approveInProgress) {
                          <mat-icon class="mb-[3px]">check</mat-icon>
                        }
                        <app-loading-button text="{{ 'approveAndSend' | translate }}" [loading]="approveInProgress"></app-loading-button>
                      </button>
                    }
                    @if(canValidate && emailDetails.state === 7) {
                      <button
                        color="primary"
                        class="page-block-button"
                        mat-flat-button
                        aria-label="Reject"
                        (click)="reject()"
                        [disabled]="approveInProgress || rejectInProgress"
                        matTooltip="{{ 'reject' | translate }}">
                        @if(!rejectInProgress) {
                          <mat-icon class="mb-[3px]">clear</mat-icon>
                        }
                        <app-loading-button text="{{ 'rejectApproval' | translate }}" [loading]="rejectInProgress"></app-loading-button>
                      </button>
                    }
                  </div>
                </div>
              }
            </div>
            <div class="flex items-center">
              <button mat-icon-button aria-label="Audit" (click)="audit()" matTooltip="{{ 'audit' | translate }}">
                <mat-icon>verified_outline</mat-icon>
              </button>
              @if(
                (showIcon([
                  mailFolderType.Inbox,
                  mailFolderType.Sent,
                  mailFolderType.Draft,
                  mailFolderType.Validation,
                  mailFolderType.Spam,
                  mailFolderType.Archive
                ]) ||
                emailDetails.state === emailStates.VirusDetected) && !emailDetails.deleted
              ) {
                <button
                  mat-icon-button
                  aria-label="Delete"
                  (click)="delete()"
                  matTooltip="{{ 'delete' | translate }}">
                  <mat-icon>delete_outline</mat-icon>
                </button>
              }

              @if(showIcon([mailFolderType.Trash]) && emailDetails.deleted) {
                <button
                  mat-icon-button
                  aria-label="Restore"
                  (click)="restoreFromTrash()"
                  matTooltip="{{ 'restore' | translate }}">
                  <mat-icon>restore_outline</mat-icon>
                </button>
              }

              @if(showIcon([mailFolderType.Inbox]) && !emailDetails.isSpam) {
                <button
                  mat-icon-button
                  aria-label="Mark as spam"
                  (click)="isSpam()"
                  matTooltip="{{ 'markAsSpam' | translate }}">
                  <mat-icon>report_outline</mat-icon>
                </button>
              }

              @if(showIcon([mailFolderType.Spam]) && emailDetails.isSpam) {
                <button
                  mat-icon-button
                  aria-label="Mark as not spam"
                  (click)="restoreMoveFolderChoose([emailId], 'restoreOneSpam')"
                  matTooltip="{{ 'markAsNotSpam' | translate }}">
                  <mat-icon>check_circle_outline</mat-icon>
                </button>
              }

              @if(
              showIcon([
                mailFolderType.Inbox,
                mailFolderType.Sent,
                mailFolderType.Validation,
                mailFolderType.Spam,
                mailFolderType.Archive
              ]) && emailDetails.seen) {
                <button
                 mat-icon-button
                 aria-label="Unread"
                 (click)="setSeen(false)"
                 matTooltip="{{ 'unread' | translate }}">
                  <mat-icon>visibility_off_outline</mat-icon>
                </button>
              }

              @if(
                showIcon([
                  mailFolderType.Inbox,
                  mailFolderType.Sent,
                  mailFolderType.Validation,
                  mailFolderType.Spam,
                  mailFolderType.Archive
                ]) && !emailDetails.seen
              ) {
                <button
                  mat-icon-button
                  aria-label="Read"
                  (click)="setSeen(true)"
                  matTooltip="{{ 'read' | translate }}">
                  <mat-icon>visibility_outline</mat-icon>
                </button>
              }
              <app-loading-icon-button
                label="validate"
                icon="check_circle_outline"
                [showButton]="
                  showIcon([mailFolderType.Inbox]) &&
                  (emailDetails.validationState === emailValidationState.NotValidated ||
                    emailDetails.validationState === emailValidationState.New) &&
                  !validatingEmail
                "
                [showLoading]="validatingEmail"
                (clickButton)="setValidated()">
              </app-loading-icon-button>

              <button mat-icon-button aria-label="Print" (click)="print()" matTooltip="{{ 'print' | translate }}">
                <mat-icon>print_outline</mat-icon>
              </button>

              <app-loading-icon-button
                label="downloadSingleEml"
                icon="download"
                [showButton]="
                  showIcon([mailFolderType.Inbox, mailFolderType.Sent, mailFolderType.Validation, mailFolderType.Spam, mailFolderType.Archive]) &&
                  !isDownloadingSingleEML
                "
                [showLoading]="isDownloadingSingleEML"
                (clickButton)="downloadSingleEML()">
              </app-loading-icon-button>
              <app-loading-icon-button
                label="downloadEml"
                icon="download_for_offline"
                [showButton]="
                  showIcon([mailFolderType.Inbox, mailFolderType.Sent, mailFolderType.Validation, mailFolderType.Spam, mailFolderType.Archive]) &&
                  !isDownloadingEML
                "
                [showLoading]="isDownloadingEML"
                (clickButton)="downloadEML()">
              </app-loading-icon-button>

              @if(haveAttachments()) {
                <button
                  mat-icon-button
                  aria-label="Open attachments"
                  (click)="showAttachments()"
                  matTooltip="{{ 'openAttachments' | translate }}">
                  <mat-icon>attachment_outline</mat-icon>
                </button>
              }

              <button mat-icon-button aria-label="Open notes" (click)="showNotes()" matTooltip="{{ 'openNotes' | translate }}">
                <mat-icon>note_outline</mat-icon>
              </button>

              @if(showIcon([
                mailFolderType.Sent,
                mailFolderType.Archive,
                mailFolderType.Outbox,
                mailFolderType.Trash
              ])) {
                <button
                  mat-icon-button
                  aria-label="Open notifications"
                  (click)="showNotifications()"
                  matTooltip="{{ 'openNotifications' | translate }}">
                  <mat-icon>notifications_outline</mat-icon>
                </button>
              }
            </div>
          </div>
        }

        @if(useMobileView) {
          <div>
            @if(
              showIcon([
                mailFolderType.Inbox,
                mailFolderType.Sent,
                mailFolderType.Validation,
                mailFolderType.Spam,
                mailFolderType.Trash,
                mailFolderType.Archive
              ]) && emailDetails.state !== 7
            ) {
              <button
                mat-icon-button
                (click)="reply()"
                matTooltip="{{ 'reply' | translate }}">
                <mat-icon>reply_outline</mat-icon>
              </button>
            }

            @if(
              showIcon([
                mailFolderType.Inbox,
                mailFolderType.Sent,
                mailFolderType.Validation,
                mailFolderType.Spam,
                mailFolderType.Trash,
                mailFolderType.Archive
              ]) && emailDetails.state !== 7
            ) {
              <button
                mat-icon-button
                (click)="replyAll()"
                matTooltip="{{ 'replyAll' | translate }}">
                <mat-icon>reply_all_outline</mat-icon>
              </button>
            }

            @if(haveAttachments()) {
              <button
                mat-icon-button
                aria-label="Open attachments"
                (click)="showAttachments()"
                matTooltip="{{ 'openAttachments' | translate }}">
                <mat-icon>attachment_outline</mat-icon>
              </button>
            }

            <button mat-icon-button [attr.aria-label]="'openNotes' | translate" (click)="showNotes()" matTooltip="Open notes">
              <mat-icon>note_outline</mat-icon>
            </button>

            <button mat-icon-button aria-label="Menu" [matMenuTriggerFor]="menu" matTooltip="{{ 'menu' | translate }}">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              @if(
                showIcon([
                  mailFolderType.Inbox,
                  mailFolderType.Sent,
                  mailFolderType.Validation,
                  mailFolderType.Spam,
                  mailFolderType.Trash,
                  mailFolderType.Archive
                ]) && emailDetails.state !== 7
              ) {
                <button
                  mat-menu-item
                  (click)="forward()"
                  [disableRipple]="true">
                  <mat-icon>shortcut</mat-icon>
                   {{ "forward" | translate }}
                </button>
              }
              <button mat-menu-item (click)="audit()" [disableRipple]="true">
                <mat-icon>verified_outline</mat-icon>{{ "audit" | translate }}
              </button>

              @if(
                showIcon([mailFolderType.Inbox]) &&
                (emailDetails.validationState === emailValidationState.NotValidated ||
                emailDetails.validationState === emailValidationState.New)
              ) {
                <button
                  mat-menu-item
                  [disableRipple]="true"
                  (click)="setValidated()">
                  <mat-icon>check_circle_outline</mat-icon>{{ "validate" | translate }}
                </button>
              }

              @if(
              (showIcon([
                mailFolderType.Inbox,
                mailFolderType.Sent,
                mailFolderType.Draft,
                mailFolderType.Validation,
                mailFolderType.Spam,
                mailFolderType.Archive
                ]) ||
                emailDetails.state === emailStates.VirusDetected) &&
                !emailDetails.deleted
              ) {
                <button
                  mat-menu-item
                  (click)="delete()"
                  [disableRipple]="true">
                  <mat-icon>delete</mat-icon>
                  {{ "delete" | translate }}
                </button>
              }

              @if(showIcon([mailFolderType.Trash]) && emailDetails.deleted) {
                <button
                  mat-menu-item
                  (click)="restoreFromTrash()"
                  [disableRipple]="true">
                  <mat-icon>restore_outline</mat-icon>
                  {{ "restore" | translate }}
                </button>
              }

              @if(showIcon([mailFolderType.Inbox]) && !emailDetails.isSpam) {
                <button
                  mat-menu-item
                  (click)="isSpam()"
                  [disableRipple]="true">
                  <mat-icon>report_outline</mat-icon>
                  {{ "markAsSpam" | translate }}
                </button>
              }

              @if(showIcon([mailFolderType.Spam]) && emailDetails.isSpam) {
                <button
                  mat-menu-item
                  (click)="restoreMoveFolderChoose([emailId], 'restoreOneSpam')"
                  [disableRipple]="true">
                  <mat-icon>check</mat-icon>
                  {{ "markAsNotSpam" | translate }}
                </button>
              }

              @if(
              showIcon([mailFolderType.Inbox, mailFolderType.Sent, mailFolderType.Validation, mailFolderType.Spam, mailFolderType.Archive]) &&
              emailDetails.seen) {
                <button
                  mat-menu-item
                  (click)="setSeen(false)"
                  [disableRipple]="true">
                  <mat-icon>visibility_off_outline</mat-icon>
                  {{ "markAsUnread" | translate }}
                </button>
              }

              @if(showIcon([mailFolderType.Inbox, mailFolderType.Sent, mailFolderType.Validation, mailFolderType.Spam, mailFolderType.Archive]) &&
              !emailDetails.seen) {
                <button
                  mat-menu-item
                  (click)="setSeen(true)"
                  [disableRipple]="true">
                  <mat-icon>visibility_outline</mat-icon>
                  {{ "markAsRead" | translate }}
                </button>
              }

              @if(
                showIcon([mailFolderType.Inbox]) &&
                (emailDetails.validationState === emailValidationState.NotValidated ||
                emailDetails.validationState === emailValidationState.New)
              ) {
                <button
                  mat-menu-item
                  (click)="setValidated()"
                  [disableRipple]="true">
                  <mat-icon>check_circle_outline</mat-icon>
                  {{ "validate" | translate }}
                </button>
              }

              @if(canValidate && emailDetails.state === 7) {
                <button mat-menu-item (click)="approve()" [disableRipple]="true">
                  <mat-icon>check</mat-icon>{{ "approve" | translate }}
                </button>
              }

              @if(canValidate && emailDetails.state === 7) {
                <button mat-menu-item (click)="approve()" [disableRipple]="true">
                  <mat-icon>clear</mat-icon>{{ "reject" | translate }}
                </button>
              }

              <button mat-menu-item (click)="print()" [disableRipple]="true"><mat-icon>print_outline</mat-icon>{{ "print" | translate }}</button>
              @if(showIcon([
                mailFolderType.Sent,
                mailFolderType.Archive,
                mailFolderType.Outbox,
                mailFolderType.Trash
              ])) {
                <button mat-menu-item (click)="showNotifications()" [disableRipple]="true">
                  <mat-icon>notifications_outline</mat-icon>{{ "openNotifications" | translate }}
                </button>
              }

              @if(showIcon([mailFolderType.Inbox, mailFolderType.Sent, mailFolderType.Validation, mailFolderType.Spam, mailFolderType.Archive])) {
              <button
                mat-menu-item
                (click)="downloadSingleEML()"
                [disableRipple]="true">
                <mat-icon>download</mat-icon>
                {{ "downloadSingleEml" | translate }}
              </button>
              }

              @if(showIcon([mailFolderType.Inbox, mailFolderType.Sent, mailFolderType.Validation, mailFolderType.Spam, mailFolderType.Archive])) {
                <button
                  mat-menu-item
                  (click)="downloadEML()"
                  [disableRipple]="true">
                  <mat-icon>download_for_offline</mat-icon>
                  {{ "downloadEml" | translate }}
                </button>
              }
            </mat-menu>
          </div>
        }
      </div>
    }
    <app-email-sidenav
      [menuContent]="menuContent"
      [useMobileView]="useMobileView"
      [emailId]="emailId"
      [mailAccountId]="mailAccountId"
      [menuIsOpened]="menuIsOpened"
      [emailDetails]="emailDetails"
      (closeMenu)="closeMenu()"
      (openEmailDetails)="openEmailDetails($event)"
    ></app-email-sidenav>
  </div>
}

