import { Injectable } from '@angular/core';
import { environment } from '@app-environments/environment';
import { ApiRequest } from '../api.request';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import {
  BaseCollectionSearchByIdRequest,
  BaseGetByIdRequest,
  BaseGetCollectionResponse,
  BaseGetSearchCountByIdRequest,
  BaseId,
  BaseResponse,
} from '@app-types/base/base';
import {
  DispatchingRule,
  DispatchingRuleAvailableDataResponse,
  DispatchingRuleCreateRequest,
  DispatchingRuleUpdateRequest,
} from '@app-types/api/Dispatching';

const API_URL = environment.apiUrl;
const { dispatchingRule } = API_ROUTES;
const {
  create,
  update,
  searchForAccount,
  getSearchForAccountCount,
  getAvailableDataForAccount,
} = dispatchingRule;

@Injectable({
  providedIn: 'root',
})
export class DispatchingRuleClient {
  constructor(private apiRequest: ApiRequest) {}

  async searchForAccount(
    request: BaseCollectionSearchByIdRequest
  ): Promise<BaseGetCollectionResponse<DispatchingRule>> {
    return this.apiRequest.get(
      `${API_URL}${searchForAccount}?${request.format()}`
    );
  }

  async getSearchForAccountCount(
    request: BaseGetSearchCountByIdRequest
  ): Promise<BaseResponse<number>> {
    return this.apiRequest.get(
      `${API_URL}${getSearchForAccountCount}?${request.format()}`
    );
  }

  async getAvailableDataForAccount(
    request: BaseGetByIdRequest
  ): Promise<BaseResponse<DispatchingRuleAvailableDataResponse>> {
    return this.apiRequest.get(
      `${API_URL}${getAvailableDataForAccount}?${request.format()}`
    );
  }

  async create(request: DispatchingRuleCreateRequest): Promise<BaseId> {
    return this.apiRequest.post<DispatchingRuleCreateRequest, BaseId>(
      request,
      `${API_URL}${create}`
    );
  }

  async update(request: DispatchingRuleUpdateRequest): Promise<BaseId> {
    return this.apiRequest.post<DispatchingRuleUpdateRequest, BaseId>(
      request,
      `${API_URL}${update}`
    );
  }

  async delete(request: BaseId): Promise<BaseId> {
    return this.apiRequest.post<BaseId, BaseId>(
      request,
      `${API_URL}${dispatchingRule.delete}`
    );
  }
}
