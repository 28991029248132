import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { UserMailAccountContract } from '@app-types/api/user-mail-account';
import { MailAccountClient } from '@app-services/api/clients/mail-account.client';
import { ActivatedRoute } from '@angular/router';
import { BaseGetByFileIdRequest } from '@app-types/base/base';
import { FileClient } from '@app-services/api/clients/file.client';
import { TempFileClient } from '@app-services/api/clients/temp-file.client';
import { ReportCreateRequest, ReportResponse } from '@app-types/api/report';
import { ReportClient } from '@app-services/api/clients/report.client';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { ReportType } from '@app-types/common';
import { SomethingWentWrongComponent } from '@app-components/common/error/something-went-wrong/something-went-wrong.component';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingButtonComponent } from '@app-components/common/loading-button/loading-button.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { REPORTS } from '@app-shared/constants/reports';
import { UserWorkspaceService } from '@app-services/user-workspace-service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  standalone: true,
  imports: [
    SomethingWentWrongComponent,
    TranslateModule,
    LoadingButtonComponent,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
  ],
})
export class ReportsComponent implements OnInit {
  public form: UntypedFormGroup = new UntypedFormGroup({
    range: new UntypedFormGroup({
      start: new UntypedFormControl(''),
      end: new UntypedFormControl(''),
    }),
    reportType: new UntypedFormControl('', Validators.required),
    mailsControl: new UntypedFormControl('', Validators.required),
  });
  @ViewChild('select') select: MatSelect;
  @ViewChild('allSelected') private allSelected: MatOption;

  public isSendingRequest = false;
  public accounts: UserMailAccountContract[] = [];
  public reportTypes: ReportType[] = REPORTS;
  public reportObject: ReportCreateRequest;

  constructor(
    private mailAccountClient: MailAccountClient,
    private route: ActivatedRoute,
    private fileClient: FileClient,
    private tempFileClient: TempFileClient,
    private reportClient: ReportClient,
    private matchError: MatchError,
    public userWorkspaceService: UserWorkspaceService
  ) {}

  async ngOnInit(): Promise<void> {
    this.accounts = this.userWorkspaceService.mailAccounts;
  }

  async getAttachmentFile(fileObj: ReportResponse): Promise<void> {
    const request = new BaseGetByFileIdRequest(fileObj.result.fileId);
    await this.tempFileClient.download(request);
  }

  async createReportRequest(): Promise<void> {
    this.isSendingRequest = true;
    try {
      const response = await this.reportClient.create(this.reportObject);
      await this.getAttachmentFile(response);
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isSendingRequest = false;
    }
  }

  onSubmit(): void {
    const values = this.form.value;
    const mailIds: number[] = [];
    // Getting selected email ids
    values.mailsControl.forEach((item: any) => {
      if (item !== 0) {
        const currEmail = this.accounts.find(
          acc => acc.mailAccountEmail === item
        );
        currEmail?.mailAccountId && mailIds.push(currEmail.mailAccountId);
      }
    });

    this.reportObject = {
      reportParameters: {
        mailAccountIds: mailIds,
        reportType: values.reportType,
        mailDateTimeEnd: values.range.end
          ? values.range.end.toISOString()
          : null,
        mailDateTimeStart: values.range.start
          ? values.range.start.toISOString()
          : null,
      },
      async: false,
    };

    this.createReportRequest();
  }

  togglePerOne(): void {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
      return;
    }
    if (this.form.controls.mailsControl.value.length === this.accounts.length) {
      this.allSelected.select();
    }
  }

  toggleAllSelection(): void {
    if (this.allSelected.selected) {
      this.form.controls.mailsControl.patchValue([
        ...this.accounts.map(item => item.mailAccountEmail),
        0,
      ]);
    } else {
      this.form.controls.mailsControl.patchValue([]);
    }
  }

  hasErrorReports(controlName: string): boolean {
    const rangeControl = this.form.get('range') as FormGroup;
    return rangeControl.controls.start.hasError(controlName);
  }
}
