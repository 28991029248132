import { Injectable, TemplateRef } from '@angular/core';
import { DrawerComponent } from '../components/common/drawer/drawer.component';

@Injectable({
  providedIn: 'root',
})
export class DrawerService {
  private drawerComponent!: DrawerComponent;
  callbackClose: (() => void) | null = null;
  className: string;

  setDrawerComponent(drawer: DrawerComponent): void {
    this.drawerComponent = drawer;
  }

  setDrawerClass(className: string): void {
    if (this.drawerComponent) {
      (
        this.drawerComponent as any
      ).drawer._elementRef.nativeElement.classList.add(className);
    }
  }

  deleteDrawerClass(): void {
    if (this.drawerComponent) {
      (
        this.drawerComponent as any
      ).drawer._elementRef.nativeElement.classList.remove(this.className);
    }
  }

  openDrawer(
    template: TemplateRef<any>,
    callbackClose?: (() => void) | null,
    className: string = 'default-w'
  ): void {
    this.className !== 'default-w' && this.deleteDrawerClass();
    this.className = className;
    this.setDrawerClass(className);
    callbackClose && (this.callbackClose = callbackClose);
    this.drawerComponent?.openDrawer(template);
  }

  closeDrawerWithCallback(): void {
    this.callbackClose
      ? this.callbackClose()
      : this.drawerComponent?.closeDrawer();
  }

  closeDrawer(): void {
    this.drawerComponent?.closeDrawer();
  }

  disabledDrawer(isDisable: boolean): void {
    this.drawerComponent?.disableCloseDrawer(isDisable);
  }
}
