@if(isError) {
  <div class="h-[80%]">
    <app-something-went-wrong
      description="{{ 'cannotLoadDispatchingRules' | translate }}"
      [showPicture]="false"
      (retryEmitter)="loadDispatchingRules(true)"
    ></app-something-went-wrong>
  </div>
}

@if(!isError) {
  <div class="flex-col-container-1">
    <div class="search-panel">
      <div class="button-group h-[40px]">
        <button
          mat-raised-button
          color="primary"
          class="page-block-button"
          aria-label="Create dispatching rule"
          (click)="openCreateModalForm()">
          <mat-icon class="{{useMobileView ? '!m-0' : '' }}">add</mat-icon>{{ useMobileView ? '' : ("newDispatchingRule" | translate) }}
        </button>
      </div>
      <div class="search-bar">
        <app-search-bar
          [fullWidth]="useMobileView"
          (searchEventEmitter)="loadDispatchingRules(true)"
          (valueChangedEventEmitter)="onSearchValueChanged($event)"
          [search]="searchValue"
        ></app-search-bar>
        @if(!useMobileView) {
          <mat-paginator
            [pageSize]="pageSize"
            [pageSizeOptions]="[25, 50, 100]"
            [length]="totalSize"
            [pageIndex]="pageIndex"
            (page)="handlePage($event)">
          </mat-paginator>
        }
      </div>
    </div>
  </div>
}
@if(useMobileView) {
  <div>
    <mat-paginator
      [pageSize]="pageSize"
      [pageSizeOptions]="[25, 50, 100]"
      [length]="totalSize"
      [pageIndex]="pageIndex"
      (page)="handlePage($event)">
    </mat-paginator>
    <div class="mobile-container-cards">
      @if(!isLoading && !isError && isUploaded) {
        @if(!!dispatchingRules.length) {
          @for(dispatchingRule of dispatchingRules; track dispatchingRule) {
            <div>
              <div class="card">
                <mat-card class="mobile-row">
                  <mat-card-content (click)="openEditModalForm(dispatchingRule)" class="cursor-default flex flex-1">
                    <div class="card-table-row">
                      <div class="card-table-row-header">{{ "name" | translate }}:</div>
                      <div class="card-table-row-value">{{ dispatchingRule.dispatchingRule?.name ?? '' }}</div>
                    </div>
                    <div class="card-table-row">
                      <div class="card-table-row-header">{{ "priority" | translate }}:</div>
                      <div class="card-table-row-value">{{ dispatchingRule.dispatchingRule?.priority ?? '' }}</div>
                    </div>
                  </mat-card-content>
                  <div class="icons-mobile-block">
                    <button
                      mat-icon-button
                      matTooltip="{{ 'editDispatchingRuleLabel' | translate }}"
                      color="primary"
                      [attr.aria-label]="'editDispatchingRuleLabel' | translate"
                      (click)="openEditModalForm(dispatchingRule)">
                      <mat-icon>edit_outline</mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      matTooltip="{{ 'delete' | translate }}"
                      color="primary"
                      aria-label="Delete"
                      (click)="deleteDispatchingRule(dispatchingRule?.dispatchingRuleId, dispatchingRule?.dispatchingRule?.name)">
                      <mat-icon>delete_outline</mat-icon>
                    </button>
                  </div>
                </mat-card>
              </div>
            </div>
          }
        }
      }
    </div>
    @if(dispatchingRules.length === 0 && !isLoading && !isError) {
       <app-no-data></app-no-data>
    }
  </div>
}
@if(!useMobileView) {
  <div>
    <div class="table-container">
      @if(!isLoading && !isError && isUploaded) {
        <table mat-table [dataSource]="dataSource" class="w-full">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>{{ "name" | translate }}</th>
            <td mat-cell *matCellDef="let dispatchingRules">{{ dispatchingRules.dispatchingRule.name }}</td>
          </ng-container>
          <ng-container matColumnDef="priority">
            <th mat-header-cell *matHeaderCellDef>{{ "priority" | translate }}</th>
            <td mat-cell *matCellDef="let dispatchingRules">{{ dispatchingRules.dispatchingRule.priority }}</td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>{{ "actions" | translate }}</th>
            <td mat-cell *matCellDef="let dispatchingRules">
              <button
                mat-icon-button
                [attr.aria-label]="'editDispatchingRuleLabel' | translate"
                matTooltip="{{ 'editDispatchingRuleLabel' | translate }}"
                (click)="openEditModalForm(dispatchingRules)">
                <mat-icon>edit_outline</mat-icon>
              </button>
              <span class="mr-2"></span>
              <button
                mat-icon-button
                aria-label="Delete"
                matTooltip="{{ 'delete' | translate }}"
                (click)="deleteDispatchingRule(dispatchingRules?.dispatchingRuleId, dispatchingRules?.dispatchingRule?.name)">
                <mat-icon>delete_outline</mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" class="cursor-default"></tr>
        </table>
        @if(dispatchingRules.length === 0) {
          <app-no-data></app-no-data>
        }
      }
    </div>
  </div>
}

@if(isLoading) {
  <div class="overflow-hidden flex-1">
    <app-skeleton-table></app-skeleton-table>
  </div>
}

<ng-template #createDrawer>
  <app-dispatching-rule-create-or-update-dialog
    (closeEventEmitter)="onCloseEditModalForm()"
    (submitEventEmitter)="editingDispatchingRule ? onEditDispatchingRule() : onCreateDispatchingRule()"
    [updatingDispatchingRule]="editingDispatchingRule"
    [mailAccountId]="mailAccountId">
  </app-dispatching-rule-create-or-update-dialog>
</ng-template>
