import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-close-upload-dialog',
  templateUrl: './close-upload-dialog.component.html',
  styleUrls: ['./close-upload-dialog.component.scss'],
  standalone: true,
  imports: [MatButtonModule, TranslateModule, MatDialogModule],
})
export class CloseUploadDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CloseUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) {}

  onCancelBtnClicked(isClose: boolean): void {
    this.dialogRef.close({ isClose });
  }
}
