import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { AuthenticationClient } from '../api/clients/authentication.client';
import { MatchError } from '../errors/error-matcher';
import { LoggingHandler } from '../errors/logging.service';

@Injectable({ providedIn: 'root' })
export class Permissions {
  constructor(
    protected router: Router,
    protected authenticationService: AuthenticationService,
    protected authenticationClient: AuthenticationClient,
    protected matchError: MatchError,
    protected loggingHandler: LoggingHandler
  ) {}

  public async authentication(webCode: string): Promise<boolean> {
    const authTypes = await this.authenticationService.loadAuthTypes(webCode);
    return (
      !!authTypes && this.authenticationService.redirectAuth(authTypes, webCode)
    );
  }

  async canActivate(route: ActivatedRouteSnapshot): Promise<any> {
    if (this.authenticationService.isLoggedIn()) {
      return true;
    }
    const webCode = route.params['webCode'];
    if (!webCode) {
      await this.loggingHandler.create('information', 'redirect !webCode');
      await this.router.navigateByUrl(`/`);
      return false;
    }
    return await this.authentication(webCode);
  }
}

export const AuthGuard: CanActivateFn = (route): Promise<any> => {
  return inject(Permissions).canActivate(route);
};
