<div class="close-button">
  <button tabIndex="-1" class="" mat-icon-button (click)="cancel()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<h1 mat-dialog-title>{{ data.title ? (data.title | translate) : ("moveToFolderTitle" | translate) }}</h1>
<mat-dialog-content class="mat-mdc-dialog-content mdc-dialog__content mat-container">
  @if(isLoading) {
    <div>
      <ngx-skeleton-loader
        [count]="1"
        [theme]="{
          'margin-bottom': 0,
          height: '74px',
          width: '100%',
        }"
      ></ngx-skeleton-loader>
    </div>
  }

  @if(cannotLoad) {
    <div>
      <app-something-went-wrong
        [showPicture]="false"
        description="{{ 'cannotLoadDataError' | translate }}"
        (retryEmitter)="loadFolders()"
      >
      </app-something-went-wrong>
    </div>
  }

  @if(!isLoading && !cannotLoad) {
    <div>
      @if(selectedFolderId) {
        <mat-form-field>
          <mat-label>{{ "folder" | translate }}</mat-label>
          <mat-select [(value)]="selectedFolderId">
            @for(folder of folders; track folder) {
            <mat-option [value]="folder.mailFolderId">
            {{ folder.creationType === 1 ? ("folderType" + folder.folderType | translate) : folder.name }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      }
      @if(cannotSave) {
        <app-error-hint
          errorText="{{ 'cannotMoveErrorPopupSubtitle' | translate }}"
          [centerAlign]="false"
        ></app-error-hint>
      }
    </div>
  }
</mat-dialog-content>
<mat-dialog-actions align="end" class="mat-mdc-dialog-actions mdc-dialog__actions">
  <button
    mat-button
    mat-flat-button
    color="primary"
    (click)="confirm()"
    [disabled]="moveInProgress || isLoading || cannotLoad"
  >
    <app-loading-button text="{{ 'move' | translate }}" [loading]="moveInProgress"></app-loading-button>
  </button>
</mat-dialog-actions>
