<div class="drawer-content-screen">
  @if(!isLoadingGroups && !cannotLoadGroups && isUploaded) {
    <div class="drawer-content-screen__title">
      {{ "add" | translate }} {{ users.length > 1 ? ("users" | translate) : ("user" | translate) }} {{ "toGroups" | translate }}
    </div>
  }

  @if(isLoadingGroups) {
    <div class="h-full">
      <app-skeleton-form-drawer></app-skeleton-form-drawer>
    </div>
  }

  @if(cannotLoadGroups) {
    <div class="flex-col-center">
      <app-something-went-wrong
        description="{{ 'cannotLoadGroups' | translate }}. {{ 'wentWrong' | translate }}"
        (retryEmitter)="fetchGroups()"
        [showPicture]="false"
      ></app-something-went-wrong>
    </div>
  }

  @if(!isLoadingGroups && !cannotLoadGroups && isUploaded) {
    <div class="h-full w-full overflow-auto">
      <app-checkable-search-filter
        (filterSelectionChange)="onFilterSelectionChange($event)"
        [search]="search"
        (searchChange)="onSearchChange($event)"
      ></app-checkable-search-filter>

      <div>
        <mat-checkbox
          color="primary"
          class="mb-2"
          [checked]="hasSelected() && isAllSelected()"
          [indeterminate]="hasSelected() && !isAllSelected()"
          (change)="$event ? masterToggle() : null"
        >
          {{ "selectAll" | translate }}
        </mat-checkbox>
        <ul>
          @for(item of filteredGroups | filter : search : 'group'; track item) {
            <li>
              <mat-checkbox color="primary" class="mb-1" [(ngModel)]="item._checked">
                {{ item.group }}
              </mat-checkbox>
            </li>
          }
        </ul>
      </div>
    </div>


    <div class="w-full flex justify-end">
      <button mat-flat-button color="primary" [disabled]="isLoading" (click)="onSubmit()">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
      </button>
    </div>
  }
</div>
