@if(hasError) {
  <div class="h-[80%]">
    <app-something-went-wrong
      [showPicture]="false"
      description="{{ 'cannotLoadGroups' | translate }}"
      (retryEmitter)="fetchGroups()"
    ></app-something-went-wrong>
  </div>
}
@if(!hasError) {
  <div class="flex-col-container-1">
    <div class="search-panel">
      <div class="button-group h-[40px]">
        <button
          mat-raised-button
          color="primary"
          aria-label="Create provider"
          (click)="onCreateGroupBtnClicked()"
        >
          <mat-icon class="{{useMobileView ? '!m-0' : '' }}">add</mat-icon>{{ useMobileView ? "" : ("newGroup" | translate) }}
        </button>

        @if(!selection.isEmpty()) {
          <div class="button-add">
            <button
              [disabled]="hasError"
              color="white"
              class="page-block-button"
              mat-icon-button
              aria-label="Add groups to users"
              matTooltip="{{ 'addGroupsToUsers' | translate }}"
              (click)="onAddGroupsToUsersBtnClicked()">
              <mat-icon>groups</mat-icon>
            </button>
          </div>
        }

      </div>
      <div class="search-bar">
        <app-search-bar
          [fullWidth]="useMobileView"
          (searchEventEmitter)="fetchGroups(true)"
          (valueChangedEventEmitter)="onSearchValueChanged($event)"
          [search]="searchValue"
        ></app-search-bar>
        @if(!useMobileView) {
          <mat-paginator
            [pageSize]="pageSize"
            [pageSizeOptions]="[25, 50, 100]"
            [length]="totalSize"
            [pageIndex]="pageIndex"
            (page)="handlePage($event)">
          </mat-paginator>
        }
      </div>
    </div>
  </div>
}

@if(useMobileView && !hasError) {
  <div>
    <div class="mobile">
      <div class="buttons-mobile-container">
        <mat-checkbox
          color="primary"
          class="mb-2 mt-4"
          [checked]="selection.hasValue() && isAllSelected()"
          (change)="$event ? masterToggle() : null"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
        >
        </mat-checkbox>
        <mat-paginator
          [pageSize]="pageSize"
          [pageSizeOptions]="[25, 50, 100]"
          [length]="totalSize"
          [pageIndex]="pageIndex"
          (page)="handlePage($event)"
        ></mat-paginator>
      </div>
      @if(isUploaded && !isLoading) {
        <div class="mobile-container-cards">
          @for(el of organizationGroups; track el) {
            <div>
              <div class="card">
                <div class="checkbox-col">
                  <mat-checkbox
                    color="primary"
                    class="mobile-card-title-checkbox"
                    (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(el) : null"
                    [checked]="selection.isSelected(el)">
                  </mat-checkbox>
                </div>

                <mat-card class="cursor-pointer mobile-row">
                  <mat-card-content class="flex flex-1" (click)="onUpdateGroupBtnClicked(el)">
                    <div class="card-table-row flex-start">
                      <div class="card-table-row-header">Name:</div>
                      <div class="card-table-row-value">{{ el.group }}</div>
                    </div>
                    <div class="card-table-row flex-start">
                      <div class="card-table-row-header">Created At:</div>
                      <div class="card-table-row-value">{{ el.creationDateTime | date: "dd/MM/yyyy HH:mm:ss" }}</div>
                    </div>
                  </mat-card-content>

                  <div class="icons-mobile-block">
                    <button
                      matTooltip="{{ 'addGroupToUsers' | translate }}"
                      mat-icon-button
                      color="primary"
                      aria-label="Add Group To Users"
                      (click)="onAddGroupToUsersBtnClicked(el)">
                      <mat-icon>groups</mat-icon>
                    </button>
                    <button matTooltip="Update" mat-icon-button color="primary" aria-label="Update" (click)="onUpdateGroupBtnClicked(el)">
                      <mat-icon>edit_outline</mat-icon>
                    </button>
                    <button matTooltip="Delete" mat-icon-button color="primary" aria-label="Delete" (click)="openConfirmDeleteDialog(el)">
                      <mat-icon>delete_outline</mat-icon>
                    </button>
                  </div>
                </mat-card>
              </div>
            </div>
          }
        </div>
        @if(organizationGroups.length === 0) {
          <app-no-data></app-no-data>
        }
      }
    </div>
  </div>
}

@if(!useMobileView) {
  <div class="table-container">
    @if(!isLoading && !hasError && isUploaded) {
      <table mat-table [dataSource]="dataSource">
        <!-- Select checkbox column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef width="3%">
            <mat-checkbox
              color="primary"
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              color="primary"
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>{{ "name" | translate }}</th>
          <td mat-cell *matCellDef="let el">{{ el.group }}</td>
        </ng-container>

        <!-- Created At Column -->
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef>{{ "createdAt" | translate }}</th>
          <td mat-cell *matCellDef="let el">{{ el.creationDateTime | date: "dd/MM/yyyy HH:mm:ss" }}</td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th class="actions-col" mat-header-cell *matHeaderCellDef>{{ "actions" | translate }}</th>
          <td mat-cell *matCellDef="let el">
            <button
              mat-icon-button
              [attr.aria-label]="'addGroupsToUsers' | translate"
              matTooltip="{{ 'addGroupsToUsers' | translate }}"
              (click)="onAddGroupToUsersBtnClicked(el)">
              <mat-icon>groups</mat-icon>
            </button>
            <button
              mat-icon-button
              [attr.aria-label]="'updateGroup' | translate"
              matTooltip="{{ 'updateGroup' | translate }}"
              (click)="onUpdateGroupBtnClicked(el)">
              <mat-icon>edit_outline</mat-icon>
            </button>
            <button
              mat-icon-button
              [attr.aria-label]="'delete' | translate"
              matTooltip="{{ 'delete' | translate }}"
              (click)="openConfirmDeleteDialog(el)">
              <mat-icon>delete_outline</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="cursor-default"></tr>
      </table>
      @if(organizationGroups.length === 0) {
        <app-no-data></app-no-data>
      }
    }
  </div>
}

@if(isLoading && !hasError) {
  <div class="overflow-hidden flex-1">
    <app-skeleton-table></app-skeleton-table>
  </div>
}

<ng-template #createDrawer>
  <app-organization-group-create-dialog
    [group]="editingGroup"
    (createEventEmitter)="fetchGroups()"
    [organizationId]="organizationId"
    (closeEventEmitter)="onCloseCreateUpdateForm()"
  ></app-organization-group-create-dialog>
</ng-template>

<ng-template #addGroupsToUsersDrawer>
  <app-add-groups-to-users-form
    (closeDrawer)="onHideAddGroupsToUsersForm()"
    (submitDrawer)="onAddGroupsToUsers()"
    [groups]="selectGroup ? [selectGroup] : selection.selected"
    [organizationID]="organizationId">
  </app-add-groups-to-users-form>
</ng-template>
