import { UserFolderContract } from '@app-types/api/mail-folder';
import { MailFolderType } from '@app-types/enums/mail-folder.type';

export const getFolderByType = (
  userFolders: UserFolderContract[],
  mailAccountId: number,
  folderType: MailFolderType
): number | null => {
  const foundFolder = userFolders.filter(
    x =>
      x.folderType === folderType &&
      x.mailAccountId === mailAccountId &&
      !x.parentFolderId
  );
  if (foundFolder && foundFolder.length > 0) {
    return foundFolder[0].mailFolderId;
  }
  return null;
};

export const getFoldersAndSubfoldersId = (
  selectedMailFolder: number,
  userFolders: UserFolderContract[]
): number[] => {
  const foldersIds = userFolders
    .filter(el => el.parentFolderId === selectedMailFolder)
    .map(el => el.mailFolderId);
  if (foldersIds.length) {
    const newFolders = foldersIds.map(el => {
      return getFoldersAndSubfoldersId(el, userFolders);
    });
    return [selectedMailFolder, ...newFolders.flat()];
  } else {
    return [selectedMailFolder];
  }
};

export const getAllFoldersMailAccount = (
  mailAccount: number,
  userFolders: UserFolderContract[]
): number[] => {
  return userFolders
    .filter(el => el.mailAccountId === mailAccount)
    .map(el => el.mailFolderId);
};

export const getFoldersMailAccountByType = (
  mailAccount: number,
  userFolders: UserFolderContract[],
  types: MailFolderType[]
): UserFolderContract[] =>
  userFolders.filter(
    el => el.mailAccountId === mailAccount && types.includes(el.folderType)
  );
