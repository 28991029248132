import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UserContract } from '@app-types/api/user';
import { UserMailAccountClient } from '@app-services/api/clients/user-mail-account.client';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { BaseGetByIdRequest } from '@app-types/base/base';
import { RoleType } from '@app-types/enums/role-type';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { DrawerService } from '@app-services/drawer.service';
import { LoadingButtonComponent } from '../../../../common/loading-button/loading-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { UserMailAccountContract } from '@app-types/api/user-mail-account';
import { SomethingWentWrongComponent } from '@app-components/common/error/something-went-wrong/something-went-wrong.component';
import { SkeletonFormDrawerComponent } from '@app-components/common/skeletons/skeleton-form-drawer/skeleton-form-drawer.component';

@Component({
  selector: 'app-mail-account-users-add',
  templateUrl: './mail-account-users-add.component.html',
  styleUrls: ['./mail-account-users-add.component.scss'],
  standalone: true,
  imports: [
    LoadingButtonComponent,
    TranslateModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    SkeletonFormDrawerComponent,
    SomethingWentWrongComponent,
  ],
})
export class MailAccountUsersAddComponent implements OnInit, OnDestroy {
  @Input() public organizationId: number;
  @Input() public mailAccountId: number | null;
  @Input() userMailAccount: UserMailAccountContract | null;
  @Output() public creationSubmit = new EventEmitter();
  @Output() public closeEventEmitter = new EventEmitter();

  public availableRoles: RoleType[] = [
    RoleType.User,
    RoleType.SuperUser,
    RoleType.Creator,
    RoleType.Validator,
    RoleType.LimitedUser,
    RoleType.LimitedCreator,
  ];
  public availableUsers: UserContract[] = [];
  public selectedUser: UserContract;
  public selectedRole: RoleType = RoleType.User;
  public roleTypes = RoleType;

  public cannotLoadAvailableOrganizationUsers = false;
  public availableOrganizationUsersIsLoading = false;
  public isUploaded = false;
  public isLoading = false;
  public form: UntypedFormGroup = new UntypedFormGroup({});

  constructor(
    private userMailAccountClient: UserMailAccountClient,
    private permissionService: PermissionService,
    public matchError: MatchError,
    private drawerService: DrawerService
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.userMailAccount) {
      this.form = new UntypedFormGroup({
        name: new UntypedFormControl(
          this.userMailAccount.userFirstName +
            ' ' +
            this.userMailAccount.userLastName
        ),
      });
      this.selectedRole = this.userMailAccount.roleId;
      this.isUploaded = true;
    } else {
      await this.loadAvailableOrganizationUsers();
    }
  }

  public async loadAvailableOrganizationUsers(): Promise<void> {
    this.isUploaded = false;
    const loaderTimeout = setTimeout(() => {
      this.availableOrganizationUsersIsLoading = true;
    }, 500);

    this.cannotLoadAvailableOrganizationUsers = false;
    try {
      const response = await this.userMailAccountClient.getAvailableUsers(
        new BaseGetByIdRequest(this.mailAccountId)
      );
      this.availableUsers = response.data.filter(e =>
        this.permissionService.hasPermissionToSeeUserByUserEmail(e.email)
      );
    } catch (e) {
      this.cannotLoadAvailableOrganizationUsers = true;
      this.matchError.logError(e);
    } finally {
      clearTimeout(loaderTimeout);
      this.isUploaded = true;
      this.availableOrganizationUsersIsLoading = false;
    }
  }

  public formIsValid(): boolean {
    return (
      !!this.selectedRole &&
      (this.userMailAccount ? !!this.userMailAccount : !!this.selectedUser)
    );
  }

  async onSubmit(): Promise<void> {
    if (this.formIsValid()) {
      this.isLoading = true;
      this.drawerService.disabledDrawer(true);
      try {
        this.userMailAccount
          ? await this.userMailAccountClient.assignRole({
              userMailAccountId: this.userMailAccount.userMailAccountId,
              roleId: this.selectedRole,
            })
          : this.mailAccountId &&
            (await this.userMailAccountClient.addMailAccountUser({
              mailAccountId: this.mailAccountId,
              userId: this.selectedUser.userId,
              roleId: this.selectedRole,
            }));
        this.drawerService.disabledDrawer(false);
        this.creationSubmit.emit({ userId: this.selectedUser?.userId });
        this.drawerService.closeDrawer();
      } catch (e) {
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
      } finally {
        this.isLoading = false;
      }
    }
  }

  ngOnDestroy(): void {
    this.closeEventEmitter && this.closeEventEmitter.emit();
  }
}
