<mat-form-field class="tags-chip-list w-full">
  <mat-label>{{ "tags" | translate }}</mat-label>
  <mat-chip-grid #chipList aria-label="Tags selection">
    @for(tag of selectedTags; track tag) {
      <mat-chip-row [removable]="true" (removed)="removeTag(tag)">
        {{ tag }}
        <mat-icon matChipRemove >cancel</mat-icon>
      </mat-chip-row>
    }

    <input
      placeholder="{{'newTag' | translate}}"
      #tagInput
      [formControl]="tagCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="addTag($event)"
    />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedTag($event)">
    @for(tag of filteredTags | async; track tag) {
      <mat-option [value]="tag">
      {{ tag }}
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
