import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { ApiRequest } from '@app-services/api/api.request';
import { environment } from '@app-environments/environment';
import { LoggingHandler } from '@app-services/errors/logging.service';
import { LocalStorageService } from '@app-services/local-storage.service';
import { makeId } from '@app-utils/common';
import packageInfo from '../../../../package.json';
import { STORAGE_NAMES, WEB_CODE } from '@app-shared/constants/constants';
import { IntervalService } from '@app-services/interval.service';
import { IdleTimeoutService } from '@app-services/idle-timeout.service';

const { CLIENT_ID } = STORAGE_NAMES;

@Component({
  selector: 'app-web-code-context',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './web-code-context.component.html',
  styleUrls: ['./web-code-context.component.scss'],
})
export class WebCodeContextComponent implements OnInit, OnDestroy {
  public webCode: string;
  clientId: string;
  constructor(
    private route: ActivatedRoute,
    private apiRequest: ApiRequest,
    private loggingHandler: LoggingHandler,
    private localStorageService: LocalStorageService,
    private intervalService: IntervalService,
    private idleTimeoutService: IdleTimeoutService
  ) {}

  @HostListener('mousemove', ['$event'])
  @HostListener('keydown', ['$event'])
  @HostListener('click', ['$event'])
  userActivityDetected(): void {
    this.idleTimeoutService.userActivityDetected(this.webCode);
  }

  async ngOnInit(): Promise<void> {
    this.webCode = this.route.snapshot.paramMap.get(WEB_CODE) ?? '';
    let clientId = this.localStorageService.getData(CLIENT_ID);
    if (!clientId) {
      clientId = makeId(20);
      this.localStorageService.setData(CLIENT_ID, clientId);
    }
    this.clientId = clientId;
    this.tokenRenew();
    console.log(`CLIENTID=${this.clientId}`);
    console.log(`PM3 ${packageInfo.version}`);
  }

  public tokenRenew(): void {
    this.intervalService.startInterval(
      'tokenRefreshInterval',
      () => {
        this.apiRequest.get(environment.renewUri);
        this.loggingHandler.create('information', 'renew token');
      },
      environment.userRenewTokenIntervalMs,
      this.webCode
    );
  }

  ngOnDestroy(): void {
    this.intervalService.clearAllIntervals();
  }
}
