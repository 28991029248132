<div class="drawer-content-screen">
  <div class="drawer-content-screen__title">
    {{ "createTag" | translate }}
  </div>
  <div class="drawer-content-screen__body">
    <form [formGroup]="form" autocomplete="off" novalidate>
      <mat-form-field >
        <mat-label>{{ "tagLabel" | translate }}</mat-label>
        <input matInput id="tag" formControlName="tag" required />
        @if(hasError('tag', 'required')) {
          <mat-error>{{ "tagIsRequired" | translate }}</mat-error>
        }
        @if(hasError('tag', 'maxlength')) {
          <mat-error>{{ "moreThan255Char" | translate }}</mat-error>
        }
      </mat-form-field>
    </form>
  </div>
  <div class="flex justify-end w-full">
    <button mat-flat-button color="primary" [disabled]="isLoading || !form.valid" (click)="onSubmit()">
      <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
    </button>
  </div>
</div>
