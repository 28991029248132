import { Injectable } from '@angular/core';
import { environment } from '@app-environments/environment';
import { ApiRequest } from '../api.request';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import { BaseGetByIdRequest, BaseId, BaseResponse } from '@app-types/base/base';
import { MailAccountSettingsContract } from '@app-types/api/mail-account';

const API_URL = environment.apiUrl;
const { getByMailAccount, update } = API_ROUTES.mailAccountSettings;

@Injectable({ providedIn: 'root' })
export class MailAccountSettingsClient {
  constructor(private apiRequest: ApiRequest) {}

  async getByMailAccount(
    request: BaseGetByIdRequest
  ): Promise<BaseResponse<MailAccountSettingsContract>> {
    return this.apiRequest.get(
      `${API_URL}${getByMailAccount}?${request.format()}`
    );
  }

  async update(request: MailAccountSettingsContract): Promise<BaseId> {
    return this.apiRequest.post<MailAccountSettingsContract, BaseId>(
      request,
      `${API_URL}${update}`
    );
  }
}
