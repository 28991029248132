import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ProviderContract } from '@app-types/api/provider';
import { Router } from '@angular/router';
import { ProviderClient } from '@app-services/api/clients/provider.client';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { DrawerService } from '@app-services/drawer.service';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingButtonComponent } from '../../common/loading-button/loading-button.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { LoaderComponent } from '../../common/loader/loader.component';
import { SomethingWentWrongComponent } from '../../common/error/something-went-wrong/something-went-wrong.component';
import { SslProtocolEnum } from '@app-types/enums/ssl-protocol';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-provider-create-dialog',
  templateUrl: './provider-create-dialog.component.html',
  styleUrls: ['./provider-create-dialog.component.scss'],
  standalone: true,
  imports: [
    LoadingButtonComponent,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    LoaderComponent,
    SomethingWentWrongComponent,
    TranslateModule,
    MatSelectModule,
  ],
})
export class ProviderCreateDialogComponent implements OnInit, OnDestroy {
  @Input() provider: ProviderContract | null;
  @Output() public creationSubmit = new EventEmitter();
  @Output() public closeEventEmitter = new EventEmitter();
  public form: UntypedFormGroup;
  public isLoading = false;
  public isError = false;
  tlsOptions = Object.keys(SslProtocolEnum)
    .filter(k => !(parseInt(k, 10) >= 0))
    .map(key => ({
      id: SslProtocolEnum[key as keyof typeof SslProtocolEnum],
      value: key,
    }));

  constructor(
    public matchError: MatchError,
    private router: Router,
    private providerClient: ProviderClient,
    private drawerService: DrawerService
  ) {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.provider?.name ?? null, [
        Validators.required,
        Validators.maxLength(128),
      ]),
      smtp: new UntypedFormControl(this.provider?.smtp ?? null, [
        Validators.required,
        Validators.maxLength(256),
      ]),
      smtpPort: new UntypedFormControl(this.provider?.smtpPort ?? null, [
        Validators.pattern('^[0-9]*$'),
        Validators.required,
        Validators.min(1),
        Validators.max(65535),
      ]),
      imap: new UntypedFormControl(this.provider?.imap ?? null, [
        Validators.required,
        Validators.maxLength(256),
      ]),
      imapPort: new UntypedFormControl(this.provider?.imapPort ?? null, [
        Validators.pattern('^[0-9]*$'),
        Validators.required,
        Validators.min(1),
        Validators.max(65535),
      ]),
      code: new UntypedFormControl(this.provider?.code ?? null, [
        Validators.maxLength(512),
      ]),
      sslProtocol: new UntypedFormControl(this.provider?.sslProtocol ?? null),
      inboxName: new UntypedFormControl(
        this.provider?.configuration?.inbox ?? null,
        [Validators.maxLength(512)]
      ),
      outboxName: new UntypedFormControl(
        this.provider?.configuration?.sent ?? null,
        [Validators.maxLength(512)]
      ),
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

  async onSubmit(providerContract: ProviderContract): Promise<void> {
    if (this.form.valid) {
      this.isLoading = true;
      this.drawerService.disabledDrawer(true);
      this.isError = false;
      try {
        this.provider &&
          (providerContract.providerId = this.provider.providerId);
        if (
          this.form.controls.inboxName.value ||
          this.form.controls.outboxName.value
        ) {
          providerContract.configuration = {
            inbox: this.form.controls.inboxName.value,
            sent: this.form.controls.outboxName.value,
          };
        }
        this.provider
          ? await this.providerClient.update(providerContract)
          : await this.providerClient.create(providerContract);

        this.drawerService.disabledDrawer(false);
        this.creationSubmit.emit();
        this.drawerService.closeDrawer();
      } catch (e) {
        this.isError = true;
        this.matchError.logError(e);
        this.drawerService.disabledDrawer(false);
      } finally {
        this.isLoading = false;
      }
    }
  }

  ngOnDestroy(): void {
    this.closeEventEmitter && this.closeEventEmitter.emit();
  }
}
