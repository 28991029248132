import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatIconModule, TranslateModule],
})
export class SearchBarComponent {
  @Input() public fullWidth = false;
  @Input() public search = '';
  @Input() public isDynamicSearch = false;
  @Output() public valueChangedEventEmitter = new EventEmitter<string>();
  @Output() public searchEventEmitter = new EventEmitter<string>();

  constructor() {}

  onValueChanged($event: Event): void {
    this.valueChangedEventEmitter.emit(
      ($event.target as HTMLInputElement).value
    );
    if (this.isDynamicSearch) {
      this.searchEventEmitter.emit();
    }
  }

  onXClicked(): void {
    this.search = '';
    this.valueChangedEventEmitter.emit('');
    this.searchEventEmitter.emit();
  }

  onSearchBtnClicked(): void {
    this.searchEventEmitter.emit();
  }

  public get widthClass(): string {
    return this.fullWidth ? ' w-full' : ' w-52 xl:w-80';
  }
}
