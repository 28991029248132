import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { EMAIL_REGEXP } from '../constants/regex';

export const outMinLimit =
  (minLimit: number): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null =>
    control.value < minLimit ? { lowerLimit: { value: control.value } } : null;

export const outMaxLimit =
  (maxLimit: number): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null =>
    control.value > maxLimit ? { higherLimit: { value: control.value } } : null;

export const hasAtLeastOneUpperCharacter =
  (): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null =>
    /[A-Z]+/.test(control.value)
      ? null
      : { hasAtLeastOneUpperCharacter: control.value };

export const hasAtLeastOneLowerCharacter =
  (): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null =>
    /[a-z]+/.test(control.value)
      ? null
      : { hasAtLeastOneLowerCharacter: control.value };

export const hasAtLeastOneDigit =
  (): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null =>
    /\d+/.test(control.value) ? null : { hasAtLeastOneDigit: control.value };

export const hasAtLeastOneSpecialSymbol =
  (): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null =>
    /[$&+,:;=?@#|'<>.^*()%!-]+/.test(control.value)
      ? null
      : { hasAtLeastOneSpecialSymbol: control.value };

export const angularEmailValidator = (email: string): boolean => {
  if (!email || email.length === 0) {
    return false;
  }
  return EMAIL_REGEXP.test(email);
};
