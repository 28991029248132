import { Component } from '@angular/core';

@Component({
  selector: 'app-error-not-existed-page',
  standalone: true,
  imports: [],
  templateUrl: './error-not-existed-page.component.html',
  styleUrls: ['./error-not-existed-page.component.scss'],
})
export class ErrorNotExistedPageComponent {
  constructor() {}
}
