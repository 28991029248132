<div class="flex h-full">
  @if(showButton) {
    <button
      mat-icon-button
      (click)="clickEmit()"
      [disabled]="disabled"
      [attr.aria-label]="label | translate"
      [matTooltip]="label | translate"
    >
      <mat-icon>{{ icon }}</mat-icon>
    </button>
  }
  @if(showLoading) {
    <div class="w-[2.5rem]">
      <app-loader padding="0" [diameter]="'22'" height="100%"></app-loader>
    </div>
  }
</div>
