<mat-drawer-container [hasBackdrop]="true">
  <mat-drawer
    #drawer
    mode="over"
    position="end"
    [disableClose]="disableClose"
    (closed)="drawerService.closeDrawerWithCallback()"
    [autoFocus]="false"
  >
    <div class="h-full px-[1.5rem] pt-[2rem] pb-[1.5rem] overflow-hidden">
      <div class="close-button">
        <button tabIndex="-1" class="" mat-icon-button (click)="drawerService.closeDrawerWithCallback()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <ng-template #drawerContent></ng-template>
    </div>
  </mat-drawer>
  <ng-content></ng-content>
</mat-drawer-container>
