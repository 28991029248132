<div class="drawer-content-screen">
  @if(!isLoadingData) {
    <div class="drawer-content-screen__title">
      {{ "quickSetupOfMailAccount" | translate }}
    </div>
  }

  @if(cannotLoadData) {
    <div class="h-full w-full">
      <app-something-went-wrong
        [title]="errorText | translate"
        (retryEmitter)="loadData()"
        [showPicture]="false"
      ></app-something-went-wrong>
    </div>
  }

  @if(isLoadingData) {
    <div class="h-full">
       <app-skeleton-form-drawer></app-skeleton-form-drawer>
     </div>
  }

  @if(!isLoadingData && !cannotLoadData && !canSetup) {
    <div class="w-full h-full flex items-center justify-center">
      @if(!canSetup) {
        <div>
          <app-error-hint errorText="{{ 'cannotSetupAccountHasCustomFoldersOrDispatchingRulesErrorHint' | translate }}"></app-error-hint>
          <button
            style="display: block; margin: 0.5rem auto"
            color="primary" (click)="cancel()"
            mat-flat-button
          >{{ "ok" | translate }}</button>
        </div>
      }
      @if(activated) {
        <div>
          <div class="text-green-600 text-sm flex mx-auto">
            <mat-icon class="mr-1 mb-2">check_circle</mat-icon>
            <span class="flex flex-wrap content-center">{{ "mailAccountWasSuccessfullyActivated" | translate }}</span>
          </div>
          <button
            style="display: block; margin: 0.5rem auto"
            color="primary"
            (click)="cancel()"
            mat-flat-button>{{ "ok" | translate }}</button>
        </div>
      }
    </div>
  }

  @if(!isLoadingData && !cannotLoadData && canSetup && !activated) {
    <div class="drawer-content-screen__body flex flex-col gap-3">
      <mat-checkbox color="primary" [ngModelOptions]="{ standalone: true }" [(ngModel)]="startMailAccount">{{
        "startMailAccount" | translate
      }}</mat-checkbox>
      <div class="drawer-content-screen__sub-title">{{ "selectFoldersForSync" | translate }}</div>
      <mat-checkbox
        color="primary"
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="selectAll"
        (change)="checkedAll(this.dataSource.data)"
      >{{
        "selectAll" | translate
      }}</mat-checkbox>
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="folder-tree">
        <mat-tree-node *matTreeNodeDef="let item" matTreeNodeToggle>
          <div class="mat-tree-node">
            <button mat-icon-button disabled> </button>

            <mat-checkbox class="checklist-leaf-node" color="primary" [checked]="item.sync" (change)="selectFolder(item)">{{
              item.shortName
            }}</mat-checkbox>
          </div>
        </mat-tree-node>
        <mat-nested-tree-node *matTreeNodeDef="let item; when: hasChild">
          <div>
            <div class="mat-tree-node">
              <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + item.shortName">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{ treeControl.isExpanded(item) ? "expand_more" : "chevron_right" }}
                </mat-icon>
              </button>
              <mat-checkbox class="checklist-leaf-node" [checked]="item.sync" color="primary" (change)="selectFolder(item)">{{
                item.shortName
              }}</mat-checkbox>
            </div>
            <div [class.folder-tree-invisible]="!treeControl.isExpanded(item)" role="group">
              <ng-container matTreeNodeOutlet></ng-container>
            </div>
          </div>
        </mat-nested-tree-node>
      </mat-tree>
      <div class="text-warning">{{ "someRemoteFoldersCanBeNotVisibleInQuickSetup" | translate }}</div>
    </div>
    <div class="w-full">
      @if(isError) {
        <div class="mb-3">
          <app-error-hint errorText="{{ 'cannotSetupOfMailAccountErrorHint' | translate }}"></app-error-hint>
        </div>
      }
      @if(!activated && !errorText) {
        <div class="flex justify-end">
          <button mat-flat-button color="primary" [disabled]="isLoading || !canSetup" (click)="onSubmit()">
            <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
          </button>
        </div>
      }
    </div>
  }
</div>
