import { ApiException } from '../errors/api.exception';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoggingHandler } from '../errors/logging.service';
import { environment } from '@app-environments/environment';

@Injectable({ providedIn: 'root' })
export class ApiRequest {
  private organizationId: number;

  constructor(
    private http: HttpClient,
    protected loggingHandler: LoggingHandler
  ) {}

  setOrganisationId(organizationId: number): void {
    this.organizationId = organizationId;
  }

  public async post<TRequest, TResponse>(
    request: TRequest,
    url: string,
    headers: any = null
  ): Promise<any> {
    try {
      const ClientId = this.loggingHandler.getClientId();
      if (this.organizationId) {
        if (request instanceof FormData) {
          request.append('organizationId', this.organizationId.toString());
        } else {
          request = {
            ...request,
            organizationId: this.organizationId,
          };
        }
      }
      const response$: Observable<TResponse> = await this.http.post<TResponse>(
        url,
        request,
        {
          headers: { ...headers, ClientId, 'X-Api-Key': environment.xApiKey },
          withCredentials: true,
        }
      );
      return !!response$ && (await lastValueFrom(response$));
    } catch (e) {
      this.throwApiException(e, { url }, { request }, { headers });
    }
  }

  public async get<TResponse>(url: string, headers: any = null): Promise<any> {
    try {
      const ClientId = this.loggingHandler.getClientId();
      const newUrl = new URL(url);
      this.organizationId &&
        newUrl.searchParams.set('organizationId', String(this.organizationId));
      const response$: Observable<TResponse> = await this.http.get<TResponse>(
        newUrl.toString(),
        {
          headers: { ...headers, ClientId, 'X-Api-Key': environment.xApiKey },
          withCredentials: true,
        }
      );
      return response$ && (await lastValueFrom(response$));
    } catch (e) {
      this.throwApiException(e, { url }, { headers });
    }
  }

  public async download(
    url: string
  ): Promise<
    { file: ArrayBuffer; filename: string; type: string } | undefined
  > {
    try {
      const ClientId = this.loggingHandler.getClientId();
      const newUrl = new URL(url);
      this.organizationId &&
        newUrl.searchParams.set('organizationId', String(this.organizationId));
      const response$ = await this.http.get(newUrl.toString(), {
        headers: { ClientId, 'X-Api-Key': environment.xApiKey },
        responseType: 'arraybuffer',
        withCredentials: true,
        observe: 'response',
      });
      const data = response$ && (await lastValueFrom(response$));
      const contentDisposition = data.headers.get('Content-Disposition');
      console.log(data.headers);
      const filenameIndex = contentDisposition?.indexOf('filename=');
      const filename =
        contentDisposition && filenameIndex
          ? contentDisposition.substring(filenameIndex + 9).split(';')[0]
          : '';
      return {
        file: data.body as ArrayBuffer,
        type: (data?.headers?.get('content-type') as string) ?? '',
        filename: filename
          .trim()
          .replace(/^['"]+|['"]+$/g, '')
          .trim(),
      };
    } catch (e) {
      this.throwApiException(e, { url });
      return;
    }
  }

  public async downloadPost<TRequest>(
    url: string,
    request: TRequest
  ): Promise<any> {
    try {
      const ClientId = this.loggingHandler.getClientId();
      const data = this.organizationId
        ? { ...request, organizationId: this.organizationId }
        : request;
      const response$ = await this.http.post(url, data, {
        headers: { ClientId, 'X-Api-Key': environment.xApiKey },
        responseType: 'arraybuffer',
        withCredentials: true,
      });
      return response$ && (await lastValueFrom(response$));
    } catch (e) {
      this.throwApiException(e, { url }, { request });
    }
  }

  public upload<TRequest, TResponse>(
    request: TRequest,
    url: string,
    headers: any = null
  ): Observable<any> {
    const ClientId = this.loggingHandler.getClientId();
    const data = this.organizationId
      ? { ...request, organizationId: this.organizationId }
      : request;
    return this.http
      .post(url, data, {
        headers: { ...headers, ClientId, 'X-Api-Key': environment.xApiKey },
        observe: 'events',
        reportProgress: true,
        withCredentials: true,
      })
      .pipe(catchError(err => throwError(err))) as Observable<TResponse>;
  }

  private throwApiException(e: any, ...args: any[]): void {
    const errorResponse = JSON.stringify(e?.error);
    const errorInformation: any[] = [];
    args.forEach(el => errorInformation.push(el));
    const message = JSON.stringify(errorInformation);
    throw new ApiException(
      `${e.status} ${e.message} ${message}, Response: ${errorResponse}`,
      e.error?.code,
      e.error?.description,
      e.error?.meta,
      e.status
    );
  }
}
