<div class="drawer-content-screen">
  @if(!isLoadingMailAccounts && !cannotLoadMailAccounts) {
    <div class="drawer-content-screen__title">
      {{ users.length > 1 ? ("addUsersToMailAccounts" | translate) : ("addUserToMailAccounts" | translate) }}
    </div>
  }

  @if(cannotLoadMailAccounts && !isLoadingMailAccounts) {
    <div class="flex-col-center">
       <app-something-went-wrong
         description="{{ 'cannotLoadMailAccounts' | translate }}. {{ 'wentWrong' | translate }}"
          (retryEmitter)="fetchMailAccounts()"
          [showPicture]="false"
       ></app-something-went-wrong>
    </div>
  }
  @if(isLoadingMailAccounts) {
    <div class="h-full">
      <app-skeleton-form-drawer></app-skeleton-form-drawer>
    </div>
  }

  @if(!isLoadingMailAccounts && !cannotLoadMailAccounts) {
    <div class="h-full w-full mb-2 overflow-auto">
      <app-checkable-search-filter
        (filterSelectionChange)="onFilterSelectionChange($event)"
        [search]="search"
        (searchChange)="onSearchChange($event)"
      ></app-checkable-search-filter>

      <div>
        <table mat-table [dataSource]="dataSource" class="w-full">
          <!-- SELECT COLUMN -->
          <ng-container matColumnDef="select">
            <th class="main-header-select" mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                color="primary"
                [checked]="hasSelected() && isAllSelected()"
                [indeterminate]="hasSelected() && !isAllSelected()"
                (change)="$event ? masterToggle() : null">
              </mat-checkbox>
            </th>
            <td class="main-header-select" mat-cell *matCellDef="let mailAccount">
              <mat-checkbox color="primary" [(ngModel)]="mailAccount._checked"> </mat-checkbox>
            </td>
          </ng-container>

          <!-- MAIL ACCOUNT COLUMN -->
          <ng-container matColumnDef="mailAccount">
            <th class="main-header-select" mat-header-cell *matHeaderCellDef>{{ "name" | translate }}</th>
            <td class="main-header-select" mat-cell *matCellDef="let mailAccount">
              {{ mailAccount.name }}
            </td>
          </ng-container>

          <!-- ROLE COLUMN -->
          <ng-container matColumnDef="role">
            <th class="main-header-select" mat-header-cell *matHeaderCellDef>{{ "role" | translate }}</th>
            <td class="main-header-select" mat-cell *matCellDef="let mailAccount">
              <mat-form-field class="w-full">
                <mat-select [(ngModel)]="mailAccount.role" id="mailAccount.mailAccountId" required>
                  @for(role of roles; track role) {
                    <mat-option [value]="role.value">
                      {{ role.name | translate }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>

    <div class="w-full flex justify-end">
      <button mat-flat-button color="primary" [disabled]="isLoading" (click)="onSubmit()">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
      </button>
    </div>
  }
</div>
