import { Injectable } from '@angular/core';
import { environment } from '@app-environments/environment';
import { ApiRequest } from '../api.request';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import {
  BaseCollectionSearchByIdRequest,
  BaseGetCollectionByIdRequest,
  BaseGetCollectionResponse,
  BaseIdListRequest,
} from '@app-types/base/base';
import { Contact } from '@app-types/api/contact';

const API_URL = environment.apiUrl;
const { contact } = API_ROUTES;
const { getForAccount, search } = contact;

@Injectable({
  providedIn: 'root',
})
export class ContactClient {
  constructor(private apiRequest: ApiRequest) {}

  async delete(request: BaseIdListRequest): Promise<void> {
    return this.apiRequest.post<BaseIdListRequest, void>(
      request,
      `${API_URL}${contact.delete}`
    );
  }

  async getForAccount(
    request: BaseGetCollectionByIdRequest
  ): Promise<BaseGetCollectionResponse<Contact>> {
    return this.apiRequest.get<BaseGetCollectionResponse<Contact>>(
      `${API_URL}${getForAccount}?${request.format()}`
    );
  }

  async searchForAccount(
    request: BaseCollectionSearchByIdRequest
  ): Promise<BaseGetCollectionResponse<Contact>> {
    return this.apiRequest.get<BaseGetCollectionResponse<Contact>>(
      `${API_URL}${search}?${request.format()}`
    );
  }
}
