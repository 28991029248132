@if(isLoading) {
  <div class="overflow-hidden flex-1">
    <app-skeleton-table></app-skeleton-table>
  </div>
}

@if(cannotLoadData) {
  <div class="h-[80%]">
    <app-something-went-wrong
      [showPicture]="false"
      (retryEmitter)="ngOnInit()"
    ></app-something-went-wrong>
  </div>
}

@if(!isLoading && !cannotLoadData && isUploaded) {
  @if(useMobileView) {
    <div class="mobile-container-cards">
      @for(folder of folders; track folder) {
        <div>
          <mat-card>
            <mat-card-content class="cursor-default">
              <div class="card-table-row">
                <div class="card-table-row-value">
                  {{ folder.creationType === 1 ? ("folderType" + folder.folderType | translate) : folder.name }}
                </div>
                <div>
                  <div class="flex justify-end">
                    @if(inInboxSentOrArchiveFolder(folder) &&
                          (folder.folderType !== mailFolderType.Archive || folder.creationType === mailCreationType.User)) {
                      <button
                        mat-icon-button
                        (click)="renameFolder(folder)">
                        <mat-icon>edit_outline</mat-icon>
                      </button>
                    }
                    @if(inInboxSentOrArchiveFolder(folder)) {
                      <button mat-icon-button aria-label="Menu" [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                    }
                    <mat-menu #menu="matMenu">
                      @if(folder.creationType === mailCreationType.User) {
                        <button mat-menu-item (click)="deleteFolder(folder)">
                          <mat-icon>delete_outline</mat-icon>{{ "delete" | translate }}
                        </button>
                      }
                      @if(inInboxSentOrArchiveFolder(folder)) {
                        <button mat-menu-item (click)="createFolder(folder)">
                          <mat-icon>create_new_folder</mat-icon>{{ "createSubfolder" | translate }}
                        </button>
                      }
                      @if(inInboxSentOrArchiveFolder(folder)) {
                        <button mat-menu-item (click)="openFolderSettings(folder)">
                          <mat-icon>settings</mat-icon>{{ "settings" | translate }}
                        </button>
                      }
                    </mat-menu>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      }
      @if(folders.length === 0) {
        <app-no-data></app-no-data>
      }
    </div>
  }

  @if(!useMobileView) {
    <div class="table-container">
      <table mat-table [dataSource]="dataSource" class="w-full">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>{{ "name" | translate }}</th>
          <td mat-cell *matCellDef="let folder">
            {{ folder.creationType === 1 ? ("folderType" + folder.folderType | translate) : folder.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="providerName">
          <th mat-header-cell *matHeaderCellDef>{{ "providerFolderName" | translate }}</th>
          <td mat-cell *matCellDef="let folder">{{ folder.providerFolderName }}</td>
        </ng-container>
        <ng-container matColumnDef="archive">
          <th mat-header-cell *matHeaderCellDef>{{ "archive" | translate }}</th>
          <td mat-cell *matCellDef="let folder">
            {{ !inInboxSentOrArchiveFolder(folder) ? "" : folder.archive ? ("active" | translate) : ("notActive" | translate) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>{{ "actions" | translate }}</th>
          <td mat-cell *matCellDef="let folder" class="action-link">
            @if(inInboxSentOrArchiveFolder(folder)) {
              <button
                mat-icon-button
                aria-label="Create subfolder"
                matTooltip="{{ 'createSubfolder' | translate }}"
                (click)="createFolder(folder)">
                <mat-icon>create_new_folder</mat-icon>
              </button>
            }
            @if(inInboxSentOrArchiveFolder(folder)) {
              <button
               mat-icon-button
               aria-label="Settings"
               matTooltip="{{ 'settings' | translate }}"
                (click)="openFolderSettings(folder)">
                <mat-icon>settings</mat-icon>
              </button>
            }
            @if(inInboxSentOrArchiveFolder(folder) &&
            (folder.folderType !== mailFolderType.Archive || folder.creationType === mailCreationType.User)) {
              <button
              mat-icon-button
              aria-label="Modify"
              matTooltip="{{ 'modify' | translate }}"
              (click)="renameFolder(folder)">
              <mat-icon>edit_outline</mat-icon>
              </button>
            }
            @if(folder.creationType === mailCreationType.User) {
              <button
                mat-icon-button
                aria-label="Delete"
                matTooltip="{{ 'delete' | translate }}"
                (click)="deleteFolder(folder)">
                <mat-icon>delete_outline</mat-icon>
              </button>
            }

          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="cursor-default"></tr>
      </table>
      @if(folders.length === 0) {
        <app-no-data></app-no-data>
      }
    </div>
  }
}

<ng-template #createDrawer>
  <app-mail-folder-create-dialog
    (closeEventEmitter)="onCloseCreateModalForm()"
    (creationSubmit)="onSubmitCreation()"
    [parentMailFolderId]="parentMailFolderIdForCreation"
    [mailAccountId]="mailAccountId"
    [parentMailFolderType]="parentMailFolderTypeForCreation"
    [isSettings]="true">
  </app-mail-folder-create-dialog>
</ng-template>

<ng-template #modifyDrawer>
  <app-mail-folder-create-dialog
    [mailFolderId]="editingFolder?.mailFolderId"
    [mailFolderName]="editingFolder?.fullName"
    [mailFolderCreationType]="editingFolder?.creationType"
    (closeEventEmitter)="onCloseRenameModalForm()"
    [providerFolderName]="editingFolder?.providerFolderName"
    (creationSubmit)="onSubmitRename()"
    [mailAccountId]="mailAccountId"
    [parentMailFolderType]="parentMailFolderTypeForCreation"
    [isSettings]="true">
  </app-mail-folder-create-dialog>
</ng-template>

<ng-template #settingsDrawer>
  <app-mail-folder-settings-dialog
    [folderType]="editingFolder?.folderType"
    [mailAccountId]="mailAccountId"
    [organizationId]="organizationId"
    [mailFolderId]="editingFolder?.mailFolderId"
    [mailFolderName]="editingFolder && (editingFolder.creationType === 1
    ? ('folderType' + (editingFolder.folderType) | translate)
    : editingFolder.fullName)"
    (closeEventEmitter)="onCloseSettingsModalForm()"
    (creationSubmit)="onSubmitSettings()">
  </app-mail-folder-settings-dialog>
</ng-template>
