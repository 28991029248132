<div class="flex items-end gap-1">
  <button mat-icon-button [attr.aria-label]="'reload' | translate" (click)="reload()" matTooltip="{{ 'reload' | translate }}">
    <mat-icon>refresh</mat-icon>
  </button>
  <div class="per-page-block">
    <mat-form-field class="w-[36px] xs:w-[56px]">
      <mat-select class="text-center" [(value)]="searchOrder" (valueChange)="searchOrderChange($event)">
        @for(searchOrder of searchOrders; track searchOrder.id) {
          <mat-option [value]="searchOrder.id" style="width: auto">
            {{ searchOrder.name | translate | lowercase }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  @if(orderDirection) {
    <button mat-icon-button (click)="changeOrder()" matTooltip="{{ 'order' | translate }}">
      <mat-icon>{{ orderDirection === 2 ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</mat-icon>
    </button>
  }
</div>
