import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { TagClient } from '@app-services/api/clients/tag.client';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Observable, fromEvent } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { Contact } from '@app-types/api/contact';
import { ContactClient } from '@app-services/api/clients/contact.client';
import {
  BaseCollectionSearchByIdRequest,
  BaseGetCollectionByIdRequest,
} from '@app-types/base/base';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  tap,
} from 'rxjs/operators';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PaginatorComponent } from '../../common/paginator/paginator.component';
import { SomethingWentWrongComponent } from '../../common/error/something-went-wrong/something-went-wrong.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { DeleteDialogComponent } from '../../common/delete-dialog/delete-dialog.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-email-contacts-dialog',
  templateUrl: './email-contacts-dialog.component.html',
  styleUrls: ['./email-contacts-dialog.component.scss'],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'select-overlay-pane' },
    },
  ],
  standalone: true,
  imports: [
    MatDialogModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    PaginatorComponent,
    NgxSkeletonLoaderModule,
    SomethingWentWrongComponent,
    MatTableModule,
    MatCheckboxModule,
    MatInputModule,
  ],
})
export class EmailContactsDialogComponent implements OnInit, AfterViewInit {
  public isLoading = true;
  @ViewChild('input', { read: ElementRef }) input: ElementRef;
  public mailAccountId: number;
  public contacts: Contact[] = [];
  public dataSource = new MatTableDataSource<Contact>(this.contacts);
  public displayedColumns: string[] = ['select', 'email', 'action'];
  private pageNumber: number;
  public pageSize: number;
  public selection = new SelectionModel<Contact>(true, []);
  public webCode: string;
  public contactToDelete: Contact | null = null;
  public myControl = new UntypedFormControl();
  public options: string[] = [];
  public filteredOptions: Observable<string[]>;
  public isError = false;
  public search = '';

  constructor(
    public dialogRef: MatDialogRef<EmailContactsDialogComponent>,
    public tagClient: TagClient,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private matchError: MatchError,
    private contactClient: ContactClient,
    public dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        this.close();
      }
    });
    this.mailAccountId = this.data.mailAccountId;
    this.pageNumber = 1;
    this.pageSize = 25;
    try {
      const response = await this.contactClient.getForAccount(
        new BaseGetCollectionByIdRequest(
          this.mailAccountId,
          this.pageNumber,
          100
        )
      );
      this.options = response.data.map(e => e.email);
      await this.getContacts();
      this.isLoading = false;
      this.isError = false;
    } catch (e) {
      this.isError = true;
      this.isLoading = false;
      this.matchError.logError(e);
    }
  }

  ngAfterViewInit(): void {
    // server-side search
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => {
          this.search = this.input?.nativeElement?.value;
          this.getContacts();
        })
      )
      .subscribe();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option =>
      option.toLowerCase().includes(filterValue)
    );
  }

  canNavigateBack(): boolean {
    return this.contacts.length ? this.pageNumber > 1 : false;
  }

  async navigateBack(): Promise<void> {
    this.pageNumber = this.pageNumber - 1;
    await this.getContacts();
    this.selection.clear();
  }

  canNavigateForward(): boolean {
    return this.contacts.length
      ? this.contacts.length === this.pageSize
      : false;
  }

  async navigateForward(): Promise<void> {
    this.pageNumber = this.pageNumber + 1;
    await this.getContacts();
    this.selection.clear();
  }

  async deleteContacts(): Promise<any> {
    if (
      !this.contactToDelete &&
      this.selection &&
      this.selection.selected.length > 0
    ) {
      await this.contactClient.delete({
        list: this.selection.selected.map(value => value.contactId),
      });
    }
    if (this.contactToDelete) {
      await this.contactClient.delete({
        list: [this.contactToDelete.contactId],
      });
    }
  }

  public deleteContact(contact: Contact): void {
    this.contactToDelete = contact;
    this.openConfirmDialog();
  }

  public openConfirmDialog(): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '450px',
      autoFocus: false,
      data: {
        title: 'deleteContactTitle',
        subTitles: [
          {
            title: 'deleteContactSubtitle',
            subTitle: '',
          },
        ],
        onDelete: async () => await this.deleteContacts(),
      },
    });

    dialogRef.afterClosed().subscribe(x => {
      if (x.isDeleted) {
        this.getContacts();
        this.contactToDelete && (this.contactToDelete = null);
        !!this.selection.selected.length && this.selection.clear();
      }
    });
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.search = event.option.viewValue;
    this.getContacts();
  }

  public async pageSizeChange(pageSize: number): Promise<void> {
    this.pageSize = pageSize;
    this.pageNumber = 1;

    await this.getContacts();
  }

  private async getContacts(): Promise<any> {
    this.isLoading = true;
    try {
      const response = !this.search
        ? await this.contactClient.getForAccount(
            new BaseGetCollectionByIdRequest(
              this.mailAccountId,
              this.pageNumber,
              this.pageSize
            )
          )
        : await this.contactClient.searchForAccount(
            new BaseCollectionSearchByIdRequest(
              this.mailAccountId,
              this.pageNumber,
              this.pageSize,
              this.search
            )
          );
      this.contacts = response.data;
      this.dataSource.data = this.contacts;
      this.isError = false;
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  public addContact(email: string): void {
    this.dialogRef.close([email]);
  }

  public addSelectedContacts(): void {
    const emails = this.selection.selected.map(e => e.email);
    this.dialogRef.close(emails);
  }

  public close(): void {
    this.dialogRef.close();
  }
}
