<div class="drawer-content-screen w-full">
  <div class="pr-2">
    <ngx-skeleton-loader
      [count]="1"
      [theme]="{
        'margin-bottom': 0,
        height: '40px',
        'max-width': '50%',
      }"
    ></ngx-skeleton-loader>
  </div>
  <div class="drawer-content-screen__body flex flex-col gap-4 !overflow-y-hidden">
    <div>
      <ngx-skeleton-loader
        [count]="1"
        [theme]="{
        'margin-bottom': 0,
        height: '40px',
      }"
      ></ngx-skeleton-loader>
    </div>
    <div>
      <ngx-skeleton-loader
         [count]="1"
         [theme]="{
          'margin-bottom': 0,
          height: '40px',
        }"
        ></ngx-skeleton-loader>
    </div>
    <div>
      <ngx-skeleton-loader
        [count]="1"
        [theme]="{
        'margin-bottom': 0,
         height: '40px',
      }"
      ></ngx-skeleton-loader>
    </div>
    <div>
      <ngx-skeleton-loader
        [count]="1"
        [theme]="{
        'margin-bottom': 0,
        height: '40px',
      }"
      ></ngx-skeleton-loader>
    </div>
    <div>
      <ngx-skeleton-loader
        [count]="1"
        [theme]="{
          'margin-bottom': 0,
          height: '40px',
          'max-width': '50%'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div>
      <ngx-skeleton-loader
        [count]="1"
        [theme]="{
          'margin-bottom': 0,
          height: '40px',
          'max-width': '50%'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div>
      <ngx-skeleton-loader
        [count]="1"
        [theme]="{
          'margin-bottom': 0,
          height: '40px',
          'max-width': '40%'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div>
      <ngx-skeleton-loader
        [count]="1"
        [theme]="{
          'margin-bottom': 0,
          height: '40px',
          'max-width': '30%'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div>
      <ngx-skeleton-loader
        [count]="1"
        [theme]="{
          'margin-bottom': 0,
          height: '40px',
        }"
      ></ngx-skeleton-loader>
    </div>
    <div>
      <ngx-skeleton-loader
        [count]="1"
        [theme]="{
          'margin-bottom': 0,
          height: '40px',
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>
  <div class="flex justify-end pr-2">
    <div class="w-[15%]">
      <ngx-skeleton-loader
        [count]="1"
        [theme]="{
          'margin-bottom': 0,
          width: '100%',
          height: '36px'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>
</div>
