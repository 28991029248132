import { Injectable, OnDestroy } from '@angular/core';
import { AuthenticationService } from './auth/authentication.service';
import { LoggingHandler } from './errors/logging.service';

@Injectable({
  providedIn: 'root',
})
export abstract class IntervalService implements OnDestroy {
  private intervalIds: { [key: string]: any } = {};

  protected constructor(
    private authenticationService: AuthenticationService,
    protected loggingHandler: LoggingHandler
  ) {}

  startInterval(
    key: string,
    callback: () => void,
    interval: number,
    webCode: string
  ): void {
    this.clearInterval(key);
    this.intervalIds[key] = setInterval(() => {
      const isLoggedIn = this.authenticationService.isLoggedIn();
      console.log('isLoggedIn', isLoggedIn);
      if (isLoggedIn) {
        this.loggingHandler.create('information', 'startInterval callback');
        callback();
      } else {
        this.clearInterval(key);
        this.authenticationService.logout(webCode);
      }
    }, interval);
  }

  clearInterval(key: string): void {
    if (this.intervalIds[key]) {
      clearInterval(this.intervalIds[key]);
      delete this.intervalIds[key];
    }
  }

  clearAllIntervals(): void {
    Object.keys(this.intervalIds).forEach(name => {
      this.clearInterval(name);
    });
  }

  ngOnDestroy(): void {
    this.clearAllIntervals();
  }
}
