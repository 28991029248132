export abstract class BaseGetRequest {
  public format(): string {
    const params = new URLSearchParams();

    for (const p in this) {
      if (this[p] !== undefined && this[p] !== null) {
        params.append(p, String(this[p]));
      }
    }

    return params.toString();
  }
}
