import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserContract, UserUpdateRequest } from '@app-types/api/user';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { UserClient } from '@app-services/api/clients/user.client';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingButtonComponent } from '../common/loading-button/loading-button.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-account-update-dialog',
  templateUrl: './account-update-dialog.component.html',
  styleUrls: ['./account-update-dialog.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    LoadingButtonComponent,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
  ],
})
export class AccountUpdateDialogComponent implements OnInit {
  @Input() user: UserContract;
  @Input() locales: Array<{ language: string; id: string }>;
  @Output() public updateEventEmitter = new EventEmitter<UserUpdateRequest>();
  @Output() public closeEventEmitter = new EventEmitter();

  public isLoading = false;
  public isError = false;
  public form = new UntypedFormGroup({});

  constructor(
    private userClient: UserClient,
    public matchError: MatchError
  ) {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      firstName: new UntypedFormControl(this.user.firstName, [
        Validators.required,
        Validators.maxLength(256),
      ]),
      lastName: new UntypedFormControl(this.user.lastName, [
        Validators.required,
        Validators.maxLength(256),
      ]),
      email: new UntypedFormControl(this.user.email),
      locale: new UntypedFormControl(this.user.localizationCode),
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

  public async onSubmit(): Promise<void> {
    if (this.form.valid) {
      this.isLoading = true;
      this.isError = false;
      const values = this.form.value;
      const request = {
        firstName: values.firstName,
        lastName: values.lastName,
        localizationCode: values.locale,
      };
      try {
        await this.userClient.update(request);
        this.updateEventEmitter.emit(request);
      } catch (e) {
        this.isError = true;
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
      } finally {
        this.isLoading = false;
      }
    }
  }
}
