import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  Router,
} from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { PermissionType } from '@app-types/enums/permission-type';
import { PermissionService } from '../permission/permission.service';
import { AuthenticationClient } from '../api/clients/authentication.client';
import { MatchError } from '../errors/error-matcher';
import { LoggingHandler } from '../errors/logging.service';
import { Permissions } from './auth.guard';

@Injectable({
  providedIn: 'root',
})
export class RootPermissionsService extends Permissions {
  constructor(
    router: Router,
    authenticationService: AuthenticationService,
    private permissionService: PermissionService,
    authenticationClient: AuthenticationClient,
    matchError: MatchError,
    loggingHandler: LoggingHandler
  ) {
    super(
      router,
      authenticationService,
      authenticationClient,
      matchError,
      loggingHandler
    );
  }

  override async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const superActivationResult = await super.canActivate(route);

    if (!superActivationResult) {
      return false;
    } else {
      return await this.permissionService.hasPermissionOver(
        null,
        null,
        PermissionType.CanEverything
      );
    }
  }
}

export const RootAuthGuard: CanActivateChildFn = (route): Promise<boolean> => {
  return inject(RootPermissionsService).canActivate(route);
};
