export enum SeenEnum {
  showAll = 0,
  onlyUnseen = 1,
  onlySeen = 2,
}

export enum SourceEnum {
  showAll = 0,
  mailboxPEC = 1,
  reassignment = 2,
}

export enum AttachmentsEnum {
  showAll = 0,
  onlyWithoutAttachments = 1,
  onlyWithAttachments = 2,
}

export enum NotificationsEnum {
  showAll = 0,
  'Only without notifications' = 1,
}

export enum ReassignedEnum {
  all = 0,
  'not reassigned' = 1,
  reassigned = 2,
}

export enum ValidatedEnum {
  all = 0,
  New = 1,
  Validated = 2,
  'Not validated' = 3,
}

export enum SearchTypeEnum {
  currSelections = 1,
  currSelectionsPlusSubfolders = 2,
  allFolders = 3,
}
