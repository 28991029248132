<mat-tab-group
  (selectedIndexChange)="tabSelected($event)"
  [selectedIndex]="selectedTabIndex"
  mat-stretch-tabs="false"
  mat-align-tabs="start"
>
  @for(tabItem of tabItemsSortedByIndex; track tabItem.index) {
    <mat-tab [label]="tabItem.label | translate"></mat-tab>
  }
</mat-tab-group>
