<div class="drawer-content-screen">
  <div class="drawer-content-screen__title">
    {{ provider ? ("updateProvider" | translate) : ("createNewProvider" | translate) }}
  </div>
  <div class="drawer-content-screen__body">
    <form class="flex flex-col w-full" [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="onSubmit(form.value)">
      <mat-form-field >
        <mat-label>{{ "code" | translate }}</mat-label>
        <input matInput id="code" formControlName="code" />
        @if(hasError('code', 'maxlength')) {
          <mat-error>{{ 'moreThan512Char' | translate }}</mat-error>
        }
      </mat-form-field>
      <mat-form-field >
        <mat-label>{{ "name" | translate }}</mat-label>
        <input matInput id="name" formControlName="name" required />
        @if(hasError('name', 'required')) {
          <mat-error>{{ "nameIsRequired" | translate }}</mat-error>
        }
        @if(hasError('name', 'maxlength')) {
          <mat-error>{{ "moreThan128Char" | translate }}</mat-error>
        }
      </mat-form-field>
      <div class="flex w-full gap-5">
        <mat-form-field class="w-2/3">
          <mat-label>{{ "smtp" | translate }}</mat-label>
          <input matInput id="smtp" formControlName="smtp" required />
          @if(hasError('smtp', 'required')) {
            <mat-error>{{ "nameIsRequired" | translate }}</mat-error>
          }
          @if(hasError('smtp', 'maxlength')) {
            <mat-error>{{ "moreThan256Char" | translate }}</mat-error>
          }
        </mat-form-field>
        <mat-form-field class="w-1/3">
          <mat-label>{{ "smtpPort" | translate }}</mat-label>
          <input type="number" matInput id="smtpPort" formControlName="smtpPort" required />
          @if(hasError('smtpPort', 'min')) {
            <mat-error>{{ "portValueMustBe" | translate }}</mat-error>
          }
          @if(hasError('smtpPort', 'max')) {
            <mat-error>{{ "portValueMustBe" | translate }}</mat-error>
          }
          @if(hasError('smtpPort', 'pattern')) {
            <mat-error>{{ "portValueMustInt" | translate }}</mat-error>
          }
        </mat-form-field>
      </div>
      <div class="flex w-full gap-5">
        <mat-form-field class="w-2/3">
          <mat-label>{{ "imap" | translate }}</mat-label>
          <input matInput id="imap" formControlName="imap" required />
          @if(hasError('imap', 'required')) {
            <mat-error>{{ "nameIsRequired" | translate }}</mat-error>
          }
          @if(hasError('imap', 'maxlength')) {
            <mat-error>{{ "moreThan256Char" | translate }}</mat-error>
          }
        </mat-form-field>
        <mat-form-field class="w-1/3">
          <mat-label>{{ "imapPort" | translate }}</mat-label>
          <input type="number" matInput id="imapPort" formControlName="imapPort" required />
          @if(hasError('imapPort', 'min')) {
            <mat-error>{{ "portValueMustBe" | translate }}</mat-error>
          }
          @if(hasError('imapPort', 'max')) {
            <mat-error>{{ "portValueMustBe" | translate }}</mat-error>
          }
          @if(hasError('imapPort', 'pattern')) {
            <mat-error>{{ "portValueMustInt" | translate }}</mat-error>
          }
        </mat-form-field>
      </div>
      <mat-form-field>
        <mat-label>{{ "tlsVersion" | translate }}</mat-label>
        <mat-select formControlName="sslProtocol">
          <mat-option></mat-option>
          @for(item of tlsOptions; track item) {
            <mat-option [value]="item.id">
              {{ item.value }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ "inboxFolderName" | translate }}</mat-label>
        <input type="text" matInput id="inboxName" formControlName="inboxName" />
        @if(hasError('inboxName', 'maxlength')) {
          <mat-error>{{ 'moreThan512Char' | translate }}</mat-error>
        }
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ "outboxFolderName" | translate }}</mat-label>
        <input type="text" matInput id="outboxName" formControlName="outboxName" />
        @if(hasError('outboxName', 'maxlength')) {
          <mat-error>{{ 'moreThan512Char' | translate }}</mat-error>
        }
      </mat-form-field>
    </form>
  </div>
  <div class="flex justify-end w-full">
    <button mat-flat-button color="primary" [disabled]="isLoading || !form.valid" (click)="onSubmit(form.value)">
      <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
    </button>
  </div>
</div>
