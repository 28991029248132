@if(!useMobileView) {
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="1">
      <th *matHeaderCellDef mat-header-cell width="5%">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            'margin-bottom': 0,
            'max-width': '30px'
          }"
        ></ngx-skeleton-loader>
      </th>
      <td *matCellDef  mat-cell width="5%">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            'margin-bottom': 0,
            'max-width': '30px'
          }"
        ></ngx-skeleton-loader>
      </td>
    </ng-container>
    <ng-container matColumnDef="2">
      <th *matHeaderCellDef mat-header-cell width="5%"></th>
      <td *matCellDef  mat-cell width="5%">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            'margin-bottom': 0,
            'max-width': '30px'
          }"
        ></ngx-skeleton-loader>
      </td>
    </ng-container>
    <ng-container matColumnDef="3">
      <th *matHeaderCellDef mat-header-cell width="15%">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            'margin-bottom': 0,
            'max-width': '100px'
          }"
        ></ngx-skeleton-loader>
      </th>
      <td *matCellDef mat-cell width="15%">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            'margin-bottom': 0,
          }"
        ></ngx-skeleton-loader>
      </td>
    </ng-container>
    <ng-container matColumnDef="4">
      <th mat-header-cell *matHeaderCellDef style="min-width: 60px">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            'margin-bottom': 0,
            'max-width': '100px'
          }"
        ></ngx-skeleton-loader>
      </th>
      <td *matCellDef mat-cell style="min-width: 60px">
        <ngx-skeleton-loader
          [theme]="{
            'margin-bottom': 0
          }"
          count="1"
        ></ngx-skeleton-loader>
      </td>
    </ng-container>
    <ng-container matColumnDef="5">
      <th *matHeaderCellDef mat-header-cell width="20%">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            'margin-bottom': 0,
            'max-width': '60px'
          }"
        ></ngx-skeleton-loader>
      </th>
      <td *matCellDef mat-cell width="20%">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            'margin-bottom': 0
          }"
        ></ngx-skeleton-loader>
      </td>
    </ng-container>
    <ng-container matColumnDef="6">
      <th *matHeaderCellDef mat-header-cell width="10%">
      </th>
      <td *matCellDef  mat-cell width="10%">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            'margin-bottom': 0
          }"
        ></ngx-skeleton-loader>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
}
@if(useMobileView) {
  <div class="mobile-container-cards">
    <div class="card w-full">
      <ngx-skeleton-loader
        [count]="dataSource.data.length"
        [theme]="{
          'margin-bottom': 0,
          height: '56px'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>
}
