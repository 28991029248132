<div class="page-content">
  @if(hasError) {
    <div class="h-[80%]">
      <app-something-went-wrong
        [showPicture]="true"
        (retryEmitter)="loadOrganization()"
      ></app-something-went-wrong>
    </div>
  }

  @if(isLoading) {
    <app-general-settings-skeleton
      [hasButtons]="false"
      [hasSubTitle]="false"
      [countLine]="5"
    ></app-general-settings-skeleton>
  }

  @if(!isLoading && !hasError && isUploaded) {
    <div class="flex flex-col gap-10">
      <div class="flex flex-col gap-4">
        <div class="header-container">
          <h1>{{ "generalInfo" | translate }}</h1>
          <button
            mat-icon-button
            aria-label="Settings"
            matTooltip="{{ 'settings' | translate }}"
            (click)="openOrganizationSettingsDialog()">
            <mat-icon>settings</mat-icon>
          </button>
        </div>
        <div class="self-start box-container">
          <div class="font-bold">
            {{ "name" | translate }}:
          </div>
          <div>{{ organization.name }}</div>
          <div class="font-bold">{{ "webCode" | translate }}:</div>
          <div>{{ organization.webCode }}</div>
          <div class="font-bold">{{ "licenseId" | translate }}:</div>
          <div>{{ organization.licenseId }}</div>
          <div class="font-bold">{{ "codeSia" | translate }}:</div>
          <div>{{ organization.codeSia }}</div>
          <div class="font-bold">{{ "anagId" | translate }}:</div>
          <div>{{ organization.anagId }}</div>
        </div>
      </div>
    </div>
  }
</div>
<ng-template #settingsDrawer>
  <app-organization-settings-dialog [organizationId]="organizationId">
  </app-organization-settings-dialog>
</ng-template>

