import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TabItem } from '@app-types/common';
import { NavigationEnd, Router } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navigation-tabs',
  templateUrl: './navigation-tabs.component.html',
  styleUrls: ['./navigation-tabs.component.scss'],
  standalone: true,
  imports: [MatTabsModule, TranslateModule],
})
export class NavigationTabsComponent implements OnInit, OnDestroy {
  @Input() public tabItems: TabItem[] = [];
  public currentRoute;
  router$: Subscription;

  constructor(private router: Router) {
    this.currentRoute = this.router.url;
  }

  ngOnInit(): void {
    this.router$ = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.currentRoute = this.router.url;
      });
  }

  get tabItemsSortedByIndex(): TabItem[] {
    return this.tabItems.sort((a, b) => a.index - b.index);
  }

  get selectedTabIndex(): number | null {
    const selectedTabItem = this.tabItemsSortedByIndex.find(
      i => i.route === this.currentRoute
    );

    if (!selectedTabItem) {
      return null;
    }

    return selectedTabItem.index;
  }

  async tabSelected(tabIndex: number): Promise<void> {
    const selectedTabItem = this.tabItemsSortedByIndex[tabIndex];
    if (selectedTabItem.route !== this.currentRoute) {
      await this.router.navigate([selectedTabItem.route]);
    }
  }

  ngOnDestroy(): void {
    this.router$?.unsubscribe();
  }
}
