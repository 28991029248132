import { Component, Input, OnInit } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-general-settings-skeleton',
  standalone: true,
  imports: [NgxSkeletonLoaderModule],
  templateUrl: './general-settings-skeleton.component.html',
  styleUrls: ['./general-settings-skeleton.component.scss'],
})
export class GeneralSettingsSkeletonComponent implements OnInit {
  @Input() hasButtons = true;
  @Input() hasSubTitle = true;
  @Input() hasTitle = true;
  @Input() countLine?: number;

  field = ['85%', '70%', '50%', '60%', '80%', '80%', '60%', '55%'];

  constructor() {}

  ngOnInit(): void {
    if (this.countLine) {
      this.field.length = this.countLine;
    }
  }
}
