<div class="close-button">
  <button tabIndex="-1" class="" mat-icon-button (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<h1 mat-dialog-title>{{ "addTags" | translate }}</h1>
<mat-dialog-content class="mat-mdc-dialog-content mdc-dialog__content mat-container">
  @if(isLoading) {
    <div>
      <ngx-skeleton-loader
        [count]="1"
        [theme]="{
          'margin-bottom': 0,
          height: '56px',
          width: '100%',
        }"
      ></ngx-skeleton-loader>
    </div>
  }

  @if(isError) {
    <div class="h-full w-full">
      <app-something-went-wrong
          [showPicture]="false"
          (retryEmitter)="ngOnInit()"
        ></app-something-went-wrong>
    </div>
  }
  @if(!isLoading && !isError) {
    <div class="tags-dialog">
      <app-tags
        [needSaveTags]="false"
        (setTags)="setTags($event)"
        (setIsSave)="setIsSave()"
        [emailId]="data.ids"
        [existedTags]="existedTags"
        [thisTags]="thisTags"
        [tagCtrl]='tagCtrl'
      ></app-tags>
    </div>
  }
</mat-dialog-content>
<mat-dialog-actions align="end" class="mat-mdc-dialog-actions mdc-dialog__actions">
  <button
    mat-flat-button
    [disabled]="isSavingTags"
    color="primary"
    (click)="saveTags()"
  >
    <app-loading-button text="{{ 'save' | translate }}" [loading]="isSavingTags"></app-loading-button>
  </button>
</mat-dialog-actions>
