import { Component, Input, OnInit } from '@angular/core';
import { BaseGetByIdRequest } from '@app-types/base/base';
import { MailAccountSimpleConfigurationClient } from '@app-services/api/clients/mail-account-simple-configuration-client';
import { RemoteFolderForSimpleConfigurationContract } from '@app-types/api/mail-account-simple-configuration';
import { MatTableDataSource } from '@angular/material/table';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { ErrorsPipe } from 'src/app/shared/pipes/error-code.pipe';
import { SomethingWentWrongComponent } from '@app-components/common/error/something-went-wrong/something-went-wrong.component';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorHintComponent } from '@app-components/common/error-hint/error-hint.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DrawerService } from '@app-services/drawer.service';
import { NestedTreeControl } from '@angular/cdk/tree';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LoadingButtonComponent } from '@app-components/common/loading-button/loading-button.component';
import { MatTreeModule } from '@angular/material/tree';
import { SkeletonFormDrawerComponent } from '@app-components/common/skeletons/skeleton-form-drawer/skeleton-form-drawer.component';

@Component({
  selector: 'app-mail-account-quick-setup-dialog',
  templateUrl: './mail-account-quick-setup-dialog.component.html',
  styleUrls: ['./mail-account-quick-setup-dialog.component.scss'],
  standalone: true,
  imports: [
    SomethingWentWrongComponent,
    TranslateModule,
    SkeletonFormDrawerComponent,
    ErrorHintComponent,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    MatCheckboxModule,
    LoadingButtonComponent,
    MatTreeModule,
  ],
})
export class MailAccountQuickSetupDialogComponent implements OnInit {
  @Input() mailAccountId: number;

  public isLoadingData = false;
  public cannotLoadData = false;
  public errorText: string;

  public isLoading = false;
  public isError = false;
  public selectAll = false;
  public canSetup = false;
  public remoteFolders: RemoteFolderForSimpleConfigurationContract[] = [];

  public dataSource =
    new MatTableDataSource<RemoteFolderForSimpleConfigurationContract>(
      this.remoteFolders
    );
  treeControl =
    new NestedTreeControl<RemoteFolderForSimpleConfigurationContract>(
      node => node.subFolders
    );

  public startMailAccount = true;
  public activated = false;
  pipeErrors = new ErrorsPipe();
  constructor(
    public matchError: MatchError,
    private mailAccountSimpleConfigurationClient: MailAccountSimpleConfigurationClient,
    public drawerService: DrawerService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadData();
  }

  async loadData(): Promise<any> {
    this.errorText = '';
    this.isLoadingData = true;
    this.cannotLoadData = false;
    try {
      const canSetupResponse =
        await this.mailAccountSimpleConfigurationClient.canSetup(
          new BaseGetByIdRequest(this.mailAccountId)
        );
      this.canSetup = canSetupResponse.canSetup;
      if (this.canSetup) {
        this.remoteFolders = canSetupResponse.folders;
        this.dataSource.data = this.remoteFolders;
        this.treeControl.dataNodes = this.remoteFolders;
        this.treeControl.expandAll();
      }
    } catch (error: any) {
      this.cannotLoadData = true;
      this.errorText =
        this.pipeErrors.transform(error.Code) ||
        `Error code: ${error.Code}: ${error.Description || 'something went wrong'}`;
      this.matchError.logError(error);
    } finally {
      this.isLoadingData = false;
    }
  }

  isSelectAll(arr: RemoteFolderForSimpleConfigurationContract[]): boolean {
    return arr.every(
      el => el.sync && (el?.subFolders ? this.isSelectAll(el.subFolders) : true)
    );
  }

  selectFolder(item: RemoteFolderForSimpleConfigurationContract): void {
    item.sync = !item.sync;
    this.selectAll = this.isSelectAll(this.remoteFolders);
  }

  getCheckedAll(arr: any[]): any[] {
    return arr.map(el => {
      return {
        ...el,
        sync: this.selectAll,
        subFolders: el?.subFolders ? this.getCheckedAll(el.subFolders) : null,
      };
    });
  }

  checkedAll(arr: any): void {
    const newData = this.getCheckedAll(arr);
    this.remoteFolders = newData;
    this.dataSource.data = newData;
    this.treeControl.dataNodes = newData;
    this.treeControl.expandAll();
  }

  hasChild = (_: number, node: RemoteFolderForSimpleConfigurationContract) =>
    !!node.subFolders && node.subFolders.length > 0;

  public async onSubmit(): Promise<void> {
    this.isLoading = true;
    this.isError = false;
    this.drawerService.disabledDrawer(true);
    try {
      await this.mailAccountSimpleConfigurationClient.create({
        mailAccountId: this.mailAccountId,
        folders: this.remoteFolders,
        startMailAccount: this.startMailAccount,
      });
      this.activated = true;
      this.drawerService.disabledDrawer(false);
      this.drawerService.closeDrawer();
    } catch (e) {
      this.isError = true;
      this.matchError.logError(e);
      this.drawerService.disabledDrawer(false);
    } finally {
      this.isLoading = false;
    }
  }

  cancel(): void {
    this.drawerService.closeDrawer();
  }
}
