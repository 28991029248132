import { Pipe, PipeTransform } from '@angular/core';
import { EmailIcons } from 'src/app/types/enums/email-icons';
import { MailFolderType } from 'src/app/types/enums/mail-folder.type';

@Pipe({
  name: 'formatEmailIconsByFolderType',
  standalone: true,
})
export class FormatEmailIconsByFolderType implements PipeTransform {
  transform(emailType: number, emailIcon: EmailIcons): boolean {
    switch (emailType) {
      case MailFolderType.Inbox:
        return [
          EmailIcons.Seen,
          EmailIcons.Archive,
          EmailIcons.Delete,
          EmailIcons.DownloadEML,
          EmailIcons.Forward,
          EmailIcons.Reply,
          EmailIcons.ReplyAll,
          EmailIcons.Move,
          EmailIcons.Groups,
          EmailIcons.Tags,
          EmailIcons.Validate,
          EmailIcons.Spam,
          EmailIcons.Reassign,
        ].includes(emailIcon);
      case MailFolderType.Sent:
        return [
          EmailIcons.Seen,
          EmailIcons.Archive,
          EmailIcons.Delete,
          EmailIcons.DownloadEML,
          EmailIcons.Forward,
          EmailIcons.Reply,
          EmailIcons.ReplyAll,
          EmailIcons.Move,
          EmailIcons.Groups,
          EmailIcons.Tags,
        ].includes(emailIcon);
      case MailFolderType.Draft:
        return [EmailIcons.Tags, EmailIcons.Delete, EmailIcons.Groups].includes(
          emailIcon
        );
      case MailFolderType.Outbox:
        return [EmailIcons.RetrySending].includes(emailIcon);
      case MailFolderType.Validation:
        return [
          EmailIcons.Seen,
          EmailIcons.Delete,
          EmailIcons.DownloadEML,
          EmailIcons.Forward,
          EmailIcons.Reply,
          EmailIcons.ReplyAll,
          EmailIcons.Move,
          EmailIcons.Groups,
          EmailIcons.Tags,
        ].includes(emailIcon);
      case MailFolderType.Spam:
        return [
          EmailIcons.Seen,
          EmailIcons.Delete,
          EmailIcons.DownloadEML,
          EmailIcons.Forward,
          EmailIcons.Reply,
          EmailIcons.ReplyAll,
          EmailIcons.Groups,
          EmailIcons.Tags,
          EmailIcons.NotSpam,
        ].includes(emailIcon);
      case MailFolderType.Trash:
        return [
          EmailIcons.Seen,
          EmailIcons.Forward,
          EmailIcons.Reply,
          EmailIcons.DownloadEML,
          EmailIcons.ReplyAll,
          EmailIcons.Groups,
          EmailIcons.Tags,
          EmailIcons.Restore,
        ].includes(emailIcon);
      case MailFolderType.Archive:
        return [
          EmailIcons.Seen,
          EmailIcons.RestoreFromArchive,
          EmailIcons.Delete,
          EmailIcons.DownloadEML,
          EmailIcons.Forward,
          EmailIcons.Reply,
          EmailIcons.ReplyAll,
          EmailIcons.Move,
          EmailIcons.Groups,
          EmailIcons.Tags,
        ].includes(emailIcon);
      default:
        return false;
    }
  }
}
