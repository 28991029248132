import { environment } from '@app-environments/environment';
import { Injectable } from '@angular/core';
import { ApiRequest } from '../api.request';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import {
  BaseGetCollectionByIdRequest,
  BaseGetCollectionResponse,
} from '@app-types/base/base';
import { EmailAuditContract } from '@app-types/api/emails/email-audit';

const API_URL = environment.apiUrl;
const { getByEmail } = API_ROUTES.emailAudit;

@Injectable({ providedIn: 'root' })
export class EmailAuditClient {
  constructor(private apiRequest: ApiRequest) {}
  async getByEmail(
    request: BaseGetCollectionByIdRequest
  ): Promise<BaseGetCollectionResponse<EmailAuditContract>> {
    return this.apiRequest.get(`${API_URL}${getByEmail}?${request.format()}`);
  }
}
