<div class="drawer-content-screen import-eml">
  @if(isLoadingFolders) {
    <div class="h-full">
      <app-skeleton-form-drawer></app-skeleton-form-drawer>
    </div>
  }

  @if(foldersError) {
    <div class="flex-col-center">
      <app-something-went-wrong
        description="{{ 'wentWrong' | translate }}"
        (retryEmitter)="getFolders()"
        [showPicture]="false"
      ></app-something-went-wrong>
    </div>
  }

  @if(!isLoadingFolders && !foldersError && isUploaded) {
    <div class="flex gap-8 items-end">
      <div class="drawer-content-screen__title">{{ "importEML" | translate }}</div>
      <div class="w-[20%] border-b border-current hover:border-primary">
        <mat-select [(value)]="selectedMailFolderId" (selectionChange)="onFolderChange($event)">
          @for(item of mailFolders; track item) {
          <mat-option [value]="item.mailFolderId">
            {{ item.creationType === 1 ? ("folderType" + item.folderType | translate) : item.name }}
          </mat-option>
          }
        </mat-select>
      </div>
    </div>
    <div class="overflow-x-auto h-full pr-[5px]">
      <div class=" flex flex-col gap-[1rem] ">
        <div class="flex flex-col gap-[0.5rem]">
          <ngx-file-drop
            [multiple]='true'
            dropZoneLabel="Drop files here"
            (onFileDrop)="onDrop($event)"
            dropZoneClassName="h-[5rem] px-[1.25rem] py-[0.625rem] rounded-[0.5rem] border-2 border-dotted border-borderColor flex items-center justify-end"
            contentClassName="h-[auto] w-full"
          >
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
              <div class="flex gap-[1rem] items-center justify-center">
                <div class="flex flex-col gap-[0.5rem] w-full">
                  @if(isLoading) {
                    <mat-progress-bar
                      class="w-full"
                      mode="determinate"
                      [value]="progressValue"
                    ></mat-progress-bar>
                  }
                  @if(textError) {
                    <mat-error class="font-[14px]">
                      {{ textError | translate }}
                    </mat-error>
                  }

                </div>
                <button
                  [disabled]="isLoading"
                  mat-flat-button
                  color="primary"
                  (click)="openFileSelector()"
                >
                  {{ 'Browse' | translate }}
                </button>
              </div>
            </ng-template>
          </ngx-file-drop>
          @if(filesError) {
            <mat-error class="font-[14px]">
              {{ 'invalidFormat' | translate }} {{ filesError | translate }}
            </mat-error>
          }
        </div>
        <div>
          <div class="search-bar flex gap-[0.5rem] items-center justify-end">
            <button mat-icon-button (click)="reload()" matTooltip="{{ 'reload' | translate }}">
              <mat-icon>refresh</mat-icon>
            </button>
            <app-search-bar
              [fullWidth]="false"
              (searchEventEmitter)="loadData()"
              (valueChangedEventEmitter)="onSearchValueChanged($event)"
              [search]="searchValue"></app-search-bar>
          </div>
          <table mat-table class="w-full" [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="folder">
              <th class="w-[10%]" mat-header-cell *matHeaderCellDef>{{ "folder" | translate }}</th>
              <td class="w-[10%]" mat-cell *matCellDef="let file">
                <span class="break-all">{{ getFolderName(file?.mailFolderId) | translate }}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="parentFileName">
              <th class="w-[28%]" mat-header-cell *matHeaderCellDef mat-sort-header>{{ "zipFilename" | translate }}</th>
              <td class="w-[28%]" mat-cell *matCellDef="let file"><span class="break-all">{{ file?.parentFileName ?? '-' }}</span></td>
            </ng-container>
            <ng-container matColumnDef="fileName">
              <th class="w-[28%]" mat-header-cell *matHeaderCellDef mat-sort-header>{{ "emlFilename" | translate }}</th>
              <td class="w-[28%]" mat-cell *matCellDef="let file"><span class="break-all">{{ file.fileName }}</span></td>
            </ng-container>
            <ng-container matColumnDef="state">
              <th class="w-[14%]" mat-header-cell *matHeaderCellDef>
                <div class="flex items-center">{{ "status" | translate }}
                  <button mat-icon-button [matMenuTriggerFor]="menuConnect">
                    <mat-icon class="icon" [class.blue]="!!filter">filter_list</mat-icon>
                  </button>
                </div>
              </th>
              <td class="w-[14%]" mat-cell *matCellDef="let file">
                <div class="flex items-center justify-between gap-[0.2rem]">
                {{ file.state | formatStateImportEml | translate }}
                <div class="w-[40px]">
                  @if(file.state === importEmlState.Uploaded || file.state === importEmlState.InProgress) {
                     <app-loader padding="0" [diameter]="'22'" height="100%"></app-loader>
                  }
                  @if(file.state === importEmlState.ImportError || file.state === importEmlState.UploadError) {
                    <mat-icon
                      class="icon"
                      color="warn"
                    >
                      error_outline
                    </mat-icon>
                  }
                  @if(file.state === importEmlState.Imported) {
                    <mat-icon
                      class="icon"
                      color="primary"
                    >
                      done
                    </mat-icon>
                  }
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="stateDateTime">
              <th class="w-[10%]" mat-header-cell *matHeaderCellDef mat-sort-header>{{ "importDateTime" | translate }}</th>
              <td class="w-[10%]" mat-cell *matCellDef="let file">{{ file.stateDateTime | date : "dd/MM/yyyy HH:mm:ss" }}</td>
            </ng-container>
            <ng-container matColumnDef="message">
              <th class="w-[10%]" mat-header-cell *matHeaderCellDef mat-sort-header>{{ "message" | translate }}</th>
              <td class="w-[10%]" mat-cell *matCellDef="let file">{{ file?.message
                ? (+file.message | formatMessageImportEml | translate) || (+file.message | errors | translate) : ''
                }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          @if(!dataSource.data.length) {
            <app-no-data></app-no-data>
          }
        </div>
      </div>
    </div>
  }
</div>

<mat-menu #menuConnect (click)="$event.stopPropagation();">
  @for(state of menuState; track state) {
    <button
      mat-menu-item
      (click)="setFilterChange('state', state.id)"
     >
      <span [class.blue]="filter === state.id">{{ state.title | translate }}</span>
    </button>
  }
</mat-menu>
