import {
  MailAccountConnectionStatusInfoType,
  MailAccountConnectionStatusType,
} from '../enums/mail-account-connection-status-type';
import { BaseGetRequest } from '../base/base-get.request';
import { AutoSaveContactSource } from '../enums/auto-save-contact.source';
import { PignorState } from '../enums/pignor-type';
import { MailFolderStatisticContract } from './mail-folder';

export interface MailAccountContract {
  mailAccountId: number;
  name: string;
  codeSia: string;
  anagId: string;
  license: string;
  email: string;
  providerId: number;
  providerName: string;
  organizationId: number;
  connectionStatus?: MailAccountConnectionStatusType;
  connectionStatusInfo?: MailAccountConnectionStatusInfoType;
}

export interface MailAccountUpdateRequest {
  mailAccountId: number;
  name: string;
  license: string;
  codeSia: string;
  anagId: string;
}

export interface MailAccountSearchRequest {
  page?: number;
  pageSize?: number;
  filter?: {
    search?: string;
    ProviderId?: number;
    isActive?: boolean;
    isArchive?: boolean;
    searchOrder?: number;
    orderDirection?: number;
  };
  organizationId: number;
}

export interface MailAccountCreateRequest {
  name: string;
  code: string;
  email: string;
  password: string;
  providerId: number;
  organizationId: number;
  codeSia: string;
}

export interface MailAccountChangePasswordRequest {
  mailAccountId: number;
  newPassword: string;
}

export interface MailAccountGetByOrganizationIdResponse {
  data: MailAccountGetByOrganizationIdContract[];
  spaceUsed: number;
}

export interface MailAccountCreateResponse {
  mailAccountId: number;
}

export interface MailAccountGetByOrganizationIdContract {
  mailAccountId: number;
  name: string;
  code: string;
  email: string;
  providerName: string;
  organizationId: number;
  configurationCompleted: boolean;
  archive?: boolean;
  isActive?: boolean;
  anagId?: number;
  codeSia?: string;
  license?: string;
}

export interface MailAccountGetBadgeRequest {
  mailAccountIds: number[];
  hideNotification: boolean;
}

export class GetOrganizationMailAccountsRequest extends BaseGetRequest {
  webCode: string;

  constructor(webCode: string) {
    super();
    this.webCode = webCode;
  }
}

export interface MailAccountGetBadgeResponse {
  mailAccountId: number;
  badge: number;
  spaceUsed: number;
  connectionStatus: MailAccountConnectionStatusType;
  connectionStatusInfo?: MailAccountConnectionStatusInfoType;
}

export interface MailAccountGetByIdResponse {
  mailAccount: MailAccountContract;
}

export interface SamlClaims {
  claimId: number | null;
  claimCode: string | null;
  organizationId: number;
  mailAccountId?: number;
  role: number;
}

export interface MailAccountSamlClaims {
  claims: SamlClaims[];
  mailAccountId: number;
}

export interface MailAccountSettingsContract {
  mailAccountId: number;
  dryRun: boolean;
  active: boolean;
  download: boolean;
  validation: boolean;
  send: boolean;
  code: string;
  archive: boolean;
  downloadCron: string;
  downloadBatchNumber: number;
  archiveSettings: string;
  validationHours: number;
  autoContactSavingType: AutoSaveContactSource;
  validateStart: Date;
  leaveMessagesOnServer: boolean;
  sendNotifyOfValidation: true;
  addressesForSend: {
    addressesForSend: string[];
  } | null;
  pignorState: PignorState;
  threadSleepForSend: number;
  pignorPath: string;
  configurationCompleted: boolean;
  archiveNotLinkedNotification: boolean;
}

export interface MailAccountStatisticContract {
  email: string;
  emailsCount: number;
  usersCount: number;
  massiveEmailsCount: number;
  diskSpace: number;
  foldersStatistic: MailFolderStatisticContract[];
}
