<div>
  <form [formGroup]="form" autocomplete="off" novalidate>
    <mat-form-field  class="w-full">
      <mat-label>{{ "selectGroups" | translate }}</mat-label>
      <mat-select [(ngModel)]="selected" formControlName="groups" #select multiple>
        @for(group of checkBoxGroups; track group) {
          <mat-option
           color="primary"
           (click)="onChange(group)"
           [value]="group.value.groupId">
          {{ group.value.group }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </form>
</div>

