import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LowerCasePipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MAT_SELECT_CONFIG, MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EmailSearchOrder } from '@app-types/enums/email.search-order';
import { OptionsEnum } from '@app-types/common';
import { MatIconModule } from '@angular/material/icon';
import { MailAccountSearchOrder } from '@app-types/enums/MailAccountSearchOrder';

type SearchOrderType = EmailSearchOrder | MailAccountSearchOrder | 0;

@Component({
  selector: 'app-search-order',
  standalone: true,
  imports: [
    TranslateModule,
    LowerCasePipe,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
  ],
  templateUrl: './search-order.component.html',
  styleUrls: ['./search-order.component.scss'],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'select-overlay-pane' },
    },
  ],
})
export class SearchOrderComponent {
  @Output() public reloadEventEmitter = new EventEmitter();
  @Output() public searchOrderChangeEventEmitter =
    new EventEmitter<SearchOrderType>();
  @Output() public changeOrderEventEmitter = new EventEmitter();
  @Input() searchOrder: SearchOrderType;
  @Input() searchOrders: OptionsEnum[];
  @Input() orderDirection?: number;

  constructor() {}

  reload(): void {
    this.reloadEventEmitter.emit();
  }

  searchOrderChange($event: EmailSearchOrder): void {
    this.searchOrderChangeEventEmitter.emit($event);
  }

  changeOrder(): void {
    this.changeOrderEventEmitter && this.changeOrderEventEmitter.emit();
  }
}
