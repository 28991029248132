<div class="folder-title show-folder title">
  <div class="whitespace-nowrap text-ellipsis max-w-full overflow-hidden leading-[3rem]">
    @if((isInbox && isAnyFolders && !isAllFoldersSelected) || (!isInbox && isAnyFolders && !isAllFoldersSelected)) {
      {{ currentFolder?.creationType === 1 ? ("folderType" + currentFolder?.folderType | translate) : currentFolderName }}
    }
    @if(isAnyFolders && isAllFoldersSelected) {
      {{ "allFoldersLabel" | translate }}
    }
  </div>
</div>
