import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AutoSaveContactSource } from '@app-types/enums/auto-save-contact.source';
import { MailAccountSettingsClient } from '@app-services/api/clients/mail-account-settings.client';
import { BaseGetByIdRequest } from '@app-types/base/base';
import {
  MailAccountSettingsContract,
  SamlClaims,
} from '@app-types/api/mail-account';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { OrganizationClient } from 'src/app/services/api/clients/organization.client';
import { PignorState } from 'src/app/types/enums/pignor-type';
import { OrganizationSettingsClient } from 'src/app/services/api/clients/organization-settings.client';
import { ClaimClient } from 'src/app/services/api/clients/claim';
import { RoleType } from 'src/app/types/enums/role-type';
import { SomethingWentWrongComponent } from '@app-components/common/error/something-went-wrong/something-went-wrong.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LoadingButtonComponent } from '@app-components/common/loading-button/loading-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { EmailTagsComponent } from '@app-components/common/email/email-tags/email-tags.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DrawerService } from '@app-services/drawer.service';
import { LocalStorageService } from '@app-services/local-storage.service';
import { STORAGE_NAMES } from '@app-shared/constants/constants';
import { TAGS_REGEX } from '@app-shared/constants/regex';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SkeletonFormDrawerComponent } from '@app-components/common/skeletons/skeleton-form-drawer/skeleton-form-drawer.component';

const { SHOW_ADVANCED_SETTINGS } = STORAGE_NAMES;

@Component({
  selector: 'app-mail-account-settings-dialog',
  templateUrl: './mail-account-settings-dialog.component.html',
  styleUrls: ['./mail-account-settings-dialog.component.scss'],
  standalone: true,
  imports: [
    SomethingWentWrongComponent,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    LoadingButtonComponent,
    SkeletonFormDrawerComponent,
    TranslateModule,
    MatCheckboxModule,
    EmailTagsComponent,
    MatTooltipModule,
    MatDatepickerModule,
  ],
})
export class MailAccountSettingsDialogComponent implements OnInit {
  @Input() public mailAccountId: number;
  @Input() public organizationId: number;
  @Output() public submitEventEmitter = new EventEmitter<{
    isActive: boolean;
  }>();
  public isLoading = false;
  public errorText: string;
  public pignor: boolean;
  public threadSleepForSend: number;
  public iscredemISAMSupport: boolean;
  public samlClaims: SamlClaims[];
  public isShowPignor: boolean;
  public isShowAdvancedSettings: boolean;
  public settingsIsLoading = false;
  public isUploaded = false;
  public cannotLoadSettings = false;
  public autoContactSavingSources = Object.keys(AutoSaveContactSource)
    .filter(k => !(parseInt(k, 10) >= 0))
    .map(key => ({
      id: AutoSaveContactSource[key as keyof typeof AutoSaveContactSource],
      name: key,
    }));
  public settings: MailAccountSettingsContract;
  public form: UntypedFormGroup = new UntypedFormGroup({});
  public mailAccountSamlClaimsForm: UntypedFormGroup = new UntypedFormGroup({});
  public pickerValue = '';
  public notificationEmails: string[] = [];
  public handlingErrorCodes = new Map<number, string>([]);

  constructor(
    private mailAccountSettingsClient: MailAccountSettingsClient,
    private organizationSettingsClient: OrganizationSettingsClient,
    private organizationClient: OrganizationClient,
    public matchError: MatchError,
    public claimClient: ClaimClient,
    public drawerService: DrawerService,
    private localStorageService: LocalStorageService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadSettings();

    this.form = new UntypedFormGroup({
      downloadCron: new UntypedFormControl(+this.settings.downloadCron[2], [
        Validators.required,
        Validators.min(1),
        Validators.max(30),
        Validators.pattern('^[0-9]*$'),
        Validators.maxLength(50),
      ]),
      validationHours: new UntypedFormControl(this.settings.validationHours, [
        Validators.pattern('^[0-9]*$'),
        Validators.required,
        Validators.min(1),
      ]),
      downloadBatchNumber: new UntypedFormControl(
        this.settings.downloadBatchNumber,
        [Validators.pattern('^[0-9]*$'), Validators.required, Validators.min(1)]
      ),
      validateStart: new UntypedFormControl(this.settings.validateStart, []),
      code: new UntypedFormControl(this.settings.code, [
        Validators.maxLength(256),
      ]),
      pignorPath: new UntypedFormControl(this.settings.pignorPath, [
        Validators.maxLength(256),
      ]),
    });

    this.mailAccountSamlClaimsForm = new UntypedFormGroup({
      userRoleClaim: new UntypedFormControl(
        this.samlClaims
          ? this.samlClaims?.find(e => e?.role === RoleType.User)?.claimCode
          : '',
        [Validators.maxLength(256), Validators.required]
      ),
      superuserRoleClaim: new UntypedFormControl(
        this.samlClaims
          ? this.samlClaims?.find(e => e?.role === RoleType.SuperUser)
              ?.claimCode
          : '',
        [Validators.maxLength(256), Validators.required]
      ),
      creatorRoleClaim: new UntypedFormControl(
        this.samlClaims
          ? this.samlClaims?.find(e => e?.role === RoleType.Creator)?.claimCode
          : '',
        [Validators.maxLength(256), Validators.required]
      ),
      validatorRoleClaim: new UntypedFormControl(
        this.samlClaims
          ? this.samlClaims?.find(e => e?.role === RoleType.Validator)
              ?.claimCode
          : '',
        [Validators.maxLength(256), Validators.required]
      ),
      limitedUserRoleClaim: new UntypedFormControl(
        this.samlClaims
          ? this.samlClaims?.find(e => e?.role === RoleType.LimitedUser)
              ?.claimCode
          : '',
        [Validators.maxLength(256), Validators.required]
      ),
      limitedCreatorRoleClaim: new UntypedFormControl(
        this.samlClaims
          ? this.samlClaims?.find(e => e?.role === RoleType.LimitedCreator)
              ?.claimCode
          : '',
        [Validators.maxLength(256), Validators.required]
      ),
    });

    this.setCodeValidation();
    this.setValidation();
  }

  public setCodeValidation(): void {
    if (this.settings.archive) {
      this.form.controls.code.setValidators([
        Validators.required,
        Validators.maxLength(256),
      ]);
    } else {
      this.form.controls.code.setValidators([Validators.maxLength(256)]);
    }
    this.form.controls.code.markAsTouched();
    this.form.controls.code.updateValueAndValidity();
  }

  public setValidation(): void {
    if (this.settings.validation) {
      this.form.controls.validateStart.setValidators([Validators.required]);
    } else {
      this.form.controls.validateStart.setValidators([]);
    }
    this.form.controls.validateStart.markAsTouched();
    this.form.controls.validateStart.updateValueAndValidity();
  }

  async loadSettings(): Promise<any> {
    this.isUploaded = false;
    const loaderTimeout = setTimeout(() => {
      this.settingsIsLoading = true;
    }, 500);

    this.cannotLoadSettings = false;
    try {
      const organizationSettingsResponse =
        await this.organizationSettingsClient.getByOrganization(
          new BaseGetByIdRequest(this.organizationId)
        );
      this.iscredemISAMSupport =
        organizationSettingsResponse.result.credemISAMSupport;
      if (this.iscredemISAMSupport) {
        const responseClaim = await this.claimClient.getByIdForAccount(
          new BaseGetByIdRequest(this.mailAccountId)
        );
        this.samlClaims = responseClaim.data;
      }

      const organizationResponse = await this.organizationClient.getById(
        new BaseGetByIdRequest(this.organizationId)
      );
      this.isShowPignor =
        organizationResponse.organization.pignorState === PignorState.Active;
      this.isShowAdvancedSettings = !!this.localStorageService.getData(
        SHOW_ADVANCED_SETTINGS
      );
      const response = await this.mailAccountSettingsClient.getByMailAccount(
        new BaseGetByIdRequest(this.mailAccountId)
      );
      this.pignor = response.result.pignorState === PignorState.Active;
      this.threadSleepForSend = response.result.threadSleepForSend;
      this.settings = response.result;
      if (response.result.addressesForSend?.addressesForSend) {
        this.notificationEmails =
          response.result.addressesForSend.addressesForSend;
      }
    } catch (e) {
      this.cannotLoadSettings = true;
      this.matchError.logError(e);
    } finally {
      clearTimeout(loaderTimeout);
      this.isUploaded = true;
      this.settingsIsLoading = false;
    }
  }

  public isValidTag(value: string): boolean {
    return !!String(value).toLowerCase().match(TAGS_REGEX);
  }

  isAllValid(): boolean {
    if (!this.settings.sendNotifyOfValidation) {
      return true;
    }
    return (
      this.notificationEmails.every(e => this.isValidTag(e)) &&
      this.notificationEmails.length > 0
    );
  }

  public setNotificationEmails(emails: string[]): void {
    this.notificationEmails = emails;
  }

  checkValidation(): void {
    if (this.settings.validation && !this.form.controls.validateStart.value) {
      this.form.patchValue({ validateStart: new Date() });
    }
    this.setValidation();
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

  public hasErrorSamlForm = (controlName: string, errorName: string) => {
    return this.mailAccountSamlClaimsForm.controls[controlName].hasError(
      errorName
    );
  };

  getClimeCode(value: RoleType): string | null {
    return (
      (value === RoleType.User &&
        this.mailAccountSamlClaimsForm.controls.userRoleClaim.value.replace(
          / /g,
          ''
        )) ||
      (value === RoleType.SuperUser &&
        this.mailAccountSamlClaimsForm.controls.superuserRoleClaim.value.replace(
          / /g,
          ''
        )) ||
      (value === RoleType.Creator &&
        this.mailAccountSamlClaimsForm.controls.creatorRoleClaim.value.replace(
          / /g,
          ''
        )) ||
      (value === RoleType.Validator &&
        this.mailAccountSamlClaimsForm.controls.validatorRoleClaim.value.replace(
          / /g,
          ''
        )) ||
      (value === RoleType.LimitedUser &&
        this.mailAccountSamlClaimsForm.controls.limitedUserRoleClaim.value.replace(
          / /g,
          ''
        )) ||
      (value === RoleType.LimitedCreator &&
        this.mailAccountSamlClaimsForm.controls.limitedCreatorRoleClaim.value.replace(
          / /g,
          ''
        )) ||
      null
    );
  }

  async onSubmit(): Promise<any> {
    if (this.form.valid) {
      this.isLoading = true;
      this.drawerService.disabledDrawer(true);
      this.settings = {
        ...(this.settings ?? {}),
        downloadCron: `*/${this.form.controls.downloadCron.value} * * * *`,
        validationHours: this.form.controls.validationHours.value,
        downloadBatchNumber: this.form.controls.downloadBatchNumber.value,
        validateStart: this.form.controls.validateStart.value,
        mailAccountId: this.mailAccountId,
        code: this.form.controls.code.value || null,
        pignorState: this.pignor ? PignorState.Active : PignorState.Disabled,
        pignorPath: this.form.controls.pignorPath.value || null,
        threadSleepForSend: this.threadSleepForSend,
        addressesForSend: this.notificationEmails.length
          ? {
              addressesForSend: this.notificationEmails,
            }
          : null,
      };

      const isAllValid = this.notificationEmails.every(e => this.isValidTag(e));
      if (!isAllValid) {
        return;
      }

      try {
        if (this.iscredemISAMSupport) {
          const roles = [
            RoleType.User,
            RoleType.SuperUser,
            RoleType.Creator,
            RoleType.Validator,
            RoleType.LimitedUser,
            RoleType.LimitedCreator,
          ];
          const samlClaims: SamlClaims[] = [];
          roles.forEach(element => {
            const updateRequestSamlClaims = {
              claimId:
                this.samlClaims?.find(e => e.role === element)?.claimId || null,
              claimCode: this.getClimeCode(element),
              organizationId: this.organizationId,
              mailAccountId: this.mailAccountId,
              role: element,
            };
            samlClaims.push(updateRequestSamlClaims);
          });
          await this.claimClient.createOrUpdate({
            claims: samlClaims,
            mailAccountId: this.mailAccountId,
          });
        }
        await this.mailAccountSettingsClient.update(this.settings);
        const isActive = this.settings.active;
        this.drawerService.disabledDrawer(false);
        this.submitEventEmitter.emit({ isActive });
        this.drawerService.closeDrawer();
      } catch (e) {
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
        this.drawerService.disabledDrawer(false);
      } finally {
        this.isLoading = false;
      }
    }
  }
}
