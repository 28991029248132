@if(isError) {
  <div class="w-full h-full">
    <app-something-went-wrong
      [showPicture]="false"
      (retryEmitter)="loadTemplates()"
    ></app-something-went-wrong>
  </div>
}

@if(isLoading) {
  <app-skeleton-card-list [hasHeader]="false"></app-skeleton-card-list>
}

@if(!isLoading && !isError) {
  <div class="drawer-content-screen !gap-2">
    <div class="mb-3">
      <app-search-bar
        [fullWidth]="true"
        [isDynamicSearch]="true"
        (searchEventEmitter)="searchTemplates()"
        (valueChangedEventEmitter)="onSearchValueChanged($event)"
        [search]="searchValue"
      ></app-search-bar>
    </div>
    <div class="notes pr-2" [style.cursor]="'default'">
      @for(template of searchedTemplates; track template) {
        <div class="template-card">
          <div>
            <div [style.color]="!isAnyTemplateDownloading ? 'black' : 'grey'" class="p-1.5 font-medium">
              {{ template.name }}
            </div>
            @if(template.description) {
              <div [style.color]="!isAnyTemplateDownloading ? 'black' : 'grey'" class="p-1.5">
                {{ template.description }}
              </div>
            }
          </div>
          <div class="p-1.5 flex justify-end">
            <button
              class="page-block-button"
              color="primary"
              mat-flat-button
              (click)="!isAnyTemplateDownloading && loadTemplate(template.templateId)"
              [disabled]="isAnyTemplateDownloading"
            >
              <app-loading-button
                text="{{ 'select' | translate }}"
                [loading]="downloadingTemplateId === template.templateId"
              ></app-loading-button>
            </button>
          </div>
        </div>
      }
    </div>
  </div>
}
