@if(showLoader) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}

<div id="print-div-id">
  <div>
    <b>{{ "from" | translate }}: </b> {{ emailDetails?.from ?? '' }}
  </div>
  <div>
    <b>{{ "to" | translate }}: </b> {{ emailDetails?.to ?? '' }}
  </div>
  <div>
    <b>{{ "cc" | translate }}: </b> {{ emailDetails?.cc ?? '' }}
  </div>
  <div>
    <b>{{ "date" | translate }}: </b> {{ emailDetails?.sentDateTime ?? '' }}
  </div>
  <div class="row">
    <div class="col-12">
      <div class="mail-print-delimiter"></div>
    </div>
  </div>

  <div>
    <b>{{ "subject" | translate }}: </b> {{ emailDetails?.subject ?? '' }}
  </div>
  <div>
    <b>{{ "body" | translate }}: </b>
  </div>
  <div [innerHTML]="emailDetails?.body ?? ''"></div>
  <br />
  @if(!!emailDetails?.attachmentFiles?.length) {
    <div>
      <b>{{ "attachments" | translate }}:</b>
    </div>
  }
  @if(!!emailDetails?.attachmentFiles?.length) {
    <div>
      @for(attachment of emailDetails.attachmentFiles; track attachment) {
        <div>
          {{ attachment.fileName }}
        </div>
      }
    </div>
  }
</div>
