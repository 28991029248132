export enum EmailAuditEventType {
  Received = 1,
  ReadyForSend = 2,
  SentError = 3,
  Sent = 4,
  Deleted = 5,
  Restored = 6,
  MarkedAsSpam = 7,
  MarkedAsNotSpam = 8,
  Read = 9,
  Unread = 10,
  Saved = 11,
  Created = 12,
  ReassignedTo = 13,
  Dispatched = 14,
  Forwarded = 15,
  Replied = 16,
  Archived = 17,
  Get = 18,
  Validated = 19,
  MoveToFolder = 20,
  ReadyToApprove = 21,
  Approved = 22,
  ForwardedMultiple = 23,
  Pignor = 24,
  Information = 25,
  ReassignedFrom = 26,
  DispatchigRuleActionError = 27,
  VirusDetected = 28,
  AssignGroups = 29,
  Imported = 30,
}
