import { Routes } from '@angular/router';
import { AccountSettingsComponent } from './pages/account-settings/account-settings.component';
import { DefaultPageComponent } from './pages/default-page/default-page.component';
import { EmailPrintComponent } from './pages/email-print/email-print.component';
import { EmailsContainerComponent } from './pages/emails/emails-container.component';
import { EmailsSideNavComponent } from './components/emails-sidenav/emails-side-nav.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { ErrorNotExistedPageComponent } from './pages/error-not-existed-page/error-not-existed-page.component';
import { MainComponent } from './components/main/main.component';
import { PluginsComponent } from './pages/plugins/plugins.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { DispatchingRuleComponent } from './pages/settings/dispatching-rule/dispatching-rule.component';
import { MailAccountTagsComponent } from './pages/settings/mail-account-tags/mail-account-tags.component';
import { MailAccountTemplatesComponent } from './pages/settings/mail-account-templates/mail-account-templates.component';
import { MailAccountUsersComponent } from './pages/settings/mail-account-users/mail-account-users.component';
import { MailAccountComponent } from './pages/settings/mail-accounts/mail-account.component';
import { OrganizationGeneralSettingsComponent } from './pages/settings/organization-general-settings/organization-general-settings.component';
import { OrganizationGroupsComponent } from './pages/settings/organization-groups/organization-groups.component';
import { OrganizationUsersComponent } from './pages/settings/organization-users/organization-users.component';
import { OrganizationComponent } from './pages/organizations/organization.component';
import { ProviderComponent } from './pages/providers/provider.component';
import { SettingsComponent } from './components/settings/settings.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { WebCodeContextComponent } from './components/web-code-context/web-code-context.component';
import { AuthGuard } from './services/auth/auth.guard';
import { RootAuthGuard } from './services/auth/root.auth.guard';
import { MailAccountGeneralSettingsComponent } from './pages/settings/mail-account-general-settings/mail-account-general-settings.component';
import { MailAccountFoldersSettingsComponent } from './pages/settings/mail-account-folders-settings/mail-account-folders-settings.component';

export const routes: Routes = [
  {
    path: '',
    component: DefaultPageComponent, // +
  },
  {
    path: ':webCode/sign-in',
    component: SignInComponent, // +
  },
  {
    path: ':webCode/403',
    component: ErrorPageComponent, // +
  },
  {
    path: ':webCode/401',
    component: ErrorPageComponent, // +
  },
  {
    path: ':webCode/not-exists',
    component: ErrorNotExistedPageComponent, // +
  },
  {
    path: ':webCode/500',
    component: ErrorPageComponent, // +
  },
  {
    path: 'requesterror',
    component: ErrorPageComponent, // +
  },
  {
    path: ':webCode',
    component: WebCodeContextComponent, // +
    children: [
      {
        path: '',
        component: MainComponent, // +
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: SettingsComponent, // +
            canActivate: [AuthGuard],
            children: [
              {
                path: 'settings/organizations', // +
                component: OrganizationComponent,
                canActivate: [AuthGuard],
              },
              {
                path: 'settings/providers',
                component: ProviderComponent, // +
                canActivate: [RootAuthGuard],
              },
              {
                path: 'settings/organization/:organizationId/general', // +
                component: OrganizationGeneralSettingsComponent,
                canActivate: [AuthGuard],
              },
              {
                path: 'settings/organization/:organizationId/mail-accounts', // +
                component: MailAccountComponent,
                canActivate: [AuthGuard],
              },
              {
                path: 'settings/organization/:organizationId/users', // +
                component: OrganizationUsersComponent,
                canActivate: [AuthGuard],
              },
              {
                path: 'settings/organization/:organizationId/groups', // +
                component: OrganizationGroupsComponent,
                canActivate: [AuthGuard],
              },
              {
                path: 'settings/organization/:organizationId/mail-account/:mailAccountId/general', // +
                component: MailAccountGeneralSettingsComponent,
                canActivate: [AuthGuard],
              },
              {
                path: 'settings/organization/:organizationId/mail-account/:mailAccountId/users', // +
                component: MailAccountUsersComponent,
                canActivate: [AuthGuard],
              },
              {
                path: 'settings/organization/:organizationId/mail-account/:mailAccountId/dispatching-rules', // +
                component: DispatchingRuleComponent,
                canActivate: [AuthGuard],
              },
              {
                path: 'settings/organization/:organizationId/mail-account/:mailAccountId/tags', // +
                component: MailAccountTagsComponent,
                canActivate: [AuthGuard],
              },
              {
                path: 'settings/organization/:organizationId/mail-account/:mailAccountId/templates', // +
                component: MailAccountTemplatesComponent,
                canActivate: [AuthGuard],
              },
              {
                path: 'settings/organization/:organizationId/mail-account/:mailAccountId/folders', // +
                component: MailAccountFoldersSettingsComponent,
                canActivate: [AuthGuard],
              },
            ],
          },
          {
            path: 'emails', // +
            component: EmailsContainerComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'reports', // +
            component: ReportsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'plugins', // +
            component: PluginsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'account-settings', // +
            component: AccountSettingsComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'mail-accounts',
        component: EmailsSideNavComponent, /// ???? need
        canActivate: [AuthGuard],
      },
      {
        path: 'mail-account/:mailAccountId/mail-folder/:mailFolderId/emails-content/:emailId/print',
        component: EmailPrintComponent, // +
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: '**',
    pathMatch: 'full',
    component: ErrorPageComponent, // +
  },
];
