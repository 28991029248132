import { ApiException } from './api.exception';
import { ErrorsPipe } from '@app-pipes/error-code.pipe';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '@app-components/common/error-dialog/error-dialog.component';
import { Injectable } from '@angular/core';
import { LoggingClient } from '../api/clients/logging.client';
import { GlobalErrorHandler } from './error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class MatchError {
  pipeErrors = new ErrorsPipe();
  constructor(
    public dialog: MatDialog,
    private globalErrorHandler: GlobalErrorHandler,
    public loggingClient: LoggingClient
  ) {}

  public errorHandler(error: any): void {
    if (!error.Code) {
      this.openErrorDialog(
        'error',
        error?.Description || 'somethingWentWrongErrorSubtitle'
      );
    } else {
      this.openErrorDialog(
        'error',
        this.pipeErrors.transform(error.Code) ||
          `Error code: ${error.Code}: ${error.Description || 'something went wrong'}`
      );
    }
  }

  public logError(e: any): void {
    if (e instanceof ApiException) {
      if (e.Status === 500) {
        this.globalErrorHandler.handleError(e);
      }
    } else {
      this.globalErrorHandler.handleError(e);
    }
  }

  public openErrorDialog(title: string, description: string): void {
    this.dialog.open(ErrorDialogComponent, {
      width: '400px',
      data: { title, description },
      backdropClass: 'error-backdrop',
      autoFocus: false,
    });
  }
}
