import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './services/auth/authentication.service';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterOutlet,
} from '@angular/router';
import { SnackbarService } from './services/snackbar.service';
import { filter } from 'rxjs/operators';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ApiRequest } from './services/api/api.request';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './services/local-storage.service';
import { STORAGE_NAMES } from './shared/constants/constants';
// import { DrawerComponent } from './components/drawer/drawer.component';
// import { DrawerService } from './services/drawer.service';

const { PRE_RELEASE, USER_LOCALE } = STORAGE_NAMES;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [RouterOutlet, TranslateModule],
  standalone: true,
})
export class AppComponent implements OnInit {
  // @ViewChild(DrawerComponent)
  // set drawer(drawer: DrawerComponent) {
  //   this.drawerService.setDrawerComponent(drawer);
  // }

  previousUrl: string | null = null;
  currentUrl: string | null = null;

  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private snackBarHelper: SnackbarService,
    private apiRequest: ApiRequest,
    public translate: TranslateService,
    private localStorageService: LocalStorageService
  ) {
    translate.addLangs(['it', 'en']);
  }

  async ngOnInit(): Promise<any> {
    if (!this.localStorageService.getData(USER_LOCALE)) {
      this.localStorageService.setData(USER_LOCALE, environment.language);
    }
    if (!this.localStorageService.getData(PRE_RELEASE)) {
      this.localStorageService.setData(PRE_RELEASE, false);
    }
    this.translate.use(this.localStorageService.getData(USER_LOCALE));
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      });
  }
}
