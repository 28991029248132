<div class="drawer-content-screen">
  <div class="drawer-content-screen__title">
    {{ "updateMailAccount" | translate }}
  </div>

  <div class="drawer-content-screen__body">
    <form [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="onSubmit()">
      <mat-form-field>
        <mat-label>{{ "license" | translate }}</mat-label>
        <input matInput id="license" formControlName="license" />
        @if(hasError('license', 'maxlength')) {
          <mat-error>{{ "moreThan255Char" | translate }}</mat-error>
        }
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ "codeSia" | translate }}</mat-label>
        <input matInput id="codeSia" formControlName="codeSia" />
        @if(hasError('codeSia', 'maxlength')) {
          <mat-error>{{ "moreThan255Char" | translate }}</mat-error>
        }
      </mat-form-field>
      <mat-form-field >
        <mat-label>{{ "anagId" | translate }}</mat-label>
        <input matInput id="anagId" formControlName="anagId" />
        @if(hasError('anagId', 'maxlength')) {
          <mat-error>{{ "moreThan255Char" | translate }}</mat-error>
        }
      </mat-form-field>
      <mat-form-field >
        <mat-label>{{ "name" | translate }}</mat-label>
        <input matInput id="name" formControlName="name" required />
        @if(hasError('name', 'required')) {
          <mat-error>{{ "nameIsRequired" | translate }}</mat-error>
        }
        @if(hasError('name', 'maxlength')) {
          <mat-error>{{ "moreThan256Char" | translate }}</mat-error>
        }
      </mat-form-field>
    </form>
  </div>

  <div class="w-full flex justify-end">
    <button mat-flat-button color="primary" [disabled]="isLoading || !form.valid" (click)="onSubmit()">
      <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
    </button>
  </div>
</div>
