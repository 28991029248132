import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@app-environments/environment';
import { API_ROUTES } from '@app-shared/constants/routes/api.routes';
import { ApiRequest } from '../api.request';
import { BaseGetCollectionResponse } from '@app-types/base/base';
import { AuthenticationType } from '@app-types/enums/authentication-type';
import { AuthenticationRequest } from '@app-types/api/authentication';

const API_URL = environment.apiUrl;
const { getAuthenticationTypes } = API_ROUTES.authentication;

@Injectable({
  providedIn: 'root',
})
export class AuthenticationClient {
  constructor(
    private http: HttpClient,
    private apiRequest: ApiRequest
  ) {}

  async getAuthenticationTypes(
    request: AuthenticationRequest
  ): Promise<BaseGetCollectionResponse<AuthenticationType>> {
    return this.apiRequest.get<BaseGetCollectionResponse<AuthenticationType>>(
      `${API_URL}${getAuthenticationTypes}?${request.format()}`
    );
  }
}
