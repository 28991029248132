<div class="drawer-content-screen">
  @if(!isLoadingUsers && !cannotLoadUsers && isUploaded) {
    <div class="drawer-content-screen__title">
     {{ mailAccounts.length > 1 ? ("addMailAccountsToUsers" | translate) : ("addMailAccountToUsers" | translate) }}
    </div>
  }

  @if(isLoadingUsers) {
   <div class="h-full">
     <app-skeleton-form-drawer></app-skeleton-form-drawer>
   </div>
  }

  @if(cannotLoadUsers && !isLoadingUsers) {
    <div class="flex-col-center">
       <app-something-went-wrong
         description="Cannot load the users. Something went wrong"
         (retryEmitter)="fetchUsers()"
         [showPicture]="false"></app-something-went-wrong>
     </div>
  }

  @if(!isLoadingUsers && !cannotLoadUsers && isUploaded) {
    <div class="drawer-content-screen__body">

       <app-checkable-search-filter
        (filterSelectionChange)="onFilterSelectionChange($event)"
        [search]="search"
        (searchChange)="onSearchChange($event)"
      ></app-checkable-search-filter>

      <div class="mt-4 pr-2">
        <table mat-table [dataSource]="dataSource" class="w-full">
          <!-- SELECT COLUMN -->
          <ng-container matColumnDef="select">
            <th class="main-header-select" mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                color="primary"
                [checked]="hasSelected() && isAllSelected()"
                [indeterminate]="hasSelected() && !isAllSelected()"
                (change)="$event ? masterToggle() : null">
              </mat-checkbox>
            </th>
            <td class="main-header-select" mat-cell *matCellDef="let filteredUser">
              <mat-checkbox color="primary" class="mb-1" [(ngModel)]="filteredUser._checked"> </mat-checkbox>
            </td>
          </ng-container>

          <!-- USERNAME COLUMN -->
          <ng-container matColumnDef="user">
            <th class="main-header-select" mat-header-cell *matHeaderCellDef>{{ "name" | translate }}</th>
            <td class="main-header-select" mat-cell *matCellDef="let filteredUser">
              {{ filteredUser.firstName + " " + filteredUser.lastName }}
            </td>
          </ng-container>

          <!-- ROLE COLUMN -->
          <ng-container matColumnDef="role">
            <th class="main-header-select" mat-header-cell *matHeaderCellDef>{{ "role" | translate }}</th>
            <td class="main-header-select" mat-cell *matCellDef="let user">
              <mat-form-field class="w-full">
                <mat-select [(ngModel)]="user.role" required>
                  @for(role of roles; track role) {
                    <mat-option id="user.userId" [value]="role.value">
                      {{ role.name | translate }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>


    <div class="flex justify-end w-full">
      <button mat-flat-button color="primary" [disabled]="isLoading" (click)="onSubmit()">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
      </button>
    </div>
  }
</div>
