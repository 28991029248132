<div class="drawer-content-screen">
  @if(isLoading) {
    <app-skeleton-card-list
      [hasTitle]="true"
      [hasHeader]="false"
      height="171px"
    ></app-skeleton-card-list>
  }

  @if(cannotLoadNotifications) {
    <div class="w-full h-full">
      <app-something-went-wrong
        description="Cannot load notifications"
        [showPicture]="false"
        (retryEmitter)="loadNotifications()"
      ></app-something-went-wrong>
    </div>
  }

  @if(title && !isLoading && !cannotLoadNotifications) {
    <div class="drawer-content-screen__title">
      {{ title | translate }}
    </div>
  }

  @if(!isLoading && !cannotLoadNotifications) {
    <div class="notifications">
      @if(!notifications.length) {
        <div class="w-full">
          <app-no-data></app-no-data>
        </div>
      }
      @for(notification of notifications; track notification) {
        <div class="notification">
          <mat-card [class.row-error]="isRowWithError(notification)" class="cursor-pointer" (click)="emailDetails(notification)">
            <mat-card-header>
              <mat-card-title class="break-words">{{ notification.from }}</mat-card-title>
              @if(notification.subject) {
                <mat-card-subtitle class="break-words">{{ notification.subject }}</mat-card-subtitle>
              }
            </mat-card-header>
            <mat-card-content class="break-words">
              <div class="card-table-row">
                <div class="card-table-row-header">{{ "date" | translate }}</div>
                <div class="card-table-row-value">{{ notification.sentDateTime | date: "dd/MM/yyyy HH:mm" }}</div>
              </div>
              <div class="card-table-row">
                <div class="card-table-row-header">{{ "state" | translate }}</div>
                <div class="card-table-row-value">{{ notification.type | emailType | translate }}</div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      }
    </div>
  }
</div>
