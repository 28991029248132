import { BaseGetRequest } from '../base/base-get.request';

export class AuthenticationRequest extends BaseGetRequest {
  webCode: string;

  constructor(webCode: string) {
    super();
    this.webCode = webCode;
  }
}
