export const REPORTS = [
  {
    name: 'reportType6',
    value: 6,
  },
  {
    name: 'reportType7',
    value: 7,
  },
  {
    name: 'reportType1',
    value: 1,
  },
  {
    name: 'reportType2',
    value: 2,
  },
  {
    name: 'reportType3',
    value: 3,
  },
  {
    name: 'reportType4',
    value: 4,
  },
  {
    name: 'reportType5',
    value: 5,
  },
  {
    name: 'reportType8',
    value: 8,
  },
];
