import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { UserGroupClient } from '@app-services/api/clients/user-group.client';
import { UserOrganizationClient } from '@app-services/api/clients/user-organization.client';
import { BaseGetByIdRequest } from '@app-types/base/base';
import { Group } from '@app-types/api/group';
import { UserOrganizationGetOrganizationUsersContract } from '@app-types/api/user-organization';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { SomethingWentWrongComponent } from '../../../../common/error/something-went-wrong/something-went-wrong.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LoadingButtonComponent } from '../../../../common/loading-button/loading-button.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { FilterPipe } from '@app-pipes/filter.pipe';
import { MatSelectModule } from '@angular/material/select';
import { DrawerService } from '@app-services/drawer.service';
import { CheckableSearchFilterComponent } from '../../../../common/сheckable-search-filter/сheckable-search-filter.component';
import { SkeletonFormDrawerComponent } from '@app-components/common/skeletons/skeleton-form-drawer/skeleton-form-drawer.component';

interface UserCheckboxes extends UserOrganizationGetOrganizationUsersContract {
  _checked: boolean;
}

@Component({
  selector: 'app-add-groups-to-users-form',
  templateUrl: './add-groups-to-users-form.component.html',
  styleUrls: ['./add-groups-to-users-form.component.scss'],
  standalone: true,
  imports: [
    SomethingWentWrongComponent,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    LoadingButtonComponent,
    MatCheckboxModule,
    SkeletonFormDrawerComponent,
    TranslateModule,
    FilterPipe,
    CheckableSearchFilterComponent,
  ],
})
export class AddGroupsToUsersFormComponent implements OnInit, OnDestroy {
  @Input() public groups: Group[];
  @Input() public organizationID: number;
  @Output() private closeDrawer = new EventEmitter();
  @Output() private submitDrawer = new EventEmitter();
  public cannotLoadUsers = false;
  public isLoading = false;
  public isLoadingUsers = false;
  public isUploaded = false;
  public search = '';
  public users: UserCheckboxes[] = [];
  public filteredUsersByAdvancedSettings: UserCheckboxes[] = [];
  public filteredUsers: UserCheckboxes[] = [];

  constructor(
    private userOrganizationClient: UserOrganizationClient,
    private permissionService: PermissionService,
    public matchError: MatchError,
    private userGroupClient: UserGroupClient,
    public dialog: MatDialog,
    private drawerService: DrawerService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.fetchUsers();
  }

  public async onSubmit(): Promise<void> {
    this.isLoading = true;
    this.drawerService.disabledDrawer(true);
    const sourceIds = this.groups.map(group => group.groupId);
    const toIds = this.users
      .filter(user => user._checked)
      .map(user => user.userId);
    this.filteredUsersByAdvancedSettings.forEach(e => {
      if (e._checked) {
        toIds.push(e.userId);
      }
    });
    try {
      await this.userGroupClient.setGroupsUsers({
        sourceIds,
        toIds,
      });
      this.drawerService.disabledDrawer(false);
      this.submitDrawer.emit();
      this.drawerService.closeDrawer();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
      this.drawerService.disabledDrawer(false);
    } finally {
      this.isLoading = false;
    }
  }

  async fetchUsers(): Promise<void> {
    this.cannotLoadUsers = false;
    this.isUploaded = false;
    const loaderTimeout = setTimeout(() => {
      this.isLoadingUsers = true;
    }, 500);

    const request = new BaseGetByIdRequest(this.organizationID);
    try {
      const res =
        await this.userOrganizationClient.getOrganizationUsers(request);

      if (this.groups.length === 1) {
        const groupUsers = await this.getGroupUsers();
        this.users = res.organizationUsers.map(user => {
          return {
            ...user,
            _checked: groupUsers.includes(user.userId),
            name: user.firstName + ' ' + user.lastName,
          };
        });
      } else {
        this.users = res.organizationUsers.map(user => {
          return {
            ...user,
            _checked: false,
            name: user.firstName + ' ' + user.lastName,
          };
        });
      }

      this.users = this.users.filter(e => {
        if (this.permissionService.hasPermissionToSeeUserByUserEmail(e.email)) {
          return true;
        } else {
          this.filteredUsersByAdvancedSettings.push(e);
          return false;
        }
      });

      this.filteredUsers = this.users;
    } catch (e) {
      this.cannotLoadUsers = true;
      this.matchError.logError(e);
    } finally {
      clearTimeout(loaderTimeout);
      this.isUploaded = true;
      this.isLoadingUsers = false;
    }
  }

  private async getGroupUsers() {
    const req = new BaseGetByIdRequest(this.groups[0].groupId);
    const groupUsers = await this.userGroupClient.getGroupUsers(req);
    return groupUsers.data;
  }

  isAllSelected(): boolean {
    const numSelected = this.users.filter(user => user._checked).length;
    const numRows = this.users.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected()
      ? this.users.forEach(user => (user._checked = false))
      : this.users.forEach(user => (user._checked = true));
  }

  public hasSelected(): boolean {
    let hasSelected = false;
    for (let i = 0; i < this.users.length; i++) {
      if (this.users[i]._checked) {
        hasSelected = true;
        break;
      }
    }
    return hasSelected;
  }

  onFilterSelectionChange(value: number): void {
    this.filteredUsers =
      value < 0
        ? this.users
        : this.users.filter(user =>
            value === 0 ? !user._checked : user._checked
          );
  }

  onSearchChange($event: string): void {
    this.search = $event;
  }

  ngOnDestroy(): void {
    this.closeDrawer.emit();
  }
}
