<div>
  <form [formGroup]="form">
    <mat-form-field  class="mat-input-without-padding">
      <mat-label>{{ "folder" | translate }}</mat-label>
      <mat-select formControlName="folder" required (selectionChange)="setFolderValue($event.value)">
        @for(folder of availableLocalFolders; track folder) {
          <mat-option [value]="folder.mailFolderId">{{
            folder?.creationType === 1 ? ("folderType" + folder?.folderType | translate) : folder.name
          }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </form>
</div>
