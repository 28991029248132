<div class="flex items-center">
  @if(pageSize) {
    <div class="per-page-block">
     <mat-form-field>
       <mat-select class="text-center" [value]="pageSize" (valueChange)="onChangePageSize($event)">
          @for(pageSizeOption of pageSizeOptions; track pageSizeOption) {
            <mat-option [value]="pageSizeOption">{{ pageSizeOption }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  }

  <div class="range-buttons">
    <div class="flex items-center">
      @if(countTotal) {
        <div class="hidden lg:block mt-[6px] min-w-[100px] text-center text-[14px]">
          {{ countFrom + " - " + countTo + (" " + ("of" | translate) + " ") + countTotal }}
        </div>
      }
      <button
        mat-icon-button
        matTooltip="{{ 'previousPage' | translate }}"
        (click)="previous()"
        [disabled]="!hasPreviousPage"
      >
        <mat-icon>navigate_before</mat-icon>
      </button>
      <button
        mat-icon-button
        matTooltip="{{ 'nextPage' | translate }}"
        [class.disabled-mat-paginator-icon]="!hasNextPage"
        (click)="next()"
        [disabled]="!hasNextPage">
        <mat-icon>navigate_next</mat-icon>
      </button>
    </div>
  </div>
</div>
