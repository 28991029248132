import { Component, Input, OnInit } from '@angular/core';
import { BaseGetByIdRequest } from '@app-types/base/base';
import { MatDialog } from '@angular/material/dialog';
import { EmailClient } from '@app-services/api/clients/email-client';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { SomethingWentWrongComponent } from '../../common/error/something-went-wrong/something-went-wrong.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-email-meta',
  templateUrl: './email-meta.component.html',
  styleUrls: ['./email-meta.component.scss'],
  standalone: true,
  imports: [
    SomethingWentWrongComponent,
    TranslateModule,
    NgxSkeletonLoaderModule,
  ],
})
export class EmailMetaComponent implements OnInit {
  @Input() emailId: number;
  @Input() title: string;
  public isLoading = false;
  public cannotLoadMeta = false;
  public meta: string;

  constructor(
    public matchError: MatchError,
    public dialog: MatDialog,
    private emailClient: EmailClient
  ) {}

  async ngOnInit(): Promise<void> {
    await this.getEmailDetail();
  }

  async getEmailDetail(): Promise<void> {
    this.isLoading = true;
    this.cannotLoadMeta = false;
    const request = new BaseGetByIdRequest(this.emailId);
    try {
      const result = await this.emailClient.getById(request);

      this.meta = result.email.meta;
    } catch (e) {
      this.cannotLoadMeta = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
